import {
  // DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { 
  Button, 
  Col, 
  Form, 
  Input, 
  message, 
  Row, 
  Select, 
  Switch,
  Tabs,
  Card,
  Table,
  Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { GlobalState } from "context-api";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import React, { useContext, useEffect, useState } from "react";
import MultipleImageUploader from "components/MultipleImageUploader";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router";
import {
  addLabPackageFaqs,
  deleteLabPackageFaqs,
  getLabPackageDetails,
  getLabPackageFaqs,
  GetLabTests,
  GetPackageCategories,
  updateLabPackageFaqs,
  UpdateLabPackages,
} from "Utils/Services/Lab/LabPackages";
import { validateMessages } from "Utils/validations";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { getReadableFileSize } from "Utils/GetReadableFileSize";

const EditLabPackage = (props) => {
  const packageId = props.match.params.package_id;
  const [data, setData] = useState(null);
  const { setHeading, Heading } = useContext(GlobalState);
  const [availableTests, setAvailableTests] = useState(null);
  const [categories, setCategories] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [packageGalleryFiles, setPackageGalleryFiles] = useState([]);
  const [packageGallery, setPackageGallery] = useState([]);
  const [packageImageMain, setPackageMainImage] = useState(null);
  const [NewImage, setNewImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");

  // start of Faqs
  const [isAddFaq, setIsAddFaq] = useState(false);
  const [isEditFaq, setIsEditFaq] = useState(false);
  const [faqs,setFaqs] = useState([]);
  const [faq, setFaq] = useState({
    title:"",
    description:"",
  });

  const handleToggleIsAddFaq = () => {
    setIsAddFaq(prev=>!prev)
  };

  const handleToggleIsEditFaq = () => {
    setIsEditFaq(prev=>!prev);
  }

  const handleConfirmAddFaq = () => {
    setFaq({
      id: "",
      title: "",
      description: ""
    });
    handleToggleIsAddFaq();
  }

  const handleConfirmEditFaq = async (row) => {
    handleToggleIsEditFaq();
    setFaq({
      id:row.id, 
      title:row.title,
      description:row.description
    });
  }

  const FetchPackagesFaqs = async () => {
    const faqsResponse = await getLabPackageFaqs({id:packageId});
    faqsResponse && setFaqs(faqsResponse);
  };

  const handleAddFaq = async (values) => {
    try{
        const payload = {
            "package_id": packageId,
            "title":values.title.replace(/'/g, "''"),
            "description":values.description.replace(/'/g, "''"),
        };
        const response = await addLabPackageFaqs(payload);
        if(response?.status == 200){
            //message.success(response.data.message);
            AntdCustomMessage({
              type: antdMessageTypes.SUCCESS,
              content: response.data.message
            });
            handleToggleIsAddFaq();
            FetchPackagesFaqs();
        }else{
            //message.error(response.message);
            AntdCustomMessage({
              type: antdMessageTypes.ERROR,
              content: response.message
            });
        }
    }catch(e){
        //message.error(e.message || "Something went wrong.")
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: e.message || "Something went wrong"
        });
    }    
  }
  
  const handleUpdateFaq = async (values) => {
    try{
        const payload = {
          "id": values.id,
          "title":values.title.replace(/'/g, "''"),
          "description":values.description.replace(/'/g, "''"),
          "active":"1",
        };
        const response = await updateLabPackageFaqs(payload);
        if(response?.status == 200){
            //message.success(response.data.message);
            AntdCustomMessage({
              type: antdMessageTypes.SUCCESS,
              content: response.data.message
            });
            window.location.reload();
        }else{
            //message.error(response.message);
            AntdCustomMessage({
              type: antdMessageTypes.ERROR,
              content: response.message
            });
        }
    }catch(e){
        //message.error(e.message || "Something went wrong.")
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: e.message || "Something went wrong."
        });
    }    
  }

  const handleDeleteFaq = async (row) => {
    try{
        const deleteResponse = await deleteLabPackageFaqs({id: row.id});
        //message.success(deleteResponse.data);
        AntdCustomMessage({
          type: antdMessageTypes.SUCCESS,
          content: deleteResponse.data
        });
        window.location.reload();
    }catch(e){
        //message.error(e.message || e.data || "Something went wrong.")
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: e.message || e.data || "Something went wrong."
        });
    }
    
  };

  const columns = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (_,row) =>{
            return row.id
        },
    },
    {
        title: 'Question',
        dataIndex: 'title',
        key: 'title',
        render: (_,row) =>{
            return row.title
        },
    },
    {
        title: 'Answer',
        dataIndex: 'description',
        key: 'description',
        render: (_,row) =>{
            return row.description
        },
    },
    {
      title: 'Edit',
      key: 'action',
      render: (_, row) => (
        <Popconfirm
          title={`Are you sure you want to edit this faq?`}
          onConfirm={() => {
            handleConfirmEditFaq(row);
          }}
          okText="Yes"
          cancelText="No"
        >
          <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
    {
        title: 'Delete',
        key: 'action',
        render: (_, row) => (
          <Popconfirm
            title={`Are you sure you want to delete this faq?`}
            onConfirm={() => handleDeleteFaq(row)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        ),
      }
]
/////end of faqs


  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Edit Lab Packages");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    const fetchData = async () => {
      const labTestsResponse = await GetLabTests();
      const packageCategoriesResponse = await GetPackageCategories();
      packageCategoriesResponse && packageCategoriesResponse?.status === 200 &&
        setCategories(packageCategoriesResponse?.data?.packagecategories);
      labTestsResponse && labTestsResponse?.status === 200 &&
        setAvailableTests(labTestsResponse?.data?.labtests);

      const packageDetailsResponse = await getLabPackageDetails({
        package_id: +packageId,
      });

      if(packageDetailsResponse){
        const contentState = stateFromHTML(
          packageDetailsResponse?.pakage[0]?.about_package
        );
        setEditorState(EditorState.createWithContent(contentState));
  
        let packageDetails = packageDetailsResponse?.pakage[0];
        setPackageMainImage(packageDetails.package_image);
        setData({
          ...packageDetails,
          package_category_id: packageDetails.package_category_id?.split(","),
          available_tests: packageDetails.available_tests?.split(","),
          sample_at_home: packageDetails.sample_at_home === "0" ? false : true,
        });
      }
    };

    if (packageId) {
      fetchData();
      FetchPackagesFaqs();
    }
  }, [packageId]);

  const onFinish = async (values) => {
    if (!packageImageMain) {
      //message.error("Package image is empty");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Package image is empty"
      });
      return;
    }
    setIsLoading(true);
    const response = await UpdateLabPackages(
      {
        package_id: +packageId,
        ...values,
        discount: values.discount,
        about_package: stateToHTML(editorState.getCurrentContent()),
        package_category_id: values.package_category_id.join(","),
        available_tests: values.available_tests.join(","),
        sample_at_home: values.sample_at_home ? "1" : "0",
        ...{ package_image: NewImage ? NewImage : packageImageMain },
        package_gallery: packageGallery.join(","),
      },
      packageGalleryFiles
    );
    if (response?.status === 200) {
      //message.success("Lab package updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Lab package updated successfully"
      });
    }
    setIsLoading(false);
  };


  useEffect(() => {
    if (window.location.href.includes("tab")) {
      const tab = window.location.href?.split("tab=")[1];
      setActiveTab(tab);
    }
  }, []);

  //save current tab id in url
  const onTabChange = (key) => {
    window.history.pushState(
      null,
      null,
      `/lab/lab-packages/edit/${packageId}?tab=${key}`
    );
    setActiveTab(key);
  };

  return data ? (
    <div className="content">
      <Button onClick={() => history.push('/lab/lab-packages')} style={{ marginBottom: "10px" }}>
        Back
      </Button>
      <Card>
      <Tabs
          type="card"
          activeKey={activeTab}
          animated={true}
          tabBarGutter={6}
          onChange={onTabChange}
        >
          <TabPane tab="Package Info" key="1">
          <div className="procedure-container">
            <Form
              layout="vertical"
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
              initialValues={data}
            >
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["package_name"]}
                    label="Package Name"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item name={["promotion_text"]} label="Promotion Text">
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["available_tests"]}
                    label="Package Tests"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      size="large"
                      placeholder="Please select tests"
                      style={{ width: "100%" }}
                      mode="multiple"
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    >
                      {availableTests?.map((item) => (
                        <Select.Option key={item.test_id}>
                          {item.test_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} lg={6}>
                  <Form.Item
                    name={["price"]}
                    label="Price"
                    rules={[
                      { required: true },
                      {
                        validator: (rule, value) => {
                          if (value < 0) {
                            return Promise.reject("Price can't be negative");
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input size="large" type="number" />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} lg={6}>
                  <Form.Item name={["discount"]} label="Discount">
                    <Input size="large" type="number" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["package_category_id"]}
                    label="Package Category"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      size="large"
                      placeholder="Please select tests"
                      style={{ width: "100%" }}
                      mode="multiple"
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    >
                      {categories?.map((item) => (
                        <Select.Option key={item.id}>
                          {item.categoryname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["sample_at_home"]}
                    label="Sample collection at Home"
                    valuePropName="checked"
                  >
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="About Package">
                    <Editor
                      editorStyle={{
                        border: "1px solid #D9D9D9",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(value) => {
                        setEditorState(value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={20}>
                  <p className="font-weight-bold">Package Gallery </p>
                  <Form.Item style={{ marginTop: "5px" }}>
                    <MultipleImageUploader
                      existingImages={
                        data.package_gallery?.split(",")?.length
                          ? data.package_gallery?.split(",")
                          : []
                      }
                      onChange={(images) => {
                        setPackageGallery(images.oldImages);
                        setPackageGalleryFiles(images.newImages);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={20}>
                  <p className="font-weight-bold">Package Image </p>
                  <p style={{marginTop:0}}>
                Updating file size should be between 
                <span className="font-weight-bold text-black"> 
                  &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} 
                </span>
                &nbsp;and 
                <span className="font-weight-bold text-black">
                  &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}
                </span>.
              </p>
                  <div style={{ display: "flex" }}>
                    {
                      <img
                        alt="lab package"
                        style={{
                          width: "80px",
                          height: "80px",
                          marginLeft: "10px",
                          objectFit: "cover",
                        }}
                        src={
                          NewImage
                            ? URL.createObjectURL(NewImage)
                            : packageImageMain
                        }
                      />
                    }
                  </div>
                  <Form.Item style={{ marginTop: "5px" }}>
                    <input
                      name="clinic_images"
                      style={{ display: "none" }}
                      type="file"
                      accept="image/jpeg,image/png"
                      id="file-uploader2"
                      onChange={(event) => {
                        //check image size here
                        const file = event?.target?.files[0];
                        const fileSize = file?.size;
                        if(
                          fileSize < Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES) || 
                          fileSize > Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)
                        ){
                          AntdCustomMessage({
                            type : antdMessageTypes.ERROR,
                            content : `Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
                              getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} and ${
                              getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}.`,
                          });
                          return;
                        }else{
                          setNewImage(event?.target?.files[0])
                        }
                      }}  
                    />
                    <label
                      htmlFor="file-uploader2"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "white",
                        padding: "5px",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        border: "1px solid #e8e8e8",
                        borderRadius: "7px",
                      }}
                    >
                      <PlusOutlined />
                      Upload image
                    </label>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          </div>
          </TabPane>
          <TabPane tab="FAQs" key="2">
          {
          
          (isAddFaq || isEditFaq) ? (
              <Card title={`${isEditFaq ? "Update" : "Add"} Faq Form`}>
                <Form
                  layout="vertical"
                  name="nest-messages"
                  onFinish={isEditFaq ? handleUpdateFaq : handleAddFaq}
                  initialValues={faq}
                >
                  {
                    isEditFaq && faq.id && 
                    <Row gutter={{ sm: 8, lg: 24 }}>
                      <Col xs={24} sm={24} lg={12}>
                          <Form.Item
                          name={["id"]}
                          label="FaqID"
                          rules={[{ required: true }]}
                          >
                          <Input size="large" disabled/>
                          </Form.Item>
                      </Col>
                      </Row>
                  }
                  <Row gutter={{ sm: 8, lg: 24 }}>
                  <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                      name={["title"]}
                      label="Question"
                      rules={[{ required: true }]}
                      >
                      <Input size="large"/>
                      </Form.Item>
                  </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["description"]}
                        label="Answer"
                        rules={[{ required: true }]}
                        >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                
                  <Row>
                    <Button type="primary" danger onClick={isEditFaq ? handleToggleIsEditFaq : handleConfirmAddFaq}>Cancel</Button>
                    <Button type="primary" style={{marginLeft:"1rem"}} htmlType="Submit">{isEditFaq ? "Update":"Add"}</Button>
                  </Row>
                </Form>
              </Card>
          ):(
            <Card>
              <div className="mb-5">
                  <Button onClick={handleToggleIsAddFaq}>Add Faq</Button>
              </div>
              {
                faqs.length ? (
                  <Card title="List of Faqs">
                    <Table columns={columns} dataSource={faqs} />
                  </Card>
                ) : null
              }
            </Card>
          )
        }
          </TabPane>
        </Tabs>
      </Card>
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default EditLabPackage;
