import { Button } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { getReadableFileSize } from "Utils/GetReadableFileSize";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const MultipleImageUploader = ({
  existingImages = [],
  onChange = () => {},
}) => {
  const [newImageFiles, setNewImageFiles] = useState([]);
  const [oldImageUrls, setOldImageUrls] = useState([
    ...existingImages.map((item) => ({ image: item })),
  ]);
  const fileRef = useRef(null);

  useEffect(() => {
    onChange({
      newImages: newImageFiles.map((item) => item.image),
      oldImages: oldImageUrls.map((item) => item.image),
    });
  }, [newImageFiles, oldImageUrls]); //eslint-disable-line

  const deletePackageGalleryImage = (imageToDelete) => {
    if (typeof imageToDelete.image === "string")
      setOldImageUrls(
        oldImageUrls.filter((image) => image.image !== imageToDelete.image)
      );
    else
      setNewImageFiles(
        newImageFiles.filter((image) => image.name !== imageToDelete.name)
      );
  };

  return (
    <>
      <div className="multiple-image-selector">
        {[...oldImageUrls, ...newImageFiles].map((item, index) => (
          <div
            key={index}
            style={{ position: "relative", width: "104px", height: "104px" }}
          >
            <img
              alt="gallery"
              key={index}
              src={
                typeof item.image === "string"
                  ? item.image
                  : URL.createObjectURL(item.image)
              }
            />
            <Button
              className="image-poper"
              onClick={() => deletePackageGalleryImage(item)}
            >
              <CloseOutlined color="#d9d9d9" />
            </Button>
          </div>
        ))}
        <input
          ref={fileRef}
          name="clinic_images"
          style={{ display: "none" }}
          type="file"
          multiple
          accept="image/jpeg,image/png"
          id="file-uploader"
          onChange={(event) => {
            let files = event.target.files;
            //check image size here
            const file = event?.target?.files[0];
            const fileSize = file?.size;
            if(
              event?.target?.files?.length &&
              (fileSize < Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES) || 
              fileSize > Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))
            ){
              AntdCustomMessage({
                type : antdMessageTypes.ERROR,
                content : `Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
                  getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} and ${
                  getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}.`,
              });
              return;
            }else{
              files = [...files].map((item) => ({ name: nanoid(), image: item }));
            //to pick same image again
            fileRef.current.value = null;
            setNewImageFiles((old) => [...old, ...files]);
            } 
            
          }}
        />
        <label
          htmlFor="file-uploader"
          style={{
            width: "104px",
            height: "125px",
            textAlign: "center",
            verticalAlign: "top",
            backgroundColor: "#fafafa",
            border: "1px dashed #d9d9d9",
            borderRadius: "2px",
            cursor: "pointer",
            transition: "border-color .3s",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <PlusOutlined style={{fontWeight: "bold"}}/>
            Upload image between       
            <span className="font-weight-bold text-black"> 
              &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} 
            </span>
            &nbsp;and 
            <span className="font-weight-bold text-black">
              &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}
            </span>          
          </div>
        </label>
        
      </div>
    </>
  );
};

export default MultipleImageUploader;
