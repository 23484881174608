import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { getLabPackagesAppointmentsChartData } from "Utils/Services/Lab/Dashboard";
import { getDashboardCounts } from "Utils/Services/Lab/Dashboard";
import { getLabAppointments } from "Utils/Services/Lab/Appointments";
import AppointmentsIcon from "Images/dashboard-icons/appointment.svg";
// import ReviewIcon from "Images/dashboard-icons/review.svg";
import PackagesIcon from "Images/dashboard-icons/procedure.png";
import PatientsIcon from "Images/dashboard-icons/patient.svg";
import { DatePicker } from "antd";
import CardMetrics from "views/components/dashboard/CardMetrics";
import { isMobile } from "react-device-detect";
import LabAppointmentStatusChart from "views/components/dashboard/LabAppointmentStatusChart";
import TodaysLabAppointmentsList from "views/components/dashboard/TodaysLabAppointmentsList";
// import TodaysLabReviewsList from "views/components/dashboard/TodaysLabReviewsList"
import { DateRanges, DATE_FORMAT } from "Utils/Constants";
import moment from "moment";
import LabPaymentsTypeChart from "views/components/dashboard/LabPaymentsTypeChart";
// import { getLabReviews } from "Utils/Services/Lab/LabReviews";
import OnlinePaymentsIcon from "Images/dashboard-icons/hospital/online.png";
import LabPackagesAppointmentsChart from "views/components/dashboard/LabPackagesAppointmentsChart";
import { GlobalState } from "context-api";

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const { LabRefreshPage } = useContext(GlobalState);
  const INIT_DATE_RANGE = [
    moment().subtract(7, "days").startOf("day"),
    moment().endOf("day"),
  ];

  const [ChartDateRange, setChartDateRange] = useState([...INIT_DATE_RANGE]);

  const [CardCounts, setCardCounts] = useState({
    appointments: "",
    patients: "",
    labPackages: "",
    onlinePackageRevenue: "",
    reviews: "",
  });

  const [AppointmentsByStatus, setAppointmentsByStatus] = useState({
    pending: 0,
    Todays: 0,
    completed: 0,
    other: 0,
    cancelled: 0,
  });

  const [PaymentsByTypes, setPaymentsByTypes] = useState({
    online: 0,
    offline: 0,
  });

  const [
    LabPackagesAppointmentsChartData,
    setLabPackagesAppointmentsChartData,
  ] = useState("");
  const [TodaysLabAppointments, setTodaysLabAppointments] = useState("");
  const [TodaysLabAppointmentsLoading, setTodaysLabAppointmentsLoading] =
    useState(false);
  // const [TodaysLabReviews, setTodaysLabReviews] = useState("");
  // const [TodaysLabReviewsLoading, setTodaysLabReviewsLoading] =
  //   useState(false);
  const [CountsLoading, setCountsLoading] = useState(false);

  const onChangeDate = (values) => {
    if (values && values.length > 0) {
      const [startDate, endDate] = values;
      setChartDateRange([moment(startDate), moment(endDate)]);
    } else {
      setChartDateRange([...INIT_DATE_RANGE]);
    }
  };

  const FetchDashboardcounts = async () => {
    setCountsLoading(true);
    const response = await getDashboardCounts();
    response && setCardCounts((counts) => ({
      ...counts,
      appointments: response?.totalMyAppointments,
      patients: response?.totalMyPatients,
      labPackages: response?.totalMyLabPackages,
      reviews: response?.totalMyReviews,
      onlinePackageRevenue: response?.onlineLabAppointmentRevenue,
    }));

    response && setAppointmentsByStatus((counts) => ({
      ...counts,
      pending: response.appointmentStatus.pending,
      approved: response.appointmentStatus.approved,
      completed: response.appointmentStatus.completed,
      cancelled: response.appointmentStatus.cancelled,
    }));
    response && setPaymentsByTypes((counts) => ({
      ...counts,
      online: response.paymentsTypes.online,
      offline: response.paymentsTypes.offline,
    }));
    setCountsLoading(false);
  };

  const FetchTodaysLabAppointments = async () => {
    setTodaysLabAppointmentsLoading(true);
    const appointment_status = "";
    const startDate = moment().startOf("day").format("YYYY-MM-DD");
    const endDate = moment().endOf("day").format("YYYY-MM-DD");
    const limit = 1000;
    const search = "";
    const start = 0;

    const response = await getLabAppointments({
      start: start,
      limit: limit,
      appointment_status: appointment_status,
      search: search,
      startDate: startDate,
      endDate: endDate,
    });
    response && setTodaysLabAppointments(response);
    setTodaysLabAppointmentsLoading(false);
  };

  // const FetchTodayLabReviews = async () => {
  //   setTodaysLabReviewsLoading(true);

  //   const startDate = moment().startOf("day").format("YYYY-MM-DD");
  //   const endDate = moment().endOf("day").format("YYYY-MM-DD");
  //   const limit = 1000;

  //   const response = await getLabReviews({
  //     start: 0,
  //     limit: limit,
  //     startDate: startDate,
  //     endDate: endDate,
  //     search: "",
  //   });
  //   setTodaysLabReviews(response);
  //   setTodaysLabReviewsLoading(false);
  // };
  const FetchChartData = async () => {
    let payload = {
      startDate: moment(ChartDateRange[0]).format("YYYY-MM-DD"),
      endDate: moment(ChartDateRange[1]).format("YYYY-MM-DD"),
    };
    const response = await getLabPackagesAppointmentsChartData(payload);
    response && setLabPackagesAppointmentsChartData(
      response.graphdata_lab_packages_appointment
    );
  };

  useEffect(() => {
    FetchDashboardcounts();
    FetchTodaysLabAppointments();
    // FetchTodayLabReviews();
  }, [LabRefreshPage]); // eslint-disable-line

  useEffect(() => {
    FetchChartData();
  }, [ChartDateRange, LabRefreshPage]); // eslint-disable-line

  // useEffect(() => {
  //   if (!!socket && socket.connected && UserData?.type === "hospital") {
  //     socket.on(`${UserData?.email || ""}_lab_appointment`, (data) => {
  //       FetchDashboardcounts();
  //       FetchTodaysLabAppointments();
  //       FetchChartData();
  //     });
  //   }

  //   return () => {
  //     !!socket && socket.connected && socket.removeAllListeners();
  //   };
  // }, [socket]); // eslint-disable-line

  return (
    <>
      <div className="content">
        <Row>
          <Col md={6} lg={4}>
            <CardMetrics
              col={4}
              CountsLoading={CountsLoading}
              isAppointments={true}
              heading="Appointments"
              stat={CardCounts?.appointments}
              icon={AppointmentsIcon}
              route="appointments"
            />
            <CardMetrics
              col={4}
              CountsLoading={CountsLoading}
              heading="Lab Packages"
              stat={CardCounts?.labPackages}
              icon={PackagesIcon}
              route="lab-packages"
            />
            <CardMetrics
              col={4}
              CountsLoading={CountsLoading}
              heading="Patients"
              stat={CardCounts.patients}
              icon={PatientsIcon}
              route="patients"
            />
            {/* <CardMetrics
            CountsLoading={CountsLoading}
            heading="Reviews"
            stat={CardCounts?.reviews}
            icon={ReviewIcon}
            route="reviews"
          /> */}
            <CardMetrics
              col={4}
              CountsLoading={CountsLoading}
              heading="Online collection for Package appointments"
              stat={
                CardCounts?.onlinePackageRevenue === null
                  ? 0
                  : "₹" + (CardCounts?.onlinePackageRevenue && CardCounts?.onlinePackageRevenue?.toLocaleString("en"))
              }
              icon={OnlinePaymentsIcon}
              route="online-payments"
            />
          </Col>
          <Col md={6} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Today's Appointments</CardTitle>
              <span className="card-header-count">
                {TodaysLabAppointments?.total_count}
              </span>
              <CardBody style={{ padding: "0px" }}>
                <TodaysLabAppointmentsList
                  TodaysLabAppointments={TodaysLabAppointments?.appointments}
                  FetchTodaysLabAppointments={FetchTodaysLabAppointments}
                  TodaysLabAppointmentsLoading={TodaysLabAppointmentsLoading}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Status</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <LabAppointmentStatusChart
                  AppointmentsByStatus={AppointmentsByStatus}
                />
              </CardBody>
            </Card>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Payments by Type</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <LabPaymentsTypeChart PaymentsByTypes={PaymentsByTypes} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={6}></Col>
          {/* <Col md={6} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Today's Reviews</CardTitle>
              <span className="card-header-count">
                {TodaysLabReviews?.total_count}
              </span>
              <CardBody style={{ padding: "0px" }}>
                <TodaysLabReviewsList
                  TodaysLabReviews={TodaysLabReviews?.reviews}
                  TodaysLabReviewsLoading={TodaysLabReviewsLoading}
                />
              </CardBody>
            </Card>
          </Col> */}
          <Col md={12} lg={6}></Col>
        </Row>

        <Row>
          <Col md={12} style={{ textAlign: "right" }}>
            <RangePicker
              dropdownClassName="range-dropdown"
              className="shadow-picker"
              style={{
                width: isMobile ? "100%" : "450px",
                marginBottom: "15px",
              }}
              value={ChartDateRange}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => onChangeDate(e)}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
          <Col md={12} lg={12}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Lab Packages</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <LabPackagesAppointmentsChart
                  LabPackagesAppointmentsChartData={
                    LabPackagesAppointmentsChartData
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
