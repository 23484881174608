import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import {
  getDateAfterAddDays,
  subscriptionDurations,
  LAB_PACKAGE_COST_PER_MONTH,
  LAB_TRIAL_DAYS,
  getPriceAfterDiscountPercentage,
  getDiscountPriceAfterDiscountPercentage,
} from "Utils/Constants";
import RazorpayPayment from "Utils/RazorpayPayment";
import { checkCouponCode } from "Utils/Services/Lab/Subscription";
import SuccessIcon from "Images/icons/success.png";
import FailureIcon from "Images/icons/failure.png";
const ActivateSubscription = ({ FetchBillingHistory, SubscriptionData }) => {
  const [Duration, setDuration] = useState('{"value":90,"label":"3 months"}');
  const [Quota, setQuota] = useState(
    SubscriptionData?.subscriptionQuota?.quotaUsed > 1
      ? SubscriptionData?.subscriptionQuota?.quotaUsed
      : 1
  );
  const [Step, setStep] = useState(1);
  const [CouponCode, setCouponCode] = useState("");
  const [CouponButtonLoading, setCouponButtonLoading] = useState(false);
  const [CouponApplied, setCouponApplied] = useState(false);
  const [CouponError, setCouponError] = useState("");
  const [DiscountPercentage, setDiscountPercentage] = useState(0);
  const [ShowPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [ShowPaymentFailure, setShowPaymentFailure] = useState(false);

  const ApplyCouponHandler = async () => {
    if (CouponCode.length === 0) {
      setCouponError("Coupon code is empty");
      setTimeout(() => {
        setCouponError("");
      }, 1000);
      return;
    }
    setCouponButtonLoading(true);
    const response = await checkCouponCode({ couponcode: CouponCode });
    if (response?.valid) {
      setDiscountPercentage(response.percentage);
      setCouponApplied(true);
      setCouponButtonLoading(false);
    } else {
      setCouponError("Coupon code is not valid");
      setTimeout(() => {
        setCouponError("");
      }, 1000);
      setCouponButtonLoading(false);
      return;
    }
  };

  const RemoveCouponHandler = () => {
    setCouponButtonLoading(true);
    setTimeout(() => {
      setCouponApplied(false);
      setCouponButtonLoading(false);
      setCouponCode("");
      setDiscountPercentage(0);
    }, 1000);
  };

  const FailureWindow = () => {
    return (
      <div className="subscription-payment-success">
        <div className="success-image">
          <img src={FailureIcon} alt="failure" />
        </div>
        <div className="success-text">
          Your payment request for subscription activation has not been
          completed.
        </div>
      </div>
    );
  };

  const SuccessWindow = () => {
    return (
      <div className="subscription-payment-success">
        <div className="success-image">
          <img src={SuccessIcon} alt="success" />
        </div>
        <div className="success-text">
          Your payment request for subscription activation has been completed
          successfully. {LAB_TRIAL_DAYS} days free trial added to the current
          subscription plan.
        </div>
      </div>
    );
  };

  useEffect(() => {
    setShowPaymentFailure(false);
    setShowPaymentSuccess(false);
  }, []);

  return (
    <div className="subscription-activation">
      {!ShowPaymentSuccess && !ShowPaymentFailure ? (
        Step === 1 ? (
          <>
            <Row style={{ marginBottom: 20 }}>
              <Col md={12}>
                <label>Subscription Duration</label>
                <Select
                  showSearch
                  defaultValue={{ label: "3 months", value: 90 }}
                  onChange={(e) => setDuration(e)}
                  optionFilterProp="children"
                  size="large"
                  value={Duration && JSON.parse(Duration)}
                  placeholder="Select Duration"
                  style={{ width: "100%" }}
                >
                  {subscriptionDurations?.map((item) => (
                    <Select.Option key={JSON.stringify(item)}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col md={12}>
                <div className="form-parallel-display">
                  <label>
                    Expires on{" "}
                    {getDateAfterAddDays(
                      (Duration && JSON.parse(Duration)).value + LAB_TRIAL_DAYS
                    ) +
                      " (" +
                      (Duration && JSON.parse(Duration)).value +
                      " + " +
                      LAB_TRIAL_DAYS +
                      " days)"}
                  </label>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col md={12}>
                <label>Lab Packages Quota</label>
                <Select
                  defaultValue={1}
                  showSearch
                  value={Quota}
                  onChange={(e) => setQuota(e)}
                  optionFilterProp="children"
                  size="large"
                  placeholder="Select Quota"
                  style={{ width: "100%" }}
                >
                  {[...Array(100).keys()]
                    .slice(
                      SubscriptionData?.subscriptionQuota?.quotaUsed > 1
                        ? SubscriptionData?.subscriptionQuota?.quotaUsed
                        : 1,
                      99
                    )
                    .map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                </Select>
              </Col>
              <Col md={12}>
                <div className="form-parallel-display">
                  <label>
                    ₹ {LAB_PACKAGE_COST_PER_MONTH} for Lab package per month
                  </label>
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col md={12}>
                <label>Discount Coupon</label>
                <Input
                  disabled={CouponApplied}
                  style={{ height: 38 }}
                  value={CouponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <div className="coupon-error">{CouponError}</div>
              </Col>
              <Col md={6}>
                <Button
                  loading={CouponButtonLoading}
                  type={CouponApplied ? "danger" : "secondary"}
                  className="apply-coupon-button"
                  onClick={() => {
                    CouponApplied
                      ? RemoveCouponHandler()
                      : ApplyCouponHandler();
                  }}
                >
                  {CouponApplied ? "Remove Coupon" : "Apply Coupon"}
                </Button>
              </Col>
            </Row>
            <Row
              style={{ marginBottom: 20 }}
              className="subscription-activate-total"
            >
              Total Amount : ₹{" "}
              {getPriceAfterDiscountPercentage(
                (LAB_PACKAGE_COST_PER_MONTH / 30) *
                  (Duration && JSON.parse(Duration)).value *
                  Quota,
                DiscountPercentage
              )}
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={() => setStep(2)}
                style={{ height: 40 }}
              >
                Next
              </Button>
            </Row>
          </>
        ) : (
          <div className="subscription-activate-summary">
            <Row>
              <Col md={24} style={{ textAlign: "center" }}>
                <h5 style={{ fontSize: 18, fontWeight: "bold" }}>Summary</h5>
              </Col>
              <div className="subscription-activate-summary-content">
                <Row>
                  <div>Duration</div>
                  <div>
                    {(Duration && JSON.parse(Duration)).value +
                      " + " +
                      LAB_TRIAL_DAYS +
                      " days"}
                  </div>
                </Row>
                <Row>
                  <div>Expires On</div>
                  <div>
                    {getDateAfterAddDays(
                      (Duration && JSON.parse(Duration)).value + LAB_TRIAL_DAYS
                    )}
                  </div>
                </Row>
                <Row>
                  <div>Lab Packages Quota</div>
                  <div>{Quota}</div>
                </Row>
                <Row>
                  <div>Lab Package per month</div>
                  <div> ₹ {LAB_PACKAGE_COST_PER_MONTH}</div>
                </Row>
                <Row>
                  <div>Total Amount</div>
                  <div>
                    ₹{" "}
                    {Math.round(
                      (LAB_PACKAGE_COST_PER_MONTH / 30) *
                        (Duration && JSON.parse(Duration)).value *
                        Quota
                    )}
                  </div>
                </Row>
                <Row>
                  <div>
                    Discount{" "}
                    {DiscountPercentage > 0
                      ? "(" + DiscountPercentage + "%)"
                      : ""}
                  </div>
                  <div>
                    {" "}
                    ₹{" "}
                    {getDiscountPriceAfterDiscountPercentage(
                      (LAB_PACKAGE_COST_PER_MONTH / 30) *
                        (Duration && JSON.parse(Duration)).value *
                        Quota,
                      DiscountPercentage
                    )}
                  </div>
                </Row>
                <Row className="total-amount">
                  <div>Total Amount To Pay</div>
                  <div>
                    {getPriceAfterDiscountPercentage(
                      (LAB_PACKAGE_COST_PER_MONTH / 30) *
                        (Duration && JSON.parse(Duration)).value *
                        Quota,
                      DiscountPercentage
                    )}
                  </div>
                </Row>
              </div>
            </Row>
            <Row style={{ justifyContent: "space-between" }}>
              <Button
                type="secondary"
                onClick={() => setStep(1)}
                style={{ height: 40 }}
              >
                Back
              </Button>
              <RazorpayPayment
                FetchBillingHistory={FetchBillingHistory}
                duration={Duration && JSON.parse(Duration).value}
                quota={Quota}
                couponApplied={CouponApplied}
                couponCode={CouponCode}
                setShowPaymentSuccess={setShowPaymentSuccess}
                setShowPaymentFailure={setShowPaymentFailure}
                orderType="Lab Subscription Activation"
              />
            </Row>
          </div>
        )
      ) : (
        ""
      )}
      {ShowPaymentFailure ? <FailureWindow /> : ""}
      {ShowPaymentSuccess ? <SuccessWindow /> : ""}
    </div>
  );
};

export default ActivateSubscription;
