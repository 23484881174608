import React, { useState, createContext, useContext } from "react";

export const PaymentsContext = createContext();

export const PaymentsContextProvider = (props) => {
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [paymentsData, setPaymentsData] = useState({
    status: false,
    data: { count: 0, onlinePayments: [] },
  });
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [date, setDate] = useState([null, null]);

  const getPaymentDetails = (orderId) => {
    let paymentDetails = {
      status: false,
      data: {},
    };
    try {
      //get the payment details of the selected orderId from the paymentsData
      paymentDetails.data = paymentsData.data.onlinePayments.find(
        (payment) => payment.orderId === orderId
      );
      paymentDetails.status = true;
    } catch (err) {
      paymentDetails = {
        status: false,
        data: {},
      };
    } finally {
      return paymentDetails;
    }
  };

  const values = {
    SearchKeyword,
    setSearchKeyword,
    paymentsData,
    setPaymentsData,
    selectedOrderId,
    setSelectedOrderId,
    showDetailsModal,
    setShowDetailsModal,
    date,
    setDate,
    getPaymentDetails,
  };

  return <PaymentsContext.Provider value={values} {...props} />;
};

export const usePayments = () => {
  const context = useContext(PaymentsContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
