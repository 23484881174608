import {
  BellOutlined,
  DownOutlined,
  PoweroffOutlined,
  SettingFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Card, Dropdown, Menu, Modal } from "antd";
import classnames from "classnames";
import { GlobalState } from "context-api";
import DummyUser from "Images/dummy/user.png";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Container, Navbar, NavbarBrand, UncontrolledTooltip } from "reactstrap";
import Cookies from "universal-cookie";
import Switch from "react-switch";
import { getNotificationFromNow } from "Utils/Constants";
import { rejectToken } from "Utils/Services/Auth";
import {
  updateAvailablityStatus,
  getPractitionerSettings,
} from "Utils/Services/Practitioner/Profile";
import NotificationDetailsCard from "views/components/Notifications/NotificationDetailsCard";


export const toggleSidebar = () => {
  document.documentElement.classList.toggle("nav-open");
};

const AdminNavbar = (props) => {
  const [color, setcolor] = useState("navbar-transparent");
  const [checked, setChecked] = useState(false);

  const {
    Heading,
    HeadingCount,
    notifications,
    notificationsCount,
    FetchNotifications,
    AccountImage,
    setAccountImage,
    setProfileName,
    ProfileName,
    UserData,
    setHospitalInformation,
    setLabInformation,
  } = useContext(GlobalState);

  const handleChange = async (isChecked) => {
    
    if(UserData?.type === "practitioner"){
      let response = await updateAvailablityStatus({
        live_availability_status: isChecked,
      });
      if (response && response?.status == 200 && response?.data?.success){
        handleToggler()
      } 
    }
  };

  const sidebarOpen = false;
  const collapseOpen = false;

  const history = useHistory();
  const cookies = new Cookies();

  const LogOutUser = async () => {
    if(UserData?.type === "practitioner"){
      await updateAvailablityStatus({
        live_availability_status: false,
      })
    }
    UserData?.refreshToken && await rejectToken(UserData?.refreshtoken);
    cookies?.remove("userdata", { path: "/" });
    cookies?.remove("token", { path: "/" });
    history?.push("/login");
    // window?.location?.reload();
    setHospitalInformation("");
    setLabInformation("");
    setAccountImage(null);
    setProfileName("");
  };

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  const [selectedNotification, setSelectedNotification] = useState("");

  useEffect(() => {
    window.addEventListener("resize", updateColor);

    UserData?.type === "practitioner" && handleToggler();
  }, [notifications, notificationsCount]); // eslint-disable-line

  const handleToggler = async () => {
    let res = await getPractitionerSettings();
    if(res && res?.status === 200){
      (res?.data?.live_availability_status == 1) ? setChecked(true) : setChecked(false)
    }
  };

  return (
    <>
      
      <Navbar
        className={classnames("navbar-absolute fixed-top", color)}
        expand="lg"
      >
        <Modal
          className="item-details-modal"
          title="Notification Details"
          visible={selectedNotification}
          footer={null}
          width={700}
          onCancel={() => setSelectedNotification(null)}
        >
          {selectedNotification !== null ? (
            <NotificationDetailsCard
              notification={selectedNotification}
              FetchNotifications={FetchNotifications}
            />
          ) : (
            <p>loading...</p>
          )}
        </Modal>
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                style={{ height: 30, width: 30, minWidth: 30, color: "#222" }}
                className="btn-icon btn-round btn-small"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames("navbar-toggle", {
                toggled: sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand onClick={(e) => e.preventDefault()}>
              <span
                className="d-none d-md-block"
                style={{ fontWeight: "500", zIndex: "10", fontSize: 16 }}
              >
                {Heading}
                {HeadingCount > 0 && (
                  <span
                    style={{ fontSize: 14, marginLeft: 5 }}
                  >{` ( ${HeadingCount} ) `}</span>
                )}
              </span>
              <span
                className="d-block d-md-none"
                style={{ fontWeight: "600", zIndex: "10" }}
              >
                {Heading}
                {HeadingCount > 0 && (
                  <span
                    style={{ fontSize: 16, marginLeft: 5 }}
                  >{` ( ${HeadingCount} ) `}</span>
                )}
              </span>
            </NavbarBrand>
          </div>
          
          <div style={{ display: "flex", alignItems: "center",gap:'16px' }}>
            {
              UserData?.type === "practitioner" ? (
                <div>
                  <span   id="UncontrolledTooltipExample">
                    <Switch height={20} width={42} onChange={handleChange} checked={checked}/>
                  </span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="UncontrolledTooltipExample"
                  >
                    Set Availability (Live/Unavailable)
                  </UncontrolledTooltip>
                </div>
              ):null
            }
            <Dropdown
              trigger={["click"]}
              overlay={
                <div>
                  <Card
                    className="navbar-notifications"
                    title="Notifications"
                    bordered={false}
                    bodyStyle={{ padding: 0 }}
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                      borderRadius: "7px",
                      padding: "0px",
                      margin: "0px",
                      right: "20px",
                    }}
                  >
                    <div className="notification_container">
                      {notifications?.filter((item) => item.read === 0)
                        .length ? (
                        notifications
                          .filter((item) => item.read === 0)
                          .map((notification) => (
                            <div
                              className="notification"
                              key={notification.id}
                              onClick={() =>
                                setSelectedNotification(notification)
                              }
                            >
                              <span className="circle"></span>
                              <div className="title_container">
                                <div className="title">
                                  {notification?.subject}
                                </div>
                                <div className="time">
                                  {getNotificationFromNow(
                                    notification.created_at
                                  )}
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <p
                          style={{
                            marginBottom: 0,
                            background: "#eaeaea",
                            padding: "10px 20px",
                            borderRadius: "7px",
                          }}
                        >
                          No Unread Notifications
                        </p>
                      )}

                      <div
                        className="notification_bottom"
                        onClick={() => {
                          if (UserData?.type === "hospital")
                            history.push("/hospital/notifications");
                          else if (UserData?.type === "lab")
                            history.push("/lab/notifications");
                          else if (UserData?.type === "practitioner")
                            history.push("/practitioner/notifications");
                        }}
                      >
                        View All Notifications
                      </div>
                    </div>
                  </Card>
                </div>
              }
              placement="bottomCenter"
            >
              <Badge count={notificationsCount} style={{ cursor: "pointer" }}>
                <BellOutlined
                  style={{ paddingTop: "3px", cursor: "pointer" }}
                />
              </Badge>
            </Dropdown>
            <Dropdown
              // trigger={["click"]}
              overlay={
                <Menu style={{ padding: "10px" }}>
                  {
                    UserData && UserData?.email ? (
                      <Menu.Item
                        style={{ display: "flex", alignItems: "center" }}
                        key="app"
                        icon={<UserOutlined />}
                      >
                        {UserData?.email}
                      </Menu.Item>
                    ):null
                  }
                  <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    key="app1"
                    icon={<SettingFilled />}
                    onClick={()=>{history.push(`/${UserData.type}/change-password`)}}
                  >
                    Change Password
                  </Menu.Item>
                  <Menu.Item
                    style={{ display: "flex", alignItems: "center" }}
                    danger
                    key="app2"
                    icon={<PoweroffOutlined />}
                    onClick={LogOutUser}
                  >
                    Logout
                  </Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "30px",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  style={{ marginRight: "10px", border: "1px solid #eaeaea" }}
                  size={{ xs: 32, sm: 32, md: 40, lg: 40 }}
                  icon={
                    <img
                      alt="avatar"
                      src={AccountImage ? AccountImage : DummyUser}
                    />
                  }
                />
                {window.innerWidth > 768 && (
                  <p
                    style={{
                      fontWeight: "600",
                      cursor: "pointer",
                      marginRight: "10px",
                      maxWidth: 200,
                    }}
                    className="menu-profile-name text-ellipsis"
                  >
                    {ProfileName}
                  </p>
                )}
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
export default AdminNavbar;
