import { notification } from "antd";
import ShopDocIcon from "Images/shopdoc_icon.png";
import React from "react";

const NotificationTrigger = (subject, message) => {
  notification.open({
    message: subject,
    description: message,
    icon: (
      <img
        src={ShopDocIcon}
        style={{ height: 28, width: "auto" }}
        alt="shopdoc-icon"
        title="shopdoc-icon"
      />
    ),
    duration: 0,
    style: {
      borderRadius: 10,
      borderTop: "3px solid #0fa0cf",
    },
  });
};

export const SocketHospitalNotifications = (data) => {
  if (data?.socketType === "appointmentBooking") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  } else if (data?.socketType === "practiceRequest") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  } else if (data?.socketType === "procedureRequest") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  } else if (data?.socketType === "packageAppointmentBooking") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  }
};

export const SocketPractitionerNotifications = (data) => {
  if (data?.socketType === "appointmentBooking") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  } else if (data?.socketType === "practiceRequest") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  }
};

export const SocketLabNotifications = (data) => {
  if (data?.socketType === "packageAppointmentBooking") {
    NotificationTrigger(data?.socketSubject, data?.socketMessage);
  }
};
