import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import { useQuickBooking } from "./useQuickBooking";
import { getMyHospitals } from "Utils/Services/Practitioner/Hospitals";
import ScheduleCard from "./ScheduleCard";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const PatientExistsContainer = () => {
  const [hospitals, setHospitals] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let hospitalsResponse = await getMyHospitals();
      if (hospitalsResponse?.status)
        setHospitals(hospitalsResponse.data.hospitals);
    };

    fetchData();
  }, []);
  const {
    patientData,
    setSelectedHospital,
    selectedHospital,
    addBooking,
    isBtnLoading,
    setIsBtnLoading,
    setSelectedAppointmentType,
    selectedAppointmentType,
  } = useQuickBooking();
  return (
    <Form layout="vertical">
      <Alert
        message="Patient already exists"
        type="success"
        closable
        style={{ marginBottom: "1rem", width: "40%" }}
      />
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item label="Name">
            <Input
              disabled
              size="large"
              type="text"
              readOnly
              value={patientData?.patient}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item label="Date of Birth">
            <Input
              disabled
              size="large"
              type="text"
              readOnly
              value={
                patientData?.dob &&
                moment(
                  new Date(moment(moment(patientData?.dob, "YYYY-MM-DD")))
                ).format("DD MMM YYYY")
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name={["hospital"]}
            label="Hospital"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select hospital"
              optionFilterProp="children"
              size="large"
              onChange={(val) => setSelectedHospital(val)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {hospitals?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {selectedHospital && appointmentTypes && (
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["booking_type_id"]}
              label="Appointment Type"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Appointment Type"
                optionFilterProp="children"
                size="large"
                onChange={(val) => {
                  setSelectedAppointmentType(val);
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {appointmentTypes?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
        <Col xs={24} sm={24} lg={12}>
          {selectedHospital && (
            <>
              <p>Appointment Slot:</p>
              <ScheduleCard
                triggerChange={(val) => {
                  setAppointmentDate(val);
                }}
                setAppointmentTypes={setAppointmentTypes}
              />
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Button
          style={{ height: 40 }}
          type="primary"
          loading={isBtnLoading}
          disabled={
            !patientData ||
            !selectedHospital ||
            !appointmentDate?.date ||
            !appointmentDate?.time ||
            !appointmentDate?.token ||
            isBtnLoading
          }
          onClick={async () => {
            setIsBtnLoading(true);
            if (!selectedAppointmentType) {
              //message.error("Please select appointment type");
              setIsBtnLoading(false);
              AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: "Please select appointment type"
              });
              return;
            }
            await addBooking({
              patient_id: patientData?.id,
              clinic_id: selectedHospital,
              appointment_date: appointmentDate?.date,
              appointment_time: appointmentDate?.time,
              booking_type_id: selectedAppointmentType,
              token: appointmentDate?.token,
            });
            setIsBtnLoading(false);
          }}
        >
          Book Appointment
        </Button>
      </Row>
    </Form>
  );
};

export default PatientExistsContainer;
