import { GetData } from "../ApiCall";
export async function getLabPackagesByLabId(lab_id) {
  return await GetData("/api/lab/getLabPackages/" + lab_id).then((response) => {
    if (response) {
      return response;
    }
  });
}
export async function appointmentList(lab_id) {
  return await GetData("/api/lab/appointmentList/" + lab_id).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
}
export async function getReviewsByLabs(lab_id) {
  return await GetData("/api/lab/getReviewsByLab/" + lab_id).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
}
export async function getAppointmentPaymentDetails(lab_id, type) {
  return await GetData(
    "/api/lab/getAppointmentPaymentDetails/" + lab_id + "/" + type
  ).then((response) => {
    if (response) {
      return response;
    }
  });
}
