import { PostData } from "Utils/ApiCall";
import { LabURLParser } from "Utils/urls.utils";

export const getAllPatients = async (values) => {
  return await PostData(LabURLParser("/getLabPatients"), values).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const getPatientDetails = async (id) => {
  const response = await PostData(LabURLParser("/patientDetails"), {
    id,
  });
  if (response) {
    return response.data;
  }
};

export const getPatientAppointments = async (
  patient_id,
  start,
  limit,
  search,
  startDate = null,
  endDate = null
) => {
  const response = await PostData(LabURLParser("/patientAppointments"), {
    patient_id,
    start,
    limit,
    search,
    startDate,
    endDate,
  });
  if (response) {
    return response.data;
  }
};
