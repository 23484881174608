/* eslint-disable */
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  Collapse,
} from "antd";
import FullWidthView from "components/FullWidthView";
import { stateToHTML } from "draft-js-export-html";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Spinner from "reactstrap/lib/Spinner";
import { useProfile } from "../../useProfile";
import AwardsSection from "./AwardsSection";
import EducationSection from "./EducationSection";
import ExperienceSection from "./ExperienceSection";
import { updatePractitionerInformation } from "Utils/Services/Practitioner/Profile";
import BugContainer from "components/ErrorContainer/BugContainer";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const PractitionerInformation = () => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { isLoading, data, fetchData, editorState, setEditorState } =
    useProfile();

  const [form] = Form.useForm();
  const { Panel } = Collapse;

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  const handleSubmit = async () => {
    setIsBtnLoading(true);
    let values = {
      ...form.getFieldsValue(),
      awards: data.data.awards,
      education: data.data.education,
      experience: data.data.experience,
      about_me: stateToHTML(editorState.getCurrentContent()),
    };
    const response = await updatePractitionerInformation(values);
    if (response?.status) {
      //message.success("Practitioner information updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Practitioner information updated successfully"
    });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
      });
    }
    setIsBtnLoading(false);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  let {
    practitioner_types = [],
    experience = [],
    education = [],
    languages = [],
    services = [],
  } = data.data || {};
  return (
    <>
      {data?.status ? (
        <Card>
          <>
            <Form
              form={form}
              layout="vertical"
              name="nest-messages"
              validateMessages={validateMessages}
              initialValues={data.data}
            >
              {/* row starts here */}
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["practitioner_type"]}
                    label="Practitioner Type"
                  >
                    <Select
                      optionFilterProp="children"
                      size="large"
                      placeholder="Practitioner Type"
                      style={{ width: "100%" }}
                    >
                      {practitioner_types.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item name={["jobtitle"]} label="Job Title">
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              {/* row ends here */}
              {/* row starts here */}
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["languages_spoken"]}
                    label="Language Spoken"
                  >
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      size="large"
                      placeholder="Please select"
                      style={{ width: "100%" }}
                    >
                      {languages.map((item) => (
                        <Select.Option key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["available_services"]}
                    label="Available Services"
                  >
                    <Select
                      mode="multiple"
                      optionFilterProp="children"
                      size="large"
                      placeholder="Please select"
                      style={{ width: "100%" }}
                    >
                      {services.map((item) => (
                        <Select.Option key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* row ends here */}
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={24}>
                  <Form.Item label="About Practice">
                    <Editor
                      editorStyle={{
                        border: "1px solid #D9D9D9",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(value) => {
                        setEditorState(value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Collapse defaultActiveKey={["1"]} onChange={() => {}}>
              <Panel header="Experience" key="1">
                <ExperienceSection data={experience} />
              </Panel>
              <Panel header="Education" key="2">
                <EducationSection data={education} />
              </Panel>
              <Panel header="Awards" key="3">
                <AwardsSection />
              </Panel>
            </Collapse>
            <Button
              type="primary"
              htmlType="submit"
              loading={isBtnLoading}
              style={{ height: 40, marginTop: "1rem" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        </Card>
      ) : (
        <BugContainer />
      )}
    </>
  );
};

export default PractitionerInformation;
