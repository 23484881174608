import { Button, Modal, Popconfirm } from "antd";
import { message, List, Empty } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import { TableSkeletonLoader } from "Utils/Constants";
import DetailsCard from "views/components/practitioners/DetailsCard";
import ListCard from "views/components/practitioners/ListCard";
const RequestedPractitioners = ({
  FetchRequestedPractitioners,
  SearchKeyword,
  actionToPractitioner,
  RequestedPractitioners,
  RequestedPractitionersLoading,
  RequestedPractitionersCount,
  FetchMyPractitioners,
  FetchAllPractitioners,
}) => {
  const { setHeading, Heading, SubscriptionData } = useContext(GlobalState);

  const QuotaBalance = SubscriptionData?.subscriptionQuota?.quotaBalance;

  const ContentItem = "Practitioner";
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [DetailsItemUrlName, setDetailsItemUrlName] = useState("");
  const [Pageno, setPageNo] = useState(1);
  const PractitionersPerPage = 12;
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const CardOnClick = (item) => {
    setshowDetailsModal(true);
    setDetailsItemUrlName(item.url_name);
  };

  const handleRequest = async (id, status) => {
    // if ((status === 1 || status === 2) && QuotaBalance <= 0) {
    //   // message.error(
    //   //   "Your practitioner quota balance is " +
    //   //     QuotaBalance +
    //   //     ". Please upgrade your quota to add more practitioners to your network."
    //   // );
    //   AntdCustomMessage({
    //     type: antdMessageTypes.ERROR,
    //     content: "Your practitioner quota balance is " +
    //     QuotaBalance +
    //     ". Please upgrade your quota to add more practitioners to your network."
    //   });
    //   return;
    // }
    //console.log(id);
    setIsBtnLoading(true);
    const response = await actionToPractitioner(id, status);
    //console.log(response);
    if (response && response?.status === 200) {
      //message.success(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: response.message
      });
      FetchAllPractitioners(Pageno, PractitionersPerPage);
      FetchMyPractitioners();
      FetchRequestedPractitioners();
    } else {
      //message.warning(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.WARNING,
        content: response.message
      });
    }
    setIsBtnLoading(false);
  };

  const ActionButtons = ({ item, QuotaBalance }) => {
    let btn = "";
    if (
      item.requested_by === "practitioner" &&
      item.request_status === "pending"
    ) {
      btn = (
        <div className="incoming-request-buttons">
          <Button
            type="primary"
            size="middle"
            loading={isBtnLoading}
            onClick={() => handleRequest(item.id, 2)}
          >
            Accept
          </Button>
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to reject?"
            onConfirm={() => {
              handleRequest(item.id, 3);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              style={{ marginLeft: "4px" }}
              type="danger"
              size="middle"
              loading={isBtnLoading}
            >
              Reject
            </Button>
          </Popconfirm>
        </div>
      );
    } else if (
      item.requested_by === "clinic" &&
      item.request_status === "pending"
    ) {
      btn = <Button className="pending-request-status">Pending</Button>;
    } else {
      btn = (
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to send request?"
          onConfirm={() => handleRequest(item.id, 1)}
          okText="Yes"
          cancelText="No"
        >
          <Button className="send-request" loading={isBtnLoading}>
            Send request
          </Button>
        </Popconfirm>
      );
    }
    return btn;
  };

  useEffect(() => {
    FetchRequestedPractitioners();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Practitioners");
    }
  }, [setHeading, Heading]);
  return (
    <div className="content">
      <div
        className={ContentItem}
        style={{
          borderRadius: "7px",
        }}
      >
        <Modal
          className="item-details-modal"
          title="Practitioner Details"
          visible={showDetailsModal}
          footer={null}
          width={700}
          onCancel={() => setshowDetailsModal(false)}
        >
          <DetailsCard urlName={DetailsItemUrlName} />
        </Modal>
        <div className="practitioners-list">
          {RequestedPractitionersLoading ? (
            TableSkeletonLoader(10, 60)
          ) : RequestedPractitioners?.length ? (
            <List
              dataSource={RequestedPractitioners}
              pagination={{
                current: Pageno,
                total: RequestedPractitionersCount,
                onChange: (page) => {
                  setPageNo(page);
                },
                pageSize: PractitionersPerPage,
              }}
              renderItem={(item) => (
                <List.Item>
                  <ListCard
                    practitioner={item}
                    CardOnClick={CardOnClick}
                    isBtnLoading={isBtnLoading}
                    ActionButtons={
                      <ActionButtons item={item} QuotaBalance={QuotaBalance} />
                    }
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty
              description="No Practitioners Found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default RequestedPractitioners;
