import React from "react";
import { AppointmentsContextProvider } from "../../Appointments/useAppointments";
import { PatientsContextProvider } from "../usePatients";
import PatientDetailsContainer from "./PatientDetialsContainer";

const PatientDetials = () => {
  return (
    <PatientsContextProvider>
      <AppointmentsContextProvider>
        <PatientDetailsContainer />
      </AppointmentsContextProvider>
    </PatientsContextProvider>
  );
};

export default PatientDetials;
