import { Input, Button, message } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import React, { useEffect, useState } from "react";
import { updatePractitionerWorkingHours } from "Utils/Services/Hospital/Practitioner";

const WorkingHours = ({ workingHours, practitioner_id }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [NewWorkingHours, setNewWorkingHours] = useState([]);
  const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const SubmitHandler = async () => {
    setIsBtnLoading(true);
    let payload = {
      practitionerId: +practitioner_id?.replace("SDP", ""),
      workingHours: NewWorkingHours,
    };
    const response = await updatePractitionerWorkingHours(payload);
    if (response?.status === 200) {
      //message.success("Practitioner working hours updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Practitioner working hours updated successfully",
      })
    }
    setIsBtnLoading(false);
  };

  const inputHandler = (value, index) => {
    let items = [...NewWorkingHours];
    let item = { ...NewWorkingHours[index] };
    item = value;
    items[index] = item;
    setNewWorkingHours(items);
  };

  useEffect(() => {
    if (workingHours) {
      setNewWorkingHours(workingHours);
    }
  }, [workingHours]);

  return (
    <div
      className="working-hours-container"
      style={{ justifyItems: "left", gap: 10 }}
    >
      {NewWorkingHours.map((item, index) => {
        return (
          <div className="working-hours-item" key={index} style={{ gap: 20 }}>
            <label style={{ minWidth: 100 }}>{Days[index]}</label>
            <Input
              style={{ background: "#f1f5f7", border: "none" }}
              value={item}
              onChange={(e) => inputHandler(e.target.value, index)}
            />
          </div>
        );
      })}
      <Button
        type="primary"
        onClick={() => SubmitHandler()}
        style={{ height: 40 }}
        loading={isBtnLoading}
      >
        Update
      </Button>
    </div>
  );
};

export default WorkingHours;
