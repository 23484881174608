/* eslint-disable */
import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Empty, Input, List, Modal, Select } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Col, Row } from "reactstrap";
import { DateRanges, DATE_FORMAT, TableSkeletonLoader } from "Utils/Constants";
import {
  getPackageAppointments,
  getPackageAppointmentStatusTypes,
} from "Utils/Services/Hospital/Appointments";
import DetailsCard from "views/components/appointments/DetailsCard";
import PackageAppointmentCard from "./PackageAppointmentCard";
import { useHistory } from "react-router";
import PackageDetailCard from "./PackageDetailCard";

const PackageAppointments = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const [selectedPackageAppointmentId, setSelectedPackageAppointmentId] =
    useState(null);
  const [selectedPractitionerId, setSelectedPractitionerId] = useState("");
  const [DataLoading, setDataLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const appointmentsPerPage = 12;
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [appointmentStatusTypes, setAppointmentStatusTypes] = useState([]);
  const [selectedStatusType, setSelectedStatusType] = useState("");
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState([null, null]);
  const { RangePicker } = DatePicker;

  const handlePackageViewClick = (row) => {
    setSelectedPackageAppointmentId(row.appointment_id);
  };

  const FetchData = async (date1 = null, date2 = null) => {
    setDataLoading(true);
    const startDate = date1 ? moment(date1).format("YYYY-MM-DD") : null;
    const endDate = date2 ? moment(date2).format("YYYY-MM-DD") : null;
    const response = await getPackageAppointments({
      start: (pageNo - 1) * appointmentsPerPage,
      limit: appointmentsPerPage,
      appointment_status: selectedStatusType,
      search: searchValue,
      startDate: startDate,
      endDate: endDate,
    });
    if (response) {
      setData([...response?.appointments]);
      setTotalAppointmentsCount(response?.total_count);
    }

    setDataLoading(false);
  };

  const FetchAppointmentStatusTypes = async () => {
    setAppointmentStatusTypes(await getPackageAppointmentStatusTypes());
  };

  useEffect(() => {
    FetchData(date[0], date[1]); // eslint-disable-next-line
  }, [date, pageNo, selectedStatusType, searchValue]); // eslint-disable-line

  useEffect(() => {
    FetchAppointmentStatusTypes(); // eslint-disable-next-line
  }, []); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Package Appointments");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalAppointmentsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalAppointmentsCount]);

  return (
    <div className="content">
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Appointment Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setshowDetailsModal(false)}
      >
        <DetailsCard appointmentId={selectedAppointmentId} />
      </Modal>
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Appointment Details"
        visible={selectedPackageAppointmentId !== null}
        footer={null}
        width={700}
        onCancel={() => setSelectedPackageAppointmentId(null)}
      >
        <PackageDetailCard appointmentId={selectedPackageAppointmentId} />
      </Modal>

      <div className="appointments-container">
        <Row className="appointments-page-header">
          <Col lg="5" md="4" sm="6" xs="12">
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              // disabledDate={(current) =>
              //   current && current > moment().endOf("day")
              // }
            />
          </Col>

          <Col lg="3" md="4" sm="6" xs="12">
            <Select
              style={{ width: "100%" }}
              defaultValue="All Status"
              size="medium"
              className="shadow-select"
              onChange={(value) => setSelectedStatusType(value)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Option value="">All Status</Option>
              {appointmentStatusTypes &&
                appointmentStatusTypes?.map((item) => (
                  <Option value={item.status} key={item.name}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col md="4" sm="6" xs="12">
            <Input
              className="shadow-input"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>

        {DataLoading ? (
          TableSkeletonLoader(10, 60)
        ) : Data?.length ? (
          <List
            className="appointments-page"
            dataSource={Data}
            pagination={{
              current: pageNo,
              total: totalAppointmentsCount,
              onChange: (page) => {
                setPageNo(page);
              },
              pageSize: appointmentsPerPage,
            }}
            renderItem={(appointment) => (
              <List.Item>
                <PackageAppointmentCard
                  history={history}
                  appointment={appointment}
                  FetchData={FetchData}
                  setSelectedAppointmentId={setSelectedAppointmentId}
                  handleViewClick={handlePackageViewClick}
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No Appointments Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};
export default PackageAppointments;
