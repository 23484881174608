import React, { useState } from "react";
import { REQUEST_STATUS } from "Utils/Constants/practitionerRequests";
import { Button, Popconfirm } from "antd";
import { updateRequestStatus } from "Utils/Services/Practitioner/Hospitals";
import { useHospitals } from "../../useHospitals";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

export const RequestedHospitalsActionButtons = ({ hospital }) => {
  const [isBtnLoading, setIsBtnLoading] = useState({
    1: false,
    2: false,
    3: false,
  });
  const { fetchRequestedHospitalsData } = useHospitals();

  const handleRequest = async (payload) => {
    setIsBtnLoading({ ...isBtnLoading, [payload.needed_status]: true });
    const response = await updateRequestStatus(payload);

    if (response && response?.status === 200) {
      //message.success(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: response.message
    });
      fetchRequestedHospitalsData();
    } else {
      //message.warning(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.WARNING,
        content: response.message
    });
    }
    setIsBtnLoading({ ...isBtnLoading, [payload.needed_status]: false });
  };

  let btn = null;
  if (
    hospital.requestedBy === "clinic" &&
    hospital.requestStatus === "pending"
  ) {
    btn = (
      <div className="incoming-request-buttons">
        <Button
          type="primary"
          size="middle"
          loading={isBtnLoading[REQUEST_STATUS.ACCEPT]}
          onClick={() =>
            handleRequest({
              clinic_id: hospital.id,
              needed_status: REQUEST_STATUS.ACCEPT,
            })
          }
        >
          Accept
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to reject?"
          onConfirm={() => {
            handleRequest({
              clinic_id: hospital.id,
              needed_status: REQUEST_STATUS.REJECT,
            });
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            style={{ marginLeft: "4px" }}
            type="danger"
            size="middle"
            loading={isBtnLoading[REQUEST_STATUS.REJECT]}
          >
            Reject
          </Button>
        </Popconfirm>
      </div>
    );
  } else if (
    hospital.requestedBy === "practitioner" &&
    hospital.requestStatus === "pending"
  ) {
    btn = <Button className="pending-request-status">Pending</Button>;
  } else {
    btn = (
      <Popconfirm
        placement="topRight"
        title="Are you sure you want to send request?"
        onConfirm={() =>
          handleRequest({
            clinic_id: hospital.id,
            needed_status: REQUEST_STATUS.SEND,
          })
        }
        okText="Yes"
        cancelText="No"
      >
        <Button
          className="send-request"
          loading={isBtnLoading[REQUEST_STATUS.SEND]}
        >
          Send request
        </Button>
      </Popconfirm>
    );
  }
  return btn;
};
