import React from "react";
import { getDateTimeFormat, TableSkeletonLoader } from "Utils/Constants";
import { Table, Button, Tag } from "antd";

const BillingHistory = ({
  BillingHistoryLoading,
  BillingHistoryData,
  BillingHistoryPageNo,
  TotalTransactionsCount,
  setBillingHistoryPageNo,
  setSelectedOrderId,
  setSelectedSubscriptionId,
  setShowPaymentDetailsModal,
  setSelectedType,
}) => {
  const TransactionsPerPage = 10;

  const getTagColor = (text) => {
    switch (text) {
      case "ACTIVATION":
        return "#45bd5b";

      case "RENEWAL":
        return "#0fa0cf";

      case "UPGRADE":
        return "#d46b08";

      case "success":
        return "green";

      case "pending":
        return "orange";

      case "failed":
        return "red";

      default:
        break;
    }
  };

  const Columns = [
    {
      key: "order_id",
      dataIndex: "order_id",
      title: "Order ID",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "Type",
      render: (text) => (
        <Tag color={getTagColor(text)} key={text}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Amount",
      render: (text) => {
        return "₹" + text;
      },
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: "Created On",
      render: (text) => {
        return getDateTimeFormat(text);
      },
    },
    {
      key: "payment_status",
      dataIndex: "payment_status",
      title: "Status",
      render: (text) => (
        <Tag color={getTagColor(text)} key={text}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      dataIndex: "action",
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            setSelectedType(record?.type);
            setSelectedOrderId(record.order_id);
            setSelectedSubscriptionId(record.subscriptionId);
            setShowPaymentDetailsModal(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="billing-history">
        <h5>Billing History</h5>
      </div>
      <div className="subscription-billing-history">
        {BillingHistoryLoading
          ? TableSkeletonLoader(10, 30)
          : BillingHistoryData && (
              <Table
                rowKey="order_id"
                dataSource={BillingHistoryData}
                columns={Columns}
                pagination={{
                  current: BillingHistoryPageNo,
                  total: TotalTransactionsCount,
                  onChange: (page) => {
                    setBillingHistoryPageNo(page);
                  },
                  pageSize: TransactionsPerPage,
                }}
              />
            )}
      </div>
    </>
  );
};

export default BillingHistory;
