import React, { useEffect, useState, useRef, useContext } from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import AppointmentsIcon from "Images/dashboard-icons/appointment.svg";
import MyPractitionersIcon from "Images/dashboard-icons/doctor.png";
import PatientsIcon from "Images/dashboard-icons/patient.svg";
import { DatePicker } from "antd";
import CardMetrics from "views/components/dashboard/CardMetrics";
import {
  getDashboardAppointmentsChart,
  getDashboardCounts,
} from "Utils/Services/Practitioner/Dashboard";
import { isMobile } from "react-device-detect";
import AppointmentsTypeChart from "views/components/dashboard/AppointmentsTypeChart";
import TodayAppointments from "./TodaysAppointment";
import LabAppointmentStatusChart from "views/components/dashboard/LabAppointmentStatusChart";
import { getPractitionerAppointments } from "Utils/Services/Practitioner/Appointments";
import moment from "moment";
import { DateRanges, DATE_FORMAT } from "Utils/Constants";
import AppointmentsChart from "./AppointmentsChart";
import { GlobalState } from "context-api";

const DashboardContainer = () => {
  const { PractitionerRefreshPage } = useContext(GlobalState);
  const isMounted = useRef(true);
  const { RangePicker } = DatePicker;
  const [CardCounts, setCardCounts] = useState({
    totalMyHospitals: "",
    totalPatients: "",
    totalAppointments: "",
  });

  const [AppointmentsByStatus, setAppointmentsByStatus] = useState({
    pending: 0,
    upcoming: 0,
    completed: 0,
    other: 0,
    cancelled: 0,
  });

  const [AppointmentsByTypes, setAppointmentsByTypes] = useState({
    inPerson: 0,
    phone: 0,
    video: 0,
  });
  const [CountsLoading, setCountsLoading] = useState(false);
  const [TodaysAppointments, setTodaysAppointments] = useState({
    appointments: [],
    count: 0,
  });
  const [TodayAppointmentsLoading, setTodayAppointmentsLoading] =
    useState(false);
  const INIT_DATE_RANGE = [
    moment().subtract(7, "days").startOf("day"),
    moment().endOf("day"),
  ];
  const [ChartDateRange, setChartDateRange] = useState([...INIT_DATE_RANGE]);
  const [AppointmentsChartData, setAppointmentsChartData] = useState({
    status: false,
    data: {
      ChartData: [],
    },
  });

  const onChangeDate = (values) => {
    if (values && values.length > 0) {
      const [startDate, endDate] = values;
      setChartDateRange([moment(startDate), moment(endDate)]);
    } else {
      setChartDateRange([...INIT_DATE_RANGE]);
    }
  };

  const FetchDashboardcounts = async () => {
    isMounted.current && setCountsLoading(true);
    const response = await getDashboardCounts();
    if (!response?.status) {
      isMounted.current && setCountsLoading(false);
      return;
    }
    let { data } = response;
    isMounted.current &&
      setCardCounts((counts) => ({
        ...counts,
        totalMyHospitals: data.totalMyHospitals,
        totalPatients: data.totalPatients,
        totalAppointments: data.totalAppointments,
      }));
    let { appointmentsCountByTypes, appointmentsCountByStatus } = data;
    isMounted.current &&
      setAppointmentsByStatus((counts) => ({
        ...counts,
        pending: appointmentsCountByStatus.pending,
        upcoming: appointmentsCountByStatus.upcoming,
        completed: appointmentsCountByStatus.completed,
        other: appointmentsCountByStatus.other,
        cancelled: appointmentsCountByStatus.cancelled,
      }));
    isMounted.current &&
      setAppointmentsByTypes((counts) => ({
        ...counts,
        inPerson: appointmentsCountByTypes.inPerson,
        phone: appointmentsCountByTypes.phone,
        video: appointmentsCountByTypes.video,
      }));
    isMounted.current && setCountsLoading(false);
  };

  const FetchTodaysAppointments = async () => {
    isMounted?.current && setTodayAppointmentsLoading(true);
    const response = await getPractitionerAppointments({
      start: "",
      limit: "1000",
      appointment_status: "",
      search: "",
      startDate: moment().startOf("day").format("YYYY-MM-DD"),
      endDate: moment().endOf("day").format("YYYY-MM-DD"),
    });
    if (response && response?.status) {
      isMounted?.current && setTodaysAppointments(response?.data);
    }
    isMounted?.current && setTodayAppointmentsLoading(false);
  };

  const FetchAppointmentsChartData = async () => {
    let response = await getDashboardAppointmentsChart({
      startDate: ChartDateRange[0].format("YYYY-MM-DD"),
      endDate: ChartDateRange[1].format("YYYY-MM-DD"),
    });
    response && isMounted.current && setAppointmentsChartData(response);
  };

  useEffect(() => {
    FetchDashboardcounts();
    FetchTodaysAppointments();
  }, [PractitionerRefreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    FetchAppointmentsChartData();

    // return () => {
    //   isMounted.current = false;
    // };
  }, [ChartDateRange, PractitionerRefreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (!!socket && socket.connected && UserData?.type === "practitioner") {
  //     socket.on(`${UserData?.email || ""}_practitioner_appointment`, (data) => {
  //       FetchDashboardcounts();
  //       FetchTodaysAppointments();
  //       FetchAppointmentsChartData();
  //     });
  //   }

  //   return () => {
  //     !!socket && socket.connected && socket.removeAllListeners();
  //   };
  // }, [socket]); // eslint-disable-line

  return (
    <>
      <div className="content">
        <Row>
          <Col md={6} lg={4}>
            <CardMetrics
              CountsLoading={CountsLoading}
              heading="Practices"
              stat={CardCounts.totalMyHospitals}
              icon={MyPractitionersIcon}
              route="practices"
              col={4}
            />

            <CardMetrics
              CountsLoading={CountsLoading}
              heading="Patients"
              stat={CardCounts.totalPatients}
              icon={PatientsIcon}
              route="patients"
              col={4}
            />
            <CardMetrics
              CountsLoading={CountsLoading}
              heading="Appointments"
              stat={CardCounts.totalAppointments}
              icon={AppointmentsIcon}
              route="appointments"
              col={4}
            />
          </Col>
          <Col md={6} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Today's Appointments</CardTitle>
              <span className="card-header-count">
                {TodaysAppointments?.count}
              </span>
              <CardBody style={{ padding: "0px" }}>
                <TodayAppointments
                  TodaysAppointments={TodaysAppointments?.appointments}
                  FetchTodayAppointments={FetchTodaysAppointments}
                  TodayAppointmentsLoading={TodayAppointmentsLoading}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={4}>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Status</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <LabAppointmentStatusChart
                  height={185}
                  AppointmentsByStatus={AppointmentsByStatus}
                />
              </CardBody>
            </Card>
            <Card className="card-stats" style={{ padding: "15px" }}>
              <CardTitle>Appointments by Type</CardTitle>
              <CardBody style={{ padding: "0px" }}>
                <AppointmentsTypeChart
                  height={160}
                  AppointmentsByTypes={AppointmentsByTypes}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: "right" }}>
            <RangePicker
              dropdownClassName="range-dropdown"
              className="shadow-picker"
              style={{
                width: isMobile ? "100%" : "450px",
                marginBottom: "15px",
              }}
              value={ChartDateRange}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => onChangeDate(e)}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
          <Col md={12} lg={12}>
            {AppointmentsChartData.status && (
              <Card className="card-stats" style={{ padding: "15px" }}>
                <CardTitle>Practitioner Appointments</CardTitle>
                <CardBody style={{ padding: "0px" }}>
                  <AppointmentsChart data={AppointmentsChartData.data} />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardContainer;
