// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.working-hours-container {
  display: grid;
  margin: 1rem 0rem;
  grid-gap: 1rem;
  gap: 1rem;
  place-items: center;
}
.working-hours-item {
  padding: 0.6rem;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  color: #222;
  font-size: 13px;
  width: 90%;
}
.details-container {
  width: 100%;
  padding: 0.6rem;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
  color: #222;
  background: #f5f8f9;
}
.details-container p {
  margin: 0;
}
.settings-container {
  padding: 1rem;
}
.buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}
@media screen and (max-width: 768px) {
  .buttons-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 576px) {
  .buttons-container {
    grid-template-columns: 1fr;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/practitioner-styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAS;EAAT,SAAS;EACT,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,UAAU;AACZ;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,SAAS;AACX;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,kCAAkC;EAClC,cAAS;EAAT,SAAS;AACX;AACA;EACE;IACE,8BAA8B;EAChC;AACF;AACA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".working-hours-container {\n  display: grid;\n  margin: 1rem 0rem;\n  gap: 1rem;\n  place-items: center;\n}\n.working-hours-item {\n  padding: 0.6rem;\n  border-radius: 7px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border: 1px solid #e5e5e5;\n  color: #222;\n  font-size: 13px;\n  width: 90%;\n}\n.details-container {\n  width: 100%;\n  padding: 0.6rem;\n  border-radius: 7px;\n  border: 1px solid #e5e5e5;\n  color: #222;\n  background: #f5f8f9;\n}\n.details-container p {\n  margin: 0;\n}\n.settings-container {\n  padding: 1rem;\n}\n.buttons-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 1rem;\n}\n@media screen and (max-width: 768px) {\n  .buttons-container {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n@media screen and (max-width: 576px) {\n  .buttons-container {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
