import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { getSubscriptionHistoryDetails } from "Utils/Services/Hospital/Subscription";
import { getDateFormat, getDateTimeFormat } from "Utils/Constants";

const PaymentDetailsCard = ({ orderId, SubscriptionId, SelectedType }) => {
  const [Data, setData] = useState("");
  const { TabPane } = Tabs;

  useEffect(() => {
    const getData = async () => {
      getSubscriptionHistoryDetails({
        orderId: orderId,
        subscriptionId: SubscriptionId,
      }).then((response) => {
        setData(response);
      });
    };

    if (orderId && SubscriptionId) {
      getData();
    }
  }, [orderId, SubscriptionId]);

  const PaymentDetails = () => {
    if (SelectedType === "UPGRADE") {
      const SubscriptionDetails = Data?.subscriptionDetails;
      const UpgradeDetails =
        Data?.subscriptionUpgradeDetails?.length &&
        Data?.subscriptionUpgradeDetails[0];
      return (
        <Tabs type="card" defaultActiveKey="1" className="practitioner-tab">
          <TabPane tab="Upgrade Info" key="1">
            <div className="details-wraper">
              <label>Purchased Quota</label>
              <h4 className="text">{UpgradeDetails?.quota}</h4>
            </div>

            <div className="details-wraper">
              <label>Remaining Duration</label>
              <h4 className="text">
                {UpgradeDetails?.remaining_duration} days
              </h4>
            </div>

            {UpgradeDetails?.coupon_code ? (
              <>
                <div className="details-wraper">
                  <label>Coupon Code</label>
                  <h4 className="text">{UpgradeDetails?.coupon_code}</h4>
                </div>
                <div className="details-wraper">
                  <label>Coupon Discount</label>
                  <h4 className="text">{UpgradeDetails?.percentage} %</h4>
                </div>
              </>
            ) : (
              ""
            )}
          </TabPane>
          <TabPane tab="Subscription Info" key="2">
            <div className="upgrade-card">
              <div className="details-wraper">
                <label>Order Id</label>
                <h4 className="text">{SubscriptionDetails?.order_id}</h4>
              </div>
              <div className="details-wraper">
                <label>Amount Paid</label>
                <h4 className="text"> {SubscriptionDetails?.amount}</h4>
              </div>
              <div className="details-wraper">
                <label>Date Time</label>
                <h4 className="text">
                  {" "}
                  {getDateTimeFormat(SubscriptionDetails?.created_at)}
                </h4>
              </div>

              <div className="details-wraper">
                <label>Quota Purchased</label>
                <h4 className="text">{SubscriptionDetails?.quota}</h4>
              </div>
              <div className="details-wraper">
                <label>Duration</label>
                <h4 className="text">{SubscriptionDetails?.duration} days</h4>
              </div>
              <div className="details-wraper">
                <label>Subscription Starts On</label>
                <h4 className="text">
                  {getDateFormat(SubscriptionDetails?.startdate)}
                </h4>
              </div>
              <div className="details-wraper">
                <label>Trial Ends On</label>
                <h4 className="text">
                  {SubscriptionDetails?.trialenddate !== "0000-00-00 00:00:00"
                    ? getDateFormat(SubscriptionDetails?.trialenddate)
                    : "---"}
                </h4>
              </div>
              {SubscriptionDetails?.coupon_code ? (
                <>
                  <div className="details-wraper">
                    <label>Applied Coupon</label>
                    <h4 className="text">{SubscriptionDetails?.coupon_name}</h4>
                  </div>
                  <div className="details-wraper">
                    <label>Coupon Code</label>
                    <h4 className="text">{SubscriptionDetails?.coupon_code}</h4>
                  </div>
                  <div className="details-wraper">
                    <label>Coupon Discount</label>
                    <h4 className="text">
                      {SubscriptionDetails?.percentage} %
                    </h4>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </TabPane>
        </Tabs>
      );
    } else {
      const SubscriptionDetails = Data?.subscriptionDetails;
      const UpgradeDetails = Data?.subscriptionUpgradeDetails;
      return (
        <Tabs type="card" defaultActiveKey="1" className="practitioner-tab">
          <TabPane tab="Subscription Info" key="1">
            <div className="details-wraper">
              <label>Quota Purchased</label>
              <h4 className="text">{SubscriptionDetails?.quota}</h4>
            </div>
            <div className="details-wraper">
              <label>Duration</label>
              <h4 className="text">{SubscriptionDetails?.duration} days</h4>
            </div>
            <div className="details-wraper">
              <label>Subscription Starts On</label>
              <h4 className="text">
                {getDateFormat(SubscriptionDetails?.startdate)}
              </h4>
            </div>
            <div className="details-wraper">
              <label>Trial Ends On</label>
              <h4 className="text">
                {SubscriptionDetails?.trialenddate !== "0000-00-00 00:00:00"
                  ? getDateFormat(SubscriptionDetails?.trialenddate)
                  : "---"}
              </h4>
            </div>
            <div className="details-wraper">
              <label>Subscription Ends On</label>
              <h4 className="text">
                {getDateFormat(SubscriptionDetails?.enddate)}
              </h4>
            </div>
            {SubscriptionDetails?.coupon_code ? (
              <>
                <div className="details-wraper">
                  <label>Applied Coupon</label>
                  <h4 className="text">{SubscriptionDetails?.coupon_name}</h4>
                </div>
                <div className="details-wraper">
                  <label>Coupon Code</label>
                  <h4 className="text">{SubscriptionDetails?.coupon_code}</h4>
                </div>
                <div className="details-wraper">
                  <label>Coupon Discount</label>
                  <h4 className="text">{SubscriptionDetails?.percentage} %</h4>
                </div>
              </>
            ) : (
              ""
            )}
          </TabPane>
          {UpgradeDetails?.length ? (
            <TabPane tab="Upgrade Info" key="2">
              {UpgradeDetails.map((item, index) => {
                return (
                  <div className="upgrade-card" key={index}>
                    <div className="details-wraper">
                      <label>Order Id</label>
                      <h4 className="text">{item.order_id}</h4>
                    </div>
                    <div className="details-wraper">
                      <label>Purchased Quota</label>
                      <h4 className="text"> {item.quota}</h4>
                    </div>
                    <div className="details-wraper">
                      <label>Amount Paid</label>
                      <h4 className="text"> ₹{item.amount}</h4>
                    </div>
                    <div className="details-wraper">
                      <label>Date Time</label>
                      <h4 className="text">
                        {" "}
                        {getDateTimeFormat(item.created_at)}
                      </h4>
                    </div>
                    <div className="details-wraper">
                      <label>Remaining duration</label>
                      <h4 className="text"> {item.remaining_duration} days</h4>
                    </div>
                    {item?.coupon_code ? (
                      <>
                        <div className="details-wraper">
                          <label>Coupon Code</label>
                          <h4 className="text">{item?.coupon_code}</h4>
                        </div>
                        <div className="details-wraper">
                          <label>Coupon Discount</label>
                          <h4 className="text">{item?.percentage} %</h4>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      );
    }
  };

  const OrderDetails = () => {
    if (SelectedType === "UPGRADE") {
      const UpgradeDetails =
        Data?.subscriptionUpgradeDetails?.length &&
        Data?.subscriptionUpgradeDetails[0];
      return (
        <div className="general-info">
          {" "}
          <Row lg="9" md="12" sm="12" className="column-items">
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray">Amount Paid</h4>
                <h4 className="text-black">₹{UpgradeDetails?.amount}</h4>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray">Date Time</h4>
                <h4 className="text-black">
                  {getDateTimeFormat(UpgradeDetails?.created_at)}
                </h4>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray">Type</h4>
                <h4 className="text-black">{SelectedType}</h4>
              </div>
            </Col>
          </Row>
          <PaymentDetails />
        </div>
      );
    } else {
      return (
        <div className="general-info">
          <Row lg="9" md="12" sm="12" className="column-items">
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray">Amount Paid</h4>
                <h4 className="text-black">
                  ₹{Data?.subscriptionDetails?.amount}
                </h4>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray">Date Time</h4>
                <h4 className="text-black">
                  {getDateTimeFormat(Data?.subscriptionDetails?.created_at)}
                </h4>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray">Type</h4>
                <h4 className="text-black">{SelectedType}</h4>
              </div>
            </Col>
          </Row>
          <PaymentDetails />
        </div>
      );
    }
  };

  const getOrderId = (SelectedType) => {
    const SubscriptionDetails = Data?.subscriptionDetails;
    const UpgradeDetails =
      Data?.subscriptionUpgradeDetails?.length &&
      Data?.subscriptionUpgradeDetails[0];

    if (SelectedType === "UPGRADE") {
      return UpgradeDetails?.order_id;
    } else {
      return SubscriptionDetails?.order_id;
    }
  };

  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <span className="appointment-id">{getOrderId(SelectedType)}</span>
      </div>
      <div className="order-details-container">
        <OrderDetails />
      </div>
    </div>
  );
};
export default PaymentDetailsCard;
