export const modelCheckPatientExistAPI = (response) => {
  let result = {
    status: false,
    patientExist: false,
    data: {},
  };
  try {
    if (typeof response !== "object") throw new Error("Something went wrong");
    const { status = 500, data = {} } = response || {};
    if (status === 200) {
      if (typeof data === "object")
        result = {
          status: true,
          patientExist: true,
          data: data,
        };
      else
        result = {
          status: true,
          patientExist: false,
          data: {},
        };
    } else if (status === 500) {
      let error = (data || []).map((item) => item?.msg);
      result = {
        status: false,
        patientExist: false,
        data: error,
      };
    }
  } catch (error) {
    result = {
      status: false,
      patientExist: false,
      data: error,
    };
  } finally {
    return result;
  }
};
