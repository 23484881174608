/* eslint-disable */
let weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export const modelScheduleTimingsAPI = (response) => {
  let result = {
    status: false,
    data: {
      workHours: [],
      timeDuration: "",
    },
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    let { status, data } = response || {};
    if (status !== 200) throw new Error("Invalid status");
    result.status = true;
    let { time_duration = "", slots = [] } = data || {};
    if (!Array.isArray(slots) && !slots.length)
      throw new Error("Invalid slots");
    result.data.timeDuration = time_duration;
    //get keys from first object
    let daysSchedule = Object.keys(slots[0]?.working_hours || {});

    //find sunday to monday and loop through each day
    let workHours = [];
    weekdays.forEach((day, index) => {
      //check if day is present in daysSchedule
      if (daysSchedule.indexOf(day) !== -1) {
        workHours.push({
          day: day,
          slots: [],
        });
        let schedules = Object.keys(slots[0]?.working_hours[day] || {});
        //loop through each schedule
        schedules.forEach((schedule) => {
          let timings = slots[0]?.working_hours[day][schedule]?.split(" to ");
          workHours.map((item) => {
            if (item.day === day) {
              item.slots.push({
                start: timings[0],
                end: timings[1],
              });
            }
          });
        });
      }
      result.data.workHours = workHours;
    });
  } catch (e) {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelUpdateSlotDurationAPI = async (response) => {
  let result = {
    status: false,
    message: "Something went wrong",
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData } = response || {};
    if (responseData.success === true) {
      result.status = true;
    } else {
      result.status = false;
      result.message = responseData?.message || "Something went wrong";
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
export const modelUpdateScheduleTimingsAPI = async (response) => {
  let result = {
    status: false,
    message: "",
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (responseData.success === true) result.status = true;
    else result.message = responseData?.message || "Something went wrong";
  } catch (e) {
    result = {
      status: false,
      message: "Something went wrong",
    };
  } finally {
    return result;
  }
};

export const modelUpdateScheduleTimingPayload = (
  workHours,
  allData,
  clinic,
  day,
  AddedTimings,
  RemovedTimings
) => {
  let result = {};
  try {
    // if (!Array.isArray(workHours)) throw new Error("invalid workHours");
    // let slotsObj = {};
    // //add allData to slotsObj
    // allData.forEach((item, index) => {
    //   let { day: dayLoop, slots } = item;
    //   slots.forEach((slot) => {
    //     if (!slotsObj[dayLoop]) slotsObj[dayLoop] = {};
    //     let { start, end } = slot;
    //     slotsObj[dayLoop][`Schedule${index + 1}`] = `${start} to ${end}`;
    //   });
    // });
    // //remove existing day data from slotsObj, so that we can add the new day data
    // if (day in slotsObj) delete slotsObj[day];
    // //adding day
    // slotsObj = {
    //   ...slotsObj,
    //   [day]: {},
    // };
    //loop through each slot and adding schedule and slot to the day
    let slots = [];
    workHours.forEach((slot, index) => {
      let { start = "", end = "" } = slot || {};
      if (start === "" || end === "") throw new Error("invalid slot");
      // slotsObj[day][`Schedule${index + 1}`] = `${start} to ${end}`;
      slots.push({ start, end });
    });
    result = {
      clinic_id: clinic,
      day: day,
      timings: slots,
      addedTimings: AddedTimings,
      removedTimings: RemovedTimings,
    };
  } catch (e) {
    throw e;
  } finally {
    return result;
  }
};

export const modelWorkHoursSlotsAPI = (response) => {
  let result = {
    status: false,
    data: {
      workHours: [],
      dates: [],
      leaveSlots: [],
    },
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data = {} } = response || {};
    if (typeof data !== "object") throw new Error("invalid data");
    result.status = true;
    let { slots = {}, datesWithSlots = [], leaveSlots = [] } = data || {};
    if (Array.isArray(datesWithSlots) && datesWithSlots.length) {
      result.data.dates = datesWithSlots;
    }
    if (Array.isArray(leaveSlots) && leaveSlots.length) {
      result.data.leaveSlots = leaveSlots;
    }
    let keys = Object.keys(slots);
    let workHours = [];
    keys.forEach((key) => {
      slots[key].forEach((item) => {
        workHours.push({
          time: item.time,
          booked: item.booked,
        });
      });
    });
    result.data.workHours = workHours;
  } catch (e) {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGetDaySlotsAPI = (response) => {
  let result = {
    status: false,
    slots: [],
    slotTimings: [],
    timeDuration: "",
    message: "",
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data = {}, status = 500 } = response || {};
    if(status === 400){
      result.message = response?.message || "";
      return result;
    }
    if (status !== 200) throw new Error("Invalid response");
    result.status = true;
    if (Array.isArray(data?.slots) && data?.slots?.length) {
      result.slots = data.slots;
    }
    if (Array.isArray(data?.slotTimings) && data?.slotTimings?.length) {
      result.slotTimings = data?.slotTimings;
    }
    result.timeDuration = data?.timeDuration;
  } catch {
    return result;
  } finally {
    return result;
  }
};
