import React, { useMemo } from "react";
import { Form, Switch, message } from "antd";
import { useHospitals } from "../../../useHospitals";
import { updateMyHospitalSettings } from "Utils/Services/Practitioner/Hospitals";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const Settings = (props) => {
  const { selectedHospital } = useHospitals();
  const initialValues = useMemo(() => {
    return {
      video_enable_status: props?.videoConsultation,
      phone_enable_status: props?.phoneConsultation,
      inperson_enable_status: props?.inPersonConsultation,
      auto_appointment_status: props?.autoAppointment,
      auto_checkin_status: props?.autoCheckin,
    };
  }, [props]);

  const onChange = async (values) => {
    let payload = {
      ...initialValues,
      ...values,
      clinic_id: selectedHospital || "",
    };
    const response = await updateMyHospitalSettings(payload);
    if(response.status===true) {
      //message.success("Settings updated successfully.");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Settings updated successfully."
    });
    }

  };
  return (
    <div className="settings-container">
      <Form
        layout="vertical"
        name="nest-messages"
        initialValues={initialValues || {}}
        // onValuesChange={onChange}
        onFinish={onChange}
      >
        <div className="buttons-container">
          <Form.Item
            label="Appointments Auto Confirmation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="auto_appointment_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Check-in Feature"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="auto_checkin_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Video Consultation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="video_enable_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Phone Consultation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="phone_enable_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="In Person Consultation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="inperson_enable_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
        <div className="text-center w-full">
          <button className="btn bg-success btn-success rounded-2" type="Submit">Save</button>
        </div>
      </Form>
    </div>
  );
};

export default Settings;
