import React, { useState } from "react";
import { Modal, Empty, List } from "antd";
import { TableSkeletonLoader } from "Utils/Constants";
import AppointmentLabCard from "views/components/Lab/Appointments/AppointmentLabCard";
import { useHistory } from "react-router";
import LabAppointmentsDetailsCard from "views/components/Lab/Appointments/LabAppointmentsDetailsCard";

const TodaysLabAppointmentsList = ({
  TodaysLabAppointments,
  FetchTodaysLabAppointments,
  TodaysLabAppointmentsLoading,
}) => {
  const history = useHistory();

  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");

  const handleViewClick = (row) => {
    setSelectedAppointmentId(row.appointment_id);
    setshowDetailsModal(true);
  };

  return (
    <div className="today-appointments lab">
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Appointment Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setshowDetailsModal(false)}
      >
        <LabAppointmentsDetailsCard appointmentId={selectedAppointmentId} />
      </Modal>
      {TodaysLabAppointmentsLoading ? (
        TableSkeletonLoader(15, 30)
      ) : TodaysLabAppointments?.length ? (
        <List
          dataSource={TodaysLabAppointments}
          renderItem={(appointment) => (
            <List.Item style={{ padding: 0 }}>
              <AppointmentLabCard
                appointment={appointment}
                FetchData={FetchTodaysLabAppointments}
                setSelectedAppointmentId={setSelectedAppointmentId}
                handleViewClick={handleViewClick}
                history={history}
                today={true}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description="No Appointments Today"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="empty-nodata"
        />
      )}
    </div>
  );
};

export default TodaysLabAppointmentsList;
