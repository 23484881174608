import { GetData } from "../../ApiCall";
import axiosApiInstance from "Utils/ApiInstance";

export const getPractitionerQuestions = async () => {
    const response = await GetData('/api/v1/practitioner/posts/getQuestions');
    if (response) {
      return response?.data;
    }
};

export const postAddPractitionerQAAnswer = async (payload) => {
  const response = await axiosApiInstance
    .post(
      '/api/v1/practitioner/posts/createAnswer',
      payload
    );
  
  if(response?.status == 200 ){
    return {
      success: true, 
      message:"Your answer is submitted successfully."
    }
  }
  return {
    message: "Something went wrong."
  };
};

export const postUpdatePractitionerQAAnswer = async (payload) => {
    const response = await axiosApiInstance
      .put(
        '/api/v1/practitioner/posts/updateAnswer',
        payload
      );
    
    if(response?.status == 200 ){
      return {
        success:true, 
        message:"Answer updated successfully.",
      }
    }
    return {
      message: response?.message || "Something went wrong."
    };
};

export const deletePractitionerQA = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/practitioner/posts/deleteAnswer/${payload.id}`);
  if(response?.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response?.data || "Something went wrong!",
  }
};