import { modelCheckPatientExistAPI } from "models/quickbooking.api.models";
import { PostData } from "Utils/ApiCall";
import { HospitalURLParser } from "Utils/urls.utils";

export const CheckPatientExist = async (value) => {
  const response = modelCheckPatientExistAPI(
    await PostData(HospitalURLParser("/quickbooking/checkPatientExists"), value)
  );
  return response;
};

export const AddPatientByInstantBooking = async (values) => {
  const response = await PostData(
    HospitalURLParser("/quickbooking/addPatientByInstantBooking"),
    values
  );
  if (response) {
    return response;
  }
};

export const PostApponitmentCheckout = async (
  object,
  existing = 1,
  password = null
) => {
  const response = await PostData(
    HospitalURLParser("/quickbooking/appointment_checkout"),
    object
  );
  if (response) {
    const responseSuccess = await PostData(
      HospitalURLParser("/quickbooking/appointment_success"),
      { order_id: response?.data?.order_id, existing, password }
    );
    if (responseSuccess) return true;
  }
  return false;
};
