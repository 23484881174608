import { HospitalURLParser } from "Utils/urls.utils";
import { GetData, PostData } from "../../ApiCall";
import { 
  modelGetClinicPractitionerDaySlotsAPI, 
  modelUpdateClinicSlotDurationAPI 
} from "models/hospital/clinic-practitioner-schedule-times.models";

//new api
export const getMyPractitioners = async (search) => {
  const response = await GetData(
    HospitalURLParser(`/myPractitioners/${search}`)
  );
  if (response) {
    return response.data;
  }
};
export const getRequestedPractitioners = async (search) => {
  const response = await PostData(
    HospitalURLParser(`/pendingRequestPractitioners`),
    {search:search}
  );
  if (response) {
    return response.data;
  }
};

export const getPractitionerDetailsByUrlName = async (url_name) => {
  const response = await PostData(HospitalURLParser("/practitionerDetails"), {
    url_name,
  });
  if (response) {
    return response?.data[0];
  }
};

export const getAllPractitioners = async (start, limit, search = null) => {
  const response = await PostData(HospitalURLParser("/allPractitioners"), {
    start: start + "",
    limit: limit + "",
    search,
  });
  if (response) {
    return response.data;
  }
};

export const actionToPractitioner = async (practitioner_id, status) => {
  const response = await PostData(HospitalURLParser("/actionToPractitioner"), {
    practitioner_id,
    status,
  });
  if (response) {
    return response;
  }
};

export const removeMyPractitioner = async (practitioner_id) => {
  const response = await PostData(HospitalURLParser("/removeMyPractitioner"), {
    practitioner_id,
  });
  if (response) {
    return response;
  }
};

export const updatePractitionerConsultationFees = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/updatePractitionerAppointmentTypesAmount"),
    payload
  );
  if (response) {
    return response;
  }
};

export const updatePractitionerAutoAppointmentAndCheckin = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/updatePractitionerAutoAppointmentAndCheckin"),
    payload
  );
  if (response) {
    return response;
  }
};

export const updatePractitionerAppointmentTypes = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/updatePractitionerAppointmentTypes"),
    payload
  );
  if (response) {
    return response;
  }
};

export const updatePractitionerWorkingHours = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/updatePractitionerWorkingHours"),
    payload
  );
  if (response) {
    return response;
  }
};

export const updateClinicPractitionerSlotDuration = async (payload) => 
  modelUpdateClinicSlotDurationAPI( await PostData(
      HospitalURLParser("/updatePractitionerTimeDuration"), 
      payload
  ));

export const getClinicPractitionerDaySlots = async (payload) => 
  modelGetClinicPractitionerDaySlotsAPI( await PostData(
      HospitalURLParser('/getPractitionerDaySlot'),
      payload
  ));

export const postClinicPractitionerDaySlots = async (payload) =>
  modelUpdateClinicSlotDurationAPI( await PostData(
      HospitalURLParser('/postPractitionerDaySlots'),
      payload
  ));
