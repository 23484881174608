import React from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { updatePractitionerConsultationFees } from "Utils/Services/Hospital/Practitioner";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const ConsultationFee = ({ data, practitioner_id }) => {
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);

  const { inperson, phone, video } = data || {};
  let formValues = {
    inperson: inperson,
    phone: phone,
    video: video,
  };

  const onSubmit = async (values) => {
    setIsBtnLoading(true);

    const payload = {
      practitionerId: +practitioner_id.replace("SDP", ""),
      videoConsultationAmount: +values.video,
      phoneConsultationAmount: +values.phone,
      inpersonConsultationAmount: +values.inperson,
    };

    const response = await updatePractitionerConsultationFees(payload);
    if (response.status === 200) {
      // message.success("Consultation fees updated successfully");
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content: "Consultation fees updated successfully",
        style: {
          marginTop: "100px",
        },
      });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type : antdMessageTypes.ERROR,
        content: "Something went wrong",
        style: {
          marginTop: "100px",
        },
      });
    }
    setIsBtnLoading(false);
  };
  return (
    <Form
      style={{ marginTop: 20 }}
      layout="vertical"
      name="nest-messages"
      onFinish={onSubmit}
      initialValues={formValues}
    >
      {/* row starts here */}
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="In person consultation"
            name="inperson"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="Video consultation"
            name="video"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="Phone consultation"
            name="phone"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      {/* row ends here */}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: 40 }}
          loading={isBtnLoading}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConsultationFee;
