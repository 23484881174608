import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Empty, Input, List, Row, Col, Modal } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRanges, DATE_FORMAT, TableSkeletonLoader, getDateTimeFormat, getExpiryCounter } from "Utils/Constants";
import { getProcedureRequests } from "Utils/Services/Hospital/MedicalProcedures";
import ProcedureRequestCard from "views/components/procedures/ProcedureRequestCard";

const ProcedureRequests = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [activeRequest, setActiveRequest] = useState({});
  const [data, setData] = useState([]);

  const [DataLoading, setDataLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const appointmentsPerPage = 12;
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState([null, null]);

  const selectedProcedureRequestId = "all";

  const { RangePicker } = DatePicker;

  const handleViewClick = (req) => {
    setshowDetailsModal(true);
    setActiveRequest(req)
  }

  const FetchData = async () => {
    setDataLoading(true);
    const startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : "";
    const endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : "";
    const response = await getProcedureRequests({
      procedure_id: selectedProcedureRequestId,
      start: (pageNo - 1) * appointmentsPerPage,
      limit: appointmentsPerPage,
      search: searchValue,
      startDate,
      endDate,
    });

    if (response) {
      setData(response?.procedureRequests);
      setTotalAppointmentsCount(response.total_count);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    FetchData(); // eslint-disable-next-line
  }, [date, pageNo, selectedProcedureRequestId, searchValue]); // eslint-disable-next-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Procedure Requests");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalAppointmentsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalAppointmentsCount]);

  return (
    <div className="content">
      <Modal
        destroyOnClose={true}
        className="item-details-modal p-5"
        title="Procedure Request Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setshowDetailsModal(false)}
      >
        <div className="p-5">
        <h3 className="text-center text-2xl text-font-label">Procedure Request Details</h3>
          <table
            style={{borderSpacing:"5px",width:"100%"}}
          >
            <tbody>
            <tr>
              <th>Procedure: </th>
              <td>{activeRequest?.procedures}</td>
            </tr>
            <tr>
              <th>Patient: </th>
              <td>{activeRequest?.patient_name}</td>
            </tr>
            <tr>
              <th>Mobile: </th>
              <td>{activeRequest?.mobile}</td>
            </tr>
            <tr>
              <th>Email: </th>
              <td>{activeRequest?.email}</td>
            </tr>
            <tr>
              <th>Requested on: </th>
              <td>{getDateTimeFormat(activeRequest?.created_at)}</td>
            </tr>
            <tr>
              <th>Request Expiry date: </th>
              <td>{getDateTimeFormat(activeRequest?.max_date)} <span className="text-danger">{getExpiryCounter(activeRequest?.max_date)}</span></td>
            </tr>
            {
              activeRequest?.problem ? (
                <tr>
                  <th>Problem described:</th>
                  <td>{activeRequest?.problem !== "null" ? activeRequest?.problem: ""}</td>
                </tr>
              ):null
            }
            {
              activeRequest?.current_medications ? (
                <tr>
                  <th>Medications using now:</th>
                  <td>{activeRequest.current_medications!== "null" ? activeRequest?.current_medications: ""}</td>
                </tr>
              ):null
            }
            {
              activeRequest?.existing_medical_conditions ? (
                <tr>
                  <th>Patient Existing Medical Conditions:</th>
                  <td>{activeRequest.existing_medical_conditions!== "null" ? activeRequest?.existing_medical_conditions: ""}</td>
                </tr>
              ):null
            }
            {
              activeRequest?.alternative_contact ? (
                <tr>
                  <th>Provided alternative contact:</th>
                  <td>{activeRequest.alternative_contact !== "null" ? activeRequest?.alternative_contact : ""}</td>
                </tr>
              ):null
            }
            </tbody>
          </table>
        </div>
      </Modal>
      <div className="appointments-container">
        <Row
          className="appointments-page-header"
          style={{ justifyContent: "space-between", marginBottom: "20px" }}
          gutter={[0, 10]}
        >
          <Col lg={9} md={8} sm={12} xs={24}>
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>

          <Col lg={8} md={8} sm={12} xs={24}>
            <Input
              className="shadow-input"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>

        {DataLoading ? (
          TableSkeletonLoader(10, 60)
        ) : data?.length ? (
          <List
            pagination={{
              current: pageNo,
              total: totalAppointmentsCount,
              onChange: (page) => {
                setPageNo(page);
              },
              pageSize: appointmentsPerPage,
            }}
            className="appointments-page"
            dataSource={data}
            renderItem={(request) => (
              <List.Item>
                <ProcedureRequestCard request={request} handleClick={handleViewClick}/>
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No Procedures Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};
export default ProcedureRequests;
