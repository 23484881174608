import moment from "moment";
import { getDateTimeFormat } from "Utils/Constants";

export const modelAppointmentsApi = (resp) => {
  let result = {
    status: false,
    data: { appointments: [], count: 0 },
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp?.status !== 200) throw new Error("Invalid status");
    let { data } = resp || {};
    result.status = true;
    let { totalCount = 0, appointments = [] } = data || {};
    if (isFinite(Number(totalCount))) result.data.count = Number(totalCount);
    if (Array.isArray(appointments) && appointments.length) {
      result.data.appointments = appointments.map((appointment) => {
        let {
          appointment_id = "",
          appointment_date = "",
          appointment_status = "",
          token = "",
          clinic_id = "",
          clinic_name = "",
          appointment_types = "",
          first_name = "",
          last_name = "",
          auto_checkin_status = undefined,
          patient_relation_name = "",
          relation_name = "",
        } = appointment || {};
        return {
          appointmentId: appointment_id || "",
          patientName: `${first_name} ${last_name}`,
          status: appointment_status || "",
          date: appointment_date || "",
          hospitalName: clinic_name || "",
          appointmentType: appointment_types || "",
          clinicId: clinic_id || "",
          token: token || "",
          checkinFeature: auto_checkin_status || false,
          patientRelationName: patient_relation_name || "",
          relationName: relation_name || ""
        };
      });
    }
  } catch {
    result = {
      status: false,
      data: { appointments: [], count: 0 },
    };
  } finally {
    return result;
  }
};

export const modelStatusTypesApi = (resp) => {
  let result = {
    statusTypes: [],
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp.status !== 200) throw new Error("Invalid status");
    let { data } = resp || {};
    if (Array.isArray(data) && data.length) {
      result.statusTypes = data.map((item) => {
        let { status = "", name = "" } = item || {};
        return {
          id: status,
          label: name,
        };
      });
    }
  } catch {
    result = {
      statusTypes: [],
    };
  } finally {
    return result;
  }
};

export const modelAppointmentDetailsAPI = (resp) => {
  let result = {
    status: false,
    data: {
      general: {},
      prescription: {},
      history:{},
      reports: {
        reports:[],
        reportText:""
      },
    },
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    let { data, status } = resp || {};
    if (status !== 200) throw new Error("Invalid status");
    if (typeof data !== "object") throw new Error("Invalid data");
    result.status = true;
    result.data.general = {
      token: data?.token || "",
      patientId: data?.patient_id || "",
      appointmentId: data?.appointment_id || "",
      patientName: data?.patient_name || "",
      patientRelationName: data?.patient_relation_name || "",
      relationName: data?.relation_name || "",
      dob: data?.dob || "",
      date: moment(data?.appointment_date).isValid()
        ? moment(data?.appointment_date).format("YYYY-MM-DD")
        : "",
      time: moment(data?.appointment_date).isValid()
        ? moment(data?.appointment_date).format("hh:mm A")
        : "",
      type: data?.app_type || "",
      hospitalName: data?.clinic_name || "",
      bookedOn: moment(data?.date).isValid()
        ? getDateTimeFormat(data?.date)
        : "",
      status: data?.appointment_status || "",
      onlinePayment: data?.amount || "",
      offlinePayment: data?.payment || "",
      observation: data?.doctor_observation || "",
      autoCheckin: data?.auto_checkin_status || false,
    };
    result.data.prescription = {
      data: data?.prescription || "",
      prescriptionUrl: data?.prescription_url || ""
    };
    result.data.history = {
      problems:data?.problems,
      symptoms:data?.symptoms,
      existingMedicalConditions:data?.existing_medical_conditions,
      allergies:data?.allergies,
      currentMedications:data?.current_medications,
      previousSurgeries:data?.previous_surgeries
    }
    if (Array.isArray(data?.reports) && data?.reports.length) {
      result.data.reports.reports = data?.reports || [];
    }
    data.report_text && (result.data.reports.reportText = data.report_text);
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelChangeAppointmentStatusAPI = (resp) => {
  let result = {
    status: false,
    isRedirect: false,
    redirectUrl: "",
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp.status !== 200) throw new Error("Invalid status");
    let { data } = resp || {};
    if (typeof data !== "object") throw new Error("Invalid data");
    if (data.success === true) result.status = true;
    if (data?.redirectUrl) {
      result.isRedirect = true;
      result.redirectUrl = data?.redirectUrl;
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGetReportsAPI = (resp) => {
  let result = {
    status: false,
    data: { 
      reports: [],
      reportText : "" 
    },  
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp.status !== 200) throw new Error("Invalid status");
    let { data = [], report_text = "" } = resp || {};
    result.status = true;
    report_text && (result.data.reportText = report_text);
    
    if (Array.isArray(data) && data.length) {
      result.data.reports = data || [];
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelSaveReportsAPI = (resp) => {
  let result = {
    status: false,
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp.status !== 200) throw new Error("Invalid status");
    let { data = {} } = resp || {};
    if (data?.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelAppointmentPrescriptionAPI = (resp) => {
  let result = {
    status: false,
    data: {
      prescription: [],
      observation: "",
      appointmentStatus: "",
      advice: "",
      bp: "",
      clinicalFindings: "",
      diagnosis: "",
      followUp:""
    },
    history:{}
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp.status !== 200) throw new Error("Invalid status");
    let { data = {} } = resp || {};
    result.status = true;
    if (Array.isArray(data.prescription) && data.prescription.length) {
      result.data.prescription = data.prescription.map((item) => {
        let {
          medicine_name,
          dose,
          unit,
          route,
          freequency,
          duration,
          directions,
          composition,
          concentration,
          type
        } = item || {};
        return {
          medicine_name: medicine_name || "",
          dose: dose || "",
          unit: unit || "",
          route: route || "",
          frequency: freequency || "",
          duration: duration || "",
          directions: directions || "",
          composition: composition|| "",
          concentration: concentration|| "",
          type: type|| ""
        };
      });
    }
    result.data.observation = data?.observation || "";
    result.data.appointmentStatus = data?.appointment_status || "";
    result.data.advice = data?.advice || "";
    result.data.bp = data?.bp || "";
    result.data.clinicalFindings = data?.clinical_findings || "";
    result.data.diagnosis = data?.diagnosis || "";
    result.data.followUp = moment(data?.follow_up).isValid() ? moment(data?.follow_up) : "";

    result.history = {
      problems : data?.problems || "",
      symptoms : data?.symptoms || "",
      existingMedicalConditions : data?.existing_medical_conditions || "",
      allergies : data?.allergies || "",
      currentMedications : data?.current_medications || "",
      previousSurgeries : data?.previous_surgeries || "",
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelUpdateAppointmentPrescriptionAPI = (resp) => {
  let result = {
    status: false,
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp.status !== 200) throw new Error("Invalid status");
    let { data = {} } = resp || {};
    if (data?.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGetAppointmentPdfAPI = (response) => {
  let result = {
    status: false,
    path: "",
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid status");
    let { data = {} } = response || {};
    result.status = true;
    result.path = data?.pdf_url || "";
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelShareAppointmentDetailsAPI = (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid status");
    let { data = {} } = response || {};
    if (data?.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGetPrescriptionInstructionsAPI = (response) => {
  let result = {
    status: false,
    data: {
      dose: [],
      unit: [],
      frequency: [],
      duration: [],
      directions: [],
      route: [],
      medicines: [],
    },
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid status");
    let { data = {} } = response || {};
    result.status = true;
    result.data.dose = data?.dose || [];
    result.data.unit = data?.unit || [];
    result.data.frequency = data?.freequency || [];
    result.data.duration = data?.duration || [];
    result.data.directions = data?.directions || [];
    result.data.route = data?.route || [];
    if (Array.isArray(data?.medicine_names)) {
      result.data.medicines = data?.medicine_names.map((item) => {
        return {
          value: item?.medicine_name,
          label: item?.medicine_name,
        };
      });
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelAddPrescriptionAPI = (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid status");
    let { data = {} } = response || {};
    if (data?.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGetMedicinesAPI = (response)=>{
  let result={
    status:false,
    data:{
      medicine:[]
    }
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid status");
    let { data = {} } = response || {};
    result.status = true;
  
    if (Array.isArray(data?.medicine_names)) {
      result.data.medicine = data?.medicine_names.map((item) => {
        return {
          value: item?.medicine_name,
          label: item?.medicine_name,
        };
      });
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
}



export const modelGetRefundDetailsAPI = (resp) => {
  let result = {
    status: false,
    data: {
      refundId: "",
      paymentId: ""
    },
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    let { data } = resp || {};
    if (typeof data !== "object") throw new Error("Invalid data");
    result.status = true;
    result.data.refundId = resp.data.refund_id;
    result.data.paymentId = resp.data.payment_id;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGetRefundDataAPI = (resp) => {
  let result = {
    status: false,
    data: {
      amount: "",
      status: ""
    },
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    let { data } = resp || {};
    if (typeof data !== "object") throw new Error("Invalid data");
    result.status = true;
    result.data.amount = resp.data.amount;
    result.data.status = resp.data.status;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};


export const modelGetPatientUploadedReports = (resp) => {
  let result = {
    status: false,
    data: [],
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    let { data } = resp || {};
    if (typeof data !== "object") throw new Error("Invalid data");
    result.status = true;
    if (Array.isArray(data)) {
      result.data = data.map((item) => {
        return {
          report: item?.report,
          title: item?.title,
        };
      });
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
