import React,{useState, useEffect} from "react";
import { Empty } from "antd";

const PrescriptionTab = ({ data }) => {
  //let { data: prescription = "" } = data?.data || {};
  let prescription = data?.data || "";
  const  prescriptionUrl = data?.prescriptionUrl || "";
  const [displayPdf, setDisplayPdf] = useState(false);
  useEffect(()=>{
    setTimeout(()=>{setDisplayPdf(true)},500)
    return(()=>setDisplayPdf(false))
  },[])
  return (
    <div className="prescription-container">

      {
       prescriptionUrl ? displayPdf && <div>
        <iframe
          title="PDF Viewer"
          width="100%"
          height="600px"
          src={prescriptionUrl}
          frameBorder="0"
          scrolling="auto"
        ></iframe>
      </div>
      :
      <h1>
        {prescription ? (
          <div dangerouslySetInnerHTML={{ __html: prescription }} />
        ) : (
          <Empty
            description="No Prescriptions Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </h1>
      }
    </div>
  );
};

export default PrescriptionTab;
