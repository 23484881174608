import { LabURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";
import {
  modelNotificationsAPI,
  modelChangeLabNotificationStatusAPI,
} from "models/lab/notifications.api.models";

export const getLabNotifications = async (payload) =>
  modelNotificationsAPI(
    await PostData(LabURLParser("/getNotifications"), payload)
  );

export const changeLabNotificationStatus = async (payload) =>
  modelChangeLabNotificationStatusAPI(
    await PostData(LabURLParser("/changeNotificationStatus"), payload)
  );
