import {
  modelAllHospitalsListAPI,
  modelMyHospitalsListAPI,
  modelMyhospitalDetailsAPI,
  modelUpdateRequestStatusAPI,
  modelUpdateMyHospitalSettingsAPI,
} from "models/practitioner/hospitals.api.models";
import { PractitionerURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";

export const getMyHospitals = async (payload) =>
  modelMyHospitalsListAPI(
    await PostData(PractitionerURLParser("/getMyHospitals"), payload)
  );
export const getAllHospitals = async (payload) =>
  modelAllHospitalsListAPI(
    await PostData(PractitionerURLParser("/getAllHospitals"), payload)
  );
export const getRequestedHospitals = async (payload) =>
  modelAllHospitalsListAPI(
    await PostData(PractitionerURLParser("/pendingRequestHospitals"), payload)
  );

export const getMyHospitalDetails = async (clinicId) =>
  modelMyhospitalDetailsAPI(
    await PostData(PractitionerURLParser("/getMyHospitalDetails"), {
      clinic_id: clinicId,
    })
  );
export const getAllHospitalDetails = async (clinicId) =>
  modelMyhospitalDetailsAPI(
    await PostData(PractitionerURLParser("/getAllHospitalDetails"), {
      clinic_id: clinicId,
    })
  );

export const updateRequestStatus = async (payload) =>
  modelUpdateRequestStatusAPI(
    await PostData(PractitionerURLParser("/updateRequestStatus"), payload)
  );

export const removeMyHospital = async (clinicId) =>
  modelUpdateRequestStatusAPI(
    await PostData(PractitionerURLParser("/removeMyHospital"), {
      clinic_id: clinicId,
    })
  );

export const updateMyHospitalSettings = async (payload) =>
  modelUpdateMyHospitalSettingsAPI(
    await PostData(PractitionerURLParser("/updateMyHospitalSettings"), payload)
  );
