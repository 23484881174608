import React from "react";

const Expired = () => {
  return (
    <div className="subscription-content">
      <h5>Your Subscription is Expired</h5>
      <p>Your account subscription has been expired.</p>
    </div>
  );
};

export default Expired;
