import React from "react";
import PaymentsContainer from "./PaymentsContainer";
import { PaymentsContextProvider } from "./usePayments";

const OnlinePayments = () => {
  return (
    <PaymentsContextProvider>
      <PaymentsContainer />
    </PaymentsContextProvider>
  );
};

export default OnlinePayments;
