import PackageIcon from "Images/dashboard-icons/medical_package.png";
import PatientIcon from "Images/dashboard-icons/patient.svg";
import React, { useState } from "react";
import {
  AppointmentStatusFormatter,
  getDateTimeFormat,
  getTimeFormat,
  SetLabCardAppointmentButtons,
} from "Utils/Constants";
import moment from "moment";
import { message, Modal, Card, DatePicker } from "antd";
import { changeAppointmentStatus } from "Utils/Services/Lab/Appointments";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const AppointmentLabCard = ({
  appointment,
  FetchData,
  handleViewClick,
  history,
  today,
}) => {
  const [ShowCheckInModal, setShowCheckInModal] = useState(false);
  const [CheckinDateTime, setCheckinDateTime] = useState(moment);

  const changeStatus = (needed_status) => {
    let payload = {
      appointment_id: appointment.appointment_id,
      needed_status: needed_status,
    };

    if (needed_status === "check-in") {
      payload.checkin_time = moment.utc(CheckinDateTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    changeAppointmentStatus(payload).then((response) => {
      if (response && response?.status === 200) {
        if (needed_status === "approved") {
          // message.success("Appointment approved successfully");
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content : "Appointment approved successfully",
          });
        }
        if (needed_status === "cancelled") {
          //message.success("Appointment cancelled successfully");
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content : "Appointment cancelled successfully",
          })
        }
        if (needed_status === "check-in") {
          //message.success("Appointment checked in successfully");
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content : "Appointment checked in successfully",
          })
        }

        FetchData();
      } else {
        //message.warning("Something went wrong");
        AntdCustomMessage({
          type : antdMessageTypes.WARNING,
          content : "Something went wrong",
        })
      }
    });
  };

  const ActionFormatter = (appointment) => {
    return SetLabCardAppointmentButtons(
      appointment,
      history,
      changeStatus,
      setShowCheckInModal
    );
  };

  const {
    appointment_id,
    appointment_status,
    patient_name,
    package_name,
    booked_on,
    payment_method,
    test_name,
  } = appointment;
  return (
    <>
      <Modal
        className="item-details-modal"
        title="Check In Details"
        visible={ShowCheckInModal}
        width={"40%"}
        onOk={() => {
          changeStatus("check-in", CheckinDateTime);
        }}
        onCancel={() => setShowCheckInModal(false)}
        okText="Check In"
      >
        <Card bordered={false} title="Check In Time">
          <DatePicker
            style={{ width: "100%" }}
            use12Hours={true}
            showTime={{ format: "h:mm A" }}
            defaultValue={CheckinDateTime}
            format="DD-MM-YYYY h:mm A"
            onChange={(date) => setCheckinDateTime(date)}
            //disable past dates other than today
            disabledDate={(current) => {
              return current && current < moment().startOf("day");
            }}
          />
        </Card>
      </Modal>
      <div className="appointment-card lab">
        <div
          className="appointment-card-head"
          onClick={() => handleViewClick(appointment)}
        >
          <div className="appointment-card-header">
            <span className="appointment-id">{appointment_id}</span>
            {test_name ? <span className="appointment-time bg-success text-white ml-1">TEST BOOKING</span> :null}
            <span className="capitalize patient-name text-ellipsis">
              <img src={PatientIcon} alt="k" />
              {patient_name}
            </span>
          </div>
          <span className="capitalize practitioner-name text-ellipsis">
            <img src={PackageIcon} alt="k" /> {package_name || test_name}
          </span>
          <div className="appointment-card-right">
            <span className="appointment-time">
              {today ? getTimeFormat(booked_on) : getDateTimeFormat(booked_on)}
            </span>
            <span className="appointment-time capitalize">
              {payment_method}
            </span>
            <span className="appointment-status">
              {AppointmentStatusFormatter(appointment_status)}
            </span>
          </div>
        </div>
        {ActionFormatter(appointment)}
      </div>
    </>
  );
};

export default AppointmentLabCard;
