import React, { useEffect, useState, useContext } from "react";
import { Pagination, Empty } from 'antd';
import { GlobalState } from "context-api";
import { getLabReviews } from "Utils/Services/Lab/LabReviews";
import ReviewCard from "views/pages/Hospital/Reviews/ReviewCard";


const LabReviews = () => {
    const [reviews, setReviews] = useState([]);
    const { UserData, setHeading, Heading, setHeadingCount } = useContext(GlobalState);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9; 

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderItems = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentItems = reviews.slice(startIndex, endIndex);
      return (
        <div 
          style = {{
              width:"100%", 
              display:"flex", 
              flexDirection:"row", 
              flexWrap:"wrap"
          }}
        >
          {
              currentItems.map((item, index) => (
                  <>  
                      <div 
                          key = {`review-${index}`} 
                          style = {{
                            width: "300px",
                            margin: "5px"
                          }}
                      >
                          <ReviewCard review={item} />
                      </div>
                  </>
              ))
          }           
        </div>
      )
    };
    
    const fetchLabReviews = async () => {
        const response = await getLabReviews({labId:UserData.userid});
        setReviews([...response.data.reviews])
    }

    useEffect(()=>{
        fetchLabReviews();
    },[]);

    useEffect(() => {
        if (setHeading && Heading) {
        setHeading("Reviews");
        }
    }, [setHeading, Heading]);

    useEffect(() => {
        setHeadingCount(reviews.length);
        return () => {
            setHeadingCount(0);
        };
    }, [reviews]);
    if(reviews.length === 0){
        return <div className="content d-flex justify-content-center align-items-center">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No reviews found"
            />
        </div>
    }
    return (
      <div className="content">
          {renderItems()}
          <Pagination
          current={currentPage}
          total={reviews.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
          />
      </div>
    );
};

export default LabReviews;
