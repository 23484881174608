import { PostData, GetData } from "Utils/ApiCall";
import { modelCheckPatientExistAPI } from "models/quickbooking.api.models";
import { LabURLParser } from "Utils/urls.utils";

export const CheckPatientExist = async (value) => {
  const response = modelCheckPatientExistAPI(
    await PostData(LabURLParser("/patient/checkPatientExists"), value)
  );
  return response;
};

export const AddPatientByInstantBooking = async (values) => {
  const response = await PostData(
    LabURLParser("/patient/addPatientByInstantBooking"),
    values
  );
  if (response) {
    return response;
  }
};

export const getCountryDialercodesLab = async () => {
  const response = await GetData(
    LabURLParser("/patient/getCountryDialercodes")
  );
  if (response) {
    return response.data;
  }
};
