import React from "react";
import PatientIcon from "Images/dashboard-icons/patient.svg";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  getDateTimeFormat,
  getTimeFormat,
  getExpiryCounter,
} from "Utils/Constants";

const ProcedureRequestCard = ({ request, today, handleClick }) => {
  const { patient_name, max_date, created_at, procedures, mobile, email, procedure_id } =
    request;

  return (
    <div className="appointment-card" style={!today ? { minHeight: 115 } : {}}
      onClick={()=>{handleClick(request)}}
    >
      <div className="appointment-card-head" style={{ minHeight: "auto" }}>
        <div className="appointment-card-header">
          <span className="appointment-procedure_name">{procedures}</span>
          <span className="patient-name capitalize text-ellipsis">
            <img src={PatientIcon} alt="k" />
            {patient_name}
          </span>
          {!today && (
            <div style={{ marginTop: 5 }}>
              <span className="patient-mobile">
                <PhoneOutlined style={{ marginRight: 5 }} />
                {mobile}
              </span>
              <span className="patient-email">
                <MailOutlined style={{ marginRight: 5 }} /> {email}
              </span>
            </div>
          )}
        </div>

        <div className="appointment-card-right">
          <span className="appointment-time">
            {today ? getTimeFormat(created_at) : getDateTimeFormat(created_at)}
          </span>
          <span className="expiry-time">{getExpiryCounter(max_date)}</span>
          {!today && (
            <span className="expiry-time expiry-appointment-time">
              {getDateTimeFormat(max_date)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProcedureRequestCard;
