import { Empty, Input, List } from "antd";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TableSkeletonLoader, EncriptString } from "Utils/Constants";
import { getAllPatients } from "Utils/Services/Lab/Patients";
import ListCard from "views/components/patients/ListCard";

const Patients = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);

  const [Data, setData] = useState([]);
  const [DataLoading, setDataLoading] = useState(true);
  const [Pageno, setPageNo] = useState(1);
  const patientsPerPage = 12;
  const [totalPatientsCount, setTotalPatientsCount] = useState(0);
  const [SearchKeyword, setSearchKeyword] = useState("");

  const history = useHistory();

  const CardOnClick = (item) => {
    history.push(`/lab/patient-details/${EncriptString(item.patient_id)}`);
  };

  const FetchData = async () => {
    setDataLoading(true);
    const response = await getAllPatients({
      start: (Pageno - 1) * patientsPerPage,
      limit: patientsPerPage,
      search: SearchKeyword,
    });
    if (response) {
      const data = response.patients;
      setData(
        data.sort(function (a, b) {
          if (a.patient_name < b.patient_name) {
            return -1;
          }
          if (a.patient_name > b.patient_name) {
            return 1;
          }
          return 0;
        })
      );

      setTotalPatientsCount(response.total_count);
    }

    setDataLoading(false);
  };

  useEffect(() => {
    FetchData();
  }, [Pageno, SearchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Patients");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalPatientsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalPatientsCount]);

  return (
    <div className="content">
      <div className="card-list-header patient">
        {/* <p>{totalPatientsCount + " "} Patients found</p> */}
        <Input
          placeholder="Search patients.."
          className="card-search"
          value={SearchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </div>
      <div className="patients-list md-mt-35">
        {DataLoading ? (
          TableSkeletonLoader(10, 60)
        ) : Data?.length ? (
          <List
            dataSource={Data}
            pagination={{
              current: Pageno,
              total: totalPatientsCount,
              onChange: (page) => {
                setPageNo(page);
              },
              pageSize: patientsPerPage,
            }}
            renderItem={(item) => (
              <List.Item>
                <ListCard patient={item} CardOnClick={CardOnClick} />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No Patients Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};
export default Patients;
