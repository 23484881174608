import moment from "moment";
/* eslint-disable */
export const modelMyHospitalsListAPI = (data) => {
  let result = {
    status: false,
    data: {
      count: 0,
      hospitals: [],
    },
  };
  try {
    if (typeof data !== "object") throw new Error("Invalid data type");
    let { data: responseData = {}, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    let { totalCount, my_hospitals } = responseData;
    result.status = true;
    if (isFinite(Number(totalCount))) result.data.count = totalCount;
    if (Array.isArray(my_hospitals) && my_hospitals?.length) {
      result.data.hospitals = my_hospitals.map(
        ({
          id = 0,
          clinic_name = "",
          address = "",
          clinic_profile_image = "",
          about_us = "",
        } = {}) => ({
          id,
          name: clinic_name,
          location: address,
          image: clinic_profile_image,
          about: about_us,
        })
      );
    }
  } catch {
    result = {
      status: false,
      data: {
        count: 0,
        hospitals: [],
      },
    };
  } finally {
    return result;
  }
};
export const modelAllHospitalsListAPI = (data) => {
  let result = {
    status: false,
    data: {
      count: 0,
      hospitals: [],
    },
  };
  try {
    if (typeof data !== "object") throw new Error("Invalid data type");
    let { data: responseData = {}, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    let { totalCount, all_hospitals } = responseData;
    result.status = true;
    if (isFinite(Number(totalCount))) result.data.count = totalCount;
    if (Array.isArray(all_hospitals) && all_hospitals?.length) {
      result.data.hospitals = all_hospitals.map(
        ({
          id = 0,
          clinic_name = "",
          address = "",
          clinic_profile_image = "",
          about_us = "",
          request_status = "",
          requested_by = "",
        } = {}) => ({
          id,
          name: clinic_name,
          location: address,
          image: clinic_profile_image,
          about: about_us,
          requestStatus: request_status,
          requestedBy: requested_by,
        })
      );
    }
  } catch {
    result = {
      status: false,
      data: {
        count: 0,
        hospitals: [],
      },
    };
  } finally {
    return result;
  }
};

export const modelMyhospitalDetailsAPI = (data) => {
  let result = {
    status: false,
    data: {},
  };
  try {
    if (typeof data !== "object") throw new Error("Invalid data type");
    let { data: responseData = {}, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    let {
      id = 0,
      contact_person_first_name = "",
      contact_person_last_name = "",
      clinic_name = "",
      email = "",
      mobile = "",
      address = "",
      about_us = "",
      available_services = [],
      my_working_hours = [],
      available_departments = [],
      auto_checkin_status = 0,
      video_enable_status = 1,
      phone_enable_status = 1,
      inperson_enable_status = 1,
      auto_appointment_status = 0,
      booking_amount = [], // {id, fee, name}
      clinic_profile_image = "",
    } = responseData || {};
    result.status = true;
    let consultationFee = {
      inperson: {},
      video: {},
      phone: {},
    };
    if (Array.isArray(booking_amount) && booking_amount.length) {
      booking_amount.map(({ id = 0, fee = 0, name = "" }) => {
        if (id === 1) consultationFee.inperson = { id, amount: fee, name };
        if (id === 3) consultationFee.video = { id, amount: fee, name };
        if (id === 4) consultationFee.phone = { id, amount: fee, name };
      });
    }
    if (Array.isArray(my_working_hours) && my_working_hours.length > 0) {
      my_working_hours = my_working_hours.map((time, index) => {
        let day = moment.weekdays(index);
        return {
          day,
          time,
        };
      });
    } else {
      my_working_hours =
        moment.weekdays().map((day) => ({
          day,
          time: "",
        })) || [];
    }
    result.data = {
      id,
      contactPerson: contact_person_first_name + " " + contact_person_last_name,
      name: clinic_name,
      email,
      mobile,
      address,
      about: about_us,
      services: available_services,
      workingHours: my_working_hours,
      departments: available_departments,
      autoCheckin: auto_checkin_status ? true : false,
      videoConsultation: video_enable_status ? true : false,
      phoneConsultation: phone_enable_status ? true : false,
      inPersonConsultation: inperson_enable_status ? true : false,
      autoAppointment: auto_appointment_status ? true : false,
      bookingAmount: consultationFee,
      profileImage: clinic_profile_image,
    };
  } catch {
    result = {
      status: false,
      data: {},
    };
  } finally {
    return result;
  }
};

export const modelAllhospitalDetailsAPI = (data) => {
  let result = {
    status: false,
    data: {},
  };
  try {
    if (typeof data !== "object") throw new Error("Invalid data type");
    let { data: responseData = {}, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    let {
      id = 0,
      contact_person_first_name = "",
      contact_person_last_name = "",
      clinic_name = "",
      email = "",
      mobile = "",
      address = "",
      about_us = "",
      available_services = [],
      working_hours = [],
      available_departments = [],
      clinic_profile_image = "",
    } = responseData || {};
    result.status = true;
    result.data = {
      id,
      contactPerson: contact_person_first_name + " " + contact_person_last_name,
      name: clinic_name,
      email,
      mobile,
      address,
      about: about_us,
      services: available_services,
      workingHours: working_hours,
      departments: available_departments,
      profileImage: clinic_profile_image,
    };
  } catch {
    result = {
      status: false,
      data: {},
    };
  } finally {
    return result;
  }
};

export const modelUpdateRequestStatusAPI = (response) => {
  let result = {
    status: 500,
    message: "",
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid data type");

    result.status = response.status;
    result.message = response.message;
  } catch (e) {
    result.status = response.status;
    result.message = response.message;
  } finally {
    return result;
  }
};

// export const modelUpdateRequestStatusAPI = (response) => {
//   let result = {
//     status: false,
//     data: {
//       success: false,
//     },
//   };
//   try {
//     if (typeof response !== "object") throw new Error("Invalid data type");
//     if (response.status !== 200) throw new Error("invalid status");
//     result.status = true;
//     if (response.success === true) result.data.success = true;
//   } catch (e) {
//     result.status = false;
//   } finally {
//     return result;
//   }
// };

export const modelUpdateMyHospitalSettingsAPI = async (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (responseData.success === true) result.status = true;
  } catch (e) {
    result.status = false;
  } finally {
    return result;
  }
};
