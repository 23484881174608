import { Col, Row, Tooltip } from "antd";
import React from "react";
import ReadIcon from "Images/icons/read.png";
import UnReadIcon from "Images/icons/unread.png";
import {
  getNotificationType,
  getNotificationFromNow,
  getDateTimeFormat,
} from "Utils/Constants";

const NotificationCard = ({ notification, CardOnClick }) => {
  return (
    <div
      className={`notification-card${notification.read === 0 ? " unread" : ""}`}
      onClick={() => CardOnClick(notification)}
    >
      <Row>
        <Col lg={2} className="read-icon">
          <img
            src={notification.read === 0 ? UnReadIcon : ReadIcon}
            alt="read"
          />
        </Col>

        <Col lg={13} className="subject">
          <span style={notification.read === 0 ? { fontWeight: "bold" } : {}}>
            {notification.subject}
          </span>
        </Col>
        <Col lg={5} className={`type`}>
          <span> {getNotificationType(notification.type)}</span>
        </Col>
        <Col lg={4}>
          <Tooltip title={getDateTimeFormat(notification.created_at)}>
            {getNotificationFromNow(notification.created_at)}
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationCard;
