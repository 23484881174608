import { Button, Card, Col, Form, Input, message, Row } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import FullWidthView from "components/FullWidthView";
import Spinner from "components/Spinner";
import { modelUpdateWorkingHoursPayload } from "models/practitioner/profile.api.model";
import React, { useEffect, useState } from "react";
import {
  getPractitionerConsultingHospitals,
  getPractitionerWorkingHours,
  updateWorkingHours,
} from "Utils/Services/Practitioner/Profile";

const WorkingHours = () => {
  const [workingHours, setWorkingHours] = useState({
    status: false,
    data: { workingHours: [] },
  });
  const [myHospitals, setMyHospitals] = useState({ status: false, data: [] });
  const [selectedHospital, setSelectedHospital] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const getWorkingHours = async (clinicId) => {
    setIsLoading(true);
    let response = await getPractitionerWorkingHours({
      clinic_id: clinicId,
    });
    response && setWorkingHours(response);
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    let hospitalsResponse = await getPractitionerConsultingHospitals();
    hospitalsResponse && setMyHospitals(hospitalsResponse);
    if (hospitalsResponse?.status && hospitalsResponse?.data?.length) {
      setSelectedHospital(hospitalsResponse?.data[0]?.id);
      getWorkingHours(hospitalsResponse?.data[0]?.id);
    } else setIsLoading(false);
  };

  const onSubmit = async () => {
    setIsBtnLoading(true);
    let response = await updateWorkingHours(
      modelUpdateWorkingHoursPayload({
        workingHours: workingHours.data.workingHours,
        clinic_id: selectedHospital,
      })
    );
    if (response?.status) {
      //message.success("Working hours updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Working hours updated successfully"
      });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
      });
    }
    setIsBtnLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  let { workingHours: workingHoursArray = [] } = workingHours.data || {};
  return (
    <Card>
      <Form layout="vertical" name="nest-messages">
        {/* row ends here */}

        <div className="buttons-container-times-marking">
          {myHospitals?.status &&
            myHospitals.data.map((item) => (
              <Button
                key={item.id}
                className={selectedHospital === item.id ? "tab" : ""}
                onClick={() => {
                  setSelectedHospital(item.id);
                  getWorkingHours(item.id);
                }}
              >
                {item.label}
              </Button>
            ))}
        </div>

        {workingHours?.status &&
          workingHoursArray.length &&
          workingHoursArray.map((item, index) => (
            <Row gutter={{ sm: 8, lg: 24 }} key={`${item.day}`}>
              <Col xs={24} sm={24} lg={20}>
                <Form.Item label={item.day}>
                  <Input
                    placeholder="Enter Working Hours"
                    value={item.time}
                    onChange={(e) => {
                      let newWorkingHoursArray = workingHoursArray;
                      newWorkingHoursArray[index].time = e.target.value;
                      setWorkingHours({
                        ...workingHours,
                        data: {
                          ...workingHours.data,
                          workingHours: newWorkingHoursArray,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ height: 40 }}
            loading={isBtnLoading}
            onClick={() => onSubmit()}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default WorkingHours;
