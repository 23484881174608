import React, { useEffect, useState, useContext } from "react";
import { GlobalState } from "context-api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  addHospitalProcedureFaqs,
  deleteHospitalProcedureFaqs,
  getActiveProcedures,
  getHospitalProcedureFaqs,
  updateHospitalProcedureFaqs,
  updateMedicalProcedure,
} from "Utils/Services/Hospital/MedicalProcedures";
import { 
  Select, 
  Button, 
  Divider, 
  Typography, 
  Alert, 
  Card,
  Form,
  Row,
  Col,
  Input, 
  message,
  Popconfirm,
  Table,
} from "antd";
import { useHistory } from "react-router";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const EditMedicalProcedure = (props) => {
  const { Option } = Select;
  const history = useHistory();

  const { setHeading, Heading } = useContext(GlobalState);
  const { procedure_id, entry_id } = props.match.params;

  const [seletcedProcedure, setSelectedProcedure] = useState(null);
  const [data, setData] = useState([]);
  const [DataLoading, setDataLoading] = useState(true);
  const { Title } = Typography;
  const [postStatus, setPostStatus] = useState({ status: null, message: "" });

  const [isAddFaq, setIsAddFaq] = useState(false);
  const [isEditFaq, setIsEditFaq] = useState(false);
  const [faqs,setFaqs] = useState([]);
  const [faq, setFaq] = useState({
    title:"",
    description:"",
  });

  const updateProcedure = async () => {
    setDataLoading(true);
    const response = await updateMedicalProcedure(entry_id, seletcedProcedure);
    response && setPostStatus({
      status: response.status,
      message: response.data.message,
    });
    response?.status === 200 &&
      setTimeout(() => history.push("/hospital/medical-procedures"), 1000);
    setDataLoading(false);
  };

  const handleToggleIsAddFaq = () => {
    setIsAddFaq(prev=>!prev)
  };

  const handleToggleIsEditFaq = () => {
    setIsEditFaq(prev=>!prev);
  }

  const handleConfirmAddFaq = () => {
    setFaq({
      id: "",
      title: "",
      description: ""
    });
    handleToggleIsAddFaq();
  }

  const handleConfirmEditFaq = async (row) => {
    handleToggleIsEditFaq();
    setFaq({
      id:row.id, 
      title:row.title,
      description:row.description
    });
  }

  const FetchProcedureFaqs = async () => {
    const faqsResponse = await getHospitalProcedureFaqs({id:procedure_id});
    faqsResponse && setFaqs(faqsResponse);
  };

  const handleAddFaq = async (values) => {
    try{
        const payload = {
            "procedure_id": procedure_id,
            "title":values.title,
            "description":values.description,
        };
        const response = await addHospitalProcedureFaqs(payload);
        if(response?.status == 200){
            //message.success(response.data.message);
            AntdCustomMessage({
              type : antdMessageTypes.SUCCESS,
              content :response.data.message,
            });
            handleToggleIsAddFaq();
            FetchProcedureFaqs();
        }else{
            //message.error(response.message);
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content : response.message,
            });
        }
    }catch(e){
        //message.error(e.message || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || "Something went wrong.",
        });
    }    
  }
  
  const handleUpdateFaq = async (values) => {
    try{
        const payload = {
          "id": values.id,
          "title":values.title,
          "description":values.description,
          "active":"1",
        };
        const response = await updateHospitalProcedureFaqs(payload);
        if(response?.status == 200){
            //message.success(response.data.message);
            AntdCustomMessage({
              type : antdMessageTypes.SUCCESS,
              content :response.data.message,
            });
            window.location.reload();
        }else{
            //message.error(response.message);
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content :response.message,
            });
        }
    }catch(e){
        //message.error(e.message || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || "Something went wrong.",
        });
    }    
  }

  const handleDeleteFaq = async (row) => {
    try{
        const deleteResponse = await deleteHospitalProcedureFaqs({id: row.id});
        //message.success(deleteResponse.data);
        AntdCustomMessage({
          type : antdMessageTypes.SUCCESS,
          content :deleteResponse.data,
        });
        window.location.reload();
    }catch(e){
        //message.error(e.message || e.data || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || e.data || "Something went wrong.",
        });
    }
    
  };

  useEffect(() => {
    const FetchData = async () => {
      setDataLoading(true);
      const { procedures } = await getActiveProcedures();
      procedures && setData(procedures);
      setDataLoading(false);
      setSelectedProcedure(+procedure_id);
    };
    if (procedure_id) {
      FetchData();
      FetchProcedureFaqs();
    }
  }, [procedure_id]);

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Procedures");
    }
  }, [setHeading, Heading]);

  const columns = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (_,row) =>{
            return row.id
        },
    },
    {
        title: 'Question',
        dataIndex: 'title',
        key: 'title',
        render: (_,row) =>{
            return row.title
        },
    },
    {
        title: 'Answer',
        dataIndex: 'description',
        key: 'description',
        render: (_,row) =>{
            return row.description
        },
    },
    {
      title: 'Edit',
      key: 'action',
      render: (_, row) => (
        <Popconfirm
          title={`Are you sure you want to edit this faq?`}
          onConfirm={() => {
            handleConfirmEditFaq(row);
          }}
          okText="Yes"
          cancelText="No"
        >
          <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
    {
        title: 'Delete',
        key: 'action',
        render: (_, row) => (
          <Popconfirm
            title={`Are you sure you want to delete this faq?`}
            onConfirm={() => handleDeleteFaq(row)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        ),
      }
]
  return (
    <div className="content">
      <Card>
        <Button onClick={() => history.goBack()} style={{ marginBottom: "10px" }}>
          Back
        </Button>
        <div className="procedure-container">
          <Title
            style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
          >
            Edit procedure
          </Title>
          <Divider style={{ margin: "12px 0px" }} />

          {data.length > 0 && (
            <Select
              className="shadow-select"
              onChange={(val) => setSelectedProcedure(val)}
              showSearch
              style={{ width: 300 }}
              placeholder="Select a procedure"
              optionFilterProp="children"
              value={seletcedProcedure}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {data?.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.procedures}
                </Option>
              ))}
            </Select>
          )}
          <br />
          <Button
            loading={DataLoading}
            style={{ marginTop: "20px", height: 40 }}
            type="primary"
            disabled={seletcedProcedure === null}
            onClick={updateProcedure}
          >
            Update Procedure
          </Button>
          {postStatus?.status !== null && (
            <Alert
              style={{ marginTop: 10 }}
              message={postStatus?.message}
              type={postStatus?.status === 200 ? "success" : "error"}
            />
          )}
        </div>
      </Card>
     
       
        {
          
          (isAddFaq || isEditFaq) ? (
              <Card title={`${isEditFaq ? "Update" : "Add"} Faq Form`}>
                <Form
                  layout="vertical"
                  name="nest-messages"
                  onFinish={isEditFaq ? handleUpdateFaq : handleAddFaq}
                  initialValues={faq}
                >
                  {
                    isEditFaq && faq.id && 
                    <Row gutter={{ sm: 8, lg: 24 }}>
                      <Col xs={24} sm={24} lg={12}>
                          <Form.Item
                          name={["id"]}
                          label="FaqID"
                          rules={[{ required: true }]}
                          >
                          <Input size="large" disabled/>
                          </Form.Item>
                      </Col>
                      </Row>
                  }
                  <Row gutter={{ sm: 8, lg: 24 }}>
                  <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                      name={["title"]}
                      label="Question"
                      rules={[{ required: true }]}
                      >
                      <Input size="large"/>
                      </Form.Item>
                  </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["description"]}
                        label="Answer"
                        rules={[{ required: true }]}
                        >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                
                  <Row>
                    <Button type="primary" danger onClick={isEditFaq ? handleToggleIsEditFaq : handleConfirmAddFaq}>Cancel</Button>
                    <Button type="primary" style={{marginLeft:"1rem"}} htmlType="Submit">{isEditFaq ? "Update":"Add"}</Button>
                  </Row>
                </Form>
              </Card>
          ):(
            <Card>
              <div className="mb-5">
                  <Button onClick={handleToggleIsAddFaq}>Add Faq</Button>
              </div>
              {
                faqs.length ? (
                  <Card title="List of Faqs">
                    <Table columns={columns} dataSource={faqs} />
                  </Card>
                ) : null
              }
            </Card>
          )
        }
    </div>
  );
};
export default EditMedicalProcedure;
