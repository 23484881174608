import moment from "moment"; /* eslint-disable */

export const modelProfileGeneralInformationAPI = (data) => {
  let result = {
    status: false,
    data: {},
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData = {}, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    let {
      practitioner_id = "",
      email = "",
      medical_license_number = "",
      telephone = "",
      mobile = "",
      url_name = "",
      gender = "",
      first_name = "",
      last_name = "",
      practitioner_profile_image = "",
      practitioner_profile_video = "",
    } = responseData || {};
    result.data = {
      practitioner_id,
      email,
      medical_license_number,
      telephone: telephone ? telephone : "",
      mobile,
      url_name,
      gender,
      first_name,
      last_name,
      practitioner_profile_image,
      practitioner_profile_video,
    };
  } catch {
    result = {
      status: false,
      data: {},
    };
  } finally {
    return result;
  }
};

export const modelPractitionerInformationAPI = (data) => {
  let result = {
    status: false,
    data: {
      awards: [],
      education: [],
      experience: [],
      practitioner_types: [],
      languages: [],
      services: [],
    },
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData = {}, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    let {
      practitioner_information = {},
      practitioner_types = [],
      languages_spoken: languages = [],
      services = [],
    } = responseData || {};
    let {
      about_me = "",
      experience = [],
      awards = [],
      education = [],
      jobtitle = "",
      auto_approve_req = 0,
      practitioner_type = 6,
      languages_spoken = "",
      available_services = [],
    } = practitioner_information || {};

    result.data = {
      about_me,
      auto_approve_req,
      practitioner_type,
      languages_spoken,
      jobtitle,
      available_services,
    };
    if (Array.isArray(experience) && experience.length) {
      result.data.experience = experience.map(
        ({ title = "", year = "", description = "" }) => ({
          title,
          year,
          description,
        })
      );
    }
    if (Array.isArray(awards) && awards.length) {
      result.data.awards = awards.map(
        ({ title = "", year = "", description = "" }) => ({
          title,
          year,
          description,
        })
      );
    }
    if (Array.isArray(education) && education.length) {
      result.data.education = education.map(
        ({ university = "", course = "", description = "" }) => ({
          university,
          course,
          description,
        })
      );
    }
    if (Array.isArray(practitioner_types) && practitioner_types.length) {
      result.data.practitioner_types = practitioner_types.map(
        ({ practitioner = "", id = 0 }) => ({
          id,
          label: practitioner,
        })
      );
    }
    if (Array.isArray(languages) && languages.length) {
      result.data.languages = languages.map(({ id = "", languages = "" }) => ({
        id,
        label: languages,
      }));
    }
    if (Array.isArray(services) && services.length) {
      result.data.services = services.map(({ id = "", services = "" }) => ({
        id,
        label: services,
      }));
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelPractitionerConsultationFeeAPI = (data) => {
  let result = {
    status: false,
    data: {
      inPersonConsultation: {},
      videoConsultation: {},
      phoneConsultation: {},
    },
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    if (Array.isArray(responseData) && responseData.length) {
      responseData.map(({ id = 0, fee = 0, name = "" }) => {
        if (id === 1)
          result.data.inPersonConsultation = { id, amount: fee, name };
        if (id === 3) result.data.videoConsultation = { id, amount: fee, name };
        if (id === 4) result.data.phoneConsultation = { id, amount: fee, name };
      });
    }
  } catch {
    result = {
      status: false,
      data: {},
    };
  } finally {
    return result;
  }
};

export const modelPractitionerConsultingHospitalsAPI = (data) => {
  let result = {
    status: false,
    data: [],
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    if (
      Array.isArray(responseData.my_hospitals) &&
      responseData.my_hospitals.length
    ) {
      result.data = responseData.my_hospitals.map(
        ({ id = 0, clinic_name = "" }) => ({
          id,
          label: clinic_name,
        })
      );
    }
  } catch {
  } finally {
    return result;
  }
};

export const modelPractitionerWorkingHoursAPI = async (response) => {
  let result = {
    status: false,
    data: {
      workingHours: [],
    },
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    if (Array.isArray(responseData) && responseData.length > 0) {
      result.data.workingHours = responseData.map((time, index) => {
        let day = moment.weekdays(index);
        return {
          day,
          time,
        };
      });
    } else {
      result.data.workingHours =
        moment.weekdays().map((day) => ({
          day,
          time: "",
        })) || [];
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGeneralInformationUpdateApi = async (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (responseData.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelGeneralInformationPayload = (data) => {
  let result = {};
  try {
    //convert payload into formData
    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));
    result = formData;
  } catch {
    result = {};
  } finally {
    return result;
  }
};

export const modelUpdateConsultationFeesAPI = async (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (responseData.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelConsultationFeesPayload = (data) => {
  let result = {
    booking_amount: [],
    clinic_id: 0,
  };
  try {
    let res = Object.keys(data.data).map((key) => {
      return {
        id: Number(data.data[key].id),
        fee: Number(data.data[key].amount),
        name: data.data[key].name,
      };
    });
    result.booking_amount = res;
    result.clinic_id = data.clinic_id;
  } catch (e) {
    result = {};
  } finally {
    return result;
  }
};

export const modelUpdateWorkingHoursAPI = async (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (responseData.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
export const modelUpdatePractitionerInformationAPI = async (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (responseData.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelUpdateWorkingHoursPayload = (data) => {
  let result = {
    clinic_id: 0,
    working_hours: [],
  };
  try {
    //remove day and time key from payload
    result.working_hours = data.workingHours.map((item) => {
      return item.time;
    });
    result.clinic_id = data.clinic_id;
  } catch {
    result = {};
  } finally {
    return result;
  }
};

export const modelGetPractitionerSettingsAPI = (response) => {
  let result = {
    status: false,
    data: {
      auto_approve_req: false,
    },
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    result.data.auto_approve_req = data?.auto_approve_req || false;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelPractitionerUpdateSettingsAPI = (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data = {}, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if (data.success === true) result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelPractitionerGetRefundAPI = (response) => {
  let result = {
    status:false,
    data:{}
  };
  try {
    //{"status":200,"data":{"practitioner_id":1899,"refund_percentage":"60"}}
    if (typeof response !== "object") throw new Error("invalid response");
    let { data = {}, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    if(response.data){
      result.status = true;
    }
    if(response.data.refund_percentage){
      result.data.refundPercentage = response.data.refund_percentage;
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
