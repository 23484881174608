import moment from "moment";

export const modelCountryDialercodesAPI = async (response) => {
  let result = [];
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (Array.isArray(response.data) && response.data.length)
      result = response.data;
  } catch {
    result = [
      {
        country_code: "in",
      },
    ];
  } finally {
    return result;
  }
};

export const modelCheckPatientExistAPI = (response) => {
  let result = {
    status: false,
    patientExist: false,
    data: {},
  };
  try {
    if (typeof response !== "object") throw new Error("Something went wrong");
    const { status = 500, data = {} } = response || {};
    if (status === 200) {
      if (typeof data === "object")
        result = {
          status: true,
          patientExist: true,
          data: data,
        };
      else
        result = {
          status: true,
          patientExist: false,
          data: {},
        };
    } else if (status === 500) {
      let error = (data || []).map((item) => item?.msg);
      result = {
        status: false,
        patientExist: false,
        data: error,
      };
    }
  } catch (error) {
    result = {
      status: false,
      patientExist: false,
      data: error,
    };
  } finally {
    return result;
  }
};

export const modelWorkHorsSlotAPI = async (response) => {
  let result = {
    slots: {},
    dates: [],
    appointmentTypes: [],
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (typeof response.data !== "object") throw new Error("Invalid response");
    result.slots = response?.data?.slots || {};
    if (Array.isArray(response?.data?.datesWithSlots)) {
      result.dates = response?.data?.datesWithSlots || [];
    }
    if (Array.isArray(response?.data?.appointmentTypes)) {
      result.appointmentTypes = response?.data?.appointmentTypes || [];
    }
  } catch {
    result = {
      slots: {},
      dates: [],
    };
  } finally {
    return result;
  }
};

export const modelAppointmentCheckoutAPI = async (response) => {
  let result = {
    status: false,
    data: {
      order_id: "",
    },
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (typeof response.data !== "object") throw new Error("Invalid response");
    result.status = true;
    result.data.order_id = response?.data?.order_id;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
export const modelAppointmentSuccessAPI = async (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status === "success") result.status = true;
    else result.status = false;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelAppointmentCheckoutApiPayload = (response) => {
  let result = {};
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    let {
      patient_id = "",
      clinic_id = "",
      appointment_date = "",
      appointment_time = "",
      booking_type_id = "",
      token = "",
    } = response || {};
    result = {
      patient_id: "SDP" + patient_id,
      clinic_id: clinic_id,
      appointment_date: moment(appointment_date).format("DD-MMM-YYYY"),
      appointment_time,
      booking_type_id,
      token,
    };
  } catch {
    result = {
      patient_id: "",
      clinic_id: "",
      appointment_date: "",
      booking_type_id: "",
      token: "",
    };
  } finally {
    return result;
  }
};

export const modelAddPatientByInstantBookingAPI = async (response) => {
  let result = {
    status: false,
    data: {},
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    let { status, data } = response || {};
    if (status !== 200) throw new Error("Invalid response");
    if (typeof data !== "object") throw new Error("Invalid response");
    result.status = true;
    result.data = {
      password: data.password,
      patient_id: data.patient_id,
    };
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
