import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Row, Table } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import columnResponsiveWrapper from "Utils/columnResponsiveWrapper";
import { DateRanges, DATE_FORMAT, getDateTimeFormat } from "Utils/Constants";
import { getLabOfflinePayments } from "Utils/Services/Lab/LabPayments";
import DetailsCardOffline from "views/components/Lab/Payments/DetailsCardOffline";
import { TableSkeletonLoader } from "../../../../Utils/Constants";

const OfflinePayments = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const [data, setData] = useState([]);
  const [DataLoading, setDataLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const paymentsPerPage = 15;
  const [totalPaymentsCount, setTotalPaymentsCount] = useState(0);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState([null, null]);

  const { RangePicker } = DatePicker;

  // const getTagColor = (status) => {
  //   if (status === "completed") {
  //     return "green";
  //   } else if (status === "pending") {
  //     return "orange";
  //   } else if (status === "Cancelled") {
  //     return "red";
  //   } else if (status === "Not Completed") {
  //     return "blue";
  //   }
  // };

  const Columns = [
    {
      key: "patient_name",
      title: "Name",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      key: "order_id",
      title: "Order Id",
    },
    {
      key: "created_at",
      title: "Date Time",
      render: (text) => <p>{getDateTimeFormat(text)}</p>,
    },
    {
      key: "amount",
      title: "Amount",
      render: (text) => <p>₹{text}</p>,
    },
    // {
    //   key: "payment_status",
    //   title: "Payment Status",
    //   render: (text) => (
    //     <Tag color={getTagColor(text)} key={text}>
    //       {text.toUpperCase()}
    //     </Tag>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            setSelectedOrderId(record.order_id);
            setShowDetailsModal(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const FetchData = async (date1 = null, date2 = null) => {
      const startDate = date1 ? moment(date1).format("YYYY-MM-DD") : null;
      const endDate = date2 ? moment(date2).format("YYYY-MM-DD") : null;

      setDataLoading(true);
      const response = await getLabOfflinePayments({
        start: (pageNo - 1) * paymentsPerPage,
        limit: paymentsPerPage,
        startDate: startDate,
        endDate: endDate,
        search: searchValue,
      });
      if (response) {
        setData(response.paymentData);
        setTotalPaymentsCount(response.total_count);
        setDataLoading(false);
      }
    };
    FetchData(...date);
  }, [date, pageNo, searchValue]);

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Offline Payments");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalPaymentsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalPaymentsCount]);

  return (
    <div className="content">
      <div className="payments-container">
        <Row
          className="appointments-page-header"
          style={{ justifyContent: "space-between", marginBottom: "20px" }}
        >
          <Col lg={9} md={4} sm={6} xs={24} style={{ marginBottom: "10px" }}>
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>

          <Col lg={8} md={4} sm={6} xs={24}>
            <Input
              className="shadow-input"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        {DataLoading
          ? TableSkeletonLoader(10, 60)
          : data && (
              <Table
                rowKey="order_id"
                loading={DataLoading}
                dataSource={data}
                columns={columnResponsiveWrapper(Columns)}
                pagination={{
                  current: pageNo,
                  total: totalPaymentsCount,
                  onChange: (page) => {
                    setPageNo(page);
                  },
                  pageSize: paymentsPerPage,
                }}
              />
            )}
      </div>
      <Modal
        className="item-details-modal"
        title="Payment Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setShowDetailsModal(false)}
      >
        <DetailsCardOffline orderId={selectedOrderId} />
      </Modal>
    </div>
  );
};
export default OfflinePayments;
