import React from "react";
import { Button, Col, Form, Input, Row, Table, Empty } from "antd";
import { useProfile } from "../../useProfile";
import columnResponsiveWrapper from "Utils/columnResponsiveWrapper";

const AwardsSection = () => {
  const { data, setData } = useProfile();
  const { awards = [] } = data.data || {};
  const [form] = Form.useForm();
  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Year", dataIndex: "year", key: "year" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Button danger size="small" onClick={() => deleteAward(record)}>
          Delete
        </Button>
      ),
    },
  ];

  const deleteAward = (row) => {
    const newData = awards.filter((item, index) => index !== row.key);
    setData({ ...data, data: { ...data.data, awards: newData } });
  };

  const addAward = (values) => {
    let { title = "", year = "", description = "" } = values;
    setData((prevState) => {
      let { awards = [] } = prevState.data;
      awards.push({ title, year, description });
      return { ...prevState, data: { ...prevState.data, awards } };
    });
    form.resetFields();
  };
  return (
    <div className="bg-gray p-6 rounded-3 gap-2 mt-2">
      {/* selected section starts here */}
      <Row
        xs={24}
        sm={24}
        lg={24}
        className="bg-white p-2 rounded-3 shadow-sm w-full mb-4 flex items-center justify-center"
      >
        {awards.length ? (
          <Table
            columns={columnResponsiveWrapper(columns)}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{ margin: 0 }}>{record.descriptionExpanded}</p>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            dataSource={awards.map((item, key) => {
              return {
                ...item,
                key: key,
                //show description with 20 characters
                description: item.description.substring(0, 20) + "...",
                descriptionExpanded: item.description,
              };
            })}
            className="w-full"
          />
        ) : (
          <Empty
            description="No Awards Added"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Row>
      {/* selected section ends here */}
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={addAward}
        form={form}
      >
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["title"]}
              label="Title"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["year"]}
              label="Year"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["description"]}
              label="Description"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Button type="" htmlType="submit">
            Add
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AwardsSection;
