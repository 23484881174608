/* eslint-disable */
import React from "react";
import moment from "moment";
import { useLeaves } from "./useLeaves";
import { Empty } from "antd";

const Slots = () => {
  const { slots, leaveDays } = useLeaves();

  //adding isLeave flag, if slot time is between leave from and to time
  const addIsLeaveFlagToSlots = (data) => {
    let newSlots = [];
    data.forEach((slot) => {
      newSlots.push({ ...slot, isLeave: false });
    });
    if (!leaveDays.length) {
      return newSlots;
    }
    leaveDays.forEach((leave) => {
      newSlots.forEach((slot) => {
        let from = moment(leave.from, "HH:mm A");
        let to = moment(leave.to, "HH:mm A");
        let slotTime = moment(slot.time, "HH:mm A");
        if (slotTime.isBetween(from, to) || slotTime.isSame(from)) {
          slot.isLeave = true;
        }
      });
    });
    return newSlots;
  };

  return (
    <>
      {slots.length ? (
        <div className="times-container">
          {addIsLeaveFlagToSlots(slots).map((slot, index) => (
            <div
              className={
                slot?.isLeave
                  ? "time-card leave"
                  : slot?.booked === "true"
                  ? "time-card selected"
                  : "time-card"
              }
              key={index}
            >
              {slot.time}
            </div>
          ))}
        </div>
      ) : (
        <Empty description="No slots available" />
      )}
    </>
  );
};

export default Slots;
