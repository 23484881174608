import React, { useState, useEffect } from "react";
import {
  TranslationOutlined,
  FolderAddOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import DummyCover from "Images/dummy/cover.jpg";
import EllipsisText from "react-ellipsis-text";
import { Tabs, Steps, Empty } from "antd";
import { getPractitionerDetailsByUrlName } from "Utils/Services/Hospital/Practitioner";
import ConsultationFee from "./ConsultationFee";
import Settings from "./Settings";
import WorkingHours from "./WorkingHours";
import { getYouTubeVideoId, getVimeoVideoId } from '../../../Utils/GetPractitionerProfileVideoId';
import ScheduleTimings from "./ScheduleTimings";

const { TabPane } = Tabs;
const { Step } = Steps;

const DetailsCard = ({ urlName, MyPractitioner }) => {
  const [data, setData] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const getEducationNames = (education) => {
    if (education.length) {
      return education.map((item, index) => (
        <Step
          key={index}
          title={item.course + " " + item.university}
          description={item.description}
        />
      ));
    }
  };

  const getExperienceNames = (experiences) => {
    if (experiences.length) {
      return experiences.map((item, index) => (
        <Step
          key={index}
          title={item.title + " " + item.year}
          description={item.description}
        />
      ));
    }
  };

  const getAwardNames = (awards) => {
    if (awards.length) {
      return awards.map((item, index) => (
        <Step
          key={index}
          title={item.title + " " + item.year}
          description={item.description}
        />
      ));
    }
  };

  const getLanguagesSpokenName = (languages) => {
    return languages.map((item, index) => <li key={index}>{item}</li>);
  };

  const getServices = (services) => {
    return services.map((item, index) => (
      <span className="item" key={index}>
        <FolderAddOutlined />
        {item}
      </span>
    ));
  };

  // const getAppointmentDetails = (appointmentTypes, prices) => {
  //   const appointmentTypesArr = Object.entries(appointmentTypes);
  //   const pricesArr = Object.entries(prices);
  //   return appointmentTypesArr.map((item, index) => (
  //     <span className="item" key={index}>
  //       {item[1]} : {pricesArr[index][1]}
  //     </span>
  //   ));
  // };

  const getProfileImage = (image, name) => {
    if (!image) {
      setProfileImage(getAlphaDummyAvatar(name.charAt(0)));
    } else {
      setProfileImage(image);
    }
  };

  const getData = async (url_name) => {
    getPractitionerDetailsByUrlName(url_name).then((response) => {
      setData(response);
      getProfileImage(response.practitioner_profile_image, response.first_name);
    });
  };

  useEffect(() => {
    if (urlName) {
      getData(urlName);
    }
    return () => {
      setData("");
    };
    // eslint-disable-next-line
  }, [urlName]);

  const getAlphaDummyAvatar = (firstletter) => {
    return <div className="alpha-dummy-avatar">{firstletter}</div>;
  };

  return (
    <div className="table-item-details-card">
      <div
        className="cover-image-holder"
        style={{ backgroundImage: "url(" + DummyCover + ")" }}
      />

      <div className="basic-information doctor">
        {typeof profileImage === "string" ? (
          <div
            className="profile-image"
            style={{ backgroundImage: `url(${profileImage})` }}
          />
        ) : (
          { ...profileImage }
        )}
        <div className="profile-info">
          <span className="doctor-speciality">{data?.practitioner_type}</span>
          <h4 className="mt-2">
            <EllipsisText
              className="capitalize"
              text={
                data.first_name ? data.first_name + " " + data.last_name : ""
              }
              length={50}
            />
          </h4>
          <p>
            <UserAddOutlined />
            <span>
              <EllipsisText
                text={data.jobtitle ? data.jobtitle : ""}
                length={80}
              />
            </span>
          </p>
        </div>
        <div className="details-tab-container tab-style">
          <Tabs type="card" defaultActiveKey="1" className="practitioner-tab">
            <TabPane tab="General" key="1">
            {
                  data.practitioner_profile_video && (
                    <div>
                      <h6 className="mb-1">Practitioner Introductory video</h6>
                     <iframe
                      title="YouTube Video Player"
                      width="100%"
                      height="390"
                      style={{borderRadius:"10px"}}
                      src={!data.practitioner_profile_video.startsWith("https://vimeo.com")?
                        `https://www.youtube.com/embed/${getYouTubeVideoId(data.practitioner_profile_video)}`:
                        `https://player.vimeo.com/video/${getVimeoVideoId(data.practitioner_profile_video)}`
                      }
                      allowFullScreen
                    ></iframe>
                  </div>
                  )
                }
              <div className="contact-info">
                {data.languages_spoken ? (
                  <div className="languages-spoken">
                    <TranslationOutlined />
                    <ul className="languages-spoken-names">
                      {getLanguagesSpokenName(data.languages_spoken)}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {data.about_me ? (
                <div className="about-container mt-3">
                  <h6 className="mb-1">About</h6>{" "}
                  <div dangerouslySetInnerHTML={{ __html: data.about_me }} />
                </div>
              ) : (
                ""
              )}
              {data.experience
                ? data.experience.length > 0 && (
                    <div className="experience-container mt-3">
                      <h6 className="mb-1">Experience</h6>
                      <Steps progressDot direction="vertical">
                        {getExperienceNames(data.experience)}
                      </Steps>
                    </div>
                  )
                : ""}
              {data.education
                ? data.education.length > 0 && (
                    <div className="education-container mt-3">
                      <h6 className="mb-1">Education</h6>
                      <Steps progressDot direction="vertical">
                        {getEducationNames(data.education)}
                      </Steps>
                    </div>
                  )
                : ""}
              {data.awards
                ? data.awards.length > 0 && (
                    <div className="awards-container mt-3">
                      <h6 className="mb-1">Awards</h6>
                      <Steps progressDot direction="vertical">
                        {getAwardNames(data.awards)}
                      </Steps>
                    </div>
                  )
                : ""}
            </TabPane>
            <TabPane tab="Services" key="2">
              {data.available_services &&
                (data.available_services.length > 0 ? (
                  <>
                    {/* <h6 className="mb-1">Available Services</h6> */}
                    <div className="service-info">
                      {getServices(data.available_services)}
                    </div>
                  </>
                ) : (
                  <Empty
                    description="No services available"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ))}
            </TabPane>
            {MyPractitioner && (
              <>
                {/* <TabPane tab="Working Hours" key="3">
                  <WorkingHours
                    workingHours={data?.working_hours}
                    practitioner_id={data.id}
                  />
                </TabPane> */}
                <TabPane tab="Consultation Fee" key="4">
                  <ConsultationFee
                    data={data?.booking_amount}
                    practitioner_id={data.id}
                  />
                </TabPane>
                <TabPane tab="Settings" key="5">
                  <Settings
                    settings={data?.settings}
                    practitioner_id={data.id}
                    urlName={urlName}
                    getData={getData}
                  />
                </TabPane>
                <TabPane tab="Schedule Timings" key="6">
                  <ScheduleTimings
                    practitioner_id={data.id}
                  />
                </TabPane>
              </>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default DetailsCard;
