import React, { useEffect, useContext, useState } from "react";
import { GlobalState } from "context-api";
import { Button, Form, Row, Col, Input, Card, Table, Popconfirm, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { 
    deleteLabSubAdmin,
    getLabSubAdmins, 
    postAddLabMultipleAdmins, 
    postUpdateLabSubAdminAccess, 
    postUpdateLabSubAdminRole
} from "Utils/Services/Lab/MultipleAdmins";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { LabRoutes } from "Routes/LabRoutes";


const MultipleAdmins = () => {
    const { UserData, setHeading, Heading, setHeadingCount } = useContext(GlobalState);
    const [subAdmins, setSubAdmins] = useState([]);
    const [isAddAdmin, setIsAddAdmin] = useState(false);
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showPasswords, setShowPasswords] = useState({
        showPassword: false,
        showConfirmPassword: false,
    })
    const [adminData, setAdminData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role:"",
    });
    const [updateRolePayload, setUpdateRolePayload] = useState({
        id:"",
        role:""
    });
    const [updateAccessPayload, setUpdateAccessPayload] = useState({
        id:"",
        access:[]
    });

    const toggleRoleModal = () => {
        setShowRoleModal(!showRoleModal);
    };
    const handleAccessChange = (e) =>{
        const alreadyExists = updateAccessPayload?.access?.includes(e?.target?.id);
        let access= [];
        if(alreadyExists){
            access = updateAccessPayload?.access?.filter(each=>each!=e?.target?.id) 
        }else{
            access = [...updateAccessPayload?.access,e?.target?.id];
        }
        setUpdateAccessPayload({
            ...updateAccessPayload,
            access
        });
    };
    // useEffect(()=>{console.log("access",updateAccessPayload)},[updateAccessPayload])
    
    const fetchSubAdmins = async () => {
        const getSubadmins = await getLabSubAdmins();
        if(getSubadmins?.success){
            getSubadmins?.data?.profile && setSubAdmins([...getSubadmins?.data?.profile]);
        }
    };

    const handleDeleteSubAdmin = async (row) => {
        try{
            const deleteResponse = await deleteLabSubAdmin({id: row.id});
           if(deleteResponse.success){
                AntdCustomMessage({
                    type : antdMessageTypes.SUCCESS,
                    content : deleteResponse?.data
                });
                window.location.reload();
           }else{
                AntdCustomMessage({
                    type : antdMessageTypes.ERROR,
                    content : deleteResponse?.message || "Something went wrong."
                });
           }
        }catch(e){
            AntdCustomMessage({
                type : antdMessageTypes.ERROR,
                content : e.message || e.data || "Something went wrong."
            });
        }
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (_,row) =>{
                return row.id
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_,row) =>{
                return row.first_name.toUpperCase() + " " + row.last_name.toUpperCase()
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_,row) =>{
                return row.email
            },
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (_,row) =>{
                return (
                    <div>
                        
                        {
                            row.role ? (
                                <div className="d-flex flex-row gap-1 align-items-center">
                                    <p>{row.role}</p>
                                    <Popconfirm
                                        title={`Are you sure you want to edit role?`}
                                        onConfirm={() => {
                                            setUpdateRolePayload({
                                                id: row.id,
                                                role: row.role
                                            });
                                            toggleRoleModal();
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
                                    </Popconfirm>
                                </div>
                            ):(
                                <button onClick={()=>{
                                    setUpdateRolePayload({
                                        id: row.id,
                                        role: ""
                                    });
                                    toggleRoleModal();
                                }}>Add</button>
                            )
                        }   
                    </div>
                )
            },
        },
        {
            title:"Access",
            key:"access",
            render: (_,row) =>{
                return (
                    <Button
                  onClick={() => {
                    setShowDetailsModal(true);
                    setUpdateAccessPayload({
                        id: row.id,
                        access: row?.access ? row?.access?.split(",") : []
                    })
                  }}
                >
                  View
                </Button>
                )
            }
        },
        {
            title: 'Delete',
            key: 'action',
            render: (_, row) => (
              <Popconfirm
                title={`Are you sure you want to delete admin ${row.first_name.toUpperCase()+" "+row.last_name.toUpperCase() }?`}
                onConfirm={() => handleDeleteSubAdmin(row)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
              </Popconfirm>
            ),
          }
    ]
    useEffect(() => {
        if (setHeading && Heading) {
        setHeading("Sub Admins");
        }
    }, [setHeading, Heading]);

    const handleToggleIsAddAdmin = () => {
        setIsAddAdmin(prev=>!prev)
    }

    const handleToggleShowPassword = (event) => {
        event.persist();
        setShowPasswords(prev=>({
            ...prev,
            [event.target.name]: !prev[event.target.name]
        }))
    }
    
    const handleAddAdmin = async (values) => {
        try{
            if(values.confirmPassword !== values.password){
                AntdCustomMessage({
                    type : antdMessageTypes.ERROR,
                    content : "Confirm Password and Password must be same."
                });
                return;
            }
            const payload = {
                "lab_id": UserData.userid,
                "first_name":values.firstName,
                "last_name":values.lastName,
                "email": values.email,
                "role": values.role,
                "password":values.password,
                "access":"",
            };
            const response = await postAddLabMultipleAdmins(payload);
            if(response?.success){
                AntdCustomMessage({
                    type : antdMessageTypes.SUCCESS,
                    content : response?.message
                });
                setIsAddAdmin(false);
                fetchSubAdmins();
            }else{
                AntdCustomMessage({
                    type : antdMessageTypes.ERROR,
                    content : response?.message
                });
            }
        }catch(e){
            AntdCustomMessage({
                type : antdMessageTypes.ERROR,
                content : e.message || "Something went wrong."
            });
        }    
    }

    const handleUpdateSubAdminRole = async (values) => {
        const updateResponse = await postUpdateLabSubAdminRole(values);
        if(updateResponse?.success){
            AntdCustomMessage({
                type : antdMessageTypes.SUCCESS,
                content : updateResponse?.data
            });
            fetchSubAdmins();
            setShowRoleModal(false);
        }else{
            AntdCustomMessage({
                type : antdMessageTypes.ERROR,
                content : updateResponse?.message || "something went wrong"
            });
        }
    };

    const handleUpdateSubAdminAccess = async () => {
        const updateAccessResponse = await postUpdateLabSubAdminAccess({
            id: updateAccessPayload?.id,
            access : updateAccessPayload?.access?.join(","),
        });
        if(updateAccessResponse?.success){
            AntdCustomMessage({
                type : antdMessageTypes.SUCCESS,
                content : updateAccessResponse?.data
            });
            fetchSubAdmins();
            setShowDetailsModal(false);
            window.location.reload();
        }else{
            AntdCustomMessage({
                type : antdMessageTypes.ERROR,
                content : updateAccessResponse?.message || "something went wrong"
            });
        }
    }

    useEffect(()=>{
        fetchSubAdmins();
    },[])

    useEffect(()=>{
        setHeadingCount(subAdmins.length);
        return () => {
            setHeadingCount(0);
        };
    },[subAdmins]);

    return (
        <div className="content">
            <Card>
            {
               isAddAdmin ? (
                    <Form
                        layout="vertical"
                        name="nest-messages"
                        onFinish={handleAddAdmin}
                        initialValues={adminData}
                    >
                        <Row gutter={{ sm: 8, lg: 24 }}>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                            name={["firstName"]}
                            label="First Name"
                            rules={[{ required: true }]}
                            >
                            <Input size="large"/>
                            </Form.Item>
                        </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} lg={12}>
                                <Form.Item
                                name={["lastName"]}
                                label="Last Name"
                                rules={[{ required: true }]}
                                >
                                <Input size="large" />
                                </Form.Item>
                        </Col>
                        </Row>
                        
                        <Row gutter={{ sm: 8, lg: 24 }}>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                            name={["email"]}
                            label="Email"
                            rules={[{ type: "email", required: true }]}
                            >
                            <Input size="large" type="email"/>
                            </Form.Item>
                        </Col>
                        </Row>

                        <Row gutter={{ sm: 8, lg: 24 }}>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                            name={["role"]}
                            label="Role"
                            rules={[{ required: true }]}
                            >
                            <Input size="large"/>
                            </Form.Item>
                        </Col>
                        </Row>
                    
                        <Row gutter={{ sm: 8, lg: 24 }}>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                name={["password"]}
                                label="Password"
                                rules={[{ required: true }]}
                                style={{marginBottom:"5px"}}
                            >
                                <Input size="large" type={showPasswords.showPassword ? "text" : "password"}/>    
                            </Form.Item>
                            <label style={{marginBottom:"20px"}}><input type='checkbox' name="showPassword" onClick={handleToggleShowPassword}/>&nbsp;Show Password</label>
                        </Col>
                        </Row>

                        <Row>
                            <Col xs={24} sm={24} lg={12}>
                                <Form.Item 
                                    name={["confirmPassword"]} 
                                    label="Confirm Password"
                                    rules={[{ required: true }]}
                                    style={{marginBottom:"5px"}}
                                >
                                <Input size="large" type={showPasswords.showConfirmPassword ? "text" : "password"}/>
                                </Form.Item>
                                <label style={{marginBottom:"20px"}}><input type='checkbox' name="showConfirmPassword" onClick={handleToggleShowPassword}/>&nbsp;Show Password</label>
                            </Col>
                        </Row>
                        <Row>
                        <Button type="primary" danger onClick={handleToggleIsAddAdmin}>Cancel</Button>
                        <Button type="primary" style={{marginLeft:"1rem"}} htmlType="Submit">Add</Button>
                        </Row>
                    </Form>
                ):(
                    <>
                        <div className="mb-5">
                            <Button onClick={handleToggleIsAddAdmin}>Add Admin</Button>
                        </div>
                        <Card title="List of SubAdmins">
                            <Table columns={columns} dataSource={subAdmins} />
                        </Card>
                    </>
                )
            }
            </Card>
            <Modal
                className="item-details-modal"
                title="Hospital Details"
                visible={showDetailsModal}
                footer={null}
                width={700}
                onCancel={() => setShowDetailsModal(false)}
            >
                <div style={{minHeight:"80vh"}} className="d-flex flex-column justify-content-center align-items-center">
                    <div>
                    <h3>View and Edit Access</h3>
                    <div>
                        {
                            LabRoutes?.map((each,index)=>{
                                return (
                                    <div key={index}>
                                        <input
                                            type="checkbox"
                                            id={each.path}
                                            name={each.name}
                                            checked={updateAccessPayload?.access?.includes(each.path)}
                                            onChange={(e) =>{
                                                e.persist();
                                                handleAccessChange(e);
                                            }}
                                        />
                                        <label htmlFor={each.name}>{each.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <button 
                        className="border-0"
                        style={{
                            backgroundColor: "rgba(0,100,255,1)",
                            borderRadius:"5px",
                            color: "white",
                            padding: "5px 10px"
                        }}
                        onClick={handleUpdateSubAdminAccess}
                    >
                        Save
                    </button>
                    </div>
                </div>
               
            </Modal>
            <Modal
                className="item-details-modal"
                title="Hospital Details"
                visible={showRoleModal}
                footer={null}
                width={700}
                onCancel={() => setShowRoleModal(false)}
            >
                <div style={{minHeight:"80vh"}} className="d-flex flex-column justify-content-center align-items-center">
                    <Card title={"Add/Update role of subadmin"}>
                        <Form
                            layout="vertical"
                            name="update-subadmin-role"
                            onFinish={handleUpdateSubAdminRole}
                            initialValues={updateRolePayload}
                        >
                            <Row gutter={{ sm: 8, lg: 24 }}>
                                <Col xs={24} sm={24} lg={24}>
                                    <Form.Item
                                        name={["id"]}
                                        label="SubAdmin Id"
                                        rules={[{ required: true }]}
                                    >
                                        <Input size="large" disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={{ sm: 8, lg: 24 }}>
                                <Col xs={24} sm={24} lg={24}>
                                    <Form.Item
                                        name={["role"]}
                                        label="Role"
                                        rules={[{ required: true }]}
                                    >
                                        <Input size="large"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Button type="primary" danger onClick={()=>{toggleRoleModal()}}>Cancel</Button>
                                <Button type="primary" style={{marginLeft:"1rem"}} htmlType="Submit">Save</Button>
                            </Row> 
                        </Form>
                    </Card>
                </div>  
            </Modal>

        </div>
    );
};

export default MultipleAdmins;
