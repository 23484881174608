import { LabURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";

export const getLabOnlinePayments = async (values) => {
  return await PostData(
    LabURLParser("/appointmentOnlinePayments"),
    values
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const getLabOnlinePaymentsDetails = async (values) => {
  return await PostData(
    LabURLParser("/appointmentOnlinePaymentDetails"),
    values
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const getLabOfflinePayments = async (values) => {
  return await PostData(
    LabURLParser("/appointmentOfflinePayments"),
    values
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const getLabOfflinePaymentsDetails = async (values) => {
  return await PostData(
    LabURLParser("/appointmentOfflinePaymentDetails"),
    values
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};
