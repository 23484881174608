import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Input,
  List,
  Modal,
  Typography,
  Table,
  Card
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  DateRanges,
  DATE_FORMAT,
  DecryptString,
  TableSkeletonLoader,
} from "Utils/Constants";
import { getPatientDetails, getPatientReports } from "Utils/Services/Practitioner/Patients";
import AppointmentCard from "views/pages/Practitioner/Appointments/AppointmentCard";
import DetailsCard from "views/pages/Practitioner/Appointments/AppointmentDetails";
import { usePatients } from "../usePatients";
import PatientInfoCard from "./PatientInfoCard";
import { useParams } from "react-router-dom";
import RescheduleCard from "views/pages/Practitioner/Appointments/RescheduleCard";
import { useAppointments } from "views/pages/Practitioner/Appointments/useAppointments";
import BugContainer from "components/ErrorContainer/BugContainer";

const PatientDetailsContainer = (props) => {
  const { patientId: id } = useParams();
  let patientId = DecryptString(id);

  const { Title } = Typography;
  const history = useHistory();
  const { RangePicker } = DatePicker;
  const [patientDetails, setPatientDetails] = useState({
    status: false,
    data: {},
  });

  const [isReports, setIsReports] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [showModal,setShowModal] = useState(false);
  const [reportUrl, setReportUrl] = useState("");
  const [reportsLoading,setReportsLoading] = useState(false);

  const [date, setDate] = useState([null, null]);
  const { setPatientsInfoLoading, selectedPractitionerId } = usePatients();
  const {
    setShowDetailsModal,
    showDetailsModal,
    showRescheduleModal,
    setShowRescheduleModal,
    selectedAppointmentId,
    FetchData,
    appointmentsPerPage,
    setAppointmentsPerPage,
    searchValue,
    setSearchValue,
    setPageNo,
    pageNo,
    totalAppointmentsCount,
    Data,
    DataLoading,
  } = useAppointments();

  const fetchPatientDetails = async () => {
    setPatientsInfoLoading(true);
    const response = await getPatientDetails({ patient_id: patientId });
    response && setPatientDetails(response);
    setPatientsInfoLoading(false);
  };

  const FetchReports = async () => {
    setReportsLoading(true);
    const reportsResponse = await getPatientReports(
      {patient_id: patientId?.replace("SDP", "")}
    );
    if(reportsResponse && reportsResponse?.status){
      setReportsData(reportsResponse?.data);
    }
    setReportsLoading(false);
  }

  const handleToggleIsReports = () => {
    setIsReports(prev=>!prev);
  }
  const handleShowModal = ()=>{
    setShowModal(true);
  }
  const handleSetUrl = (url) => {
    setReportUrl(url);
  }

  useEffect(() => {
    patientId && fetchPatientDetails();
    patientId && FetchReports();
  }, [patientId]); // eslint-disable-line

  useEffect(() => {
    patientId && FetchData(date[0], date[1], Number(patientId));
  }, [patientId, pageNo, date, searchValue, appointmentsPerPage]); // eslint-disable-line

  let { appointments = [] } = Data.data || {};

  return (
    <div className="content">
      <Modal
        destroyOnClose={true}
        className="item-details-modal p-5"
        title="Report"
        visible={showModal}
        footer={null}
        width={700}
        onCancel={() => setShowModal(false)}
      >
        <div className="p-5">
         <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center", margin:"1px"}}>
        {
            reportUrl && !reportUrl.endsWith(".pdf")?(
              reportUrl && <img 
              src={reportUrl} 
              alt={`report-${reportUrl}`}
              style={{width:"400px",height:"380px",borderRadius:"5px"}}
            />
            ):(
                <iframe
                  title="PDF Viewer"
                  src={`https://docs.google.com/gview?url=${reportUrl}&embedded=true`}
                  width="80%"
                  height="500"
                  frameBorder="0"
                />
            )
          }
        </div>
        </div>
      </Modal>
      <Button style={{ marginBottom: "1rem" }} onClick={() => history.goBack()}>
        Back
      </Button>
      <Button style={{ marginBottom: "1rem", marginLeft: "1rem" }} onClick={() => {
        handleToggleIsReports();
      }}>
        {isReports ? "Appointment History" : "Reports"}
      </Button>
      {
        !isReports ? (
          <>
            <PatientInfoCard data={patientDetails} />

            <Modal
              destroyOnClose={true}
              className="item-details-modal"
              title="Hospital Details"
              visible={showDetailsModal}
              footer={null}
              width={700}
              onCancel={() => setShowDetailsModal(false)}
            >
              <DetailsCard />
            </Modal>
            <Modal
              className="item-details-modal"
              title="Reschedule Details"
              visible={showRescheduleModal}
              footer={null}
              width={500}
              onCancel={() => setShowRescheduleModal(false)}
            >
              <RescheduleCard
                practitioner_id={selectedPractitionerId}
                appointment_id={selectedAppointmentId}
                FetchData={FetchData}
                setShowRescheduleModal={setShowRescheduleModal}
              />
            </Modal>

            <div className="appointments-history-container">
              <Title
                level={3}
                style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
              >
                Appointment History
              </Title>
              <Divider style={{ margin: "12px 0px" }} />

              <Row
                style={{
                  display: "flex",
                  position: "relative",

                  justifyContent: "space-between",
                }}
              >
                <Col md="6" sm="6" xs="12">
                  <RangePicker
                    // className="shadow-picker"
                    style={{
                      width: "100%",
                      height: "40px",
                      marginBottom: isMobile ? 10 : 0,
                    }}
                    value={date}
                    ranges={DateRanges()}
                    format={DATE_FORMAT}
                    onChange={(e) => {
                      e === null ? setDate([null, null]) : setDate(e);
                    }}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </Col>
                <Col md="6" sm="6" xs="12">
                  <Input
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    placeholder="Search"
                    style={{
                      alignSelf: isMobile ? "center" : "flex-end",
                      width: isMobile ? "100%" : "70%",
                      float: isMobile ? "" : "right",
                    }}
                    prefix={<SearchOutlined />}
                    size="large"
                  />
                </Col>
              </Row>

              {DataLoading ? (
                TableSkeletonLoader(8, 30)
              ) : Data?.status ? (
                appointments.length ? (
                  <List
                    className="appointments-page patient"
                    dataSource={appointments}
                    pagination={{
                      current: pageNo,
                      total: totalAppointmentsCount,
                      onChange: (page) => {
                        setPageNo(page);
                      },
                      pageSize: appointmentsPerPage,
                      onShowSizeChange: (current, size) => {
                        setAppointmentsPerPage(size);
                      },
                      pageSizeOptions: ["12", "24", "36", "48", "60"],
                      // current: Pageno,
                      // total: totalAppointmentsCount,
                      // onChange: (page) => {
                      //   console.log("page", page);
                      //   setPageNo(page);
                      // },
                      // pageSize: appointmentsPerPage,
                    }}
                    renderItem={(appointment) => (
                      <List.Item>
                        <AppointmentCard
                          appointment={appointment}
                          FetchData={FetchData}
                          patientId={Number(patientId)}
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <Empty
                    description="No Appointments Found"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )
              ) : (
                <BugContainer />
              )}
            </div>
          </>
        ) : (
          <Card style={{marginTop:"1rem"}}>
            <Table
              rowKey="id"
              dataSource={reportsData?.map((item, index) => {
                return {
                  id: index,
                  report_clinic_Id: item.id,
                  clinic_id: item.clinic_id, 
                  clinic_name: item.clinic_name, 
                  report_index: item.index,
                  report_title: item.title,
                  report: item.report
                };
              })}
            >
              <Table.Column 
                title="Sl No" 
                dataIndex="id" 
                key="id" 
                render={(text, record) => (
                  <p>
                    {record.id + 1}
                  </p>
                )}
              />
              <Table.Column
                title="Report Title"
                dataIndex="report_title"
                key="report_title"
              />
              <Table.Column
                title="Uploaded By Clinic"
                dataIndex="clinic_name"
                key="clinic_name"
              />
              <Table.Column
                key="download"
                dataIndex="download"
                title="Download"
                render={(text, record) => (
                <button
                  onClick={()=>{
                    handleSetUrl(record.report);
                    handleShowModal();
                  }}
                  className="rounded"
                >
                  View
                </button>
                )}
              />
            </Table>
          </Card>
        )
      }
    </div>
  );
};

export default PatientDetailsContainer;
