import { PostData } from "Utils/ApiCall";
import { HospitalURLParser } from "Utils/urls.utils";

export const newSubscriptionOrderHospital = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/NewSubscriptionOrder"),
    payload
  );
  if (response) {
    return response.data;
  }
};
export const subscriptionUpgradeOrderHospital = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/subscriptionUpgradeOrder"),
    payload
  );
  if (response) {
    return response.data;
  }
};
export const checkCouponCode = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/checkCouponCode"),
    payload
  );
  if (response) {
    return response.data;
  }
};

export const getSubscriptionHistory = async () => {
  const response = await PostData(
    HospitalURLParser("/getSubscriptionHistory"),
    {}
  );
  if (response) {
    return response.data;
  }
};

export const subscriptionSuccessHospital = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/subscriptionSuccess"),
    payload
  );
  if (response) {
    return response.data;
  }
};

export const getSubscriptionHistoryDetails = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/getSubscriptionHistoryDetails"),
    payload
  );
  if (response) {
    return response.data;
  }
};
