import React from "react";
import { DashboardContextProvider } from "./useDashboard";
import DashboardContainer from "./DashboardContainer";
import { AppointmentsContextProvider } from "../Appointments/useAppointments";

const Dashboard = () => {
  return (
    <DashboardContextProvider>
      <AppointmentsContextProvider>
        <DashboardContainer />
      </AppointmentsContextProvider>
    </DashboardContextProvider>
  );
};

export default Dashboard;
