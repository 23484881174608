import { LabURLParser } from "Utils/urls.utils";
import { PostData, GetData } from "../../ApiCall";
import { modelLabReviewsAPI } from "models/lab/reviews.api.models";
// import { LabReviews } from "DummyData";

// export const getLabReviews = async (values) => {
//   return await PostData(LabURLParser("/reviews"), values).then((response) => {
//     if (response) {
//       // const dummy = LabReviews
//       return response.data;
//     }
//   });
// };

export const getLabReviewDetails = async (values) => {
  return await PostData(LabURLParser("/reviewDetails"), values).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};


export const getLabReviews = async ({labId}) =>
  modelLabReviewsAPI(
    await GetData(`${process.env.REACT_APP_BASE_URL}/api/common/lab/${labId}/reviews/patient`)
  );
