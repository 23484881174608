import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState, useContext } from "react";
import {
  getLabProfileGeneralInformation,
  postLabProfileGeneralInformation,
} from "Utils/Services/Lab/LabProfile";
import { GlobalState } from "context-api";
import { validateMessages } from "Utils/validations";
import { EditFilled } from "@ant-design/icons";
import BannerPlaceholder from "Images/bg/lab_banner.jpeg";
import Compressor from 'compressorjs';
import { getReadableFileSize } from "Utils/GetReadableFileSize";

const GeneralInformation = () => {
  const { FetchAccountInformation } = useContext(GlobalState);
  const [data, setData] = useState(null);
  const [newUserPhoto, setNewUserPhoto] = useState(null);
  const [newCoverPhoto, setNewCoverPhoto] = useState(null);
  const [showSizeError, setShowSizeError] = useState(false);
  const [sizeErrorMessage, setSizeErrorMessage] = useState("");

  const compressAndResizeImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.5, // Adjust quality as per your requirement (0 to 1)
        maxWidth: Number(process.env.REACT_APP_PROFILE_PIC_MAX_WIDTH_PX),
        maxHeight: Number(process.env.REACT_APP_PROFILE_PIC_MAX_HEIGHT_PX),
        minWidth: Number(process.env.REACT_APP_PROFILE_PIC_MIN_WIDTH_PX),
        minHeight: Number(process.env.REACT_APP_PROFILE_PIC_MIN_HEIGHT_PX),
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
  };

  const handleCoverPicChange = async (event) => {
    event.persist();
    const file = event?.target?.files[0];
    const fileSize = file?.size;
    if(
      fileSize < process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES || 
      fileSize > process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES
    ){
      message.error(`Uploaded file for cover picture is of size ${
        getReadableFileSize(fileSize)}, File size should be between ${
        getReadableFileSize(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES)} and ${
        getReadableFileSize(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)}.`);
      setShowSizeError(true);
      setSizeErrorMessage(`Uploaded file for cover picture is of size ${
        getReadableFileSize(fileSize)}, File size should be between ${
        getReadableFileSize(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES)} and ${
        getReadableFileSize(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)}.`);
      return;
    }
    setShowSizeError(false);
    file && setNewCoverPhoto(file)
  }

  const handleProfileImageChange = async (event) => {
    event.persist();
    const file = event?.target?.files[0];
    const fileSize = file?.size;
    if(
      fileSize < process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES || 
      fileSize > process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES
    ){
      message.error(`Uploaded file for profile picture is of size ${
        getReadableFileSize(fileSize)}, File size should be between ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.`);
      setShowSizeError(true);
      setSizeErrorMessage(`Uploaded file for profile picture is of size ${
        getReadableFileSize(fileSize)}, File size should be between ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.`);
      return;
    }
    if(file){
      const resizedImage = await compressAndResizeImage(file);
      setNewUserPhoto(resizedImage);
    }
    setShowSizeError(false);

    // const img = new Image();
    // img.onload = () => {
    //   if(
    //     img.width <= process.env.REACT_APP_PROFILE_PIC_MAX_WIDTH_PX &&
    //     img.width >= process.env.REACT_APP_PROFILE_PIC_MIN_WIDTH_PX &&
    //     img.height <= process.env.REACT_APP_PROFILE_PIC_MAX_HEIGHT_PX &&
    //     img.height >= process.env.REACT_APP_PROFILE_PIC_MIN_HEIGHT_PX 
    //   ){
    //     setNewUserPhoto(event.target?.files[0]);
    //     setShowSizeError(false);
    //     setSizeErrorMessage("");
    //   }else{
    //     message.error(`Uploaded file is of ${img.width} * ${img.height}. It should be in required dimensions.`);
    //     setShowSizeError(true);
    //     setSizeErrorMessage(`Uploaded Image is of ${img.width}*${img.height}px.
    //       Please upload image with required dimensions.`);
    //     return;
    //   }
    // }
    // img.error = (error) => {
    //   throw new Error('Failed to load image');
    // }
    // img.src = URL.createObjectURL(file);
  }



  useEffect(() => {
    const fetchData = async () => {
      const response = await getLabProfileGeneralInformation();
      response?.profile && setData(response.profile[0]);
    };
    fetchData();
  }, []);

  const onFinish = async (values) => {
    if(newUserPhoto){
      // code for checking image size and dimensions
      if(
        newUserPhoto.size < process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES ||
        newUserPhoto.size > process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES
      ){
        message.error(`Uploaded file is of size ${
          getReadableFileSize(newUserPhoto.size)}, File size should be between ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.
        `);
        setShowSizeError(true);
        setSizeErrorMessage(`Uploaded file is of size ${
          getReadableFileSize(newUserPhoto.size)}, File size should be between ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.
        `);
        return;
      }
      const img = new Image();
      img.onload = () => {
        if(
          !img.width <= process.env.REACT_APP_PROFILE_PIC_MAX_WIDTH_PX &&
          !img.width >= process.env.REACT_APP_PROFILE_PIC_MIN_WIDTH_PX &&
          !img.height <= process.env.REACT_APP_PROFILE_PIC_MAX_HEIGHT_PX &&
          !img.height >= process.env.REACT_APP_PROFILE_PIC_MIN_HEIGHT_PX 
        ){
          message.error(`Uploaded Image is of ${img.width}*${img.height}px.
            Please upload image with required dimensions.`);
          setShowSizeError(true);
          setSizeErrorMessage(`Uploaded Image is of ${img.width}*${img.height}px.
            Please upload image with required dimensions.`);
          return;
        }
      }
      img.error = (error) => {
        throw new Error('Failed to load image');
      }
      img.src = URL.createObjectURL(newUserPhoto);
      // end of checking size and dimensions
    }
    const { email, lab_id, mobile, ...filteredValues } = values;
    const response = await postLabProfileGeneralInformation({
      ...filteredValues,
      ...(newCoverPhoto && { lab_cover_image: newCoverPhoto }),
      ...(newUserPhoto && { lab_profile_image: newUserPhoto }),
    });
    if (response?.status === 200) {
      message.success({
        content: "Succesfully Updated.",
        style: {
          marginTop: "100px",
        },
      });
      setShowSizeError(false);
      setSizeErrorMessage("");
      FetchAccountInformation("lab");
    }
  };

  return (
    data && (
      <Card>
        <>
          <Row style={{ position: "relative" }}>
            <img
              style={{
                objectFit: "cover",
                width: "100%",
                aspectRatio: "	320/69",
                boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                borderRadius: "7px",
              }}
              alt="banner"
              src={
                !newCoverPhoto
                  ? data?.lab_cover_image
                    ? data?.lab_cover_image
                    : BannerPlaceholder
                  : URL.createObjectURL(newCoverPhoto)
              }
            />
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/jpeg,image/png"
              id="file-upload-cover"
              onChange={handleCoverPicChange}
            />
            <label
              htmlFor="file-upload-cover"
              style={{
                cursor: "pointer",
                backgroundColor: "white",
                padding: "5px",
                paddingLeft: "12px",
                paddingRight: "12px",
                border: "1px solid #e8e8e8",
                borderRadius: "7px",
                position: "absolute",
                bottom: "10px",
                right: "10px",
              }}
            >
              <EditFilled
                style={{
                  fontSize: "14px",
                  color: "#000",
                  marginRight: "2px",
                }}
              />
              Update cover
            </label>
          </Row>
          <Row justify="center" style={{ marginTop: "-30px" }}>
            <Avatar
              size={{ xs: 80, sm: 80, md: 40, lg: 64, xl: 80, xxl: 100 }}
              style={{ border: "4px solid #fff" }}
              icon={
                !newUserPhoto ? (
                  data?.lab_profile_image ? (
                    <img src={data.lab_profile_image} alt="profile" />
                  ) : (
                    <UserOutlined />
                  )
                ) : (
                  <img src={URL.createObjectURL(newUserPhoto)} alt="profile" />
                )
              }
            />
          </Row>
          <Row
            justify="center"
          >
            <div>
            <div className="flex flex-row justify-center items-center text-center">
            <p>
                Profile Image size should be between 
                <span className="font-weight-bold"> 
                  &nbsp;{getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} 
                </span>
                &nbsp;and 
                <span className="font-weight-bold">
                  &nbsp;{getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}
                </span>. And Cover Picture size should be between 
                <span className="font-weight-bold">
                  &nbsp;{getReadableFileSize(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES)}
                </span>
                &nbsp;and 
                <span className="font-weight-bold">
                  &nbsp;{getReadableFileSize(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)}
                </span>.
              </p>
            </div>
            {
              showSizeError && (
                <div className="flex flex-row justify-center items-center text-center">
                  <p
                    style={{color:"red"}}
                  >
                    * {sizeErrorMessage}
                  </p>
                </div>
              )
            }
            </div>
          </Row>
          <Row
            justify="center"
            style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
          >
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/jpeg,image/png"
              id="file-upload"
              onChange={handleProfileImageChange}
            />
            <label
              htmlFor="file-upload"
              style={{
                cursor: "pointer",
                backgroundColor: "white",
                padding: "5px",
                paddingLeft: "12px",
                paddingRight: "12px",
                border: "1px solid #e8e8e8",
                borderRadius: "7px",
              }}
            >
              Upload photo
            </label>
          </Row>
          <Form
            layout="vertical"
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={data}
          >
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["lab_id"]}
                  label="Lab ID "
                  rules={[{ required: true }]}
                >
                  <Input size="large" readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["email"]}
                  label="Email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input size="large" readOnly disabled />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["contact_person_first_name"]}
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["contact_person_last_name"]}
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["mobile"]}
                  label="Mobile Number"
                  rules={[{ required: true }]}
                >
                  <Input size="large" readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["telephone"]}
                  label="Telephone Number"
                  rules={[{ required: false }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name={["lab_url"]} label="Lab url">
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={showSizeError}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      </Card>
    )
  );
};

export default GeneralInformation;
