import React, { useMemo } from "react";
import { CloseCircleOutlined  } from "@ant-design/icons";
import { Button, TimePicker, Form, Row } from "antd";
import { useLeaves } from "./useLeaves";
import { nanoid } from "nanoid";
import moment from "moment";

const LeaveAddCard = ({leaveMode}) => {
  const { leaveDays, setLeaveDays, timeDuration, leaveSlots } = useLeaves();
  const { RangePicker } = TimePicker;
  const leaveDaysWithId = useMemo(() => {
    return leaveDays.map((item) => ({ ...item, id: nanoid() }));
  }, [leaveDays]);
  const addLeaveDay = () => {
    setLeaveDays([...leaveDays, { from: "", to: "", id: nanoid() }]);
  };
  const onDelete = (id) => {
    let data = leaveDaysWithId.filter((item) => item.id !== id);
    setLeaveDays(data);
  };

  return (
    <>
      <h1 style={{ fontSize: "15px", fontWeight: "500", marginBottom: "0px" }}>Leave slots:</h1>
      {
        leaveMode === "apply" ? null : 
        <p 
          className="text-danger mb-0"
        >
          Remove the slots you want to delete from your leaves.
        </p>
      }

      <div className="times-container">
        {leaveSlots.map((slot) => (
          <div className="time-card leave" key={slot}>
            {slot}
          </div>
        ))}
      </div>
      <Form layout="vertical" name="nest-messages" className="leaves-container">
        {leaveDaysWithId.length
          ? leaveDaysWithId.map((leaveDay) => (
              <Row 
                key={leaveDay.id} 
                align="middle"
              >
                <RangePicker
                  //disable seconds
                  showSecond={false}
                  use12Hours={true}
                  //format to 24 hour
                  format="hh:mm A"
                  onChange={(value) => {
                    setLeaveDays(
                      leaveDaysWithId.map((leave) => {
                        if (leave.id === leaveDay.id) {
                          leave.from = value[0]
                            ? moment(value[0]).format("hh:mm A")
                            : "";
                          leave.to = value[1]
                            ? moment(value[1]).format("hh:mm A")
                            : "";
                        }
                        return leave;
                      })
                    );
                  }}
                  value={[
                    leaveDay.from ? moment(leaveDay.from, "hh:mm A") : null,
                    leaveDay.to ? moment(leaveDay.to, "hh:mm A") : null,
                  ]}
                  allowClear={false}
                  minuteStep={timeDuration}
                />

                <CloseCircleOutlined 
                  onClick={() => onDelete(leaveDay.id)}
                  style={{
                    fontSize: "14px",
                    marginLeft: "10px",
                    color: "red"
                  }}
                />
              </Row>
            ))
          : null}
      </Form>
      <Button className="mt-2" onClick={() => addLeaveDay()}>
        Add
      </Button>
    </>
  );
};

export default LeaveAddCard;
