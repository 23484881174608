import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Input, List, Switch, message } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TableSkeletonLoader } from "Utils/Constants";
import {
  deleteHospitalProcedure,
  getMedicalProcedures,
  updateHospitalProcedureStatus,
} from "Utils/Services/Hospital/MedicalProcedures";

const MedicalProcedures = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);

  const ContentItem = "Practitioner";
  const [data, setData] = useState([]);
  const [DataLoading, setDataLoading] = useState(true);
  const [Pageno, setPageNo] = useState(1);
  const proceduresPerPage = 20;
  const [totalProceduresCount, setTotalProcedures] = useState(0);
  const [SearchKeyword, setSearchKeyword] = useState("");

  const history = useHistory();

  const updateProcedureStatus = async (id, active) => {
    await updateHospitalProcedureStatus(id, active === "1" ? "0" : "1");
    //locally changing active state
    const updatedData = data.map((item) => {
      if (item.id === id) {
        item.active = active === "1" ? "0" : "1";
      }
      return item;
    });
    //message.success("Medical procedure status updated successfully");
    AntdCustomMessage({
      type : antdMessageTypes.SUCCESS,
      content :"Medical procedure status updated successfully"
    });
    setData(updatedData);
  };

  const handleDeleteProcedure = async (id) => {
    try{
      const confirmation = window.confirm("Are you sure to delete this package?");
      if(confirmation){
        const deleteResponse = await deleteHospitalProcedure({id:id});
        if(deleteResponse?.status === 200){
          //message.success(deleteResponse.data);
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content :deleteResponse.data,
          });
          window.location.reload();
        }else{
          //message.error("Something went wrong.");
          AntdCustomMessage({
            type : antdMessageTypes.ERROR,
            content :"Something went wrong.",
          });
        }
      }
    }catch(e){
        //message.error(e.message || e.data || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || e.data || "Something went wrong."
        });
    }
  }
  
  const ProcedureCard = ({ procedure }) => {
    return (
      <div className="procedure-card">
        <div className="procedure-card-image-container">
          <img src={procedure.image} alt="procedure" />
          <div className="procedure-title">
            <p>{procedure.procedures}</p>
          </div>
        </div>

        <div className="procedure-card-actions">
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={procedure.active === "1" ? true : false}
            onChange={async () =>
              await updateProcedureStatus(procedure.id, procedure.active)
            }
          />
          <Button
            onClick={() => {
              history.push(
                `/hospital/edit-medical-procedures/${procedure.id}/${procedure.procedure_id}`
              );
            }}
          >
            <EditOutlined />
          </Button>
          <DeleteOutlined 
            style={{ color: 'red', cursor: 'pointer' }} 
            onClick={()=>{handleDeleteProcedure(procedure.id)}}
          />
        </div>
      </div>
    );
  };

  const FilteredData = (data) => {
    let filteredData = data;

    if (SearchKeyword && data)
      filteredData = data.filter((item) => {
        return item.procedures
          .toLowerCase()
          .includes(SearchKeyword.toLowerCase());
      });

    return filteredData.sort(function (a, b) {
      if (a.procedures < b.procedures) {
        return -1;
      }
      if (a.procedures > b.procedures) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    const FetchData = async () => {
      setDataLoading(true);
      const response = await getMedicalProcedures(
        (Pageno - 1) * proceduresPerPage,
        proceduresPerPage
      );
      if (response) {
        setData(response.procedureRequests);

        setTotalProcedures(response.total_count);
      }
      setDataLoading(false);
    };
    FetchData();
  }, [Pageno]);

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Medical Procedures");
    }
  }, [setHeading, Heading]);
  useEffect(() => {
    setHeadingCount(totalProceduresCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalProceduresCount]);

  return (
    <div className="content">
      <div className={ContentItem}>
        <div className="card-list-header patient">
          <Button
            onClick={() => history.push("add-medical-procedures")}
            type="primary"
            style={{ marginBottom: 10, height: 40 }}
          >
            Add Procedure{" "}
          </Button>
          {/* <p>{totalPatientsCount+" "} Patients found</p> */}
          <Input
            placeholder="Search procedures.."
            className="card-search"
            value={SearchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>

        {DataLoading
          ? TableSkeletonLoader(10, 60)
          : FilteredData(data) && (
              <List
                className="procedures-page"
                dataSource={FilteredData(data)}
                pagination={{
                  current: Pageno,
                  total: totalProceduresCount,
                  onChange: (page) => {
                    setPageNo(page);
                  },
                  pageSize: proceduresPerPage,
                }}
                renderItem={(procedure) => (
                  <List.Item>
                    <ProcedureCard procedure={procedure} />
                  </List.Item>
                )}
              />
            )}
      </div>
    </div>
  );
};
export default MedicalProcedures;
