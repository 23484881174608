import { Button, Card, Col, Form, Input, message, Row } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import MultipleImageUploader from "components/MultipleImageUploader";
import React, { useEffect, useState } from "react";
import {
  getLabProfileInformation,
  postLabProfileInformation,
} from "Utils/Services/Lab/LabProfile";
import { validateMessages } from "Utils/validations";

const LabInformation = () => {
  const [data, setData] = useState(null);
  const [labImageFiles, setLabImageFiles] = useState(null);
  const [labImages, setLabImages] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getLabProfileInformation();
      setData(response?.Lab_info[0]);
    };
    fetchData();
  }, []);

  const onFinish = async (values) => {
    const response = await postLabProfileInformation(
      {
        ...values,
        lab_images: labImages.join(","),
      },
      labImageFiles
    );
    if (response?.status === 200) {
      // message.success({
      //   content: "Succesfully Updated.",
      //   style: {
      //     marginTop: "100px",
      //   },
      // });
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content: "Succesfully Updated.",
        style: {
          marginTop: "100px",
        },
      });
    }
  };

  return (
    data && (
      <Card>
        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={data}
        >
          {/* row starts here */}
          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name={["lab_name"]}
                label="Lab Name"
                rules={[{ required: true }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={18}>
              <Form.Item name={["about_us"]} label="About Lab">
                <Input.TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={20}>
              <Form.Item style={{ marginTop: "15px" }}>
                <p>Practice Images</p>
                <MultipleImageUploader
                  existingImages={
                    data?.lab_images?.length ? data?.lab_images : []
                  }
                  onChange={(images) => {
                    setLabImages(images.oldImages);
                    setLabImageFiles(images.newImages);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    )
  );
};

export default LabInformation;
