import { Card, Tabs } from "antd";
// import moment from "moment";
import React, { useEffect, useState } from "react";
import EllipsisText from "react-ellipsis-text";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { getDateTimeFormat } from "Utils/Constants";
import { getLabOnlinePaymentsDetails } from "Utils/Services/Lab/LabPayments";

const DetailsCardOnline = ({ orderId }) => {
  const [data, setData] = useState("");

  useEffect(() => {
    const getData = async () => {
      getLabOnlinePaymentsDetails({ order_id: orderId }).then((response) => {
        setData(response?.payment_details[0]);
      });
    };
    if (orderId) {
      getData();
    }
  }, [orderId]);

  const SingleDetails = ({ name, data }) => {
    return (
      <div className="details-wraper">
        <label style={{ fontSize: 14 }}>{name}</label>
        <h4
          className="text"
          style={{ fontSize: 14, textTransform: "capitalize" }}
        >
          {data ? data : ""}
        </h4>
      </div>
    );
  };

  const OrderDetails = () => {
    return (
      <div className="general-info">
        <Row lg="9" md="12" sm="12" className="column-items">
          {/* <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Created At
              </h4>
              <h4 className="text-black" style={{ fontSize: 16 }}>
                {moment(data?.created_at).format("DD MMM YYYY")}
              </h4>
            </div>
          </Col> */}
          <Col lg="6" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Package
              </h4>
              <h4 className="text-black" style={{ fontSize: 16 }}>
                {data?.package_name}
              </h4>
            </div>
          </Col>
          <Col lg="6" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Sample Taken From
              </h4>
              <h4
                className="text-black"
                style={{ fontSize: 16, textTransform: "capitalize" }}
              >
                {data?.sample_from}
              </h4>
            </div>
          </Col>
        </Row>
        <Card
          style={{ width: "100%", padding: "10px" }}
          bodyStyle={{ padding: "0px 0px 10px" }}
        >
          <SingleDetails
            name="Created on"
            data={
              data?.created_at ? getDateTimeFormat(data.created_at) : ""
            }
          />
          <SingleDetails name="Amount" data={"₹" + data?.amount} />

          <SingleDetails
            name="Payment Status"
            data={data?.payment_status ? data.payment_status : ""}
          />
        </Card>
      </div>
    );
  };

  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <span className="appointment-id">{data?.order_id}</span>
        <h4 className="mt-2">
          <EllipsisText
            style={{ fontSize: 16, fontWeight: "bold" }}
            className="capitalize"
            text={data?.patient_name ? data.patient_name : " "}
            length={50}
          />
        </h4>
      </div>
      <div className="details-tab-container tab-style">
        <Tabs type="card" defaultActiveKey="1">
          <Tabs.TabPane tab="Online Payment Details" key="1">
            <div className="order-details-container">
              <OrderDetails />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default DetailsCardOnline;
