import React, { useMemo } from "react";
import { Typography, Divider, Empty } from "antd";
import { Col, Row } from "reactstrap";
import { TableSkeletonLoader } from "Utils/Constants";
import moment from "moment";
import { usePatients } from "../usePatients";
import { getAgeFromDOB } from "Utils/GetAgeFromDOB";

const PatientInfoCard = ({ data }) => {
  const { Title, Text } = Typography;
  const { patientsInfoLoading } = usePatients();
  let { status, data: patientsData } = data || {};
  let {
    id = "",
    name = "",
    gender = "",
    dob = "",
    bloodGroup = "",
    email = "",
    mobile = "",
    height = "",
    weight = "",
    medicalConditions = "",
    allergicConditions = "",
  } = patientsData || {};
  const age = useMemo(() => {
    if (dob) {
      return moment().diff(moment(dob, "YYYY-MM-DD"), "years");
    }
    return "";
  }, [dob]);
  return (
    <div className="patient-details-container">
      <Title
        level={3}
        style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
      >
        Patient Details
      </Title>
      <Divider style={{ margin: "12px 0px" }} />
      {patientsInfoLoading ? (
        TableSkeletonLoader(5, 30)
      ) : !status ? (
        <Empty
          description="Something went wrong"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <>
          <Row className="patient-info-row" style={{ fontSize: "16px" }}>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    {" "}
                    Patient Id{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>{id} </Text>{" "}
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Patient Name{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text
                    style={{ color: "#3b3b3b", fontSize: 14 }}
                    className="capitalize"
                  >
                    {name}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="patient-info-row" style={{ fontSize: "16px" }}>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Gender{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {gender}{" "}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    DOB{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {dob && moment(dob, "YYYY-MM-DD").format("DD MMM YYYY")}{" "}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <Row className="patient-info-row" style={{ fontSize: "16px" }}>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Email Address{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {email && email !== "undefined" ? email : ""}{" "}
                  </Text>{" "}
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    {" "}
                    Mobile{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "##3b3b3b" }}>{mobile}</Text>
                </Col>
              </Row>
            </Col>
          </Row> */}
          <Row className="patient-info-row" style={{ fontSize: "16px" }}>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Age{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>{dob && getAgeFromDOB(dob)} </Text>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Blood Group{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {bloodGroup}{" "}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="patient-info-row" style={{ fontSize: "16px" }}>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Weight{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {weight}{" "}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Height{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {height}{" "}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="patient-info-row" style={{ fontSize: "16px" }}>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Allergic Conditions:{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {allergicConditions && allergicConditions.length > 0
                      ? allergicConditions
                      : "N/A"}
                  </Text>{" "}
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Row>
                <Col lg={4}>
                  <Text strong style={{ fontSize: 14 }}>
                    Medical Conditions{" "}
                  </Text>{" "}
                </Col>
                <Col lg={8}>
                  <Text style={{ color: "#3b3b3b", fontSize: 14 }}>
                    {medicalConditions && medicalConditions.length > 0
                      ? medicalConditions
                      : "N/A"}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default PatientInfoCard;
