import { Button, Card, Col, Form, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  getLabProfileWorkingHoursInfomation,
  PostLabProfileWorkingHoursInformation,
} from "Utils/Services/Lab/LabProfile";
import WorkingHoursSelector from "../../../../components/WorkingHoursSelector";
import { validateMessages } from "Utils/validations";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const WorkingHours = () => {
  const [data, setData] = useState(null);
  const [hoursEdited, setHoursEdited] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    let response = await getLabProfileWorkingHoursInfomation();
    response?.Lab_hours[0]?.working_hours &&
      setHoursEdited(response?.Lab_hours[0]?.working_hours);
    let workingHours = response?.Lab_hours[0]?.working_hours;
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let filteredData = days.map((item, i) => ({
      day: item,
      time: workingHours ? workingHours[i] : "",
    }));
    setData(filteredData);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async () => {
    let isValid = true;
    hoursEdited.forEach((item) => {
      if(item === ""){

      }else if (item !== "Closed") {
        let hours = item?.split("-");
        if(hours && hours?.length){
          hours[0] = hours[0]?.substring(0, hours[0]?.length - 1);
          hours[1] = hours[1]?.substring(1);
        }
        if (
          (hours && hours?.length ) &&
          (hours[0] === "" ||
          hours[0] === "Invalid date" ||
          hours[1] === "Invalid date" ||
          hours[1] === "" ||
          hours[0] === hours[1])
        ) {
          isValid = false;
        }
      }
    });
    if (isValid) {
      setIsLoading(true);
      const response = await PostLabProfileWorkingHoursInformation(hoursEdited);
      if (response?.status === 200) {
        // message.success({
        //   content: "Succesfully Updated.",
        //   style: {
        //     marginTop: "100px",
        //   },
        // });
        AntdCustomMessage({
          type : antdMessageTypes?. SUCCESS,
          content: "Succesfully Updated.",
          style: {
            marginTop: "100px",
          },
        });
      }
    } else {
      // message.error({
      //   content: "Please enter valid time.",
      //   style: {
      //     marginTop: "100px",
      //   },
      // });
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Please enter valid time.",
        style: {
          marginTop: "100px",
        },
      });
    }

    setIsLoading(false);
  };

  return (
    <Card>
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        {/* row ends here */}
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={20}>
            {data ? (
              data?.map((item, index) => (
                <Form.Item label={item.day} name={item.day} key={item.day}>
                  <WorkingHoursSelector
                    time={item.time}
                    triggerChange={(val) => {
                      let hoursCopy = [...hoursEdited];
                      hoursCopy[index] = val;
                      setHoursEdited(hoursCopy);
                    }}
                  />
                </Form.Item>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default WorkingHours;
