import React, { useState, createContext, useContext } from "react";
import moment from "moment";
import { getPractitionerAppointments } from "Utils/Services/Practitioner/Appointments";

export const AppointmentsContext = createContext();

export const AppointmentsContextProvider = (props) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [selectedHospitalId, setSelectedHospitalId] = useState("");
  const [selectedStatusType, setSelectedStatusType] = useState("");
  const [Data, setData] = useState({ status: false, data: {} });
  const [appointmentsPerPage, setAppointmentsPerPage] = useState(12);
  const [searchValue, setSearchValue] = useState("");
  const [DataLoading, setDataLoading] = useState(true);
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const handleViewClick = (row) => {
    setShowDetailsModal(true);
    setSelectedAppointmentId(row.appointmentId);
  };
  const FetchData = async (date1 = null, date2 = null, patient_id = "") => {
    setDataLoading(true);
    const startDate = date1 ? moment(date1).format("YYYY-MM-DD") : null;
    const endDate = date2 ? moment(date2).format("YYYY-MM-DD") : null;
    const response = await getPractitionerAppointments({
      startDate,
      endDate,
      limit: appointmentsPerPage || 12,
      start: (pageNo - 1) * appointmentsPerPage,
      search: searchValue || "",
      appointment_status: selectedStatusType || "",
      patient_id: patient_id || "",
    });
    if (response) {
      setData(response);
      setTotalAppointmentsCount(response.data.count);
    }
    setDataLoading(false);
  };

  const values = {
    setShowDetailsModal,
    showDetailsModal,
    showRescheduleModal,
    setShowRescheduleModal,
    selectedAppointmentId,
    setSelectedAppointmentId,
    selectedHospitalId,
    setSelectedHospitalId,
    handleViewClick,
    selectedStatusType,
    setSelectedStatusType,
    FetchData,
    Data,
    appointmentsPerPage,
    setAppointmentsPerPage,
    searchValue,
    setSearchValue,
    DataLoading,
    setDataLoading,
    totalAppointmentsCount,
    setTotalAppointmentsCount,
    pageNo,
    setPageNo,
  };

  return <AppointmentsContext.Provider value={values} {...props} />;
};

export const useAppointments = () => {
  const context = useContext(AppointmentsContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
