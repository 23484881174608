import React from "react";
import { Row, Col } from "antd";
import StarIcon from "Images/icons/star.png";
import StarEmptyIcon from "Images/icons/star_white.png";
import PatientIcon from "Images/dashboard-icons/patient.svg";

// return stars svg based on rating
const getStars = (rating = 5) => {
  return (
    <div style={{display:"flex", flexDirection:"row",}}>
      {[...Array(rating)].map((item, index) => {
        return (
          <img
            key={index}
            src={StarIcon}
            alt="k"
            style={{ width: "15px", marginRight: "2px" }}
          />
        );
      })}
      {[...Array(5 - rating)].map((item, index) => {
        return (
          <img
            key={index}
            src={StarEmptyIcon}
            alt="k"
            style={{ width: "15px", marginRight: "2px" }}
          />
        );
      })}
    </div>
  );
};

const ReviewCard = ({ review }) => {
  let {
    id = "",
    description = "",
    title = "",
    patientName = "",
    rating = "",
    patientProfileImage = "",
    problem = "",
    treatment = ""
  } = review || {};

  const topBadgeStyle = {
    backgroundColor:"#45bd5b",
    color:"white", 
    padding:"2px 10px",
    borderRadius:"1rem",
    margin:"2px"
  };

  return (
    <div
      className="review-card"
    >
      
      <div style={{
        width:"100%",
        display:"flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start"
      }}>
          {getStars(rating)}
        <div style={{
          width:"50%",
          height:"80px",
          display:"flex",
          flexDirection: "column",
          alignItems: "flex-end",
          overflow: "auto",
          wordBreak:"break-all",
        }}>
          {
            problem && 
            <span style={topBadgeStyle}>
              {problem}
            </span>
          }
          {
            treatment && 
            <span style={topBadgeStyle}>
              {treatment}
            </span>
          }
        </div>
      </div>
      <Row  style={{ height: "100px", overflow:"auto"}} className="flex-grow-1 flex-shrink-1 flex flex-col align-items-end">
        <div
          style={{
            fontSize: "14px",
            paddingRight: "30px",
            color: "#6E757F",
            overflow: "scroll",
            wordBreak: "break-all",
            wordWrap: "break-word",
          }}
        >
          <p>{description}</p>
        </div>
      </Row>
      <hr style={{margin:"5px",borderWidth:"1px"}}/>
      <Row>
        <Col>
          <img
            style={{
              height: "30px",
              width: "30px",
              marginRight: "6px",
            }}
            src={PatientIcon}
            alt="patientIcon"
          />
        </Col>
        <Col>
        <div style={{ fontWeight: "300", fontSize: "18px" }}>
          {patientName}
        </div>
        </Col>
      </Row> 
    </div>
  );
};

export default ReviewCard;
