// import { LabAppointments } from "DummyData";
import { LabURLParser } from "Utils/urls.utils";
import { PostData, GetData } from "../../ApiCall";

export const getLabAppointments = async (values) => {
  return await PostData(LabURLParser("/appointments"), values).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const getAppointmentDetails = async (values) => {
  return await PostData(LabURLParser("/appointmentDetails"), values).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const getAppointmentReports = async (values) => {
  return await PostData(LabURLParser("/reports"), values).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const saveLabReports = async (values) => {
  return await PostData(
    LabURLParser("/appointments/saveLabReports"),
    values
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const changeAppointmentStatus = async (values) => {
  return await PostData(
    LabURLParser("/appointments/change_appointment_status"),
    values
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const getAppointmentStatusTypes = async () => {
  const response = await GetData(LabURLParser("/getAppointmentStatusTypes"));
  if (response) {
    return response?.data?.appointment_status_types;
  }
};
