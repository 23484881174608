import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { Card } from "antd";

const AppointmentHistoryTab = ({ data }) => {
  let {
    problems = "",
    symptoms = "",
    existingMedicalConditions = "",
    allergies = "",
    currentMedications = "",
    previousSurgeries = "",
  } = data || {};
  return (
    <div className="general-info">
        <Card
            style={{ width: "100%", padding: "10px" }}
            bodyStyle={{ padding: "0px 0px 10px" }}
        >
            <div className="details-wraper" style={{alignItems:"start"}}>
            <label style={{ fontSize: 14 }}>Problems</label>
            <h4 className="text" style={{  width:'100em',fontSize: 14, overflowX:"scroll"  }}>
                {problems}
            </h4>
            </div>
            <div className="details-wraper" style={{alignItems:"start"}}>
            <label style={{ fontSize: 14 }}>Symptoms</label>
            <h4 className="text" style={{  width:'100em',fontSize: 14, overflowX:"scroll"  }}>
                {symptoms}
            </h4>
            </div>
            <div className="details-wraper" style={{alignItems:"start"}}>
            <label style={{ fontSize: 14 }}>Existing Medical Conditions</label>
            <h4 className="text" style={{  width:'100em',fontSize: 14, overflowX:"scroll"  }}>
                {existingMedicalConditions}
            </h4>
            </div>
            <div className="details-wraper" style={{alignItems:"start"}}>
            <label style={{ fontSize: 14 }}>Allergies</label>
            <h4 className="text" style={{  width:'100em',fontSize: 14, overflowX:"scroll"  }}>
                {allergies}
            </h4>
            </div>
            <div className="details-wraper" style={{alignItems:"start"}}>
            <label style={{ fontSize: 14 }}>Current Medications using</label>
            <h4 className="text" style={{  width:'100em',fontSize: 14, overflowX:"scroll"  }}>
                {currentMedications}
            </h4>
            </div>
            <div className="details-wraper" style={{alignItems:"start"}}>
            <label style={{ fontSize: 14 }}>Previous Surgeries</label>
            <h4 style={{  width:'100em',fontSize: 14, overflowX:"scroll"  }} className="text">
                {previousSurgeries}
            </h4>
            </div>
        </Card>
    </div>
  );
};

export default AppointmentHistoryTab;
