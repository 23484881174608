import React from "react";

const WorkingHours = ({ workingHours }) => {
  return (
    <div className="working-hours-container">
      {workingHours.map((item) => {
        return (
          <div className="working-hours-item" key={`${item.day}${item.time}`}>
            <div>{item.day}</div>
            <div>{item?.time}</div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkingHours;
