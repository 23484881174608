import {
  modelNotificationsAPI,
  modelChangePractitionerNotificationStatusAPI,
} from "models/practitioner/notifications.api.models";
import { PractitionerURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";

export const getPractitionerNotifications = async (payload) =>
  modelNotificationsAPI(
    await PostData(PractitionerURLParser("/getNotifications"), payload)
  );

export const changePractitionerNotificationStatus = async (payload) =>
  modelChangePractitionerNotificationStatusAPI(
    await PostData(PractitionerURLParser("/changeNotificationStatus"), payload)
  );
