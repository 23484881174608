import { modelAppointmentsApi } from "models/practitioner/appointments.api.models";
import {
  modelPatientsListAPI,
  modelPatientDetailsAPI,
} from "models/practitioner/patients.api.models";
import { PractitionerURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";
import { modelPatientReports } from "models/hospital/patient-reports.api.models";

export const getPatients = async (payload) =>
  modelPatientsListAPI(
    await PostData(PractitionerURLParser("/getPatients"), payload)
  );

export const getPatientDetails = async (payload) =>
  modelPatientDetailsAPI(
    await PostData(PractitionerURLParser("/getPatientsDetails"), payload)
  );

export const getPatientAppointments = async (payload) =>
  modelAppointmentsApi(
    await PostData(PractitionerURLParser("/getPatientsAppointments"), payload)
  );

export const getPatientReports = async (payload) => {
  const response =  modelPatientReports(await PostData(
    PractitionerURLParser("/getClinicUploadedReports"),
    payload
  ));
  return response;
  }
