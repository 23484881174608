import React, { useEffect, useState } from "react";
import { Card, Form, message, Switch } from "antd";
import {
  getPractitionerSettings,
  updatePractitionerSettings,
} from "Utils/Services/Practitioner/Profile";
import FullWidthView from "components/FullWidthView";
import Spinner from "components/Spinner";
import BugContainer from "components/ErrorContainer/BugContainer";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({ status: false, data: {} });

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getPractitionerSettings();
    response && setSettings(response);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = async (values) => {
    let response = await updatePractitionerSettings(values);
    if (response?.status){
       //message.success("Settings updated successfully");
       AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Settings updated successfully"
    });
    }else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
    });
    }
  };

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  if (!settings?.status) return <BugContainer />;
  return (
    <Card>
      <Form
        layout="vertical"
        name="nest-messages"
        initialValues={settings.data}
        onValuesChange={onChange}
      >
        <Form.Item
          label="Request Auto Confirmation"
          rules={[{ required: true }]}
          //className="details-container p-3"
          name="auto_approve_req"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Settings;
