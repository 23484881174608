import React, { useEffect, useContext, useState } from "react";
import { GlobalState } from "context-api";
import { Tabs } from "antd";
import GeneralInformation from "./GeneralInformation/index";
import PractitionerInformation from "./PractitionerInformation/index";
import WorkingHours from "./WorkingHours/index";
import ConsultationFee from "./ConsultationFee/index";
import Settings from "./Settings";
import RefundPercentageTab from "./RefundPercentage/index";

const PractitionerProfileContainer = () => {
  const { setHeading, Heading } = useContext(GlobalState);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Practitioner Profile");
    }
  }, [setHeading, Heading]);

  //set active tab from url params if present
  useEffect(() => {
    if (window.location.href.includes("tab")) {
      const tab = window.location.href?.split("tab=")[1];
      setActiveTab(tab);
    }
  }, []);

  //save current tab id in url
  const onTabChange = (key) => {
    window.history.pushState(
      null,
      null,
      `/practitioner/practitioner_profile/?tab=${key}`
    );
    setActiveTab(key);
  };

  return (
    <div className="content">
      <div className="details-tab-container tab-style">
        <Tabs
          type="card"
          activeKey={activeTab}
          animated={true}
          tabBarGutter={6}
          onChange={onTabChange}
        >
          <TabPane tab="General information" key="1">
            <GeneralInformation />
          </TabPane>
          <TabPane tab="Practitioner information" key="2">
            <PractitionerInformation />
          </TabPane>
          {/* <TabPane tab="Working hours" key="3">
            <WorkingHours />
          </TabPane> */}
          {/* <TabPane tab="Consultation fee" key="4">
            <ConsultationFee />
          </TabPane> */}
          <TabPane tab="Settings" key="5">
            <Settings />
          </TabPane>
          <TabPane tab="Refund Percentage" key="6">
            <RefundPercentageTab/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default PractitionerProfileContainer;
