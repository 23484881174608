import AuthLayout from "layouts/Auth.js";
import HospitalLayout from "layouts/Hospital.js";
import LabLayout from "layouts/Lab.js";
import PractitionerLayout from "layouts/Practitioner.js";
import React, { useMemo, useState, useEffect } from "react";
import { Redirect, Route, useHistory  } from "react-router-dom";
import Cookies from "universal-cookie";
import { DecryptString, TotalSpinner } from "Utils/Constants";
import ForgotPassword from "views/pages/Auth/ForgotPassord";
import LoginPage from "views/pages/Auth/login";
import Register from "views/pages/Auth/Register";
import ResetPassword from "views/pages/Auth/ResetPassword";
import HospitalOnboarding from "views/pages/Hospital/Onboarding";
import LabOnboarding from "views/pages/Lab/Onboarding";
import PractitionerOnboarding from "views/pages/Practitioner/Onboarding";

const RootIndex = ({ location }) => {
  const cookies = new Cookies();
  const history = new useHistory();

  //setting the usertype for development purposes

  let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
  let UserData = cookieUserData && JSON.parse(cookieUserData);
  const LoggedInUserType = UserData?.type;

  const UserType = UserData?.type;
  const SubStatus = UserData?.subscriptionStatus;

  const PrivateRoute = ({ render, path }) => {
    const authed = UserType ? true : false;
    const ele =
      authed === true
        ? render
        : () => (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: path },
              }}
            />
          );

    return <Route path={path} render={ele} />;
  };

  const DefaultRedirect = useMemo(() => {
    if (location?.pathname === "/") {
      if (LoggedInUserType === "hospital") {
        if(UserData?.subadmin || UserData?.profile_completed){
          return <Redirect to="/hospital/dashboard" />;
        }else{
          return <Redirect to="/onboarding/hospital" />;
        }
        
        // if (SubStatus === "Not Activated" || SubStatus === "Expired") {
        //   return <Redirect to="/hospital/subscription" />;
        // } else {
        //   return <Redirect to="/hospital/dashboard" />;
        // }
      } else if (LoggedInUserType === "lab") {
        if(UserData?.subadmin || UserData?.profile_completed){
          return <Redirect to="/lab/dashboard" />;
        }else{
          return <Redirect to="/onboarding/lab" />;
        }
        // if (SubStatus === "Not Activated" || SubStatus === "Expired") {
        //   return <Redirect to="/lab/subscription" />;
        // } else {
        //   return <Redirect to="/lab/dashboard" />;
        // }
      } else if (LoggedInUserType === "practitioner") {
        if(UserData?.profile_completed){
          return <Redirect to="/practitioner/dashboard" />;
        }else{
          return <Redirect to="/onboarding/practitioner" />;
        }
        
      } else if (location?.pathname === "/") {
        return <Redirect to="/login" />;
      } else {
        return <Redirect to={location.pathname} />;
      }
    } // eslint-disable-line
    // eslint-disable-line
  }, [LoggedInUserType, location]); // eslint-disable-line

  const LogOutUser = async () => {
    cookies.remove("userdata", { path: "/" });
    cookies.remove("token", { path: "/" });
    history?.push("/login");
    window?.location?.reload();
  };

  //// Session expiration starts
  const [lastActivityTime, setLastActivityTime] = useState(new Date());

  // Update the last activity time whenever there is user interaction
  const handleUserActivity = () => {
    setLastActivityTime(new Date());
  };

  function customThrottleEvent(func, interval) {
    let lastExecutionTime = 0;
  
    return function (...args) {
      const currentTime = Date.now();
  
      if (currentTime - lastExecutionTime >= interval) {
        func();
        lastExecutionTime = currentTime;
      }
    };
  }
  const throttleInterval =  10 * 60 * 1000

  useEffect(() => {
    // Attach event listeners for user activity
    window.addEventListener('mousemove',customThrottleEvent(handleUserActivity,throttleInterval));

    // Cleanup event listeners when the component is unmounted
    return () => {
      window.removeEventListener('mousemove', customThrottleEvent(handleUserActivity,throttleInterval));
    };
  }, []);

  useEffect(() => {
    const checkIdleTime = () => {
      const currentTime = new Date();
      const idleTime = currentTime - lastActivityTime;
  
      // Set the idle time limit to 1 hour (in milliseconds)
      const idleTimeLimit = 60 * 60 * 1000;
      idleTime > idleTimeLimit && LogOutUser();
    };
  
    // Check idle time every 5 minutes
    const checkInterval = setInterval(checkIdleTime, 5 * 60 * 1000);

    return () => clearInterval(checkInterval);
  }, [lastActivityTime]);
  //// End of Session  expiration

  return (
    <>
      <Route
        path="/register"
        render={(props) => (
          <Register />
        )}
      />
      <Route
        path="/login"
        render={(props) => (
          <AuthLayout>
            <TotalSpinner />
            <LoginPage />
          </AuthLayout>
        )}
      />
      <Route
        path="/onboarding/practitioner"
        render={(props) => (
          <PractitionerOnboarding/>
        )}
      />
      <Route
        path="/onboarding/hospital"
        render={(props) => (
          <HospitalOnboarding/>
        )}
      />
       <Route
        path="/onboarding/lab"
        render={(props) => (
          <LabOnboarding/>
        )}
      />
      
      <Route
        path="/forgot-password"
        render={(props) => (
          <AuthLayout>
            <TotalSpinner />
            <ForgotPassword />
          </AuthLayout>
        )}
      />
      <Route
        path="/reset-password/:id/:type"
        render={(props) => (
          <AuthLayout>
            <TotalSpinner />
            <ResetPassword />
          </AuthLayout>
        )}
      />
      <PrivateRoute
        path="/hospital"
        render={(props) => (
          <HospitalLayout {...props} SubscriptionStatus={SubStatus} />
        )}
      />
      <PrivateRoute
        path="/lab"
        render={(props) => (
          <LabLayout {...props} SubscriptionStatus={SubStatus} />
        )}
      />
      <PrivateRoute
        path="/practitioner"
        render={(props) => <PractitionerLayout {...props} />}
      />

      {DefaultRedirect}
    </>
  );
};

export default RootIndex;
