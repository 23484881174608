import { GetData, PostData } from "../../ApiCall";
import { HospitalURLParser } from "Utils/urls.utils";
import {
  modelGetReportsAPI,
  modelSaveReportsAPI,
} from "models/hospital/appointments.api.models";
import { modelWorkHorsSlotAPI } from "models/hospital/quickbooking.api.models";
import { modelShareAppointmentDetailsAPI } from "models/practitioner/appointments.api.models";

export const getAppointments = async (
  start,
  limit,
  appointment_status,
  search,
  startDate,
  endDate
) => {
  const response = await PostData(HospitalURLParser("/appointments"), {
    appointment_status,
    start,
    limit,
    search,
    startDate,
    endDate,
  });
  if (response) {
    return response?.data;
  }
};

export const getAppointmentStatusTypes = async () => {
  const response = await GetData(
    HospitalURLParser("/getAppointmentStatusTypes")
  );
  if (response) {
    return response?.data?.appointment_status_types;
  }
};

export const getPatientAppointmentDetails = async (appointment_id) => {
  const response = await PostData(
    HospitalURLParser("/patientAppointmentDetails"),
    {
      appointment_id,
    }
  );
  if (response) {
    return response?.data?.appointment_details[0];
  }
};
export const ChangeAppointmentStatus = async (data) => {
  const response = await PostData(
    HospitalURLParser("/appointment/change_appointment_status"),
    {
      ...data,
    }
  );
  if (response) {
    return response;
  }
};

export const getWorkHourSlots = async (payload) =>
  modelWorkHorsSlotAPI(
    await PostData(HospitalURLParser("/practitioner/workHoursSlots"), payload)
  );

export const getReports = async (payload) =>
  modelGetReportsAPI(await PostData(HospitalURLParser("/reports"), payload));

export const saveReports = async (payload) =>
  modelSaveReportsAPI(
    await PostData(
      HospitalURLParser("/appointments/saveHospitalReports"),
      payload
    )
  );

export const getPackageAppointmentDetails = async (values) => {
  return await PostData(
    HospitalURLParser("/getHospitalPackageAppointmentDetails"),
    values
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const changePackageAppointmentStatus = async (values) => {
  return await PostData(
    HospitalURLParser("/changePackageAppointmentStatus"),
    values
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const savePackageReports = async (values) => {
  return await PostData(
    HospitalURLParser("/saveHospitalPackageReports"),
    values
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const getPackageAppointmentReports = async (values) => {
  return await PostData(
    HospitalURLParser("/hospitalPackageReports"),
    values
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const getPackageAppointments = async (payload) => {
  return await PostData(
    HospitalURLParser("/getAppointmentsWithPackages"),
    payload
  ).then((resp) => {
    if (resp) return resp?.data;
  });
};

export const getPackageAppointmentStatusTypes = async () => {
  const response = await GetData(
    HospitalURLParser("/getPackageAppointmentStatusTypes")
  );
  if (response) {
    return response?.data?.appointment_status_types;
  }
};

export const shareAppointmentDetailsClinic = async (payload) =>
  modelShareAppointmentDetailsAPI(
    await PostData(HospitalURLParser("/shareAppointmentDetails"), payload)
  );

