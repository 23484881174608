export function getYouTubeVideoId(url) {
    // Regular expression to match YouTube video ID in various URL formats
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);

    // If there is a match, return the video ID, otherwise return null
    return match ? match[1] : null;
}

export function getVimeoVideoId(url) {
    const vimeoUrlPattern = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(\d+)(?:.*)/;
    const match = url.match(vimeoUrlPattern);
  
    // If a match is found, return the video ID, otherwise return null
    return match ? match[1] : null;
  }
  
