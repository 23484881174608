export const modelLeaveDaysAPI = async (response) => {
  let result = {
    status: false,
    data: {
      leaveDays: [],
      timeDuration: 0,
    },
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    if (Array.isArray(responseData.leave_days.slots)) {
      result.data.leaveDays = responseData.leave_days.slots;
    }
    result.data.timeDuration = Number(responseData.time_duration) || 1;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
