import React from "react";
import { ReviewsContextProvider } from "./useReviews";
import ReviewsContainer from "./ReviewsContainer";

const Reviews = () => {
  return (
    <ReviewsContextProvider>
      <ReviewsContainer />
    </ReviewsContextProvider>
  );
};

export default Reviews;
