export const modelNotificationsAPI = (response) => {
  let result = {
    status: false,
    data: {
      notifications: [],
      total_count: 0,
    },
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid response");
    if (typeof response.data !== "object") throw new Error("Invalid response");
    result.status = true;
    let { total_count = 0, notifications = [] } = response.data || {};
    result.data.total_count = total_count;
    if (Array.isArray(notifications) && notifications.length) {
      result.data.notifications = notifications;
    }
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelChangePractitionerNotificationStatusAPI = (response) => {
  let result = {
    status: false,
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid response");
    if (typeof response.data !== "object") throw new Error("Invalid response");
    result.status = response?.data?.success || false;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};
