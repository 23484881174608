import React from "react";
import { Button, Col, Form, Input, Row, Table } from "antd";
import { useProfile } from "../../useProfile";
import columnResponsiveWrapper from "Utils/columnResponsiveWrapper";

const ExperienceSection = () => {
  const { data, setData } = useProfile();
  const { experience = [] } = data.data || {};
  const [form] = Form.useForm();

  const deleteExperiance = (row) => {
    const newData = experience.filter((item, index) => index !== row.key);
    setData({ ...data, data: { ...data.data, experience: newData } });
  };

  const addExperiance = (values) => {
    let { title = "", year = "", description = "" } = values;
    setData((prevState) => {
      let { experience = [] } = prevState.data;
      experience.push({ title, year, description });
      return { ...prevState, data: { ...prevState.data, experience } };
    });
    form.resetFields();
  };

  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Year", dataIndex: "year", key: "year" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Button danger size="small" onClick={() => deleteExperiance(record)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="bg-gray p-6 sm-p-2 rounded-3 gap-2">
      {/* selected section starts here */}
      <Row
        xs={24}
        sm={24}
        lg={24}
        className="bg-white p-2 rounded-3 shadow-sm w-full mb-4"
      >
        <Table
          columns={columnResponsiveWrapper(columns)}
          expandable={{
            expandedRowRender: (record) => (
              <p style={{ margin: 0 }}>{record.descriptionExpanded}</p>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          dataSource={experience.map((item, key) => {
            return {
              ...item,
              key: key,
              //show description with 20 characters
              description: item.description.substring(0, 20) + "...",
              descriptionExpanded: item.description,
            };
          })}
          className="w-full"
        />
      </Row>
      {/* selected section ends here */}
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={addExperiance}
        form={form}
      >
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["title"]}
              label="Title"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["year"]}
              label="Year"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["description"]}
              label="Description"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Button htmlType="submit">Add</Button>
        </Row>
      </Form>
    </div>
  );
};

export default ExperienceSection;
