import { Button, Card, Col, Form, message, Row, Switch, Radio } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import React, { useEffect, useState } from "react";
import {
  getHospitalProfileSettingsInformation,
  PostHospitalProfileSettingsInformation,
} from "Utils/Services/Hospital/HospitalProfile";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [AutoApproval, setAutoApproval] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState(null);

  const PaymentOptions = [
    { value: 0, label: "Offline" },
    { value: 1, label: "Online" },
    { value: 2, label: "Both" },
  ];

  const FetchData = async () => {
    setIsLoading(true);
    const response = await getHospitalProfileSettingsInformation();
    if (response) {
      setPaymentMethod(response?.settings_info[0]?.onlinePaymentMethod);
      setAutoApproval(response?.settings_info[0]?.auto_approve_req);
    }
    setIsLoading(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    let payload = {
      onlinePaymentMethod: PaymentMethod,
      auto_approve_req: AutoApproval,
    };
    const response = await PostHospitalProfileSettingsInformation(payload);
    if (response?.status === 200) {
      // message.success("Account settings updated successfully");
      AntdCustomMessage({
        content : "Account settings updated successfully",
        type: antdMessageTypes.SUCCESS
      });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        content : "Something went wrong",
        type: antdMessageTypes.ERROR
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <Card>
      <Row gutter={{ sm: 24, lg: 24 }}>
        <h1 style={{ fontSize: "18px" }}>Practitioner Practice Request</h1>
      </Row>
      <Form layout="vertical" onFinish={onFinish} name="nest-messages">
        {/* row starts here */}
        <Row gutter={{ sm: 24, lg: 24 }}>
          <Col xs={24} sm={24} lg={18}>
            <Form.Item label="Auto Approve Appointments">
              <Switch
                checked={AutoApproval}
                onChange={() => setAutoApproval((old) => (old === 1 ? 0 : 1))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 24, lg: 24 }}>
          <Col xs={24} sm={24} lg={18}>
            <Form.Item label="Package Payment Method">
              <Radio.Group
                className="primaryButtonRadio"
                options={PaymentOptions}
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
                value={PaymentMethod}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={{ sm: 24, lg: 24 }}>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ height: 40 }}
            >
              Submit
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Settings;
