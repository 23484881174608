import React, { useContext, useEffect, useState } from 'react';
import { 
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography
} from  'antd';
import { GlobalState } from 'context-api';
import { 
  ChangeHospitalPassword, 
  ChangeHospitalSubAdminPassword, 
  ChangeLabPassword, 
  ChangeLabSubAdminPassword, 
  ChangePractitionerPassword 
} from 'Utils/Services/Auth';
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const {
    setHeading,
    UserData,
  } = useContext(GlobalState);

  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const onFinish = async (values) => {
    setIsLoading(true);
    let changePasswordResponse = {};
    const pathname = window.location.pathname;
    if(pathname.startsWith("/hospital")){
      changePasswordResponse = UserData?.subadmin ? await ChangeHospitalSubAdminPassword({
        ...values,
        subadmin_id: UserData.subadminId,
      }) : await ChangeHospitalPassword({
        ...values
      });
    }else if(pathname.startsWith("/lab")){
      changePasswordResponse = UserData?.subadmin ? await ChangeLabSubAdminPassword({
        ...values,
        subadmin_id: UserData.subadminId,
      }) : await ChangeLabPassword({
        ...values
      });
    }else if(pathname.startsWith("/practitioner")){
      changePasswordResponse = await ChangePractitionerPassword({
        ...values
      })
    }
    

    if(changePasswordResponse.success){
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: changePasswordResponse.message,
      });
      setData({
        old_password: "",
        new_password: "",
        confirm_password: "",
      });
      form.resetFields();
    }else{
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: changePasswordResponse?.message,
      });
    }
    setIsLoading(false);
  };

  const onFinishFailed = async (errorInfo) => {
      setIsLoading(false);
  };

  useEffect(()=>{setHeading("Change Password")},[])

  return (
    <div className='content'>
      <Col sm={24}>
        <Typography.Title level={3} className='text-center'>Change Password</Typography.Title>
      </Col>
      <Row justify="center">
        <Col xs={20} sm={20} md={16} lg={12} xl={10} xxl={8}>
          <Form
            form={form}
            name="changePasswordForm"
            initialValues={data}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
          >
            <Form.Item
                label="Enter Old Password"
                name="old_password"
                rules={[
                    {
                    required: true,
                    message: "Please enter your old password",
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>                         
            <Form.Item
                label="Enter Password"
                name="new_password"
                rules={[
                    {
                    required: true,
                    message: "Please enter password",
                    },
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="confirm_password"
                dependencies={['password']}
                rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('new_password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The passwords that you entered do not match!'));
                    },
                }),
                ]}
            >
                <Input.Password/>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    span: 24,
                }}
                className='text-center'
            >
                <Button 
                    type="primary" 
                    htmlType="submit"
                    // disabled={!isValid}
                    loading={isLoading}
                >
                    Submit
                </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default ChangePassword