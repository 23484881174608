import { HospitalURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";
import { modelPractitionerOnlinePaymentsListAPI } from "models/hospital/appointments.api.models";

// export const getPayments = async (payload) => {
//   const response = await PostData(
//     HospitalURLParser("/appointmentPayments"),
//     payload
//   );
//   if (response) {
//     return response.data;
//   }
// };



export const getPayments = async (payload) =>
  modelPractitionerOnlinePaymentsListAPI(
    await PostData( HospitalURLParser("/appointmentPayments"), payload)
  );

export const getPaymentDetails = async (order_id) => {
  const response = await PostData(
    HospitalURLParser("/appointmentPaymentDetails"),
    {
      order_id,
    }
  );
  if (response) {
    return response.data;
  }
};
