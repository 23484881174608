import moment from "moment";

export const modelLeaveDaysAPI = async (response) => {
  let result = {
    status: false,
    data: {
      leaveDays: [],
      timeDuration: 0,
    },
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    let { data: responseData, status = 500 } = response || {};
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    if (Array.isArray(responseData.leave_days.slots)) {
      result.data.leaveDays = responseData.leave_days.slots;
    }
    result.data.timeDuration = Number(responseData.time_duration) || 1;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelLeaveDaysUpdateAPI = async (response) => {
  let result = {
    status: false,
    data: {
      success: false,
      update: false,
      message: "",
    },
  };
  try {
    if (typeof response !== "object") throw new Error("invalid response");
    if (response.status !== 200) throw new Error("invalid status");
    result.status = true;
    if (response.data.success === true) {
      result.data.success = true;
    } else result.data.message = response?.data?.message || "";
    if (response.data.update === true) result.data.update = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelUpdateLeaveDaysPayload = ({
  leaveDate,
  endLeaveDate,
  leaveDays = [],
  clinic_id
}) => {
  let result = {};
  try {
    if (!Array.isArray(leaveDays)) throw new Error("invalid leaveDays");
    let leaves = [];
    //loop through each slot
    leaveDays.forEach((leave) => {
      let { from = "", to = "" } = leave || {};
      if (from === "" || to === "") throw new Error("invalid slot");
      //convert from and to to 24 hour format
      let fromTime = moment(from, "hh:mm A").format("HH:mm");
      let toTime = moment(to, "hh:mm A").format("HH:mm");
      leaves.push({
        from: fromTime,
        to: toTime,
      });
    });
    result = {
      clinic_id: clinic_id,
      leave_date: moment(leaveDate).format("YYYY-MM-DD"),
      end_leave_date: endLeaveDate ? moment(endLeaveDate).format("YYYY-MM-DD") : "",
      slots: leaves,
    };
  } catch {
    result = {
      clinic_id: "",
      date: "",
      slots: [],
    };
  } finally {
    return result;
  }
};
