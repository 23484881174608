import React, { useState, createContext, useContext } from "react";

export const PatientsContext = createContext();

export const PatientsContextProvider = (props) => {
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [patientsInfoLoading, setPatientsInfoLoading] = useState(true);
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);

  const values = {
    SearchKeyword,
    setSearchKeyword,
    patientsInfoLoading,
    setPatientsInfoLoading,
    appointmentsLoading,
    setAppointmentsLoading,
  };

  return <PatientsContext.Provider value={values} {...props} />;
};

export const usePatients = () => {
  const context = useContext(PatientsContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
