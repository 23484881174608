import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

const AppointmentsChart = ({ data }) => {
  const options = {
    chart: {
      type: "spline",
      height: 500,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        overflow: "justify",
      },
      gridLineColor: "#f3f3f3",
      gridLineDashStyle: "longdash",
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + "<br/>" + point.series.name + ": " + point.y;
        }, "<b>" + moment(this.x).format("DD MMM YYYY") + "</b>");
      },
      pointFormat: "{series.name} : {point.y}<br></br>",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    series: data,
    time: {
      useUTC: false,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AppointmentsChart;
