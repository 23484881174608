import AppointmentsIcon from "Images/dashboard-icons/lab/appointment.png";
import DashboardIcon from "Images/dashboard-icons/lab/speedometer.png";
import PatientRegistrationIcon from "Images/dashboard-icons/lab/patient.png";

import OnlinePaymentIcon from "Images/dashboard-icons/lab/online.png";
import OfflinePaymentIcon from "Images/dashboard-icons/lab/offline.png";
import QuickBookingIcon from "Images/dashboard-icons/lab/booking.png";
import LabprofileIcon from "Images/dashboard-icons/lab/user.png";
import PackagesIcon from "Images/dashboard-icons/lab/flask.png";
import AdminIcon from "Images/dashboard-icons/hospital/admin.png";

import ReviewsIcon from "Images/sidebar-icons/reviews.svg";
import LabAppointments from "views/pages/Lab/Appointments/index";
import LabDashboard from "views/pages/Lab/Dashboard/Dashboard";
import LabPackages from "views/pages/Lab/LabPackages/index";
// import PatientRegistration from "views/pages/Lab/PatientRegistration/index";
import LabProfile from "views/pages/Lab/LabProfile";
import LabOfflinePayments from "views/pages/Lab/OfflinePayments/index";
import LabOnlinePayments from "views/pages/Lab/OnlinePayments/index";
import LabReviews from "views/pages/Lab/Reviews/index";
import QuickBooking from "views/pages/Lab/QuickBooking";
import Patients from "views/pages/Lab/Patients";
import Notifications from "views/pages/Lab/Notifications.js";
import NotificationIcon from "Images/dashboard-icons/lab/notification.png";
import SubscriptionIcon from "Images/dashboard-icons/lab/subscription.png";
import Subscription from "views/pages/Lab/Subscription";
import LabTestsComponent from "views/pages/Lab/LabTests";
import MultipleAdmins from "views/pages/Lab/MultipleAdmins";

export const LabRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: LabDashboard,
    layout: "/lab",
  },
  {
    path: "/quick_booking",
    name: "Instant booking",
    icon: QuickBookingIcon,
    component: QuickBooking,
    layout: "/lab",
  },
  {
    path: "/online-payments",
    name: "Online Payments",
    icon: OnlinePaymentIcon,
    component: LabOnlinePayments,
    layout: "/lab",
  },
  {
    path: "/offline-payments",
    name: "Offline Payments",
    icon: OfflinePaymentIcon,
    component: LabOfflinePayments,
    layout: "/lab",
  },
  {
    path:"/subAdmins",
    name: "Sub Admins",
    icon: AdminIcon,
    component: MultipleAdmins,
    layout: "/lab",
  },
  {
    path: "/lab-packages",
    name: "Lab Packages",
    icon: PackagesIcon,
    component: LabPackages,
    layout: "/lab",
  },
  {
    path: "/lab-tests",
    name: "Lab Tests",
    icon: PackagesIcon,
    component: LabTestsComponent,
    layout: "/lab",
  },
  {
    path: "/patients",
    name: "Patients",
    icon: PatientRegistrationIcon,
    component: Patients,
    layout: "/lab",
  },

  {
    path: "/appointments",
    name: "Appointments",
    icon: AppointmentsIcon,
    component: LabAppointments,
    layout: "/lab",
  },
  // {
  //   path: "/patient_registration",
  //   name: "Patient Registration",
  //   icon: PatientRegistrationIcon,
  //   component: PatientRegistration,
  //   layout: "/lab",
  // },
  
  {
    path: "/reviews",
    name: "Reviews",
    icon: ReviewsIcon,
    component: LabReviews,
    layout: "/lab",
  },
  // {
  //   path: "/subscription",
  //   name: "Subscription",
  //   icon: SubscriptionIcon,
  //   component: Subscription,
  //   layout: "/lab",
  // },
  {
    path: "/lab-profile",
    name: "Lab Profile",
    icon: LabprofileIcon,
    component: LabProfile,
    layout: "/lab",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: NotificationIcon,
    component: Notifications,
    layout: "/lab",
  },
];

export const LabNoSubscriptionRoutes = [
  {
    path: "/subscription",
    name: "Subscription",
    icon: SubscriptionIcon,
    component: Subscription,
    layout: "/lab",
  },
  {
    path: "/lab-profile",
    name: "Lab Profile",
    icon: LabprofileIcon,
    component: LabProfile,
    layout: "/lab",
  },
];
