import React, { useState, createContext, useContext } from "react";
import { getPractitionerConsultingHospitals } from "Utils/Services/Practitioner/Profile";
import { getWorkHoursSlots } from "Utils/Services/Practitioner/ScheduleTimings";
import { getLeaveDays } from "Utils/Services/Practitioner/LeaveDays";
import moment from "moment";
import { nanoid } from "nanoid";

export const LeavesContext = createContext();

export const LeavesContextProvider = (props) => {
  const [slots, setSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [myHospitals, setMyHospitals] = useState({ status: false, data: [] });
  const [selectedHospital, setSelectedHospital] = useState("");
  const [timeDuration, setTimeDuration] = useState(1);
  const [leaveDays, setLeaveDays] = useState([
    { from: "", to: "", id: nanoid() },
  ]);
  const Today = moment(new Date()).format("YYYY-MM-DD");
  const [date, setDate] = useState(Today);
  const [leaveDate, setLeaveDate] = useState(Today);
  const [endLeaveDate, setEndLeaveDate] = useState(moment(Today).add(1,"d"));
  const [dates, setDates] = useState([]);
  const [isDateAreaExpanded, setIsDateAreaExpanded] = useState(true);
  const [leaveSlots, setLeaveSlots] = useState([]);

  const fetchWorkHoursSlot = async (clinic_id, date) => {
    setIsLoading(true);
    let response = await getWorkHoursSlots({
      date: moment(date).format("YYYY-MM-DD"),
      clinic_id,
    });
    if (response?.status) {
      setSlots(response?.data?.workHours);
      setDates(response?.data?.dates);
      setLeaveSlots(response?.data?.leaveSlots);
    }
    setIsLoading(false);
  };

  const fetchLeaveDays = async (clinic_id, date) => {
    setLeaveDays([{ from: "", to: "", id: nanoid() }]);
    setIsLoading(true);
    let response = await getLeaveDays({
      leave_date: date,
      clinic_id,
    });
    if (response?.status) {
      setLeaveDays(response?.data?.leaveDays);
      setTimeDuration(response?.data?.timeDuration);
    }
    setIsLoading(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    let hospitalsResponse = await getPractitionerConsultingHospitals();
    setMyHospitals(hospitalsResponse);
    if (hospitalsResponse && hospitalsResponse?.status && hospitalsResponse?.data?.length) {
      setSelectedHospital(hospitalsResponse?.data[0]?.id);
      fetchWorkHoursSlot(hospitalsResponse?.data[0]?.id, date);
      fetchLeaveDays(hospitalsResponse?.data[0]?.id, date);
    } else setIsLoading(false);
  };

  const onDateChange = (Date) => {
    setDate(Date);
    setLeaveDate(Date);
    setEndLeaveDate(moment(Date).add(1,"d"));
    fetchWorkHoursSlot(selectedHospital, Date);
    fetchLeaveDays(selectedHospital, Date);
  };

  const values = {
    slots,
    setSlots,
    isLoading,
    setIsLoading,
    Today,
    myHospitals,
    setMyHospitals,
    selectedHospital,
    setSelectedHospital,
    leaveDays,
    setLeaveDays,
    date,
    setDate,
    leaveDate,
    setLeaveDate,
    endLeaveDate,
    setEndLeaveDate,
    fetchData,
    fetchWorkHoursSlot,
    fetchLeaveDays,
    timeDuration,
    dates,
    onDateChange,
    isDateAreaExpanded,
    setIsDateAreaExpanded,
    leaveSlots,
  };

  return <LeavesContext.Provider value={values} {...props} />;
};

export const useLeaves = () => {
  const context = useContext(LeavesContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
