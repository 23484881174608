import React from "react";
import { Modal, Empty, List } from "antd";
import { TableSkeletonLoader } from "Utils/Constants";
import AppointmentCard from "views/pages/Practitioner/Appointments/AppointmentCard";
import RescheduleCard from "views/pages/Practitioner/Appointments/RescheduleCard";
import DetailsCard from "views/pages/Practitioner/Appointments/AppointmentDetails/index";
import { useAppointments } from "views/pages/Practitioner/Appointments/useAppointments";

const TodaysAppointments = ({
  TodaysAppointments,
  FetchTodayAppointments,
  TodayAppointmentsLoading,
}) => {
  const {
    selectedAppointmentId,
    showRescheduleModal,
    setShowRescheduleModal,
    showDetailsModal,
    setShowDetailsModal,
    selectedPractitionerId,
  } = useAppointments();

  return (
    <div
      className="today-appointments"
      style={{
        height: "434px",
        minHeight: "434px",
      }}
    >
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Hospital Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setShowDetailsModal(false)}
      >
        <DetailsCard />
      </Modal>
      <Modal
        className="item-details-modal"
        title="Reschedule Details"
        visible={showRescheduleModal}
        footer={null}
        width={500}
        onCancel={() => setShowRescheduleModal(false)}
      >
        <RescheduleCard
          practitioner_id={selectedPractitionerId}
          appointment_id={selectedAppointmentId}
          FetchData={FetchTodayAppointments}
          setShowRescheduleModal={setShowRescheduleModal}
        />
      </Modal>
      {TodayAppointmentsLoading ? (
        TableSkeletonLoader(15, 30)
      ) : TodaysAppointments?.length ? (
        <List
          dataSource={TodaysAppointments}
          renderItem={(appointment) => (
            <List.Item style={{ padding: 0 }}>
              <AppointmentCard
                appointment={appointment}
                FetchData={FetchTodayAppointments}
                today={true}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description="No Appointments Today"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="empty-nodata"
        />
      )}
    </div>
  );
};

export default TodaysAppointments;
