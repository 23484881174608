import { Button, Card, Col, Form, Input, message, Row, Select } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import LocationMap from "components/LocationMap";
import React, { useEffect, useState} from "react";

import {
  GetDistrictsRelatedCities,
  GetHospitalLocationDetails,
  PostHospitalLocationDetails,
} from "Utils/Services/Hospital/HospitalProfile";
import { getDistrictsOfState } from "Utils/Services/Onboarding";
import { validateMessages } from "Utils/validations";

const LocationDetails = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [form] = Form.useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const fetchData = async () => {
    const response = await GetHospitalLocationDetails();
    response && setData(response);
    response?.location_info[0]?.latitude && setLat(response.location_info[0].latitude?.replace(/[^0-9.-]/g, ''));
    response?.location_info[0]?.longitude && setLng(response.location_info[0].longitude?.replace(/[^0-9.-]/g, ''));
    response && form.setFieldsValue(response.location_info[0]);
  };
  
  const getGeoLocation = ()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position)=>{
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            latitude:position.coords.latitude,
            longitude:position.coords.longitude
          });
        },
        (error)=>{
          if(error.code === 1){
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content: "Please allow ShopDoc to access your location.",
              style: {
                marginTop: "100px",
              },
              closeable : true,
            })
          }else{
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content: "Unable to fetch your location.",
              style: {
                marginTop: "100px",
              },
              closeable : true,
            })
          }
          // console.log(error)
        },
        { enableHighAccuracy: true }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  const showGeoLocation = ()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position)=>{
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        (error)=>{
          // console.log(error)
        },
        { enableHighAccuracy: true }
      );
      
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  useEffect(() => {
    fetchData();
    const formValues = form.getFieldsValue();
    if(!formValues?.latitude && !formValues?.longitude){
      showGeoLocation();
    }
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      const response = await GetDistrictsRelatedCities({
        district_id: selectedDistrict,
      });
      response && setCities(response);
    };
    selectedDistrict && fetchCities();
  }, [selectedDistrict]);

  useEffect(()=>{
    const fetchDistricts = async () => {
      const response = await getDistrictsOfState({
        state_id: selectedState,
      });
      response && setDistricts(response?.data);
    };
    selectedState && fetchDistricts();
  },[selectedState]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // form.setFieldsValue({[name]:value?.replace(/[^0-9.-]/g, '')});
    form.setFieldsValue({[name]:value});
    if(name==="latitude"){
      setLat(value.replace(/[^0-9.-]/g, ''));
    }else{
      setLng(value.replace(/[^0-9.-]/g, ''));
    }
    if(!e.target.value){
      showGeoLocation();
    }
  };

  const handleMapClick = ({lat,lng}) => {
    setLat(lat);
    setLng(lng);
    form.setFieldsValue({...form.getFieldsValue(),latitude:lat,longitude:lng});
  };

  const validateLatitude = (_, value) => {
    const longitude = form.getFieldValue('longitude');
    if (!value) {
      return longitude ? Promise.reject('Latitude is required'): Promise.resolve();
    }
    return Promise.resolve();
  };

  const validateLongitude = (_, value) => {
    const latitude = form.getFieldValue('latitude');
    if (!value) {
      return latitude ? Promise.reject('Longitude is required'): Promise.resolve();
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const response = await PostHospitalLocationDetails(values);
    if (response?.status === 200) {
      // message.success({
      //   content: "Succesfully Updated.",
      //   style: {
      //     marginTop: "100px",
      //   },
      // });
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content: "Succesfully Updated.",
        style: {
          marginTop: "100px",
        },
        closeable : true,
      });
    }
    window.location.reload();
    fetchData();
    setIsLoading(false);
  };

  return data?.location_info ? (
    <Card>
      <Form
        form={form}
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={data?.location_info[0]}
      >
        {/* row starts here */}
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["address"]}
              label="Address"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["state_id"]}
              label="State"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a state"
                optionFilterProp="children"
                size="large"
                onChange={(value) => {
                  setSelectedState(value);
                  form.setFieldsValue({
                    district_id:"",
                    city:""
                  });
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {data?.states?.map((item) => (
                  <Select.Option key={item.id}>{item.state}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["district_id"]}
              label="District"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a district"
                optionFilterProp="children"
                size="large"
                onChange={(value) => {
                  setSelectedDistrict(value);
                  form.setFieldsValue({
                    city: "",
                  });
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                 {districts.length > 0
                  ? districts?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.districts}
                      </Select.Option>
                    ))
                  : data?.districts?.map((item) => (
                    <Select.Option key={item.id}>{item.districts}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["city"]}
              label="City"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a city"
                optionFilterProp="children"
                size="large"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {cities.length > 0
                  ? cities?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.city}
                      </Select.Option>
                    ))
                  : data?.cities?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.city}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["town"]}
              label="Town"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["pincode"]}
              label="Postal Code"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          
        </Row>

        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item 
              name={["latitude"]} 
              label="Latitude"
              rules={[
                { validator: validateLatitude, dependencies: ['longitude'] },
              ]}
            >
              <Input size="large" name="latitude" onChange={handleInputChange}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item 
              name={["longitude"]} 
              label="Longitude"
              rules={[
                { validator: validateLongitude, dependencies: ['latitude'] },
              ]}
            >
              <Input size="large" name="longitude" onChange={handleInputChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} lg={24}>
            <Button onClick={getGeoLocation}>Click here to fill latitude and longitude from geolocation.</Button>
          </Col>
        </Row>
          
        <Row style={{margin:"5px 0"}}>
          <Col xs={24} sm={24} lg={24}>
          <LocationMap position={{lat,lng}} handleMapClick={handleMapClick}/>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ height: 40 }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  ) : (
    <p>loading..</p>
  );
};

export default LocationDetails;
