import axios from "axios";
import Cookies from "universal-cookie";
import { EncriptString } from "Utils/Constants";

export const checkLogin = async (values) => {
  const cookies = new Cookies();

  try{
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/checklogin`,
      {
        ...values,
      }
    );
    if(response?.status === 200 && response?.data?.status === 500){
      return {
        status: false,
        message: response?.data?.message
      }
    }
    if (response !== 403 && response?.data?.status !== 500) {
      response = response?.data;
      cookies.set("token", response?.token, {
        path: "/",
      });
      cookies.set(
        "userdata",
        EncriptString(
          JSON.stringify({
            email: values?.email,
            type: values?.type,
            userid: response?.userid,
            refreshtoken: response?.refreshToken,
            clinic_name: response?.clinic_name,
            lab_name: response?.lab_name,
            practitioner_name: response?.practitioner_name,
            profileImage: response?.profileImage,
            subscriptionStatus: response?.subscriptionStatus,
            subadmin: response?.subadmin,
            subadminId: response?.subadmin_id,
            profile_completed: response?.profile_completed,
          })
        ),
        { path: "/" }
      );
  
      return { status: true, subscriptionStatus: response?.subscriptionStatus };
    }
    return false;
  }catch(e){
    return false;
  }
};

export const refreshToken = async (values) => {
  const cookies = new Cookies();

  const response = await axios
    .post(`${process.env.REACT_APP_BASE_URL}/auth/token`, {
      ...values,
    })
    .catch((error) => {
      cookies.remove("token", { path: "/" });
      cookies.remove("userdata", { path: "/" });
      return (window.location.href = "/login");
    });

  if (response.data !== 403 && response.data !== 401) {
    cookies.set("token", response?.token, {
      path: "/",
    });
    return response;
  }
  return (window.location.href = "/login");
};

export const rejectToken = async (refreshToken) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/reject_refreshtoken`,
    {
      refreshToken,
    }
  );
  if (response !== 403) {
    return response;
  }
  return false;
};

export const forgotPassword = async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/forgot_password`,
    {
      ...values,
    }
  );
  if (response !== 403) {
    return response;
  }
  return false;
};

export const resetPassword = async (values) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/auth/reset_password`,
    {
      ...values,
    }
  );
  if (response !== 403) {
    return response;
  }
  return false;
};

export const providerRegister = async (values) => {
  let result = {
    status: false,
    message: ""
  }
  try{
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/common/provider/register`,
      {
        ...values,
      }
    );
    if(response?.status === 200 && response?.data?.affectedRows === 1) {
      result.status = true ;
      result.message = "Registered with shopdoc successfully.";
    }else if(response?.status === 200 && response?.data?.errors){
      result.status = false ;
      result.message = response?.data?.errors[0];
    }else{
      result.status = false;
      result.message = "Something went wrong";
    }
  }catch(e){
    result.status = false;
    result.message = e?.response?.data?.message ||
      e?.response?.data?.error || 
      e?.response?.data?.errors[0] || 
      e?.error || 
      e?.message || 
      "Something went wrong";
  }finally{
    return result;
  }
};

export const providerRegisterResendOTP = async (values) => {
  let result = {
    status: false,
    message: ""
  }
  try{
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/common/provider/otp/resend`,
      {
        ...values,
      }
    );
    
    if(response?.status === 200){
      result.status = true;
      result.message = "OTP is sent to your email."
    }else{
      result.status = false;
      result.message = "Something went wrong while sending OTP."
    }
  }catch(e){
    result.status = false;
    result.message = e?.response?.message || e?.response?.error || (e?.response?.data?.errors && e?.response?.data?.errors[0]) || "Something went wrong";
  }finally{
    return result;
  }
}

export const providerVerifyOTP = async (values) => {
  let result = {
    status: false,
    message: ""
  }
  try{
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/common/provider/otp/verify`,
      {
        ...values,
      }
    );
    
    if(response.status === 200 && response.data.success === true) {
      result.status = true ;
      result.message = "OTP verified successfully.";
    }else{
      result.status = false;
      result.message = "Something went wrong. Please try again.";
    }
  }catch(e){
    result.status = false;
    result.message = e?.response?.message || e?.response?.error || (e?.response?.errors && e?.response?.errors[0])||(e?.response?.data?.errors && e?.response?.data?.errors[0]) || "Something went wrong";
  }finally{
    return result;
  }
};

export const checkProviderEmailExists = async (values) => {
  const providerType = {
    practitioner : "Practitioner",
    clinic : "Clinic",
    hospital : "Clinic",
    lab : "Lab",
  };

  let response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/common/check${providerType[values?.type]}EmailExists/${values?.email}`,
  );
  return response;
}
export const checkProviderMobileExists = async (values) => {
  const providerType = {
    practitioner : "Practitioner",
    clinic : "Clinic",
    hospital : "Clinic",
    lab : "Lab",
  };

  let response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/common/check${providerType[values.type]}MobileExists/${values.mobile}`,
  );
  return response;
};

export const ChangePassword = async (url,values) => {
  const cookies = new Cookies();
  let result = {
    success: false,
    message: ""
  };
  try{
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${url}`,
      values,
      {
        headers:{
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: cookies.get("token", { path: "/" })
            ? cookies.get("token", { path: "/" })
            : "",
        }
      }
    );
    
    if(response?.status === 200 && response?.data?.status === 200) {
      result.success = true ;
      result.message = response?.data?.message;
    }else if(response?.status === 200 && response?.data?.message){
      result.success = true ;
      result.message = response?.data?.message;
    }else{
      result.success = false;
      result.message = "Something went wrong";
    }
  }catch(e){
    result.success = false;
    result.message = e?.response?.data?.message ||
      e?.response?.data?.error || 
      e?.response?.data?.errors[0] || 
      "Something went wrong";
  }finally{
    return result;
  }
};

export const ChangeLabPassword = (values) => ChangePassword(
  "/api/v1/lab/changeLabPassword",values
);

export const ChangeLabSubAdminPassword = (values) => ChangePassword(
  "/api/v1/lab/subadmin/changePassword",values
);

export const ChangeHospitalPassword = (values) => ChangePassword(
  "/api/v1/hospital/changePassword",values
);

export const ChangeHospitalSubAdminPassword = (values) => ChangePassword(
  "/api/v1/hospital/subadmin/changePassword",values
);

export const ChangePractitionerPassword = (values) => ChangePassword(
  "/api/v1/practitioner/changePassword",values
);

