import { LabURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";

export const getLabProfileGeneralInformation = async () => {
  return await PostData(
    LabURLParser("/GetLabProfileGeneralInformation"),
    {}
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const postLabProfileGeneralInformation = async (values) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => formData.append(key, values[key]));
  return await PostData(
    LabURLParser("/PostLabProfileGeneralInformation"),
    formData
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const getLabProfileWorkingHoursInfomation = async () => {
  return await PostData(
    LabURLParser("/GetLabProfileWorkingHoursInfomation"),
    {}
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const PostLabProfileWorkingHoursInformation = async (values) => {
  const response = await PostData(
    LabURLParser("/PostLabProfileWorkingHoursInformation"),
    { working_hours: values.toString() }
  );
  if (response) {
    return response;
  }
};

export const getLabProfileLocationDetails = async () => {
  return await PostData(LabURLParser("/GetLabProfileLocationDetails"), {}).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};
export const getLabDistrictsRelatedCities = async (data) => {
  return await PostData(LabURLParser("/getDistrictsRelatedCities"), data).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const PostLabProfileLocationDetails = async (values) => {
  const response = await PostData(
    LabURLParser("/PostLabProfileLocationDetails"),
    values
  );
  if (response) {
    return response;
  }
};

export const getLabProfileInformation = async () => {
  return await PostData(LabURLParser("/GetLabProfileInformation"), {}).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const postLabProfileInformation = async (values, images) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => formData.append(key, values[key]));
  images && images.forEach((item) => formData.append("lab_images", item));

  return await PostData(
    LabURLParser("/PostLabProfileInformation"),
    formData
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const GetLabProfileSettingsInformation = async () => {
  return await PostData(
    LabURLParser("/GetLabProfileSettingsInformation"),
    {}
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};

export const PostLabProfileSettingsInformation = async (payload) => {
  return await PostData(
    LabURLParser("/PostLabProfileSettingsInformation"),
    payload
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};
