import React, { useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import customMarkerIcon from '../Images/map-marker-svgrepo-com.svg';
import mapTiler from '../map-providers';


const LocationMap = ({ position, handleMapClick }) => {
  const markerRef = useRef();
  const mapRef = useRef();

  // Set custom marker icon
  const customIcon = new L.Icon({
    iconUrl: customMarkerIcon,
    iconSize: [32, 32], // adjust the size of custom icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
  });
 
  useEffect(() => {
    // Initialize the map
    const map = L.map('map',{ scrollWheelZoom: true })
      .setView([position.lat, position.lng], 13);
    
    L.tileLayer(`https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=${mapTiler.apiKey}`, {
      attribution: mapTiler.attribution
    }).addTo(map);
    // Add a marker to the map
    const newMarker = L.marker([position.lat, position.lng],{icon:customIcon}).addTo(map);
    markerRef.current = newMarker;

    // Add click event to the map
    map.on('click', (event) => {
      const { lat, lng } = event.latlng;
      if(lat && lng) {
        markerRef.current.setLatLng([parseFloat(lat), parseFloat(lng)]);
        handleMapClick({lat:parseFloat(lat),lng:parseFloat(lng)});
        map.fitBounds(event.target.getBounds());
      }
    });

    map.on('locationerror', (event) => {
      // console.error('Error getting location:', event.message);
    });

    mapRef.current = map;

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(()=>{
    markerRef.current && markerRef.current.setLatLng([position.lat,position.lng]);
    mapRef.current && mapRef.current.setView([position.lat, position.lng], mapRef.current.getZoom());
  },[position.lat,position.lng]);

  return (
    <div id="map" style={{ height: '400px' }}></div>
  );
};

export default LocationMap;