import { HospitalURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";
import {
  modelNotificationsAPI,
  modelChangeHospitalNotificationStatusAPI,
} from "models/hospital/notifications.api.models";

export const getHospitalNotifications = async (payload) =>
  modelNotificationsAPI(
    await PostData(HospitalURLParser("/getNotifications"), payload)
  );

export const changeHospitalNotificationStatus = async (payload) =>
  modelChangeHospitalNotificationStatusAPI(
    await PostData(HospitalURLParser("/changeNotificationStatus"), payload)
  );
