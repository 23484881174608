import { HospitalURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";

export const getDashboardCounts = async (payload) => {
  return await PostData(HospitalURLParser("/dashboardCounts"), payload).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
};

export const getDashboardAppointmentsChartData = async (payload) => {
  return await PostData(
    HospitalURLParser("/dashboardAppointmentsCharts"),
    payload
  ).then((response) => {
    if (response?.data) {
      return response.data;
    }
  });
};
