import React, { useState, useEffect, useContext } from "react";
import { GlobalState } from "context-api";
import { Tabs, Input } from "antd";
import MyHospitals from "./MyHospitals";
import AllHospitals from "./AllHospitals";
import { useHospitals } from "../useHospitals";
import RequestedHospitals from "./RequestedHospitals";

const HospitalTabs = () => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");
  const { setHeading, Heading } = useContext(GlobalState);
  const {
    SearchKeyword = "",
    setSearchKeyword,
    MyPracticesCount,
    AllPracticesCount,
    RequestedPracticesCount,
  } = useHospitals();

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Practices");
    }
  }, [setHeading, Heading]);

  //save current tab id in url
  const onTabChange = (key) => {
    // window.history.pushState(null, null, `/practitioner/hospital/?tab=${key}`);
    setActiveTab(key);
  };
  return (
    <div className="content">
      <Input
        style={{ marginTop: "10px" }}
        placeholder="Search hospitals.."
        className="card-search"
        value={SearchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
      <Tabs
        type="card"
        activeKey={activeTab}
        animated={true}
        tabBarGutter={6}
        onChange={onTabChange}
        destroyInactiveTabPane={true}
      >
        <TabPane
          tab={`My Practices ${
            MyPracticesCount > 0 ? "(" + MyPracticesCount + ")" : ""
          }`}
          key="1"
        >
          <MyHospitals />
        </TabPane>
        <TabPane
          tab={`Requested Practices ${
            RequestedPracticesCount > 0 ? "(" + RequestedPracticesCount + ")" : ""
          }`}
          key="2"
        >
          <RequestedHospitals/>
        </TabPane>
        <TabPane
          tab={`All Practices ${
            AllPracticesCount > 0 ? "(" + AllPracticesCount + ")" : ""
          }`}
          key="3"
        >
          <AllHospitals />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default HospitalTabs;
