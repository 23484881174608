import React from "react";
import { PatientsContextProvider } from "./usePatients";
import PatientsContainer from "./PatientsContainer";

const Patients = () => {
  return (
    <PatientsContextProvider>
      <PatientsContainer />
    </PatientsContextProvider>
  );
};

export default Patients;
