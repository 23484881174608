import { PostData } from "Utils/ApiCall";
import {
  modelLeaveDaysAPI,
  modelLeaveDaysUpdateAPI,
} from "models/practitioner/leave-marking.api.models";
import { PractitionerURLParser } from "Utils/urls.utils";

export const getLeaveDays = async (payload) =>
  modelLeaveDaysAPI(
    await PostData(PractitionerURLParser("/getLeaveDays"), payload)
  );

export const updateLeaveDays = async (payload) =>
  modelLeaveDaysUpdateAPI(
    await PostData(PractitionerURLParser("/updateLeaveDays"), payload)
  );
