import React, { useEffect, useState, useContext } from "react";
import { GlobalState } from "context-api";
import {
  addMedicalProcedure,
  getActiveProcedures,
} from "Utils/Services/Hospital/MedicalProcedures";
import { Select, Button, Divider, Typography, Alert } from "antd";
import { useHistory } from "react-router";

const AddMedicalProcedures = () => {
  const { Option } = Select;
  const history = useHistory();

  const { setHeading, Heading } = useContext(GlobalState);

  const [seletcedProcedure, setSelectedProcedure] = useState(null);
  const [data, setData] = useState([]);
  const [DataLoading, setDataLoading] = useState(true);
  const { Title } = Typography;
  const [postStatus, setPostStatus] = useState({ status: null, message: "" });

  const FetchData = async () => {
    setDataLoading(true);
    const proceduresResponse = await getActiveProcedures();
    proceduresResponse?.procedures && setData(proceduresResponse?.procedures);
    setDataLoading(false);
  };

  const addProcedure = async () => {
    setDataLoading(true);
    const response = await addMedicalProcedure(seletcedProcedure);
    response && setPostStatus({
      status: response.status,
      message: response.data.message,
    });
    response?.status === 200 &&
      setTimeout(() => history.push("medical-procedures"), 1000);
    setDataLoading(false);
  };

  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Procedures");
    }
  }, [setHeading, Heading]);

  return (
    <div className="content">
      <Button onClick={() => history.goBack()} style={{ marginBottom: "10px" }}>
        Back
      </Button>
      <div className="procedure-container">
        <Title
          style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
        >
          Add procedure
        </Title>
        <Divider style={{ margin: "12px 0px" }} />
        <Select
          className="shadow-select"
          onChange={(val) => setSelectedProcedure(val)}
          showSearch
          style={{ width: 300, height: 40 }}
          placeholder="Select a procedure"
          optionFilterProp="children"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {data?.map((item) => (
            <Option value={item.id} key={item.id}>
              {item.procedures}
            </Option>
          ))}
        </Select>
        <br />
        <Button
          loading={DataLoading}
          style={{ marginTop: "20px", height: 40 }}
          type="primary"
          disabled={seletcedProcedure === null}
          onClick={addProcedure}
        >
          Add Procedure
        </Button>
        {postStatus.status !== null && (
          <Alert
            style={{ marginTop: 10 }}
            message={postStatus.message}
            type={postStatus.status === 200 ? "success" : "error"}
          />
        )}
      </div>
    </div>
  );
};
export default AddMedicalProcedures;
