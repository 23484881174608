import React from "react";
import { Card, CardBody } from "reactstrap";
import EllipsisText from "react-ellipsis-text";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { EncriptString } from "Utils/Constants";

const PatientCard = ({ patient }) => {
  let history = useHistory();
  let { id, name, email, mobile } = patient || {};
  let encryptedId = EncriptString(id.toString());
  return (
    <div className="patient-list-card">
      <Card className="card-stats" style={{ padding: "10px" }}>
        <CardBody style={{ padding: "0px", position: "relative" }}>
          <div
            onClick={() =>
              history.push(`/practitioner/patient-details/${encryptedId}`)
            }
          >
            <div className="profile-image">
              <div className="char-avatar">
                <span>{name.charAt(0).toUpperCase()}</span>
              </div>
            </div>
            <div className="info-container">
              <h4>
                <EllipsisText className="capitalize" text={name} length={25} />
              </h4>
              {/* {mobile && mobile !== "undefined" ? (
                <p className="email">
                  <PhoneOutlined />
                  {mobile}
                </p>
              ) : (
                ""
              )} */}
              {/* {email && email !== "undefined" ? (
                <p className="mobile">
                  <MailOutlined />
                  {email}
                </p>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PatientCard;
