import React from "react";
import moment from "moment";
import { useLeaves } from "./useLeaves";

const Calendar = () => {
  const { dates, date: selectedDate, onDateChange } = useLeaves();
  const SingleDate = ({ Date }) => {
    const isSelected =
      moment(selectedDate).format("YYYY-MM-DD") ===
      moment(Date).format("YYYY-MM-DD");

    return (
      <div
        className={isSelected ? "date-card selected" : "date-card"}
        onClick={() => onDateChange(Date)}
      >
        <h1 className={isSelected ? "day selected" : "day"}>
          {moment(Date).format("DD")}
        </h1>
        <h1 className={isSelected ? "month selected" : "month"}>
          {moment(Date).format("MMM YYYY")}
        </h1>
      </div>
    );
  };

  return dates.map((Date) => {
    return <SingleDate key={Date} Date={moment(Date).format("YYYY-MM-DD")} />;
  });
};

export default Calendar;
