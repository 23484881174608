import React from "react";
import LeaveMarkingContainer from "./LeaveMarkingContainer";
import { LeavesContextProvider } from "./useLeaves";

const LeaveMarking = () => {
  return (
    <LeavesContextProvider>
      <LeaveMarkingContainer />
    </LeavesContextProvider>
  );
};

export default LeaveMarking;
