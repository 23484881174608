export function getAgeFromDOB(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
        if(years <= 0 && months <= 0) {
            days = 0
        }else{
            months--;
            const tempDate = new Date(today.getFullYear(), today.getMonth() - 1, 0);
            days += tempDate.getDate();
        }
    }

    if (months < 0) {
        years--;
        months += 12;
    }

    years = years < 0   ? 0 : years;
    months = months < 0 ? 0 : months;
    days = days < 0? 0 : days;

    let age = (years ? (years + " years ") : "") +
          (months ? (months+ " months ") :"") + 
          (days ? (days + " days "):"");
    age = age || "0 days";
    return age;
}