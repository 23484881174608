import { toggleSidebar } from "components/Navbars/AdminNavbar";
import { GlobalState } from "context-api";
import shopdoc_logo from "Images/shopdocLogoDark.svg";
import shopdoc_icon from "Images/shopdoc_icon.png";
import React, { useContext, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, Nav } from "reactstrap";
import Cookies from "universal-cookie";
import { DecryptString } from "Utils/Constants";
/* eslint-disable */
const Sidebar = (props) => {
  const cookies = new Cookies();

  const {
    setHeading,
    AccountImage = null,
    ProfileName = "",
  } = useContext(GlobalState);
  let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
  let UserData = cookieUserData && JSON.parse(cookieUserData);

  const sidebar = useRef();

  const [openAvatar, setopenAvatar] = useState(false);
  const [abc, setabc] = useState(false);

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              data-toggle="collapse"
              aria-expanded={abc}
              onClick={(e) => {
                e.preventDefault();
                setabc(!abc);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <img alt="logo" src={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={!abc}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          onClick={() => {
            setHeading(prop.name);
            toggleSidebar();
          }}
        >
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <img alt="logo" src={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href="/" className="simple-text logo-mini">
          <div className="logo-img">
            <img alt="logo" src={shopdoc_icon} />
          </div>
        </a>
        <a href="/" className="simple-text logo-normal">
          <img alt="logo" src={shopdoc_logo} style={{ borderRadius: 0 }} />
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <div className="user">
          <div className="photo">
            <img src={AccountImage} alt="Avatar" style={{ height: "100%" }} />
          </div>
          <div className="info">
            <a
              data-toggle="collapse"
              aria-expanded={openAvatar}
              onClick={() => setopenAvatar(!openAvatar)}
            >
              <span className="text-ellipsis">{ProfileName}</span>
            </a>
          </div>
        </div>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
};
export default Sidebar;
