import React, { useMemo } from "react";
import { Form, message, Switch } from "antd";
import {
  updatePractitionerAutoAppointmentAndCheckin,
  updatePractitionerAppointmentTypes,
} from "Utils/Services/Hospital/Practitioner";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const Settings = ({ settings, practitioner_id, getData, urlName }) => {
  const initialValues = useMemo(() => {
    return {
      video_enable_status: settings?.videoStatus === 1,
      phone_enable_status: settings?.phoneStatus === 1,
      inperson_enable_status: settings?.inpersonStatus === 1,
      auto_appointment_status: settings?.appointmentAutoApprovalStatus === 1,
      auto_checkin_status: settings?.autoCheckinStatus === 1,
    };
  }, [settings]);

  const onChange = async (values) => {
    const key = Object.keys(values)[0];
    let payload = {
      practitionerId: +practitioner_id?.replace("SDP", ""),
    };
    if (key === "auto_appointment_status") {
      payload.appointmentAutoApprovalStatus = values.auto_appointment_status
        ? 1
        : 0;
      payload.autoCheckinStatus = settings.autoCheckinStatus;
    }
    if (key === "auto_checkin_status") {
      payload.autoCheckinStatus = values.auto_checkin_status ? 1 : 0;
      payload.appointmentAutoApprovalStatus =
        settings.appointmentAutoApprovalStatus;
    }
    if (key === "inperson_enable_status") {
      payload.inpersonEnableStatus = values.inperson_enable_status ? 1 : 0;
      payload.phoneEnableStatus = settings.phoneStatus;
      payload.videoEnableStatus = settings.videoStatus;
    }
    if (key === "phone_enable_status") {
      payload.phoneEnableStatus = values.phone_enable_status ? 1 : 0;
      payload.videoEnableStatus = settings.videoStatus;
      payload.inpersonEnableStatus = settings.inpersonStatus;
    }
    if (key === "video_enable_status") {
      payload.videoEnableStatus = values.video_enable_status ? 1 : 0;
      payload.inpersonEnableStatus = settings.inpersonStatus;
      payload.phoneEnableStatus = settings.phoneStatus;
    }

    let response;

    if (key === "auto_appointment_status" || key === "auto_checkin_status") {
      response = await updatePractitionerAutoAppointmentAndCheckin(payload);
    } else {
      response = await updatePractitionerAppointmentTypes(payload);
    }
    if (response.status === 200) {
      //message.success("Practitioner settings updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Practitioner settings updated successfully",
      })
      getData(urlName);
    }
  };
  return (
    <div className="settings-container">
      <Form
        layout="vertical"
        name="nest-messages"
        initialValues={initialValues || {}}
        onValuesChange={onChange}
      >
        <div className="buttons-container">
          <Form.Item
            label="Appointments Auto Confirmation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="auto_appointment_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Check-in Feature"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="auto_checkin_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Video Consultation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="video_enable_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Phone Consultation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="phone_enable_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="In Person Consultation"
            rules={[{ required: true }]}
            className="details-container p-3"
            name="inperson_enable_status"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Settings;
