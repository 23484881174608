import React, { useState } from "react";
import { Modal, Empty, List } from "antd";
import { TableSkeletonLoader } from "Utils/Constants";
import PackageAppointmentCard from "views/pages/Hospital/Appointments/PackageAppointmentCard";
import PackageDetailCard from "views/pages/Hospital/Appointments/PackageDetailCard";
import { useHistory } from "react-router-dom";

const TodayPackageAppointments = ({
  TodaysAppointments,
  FetchTodayAppointments,
  TodayAppointmentsLoading,
}) => {
  const [selectedPackageAppointmentId, setSelectedPackageAppointmentId] =
    useState(null);
  const history = useHistory();

  const handlePackageViewClick = (row) => {
    setSelectedPackageAppointmentId(row.appointment_id);
  };

  return (
    <div className="today-appointments">
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Appointment Details"
        visible={selectedPackageAppointmentId !== null}
        footer={null}
        width={700}
        onCancel={() => setSelectedPackageAppointmentId(null)}
      >
        <PackageDetailCard appointmentId={selectedPackageAppointmentId} />
      </Modal>
      {TodayAppointmentsLoading ? (
        TableSkeletonLoader(15, 30)
      ) : TodaysAppointments?.length ? (
        <List
          dataSource={TodaysAppointments}
          renderItem={(appointment) => (
            <List.Item style={{ padding: 0 }}>
              <PackageAppointmentCard
                appointment={appointment}
                FetchData={FetchTodayAppointments}
                handleViewClick={handlePackageViewClick}
                history={history}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description="No Package Appointments Today"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="empty-nodata"
        />
      )}
    </div>
  );
};

export default TodayPackageAppointments;
