import React from "react";
import moment from "moment";

const WorkingHours = ({ workingHours }) => {
  return (
    <div className="working-hours-container">
      {workingHours.map((item, index) => {
        const day = moment.weekdays(index);
        return (
          <div className="working-hours-item" key={`${day}${item}`}>
            <div>{day}</div>
            <div>{item?.time}</div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkingHours;
