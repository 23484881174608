import React, { createContext, useContext } from "react";

export const DashboardContext = createContext();

export const DashboardContextProvider = (props) => {
  const values = {};

  return <DashboardContext.Provider value={values} {...props} />;
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
