import { getRefundData } from "Utils/Services/Practitioner/Appointments";
import moment from "moment";

export const modelGetReportsAPI = (resp) => {
  let result = {
    status: false,
    data: { 
      reports: [],
      reportText : "" 
    },  
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp?.status !== 200) throw new Error("Invalid status");
    let { data : {report_text = "", reports = []} } = resp || {};
    result.status = true;
    report_text && (result.data.reportText = report_text);
    
    if (Array.isArray(reports) && reports.length) {
      result.data.reports = reports || [];
    }
  } catch (err) {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelSaveReportsAPI = (resp) => {
  let result = {
    status: false,
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    if (resp?.status !== 200) throw new Error("Invalid status");
    let { data = "" } = resp || {};
    if (data === "success") result.status = true;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelTodaysAppointmentsAPI = (resp) => {
  let result = {
    appointments: [],
    total_count: 0,
  };
  try {
    if (typeof resp !== "object") throw new Error("Invalid response");
    let { appointments = [], total_count = 0 } = resp || {};
    result.total_count = total_count || 0;
    if (Array.isArray(appointments) && appointments.length) {
      // remove appointments if practitioner_id === "" and appointment_status === "completed"
      result.appointments = appointments.filter(
        (item) =>
          item.practitioner_id !== "" || item.appointment_status !== "completed"
      );
      // result.appointments = appointments;
    }
  } catch {
    return result;
  } finally {
    return result;
  }
};

export const modelPractitionerOnlinePaymentsListAPI = async (data) => {
  let result = {
    status: false,
    data: {
      count: 0,
      paymentData: [],
    },
  };
  try{
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    let { total_count = 0, paymentData = [] } = responseData || {};
    if (isFinite(Number(total_count))) result.data.count = total_count;
    if (Array.isArray(paymentData) && paymentData.length) {
      const payments = paymentData.map(
          async ({
            order_id = "",
            patient_name = "",
            practitioner_name = "",
            clinic_name = "",
            appointment_date = "",
            appointment_time = "",
            appointment_type_name = "",
            payment_status = "",
            created_date = "",
            amount = 0,
            refund_id = "",
            appointment_id = "",
          }) =>{
            let refund_amount = 0;
            if(refund_id && appointment_id){
              const r = await getRefundData(appointment_id.toString());
              refund_amount = r.data.amount;
            }else if(refund_id){
              refund_amount = "Not enough details to fetch refund amount."
            }
            return {
              orderId: order_id,
              patientName: patient_name,
              practitionerName: practitioner_name,
              clinicName: clinic_name,
              date: appointment_date,
              time: appointment_time,
              appointmentType: appointment_type_name,
              paymentStatus: payment_status,
              createdDate: moment(created_date).isValid ? created_date : "unknown",
              amount,
              refundId: refund_id,
              refundAmount: refund_amount,
              appointmentId: appointment_id,
            }
          }
    );
    result.data.paymentData= await Promise.all(payments);
  }
  }catch(e){
    result = {
      status: false,
      data: {
        count: 0,
        paymentData: [],
      },
    };
  }finally{
    return result;

  }
};
