import React from "react";
import FullWidthView from "components/FullWidthView";
import ErrorImage from "Images/dashboard-icons/practitioner/Error.svg";
import { Button } from "antd";

const BugContainer = () => {
  return (
    <FullWidthView className="flex items-center justify-center d-col">
      <img src={ErrorImage} alt="Error" width="30%" height="auto" />
      <h3
        style={{
          fontSize: "27px",
          fontWeight: "400",
          color: "rgb(118 118 118)",
          marginTop: "30px",
        }}
      >
        Something went wrong!
      </h3>
      <Button
        type="primary"
        size="large"
        onClick={() => window.location.reload()}
      >
        Try Again
      </Button>
    </FullWidthView>
  );
};

export default BugContainer;
