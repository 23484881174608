import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useState, useEffect, useRef } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import TestAndReports from "views/pages/Lab/Appointments/TestAndReports";
import AddLabPackage from "views/pages/Lab/LabPackages/AddLabPackage";
import EditLabPackage from "views/pages/Lab/LabPackages/EditLabPackage";
import Notifications from "views/pages/Lab/Notifications.js";
import { LabRoutes, LabNoSubscriptionRoutes } from "../Routes/LabRoutes";
import PatientDetails from "views/pages/Lab/Patients/PatientDetails";
import { DecryptString } from "Utils/Constants";
import { getLabSubAdminAccess } from "Utils/Services/Lab/MultipleAdmins";
import UnAuthorized from "views/pages/UnAuthorized";
import ChangePassword from "views/pages/Auth/ChangePassword";

const Lab = (props) => {
  let history = useHistory();
  const mainPanel = useRef();
  let cookies = new Cookies();

  const [allowedPaths, setAllowedPaths] = useState([]);
  const [isSubAdminAccessFetched, setIsSubAdminAccessFetched] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);

  useEffect(() => {
    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);
    (UserData?.subadmin === true) && setIsSubAdmin(true);
    if (UserData?.type !== "lab") {
      history.push("/login");
    }else {
      if (!isSubAdminAccessFetched && UserData?.subadmin) {
        fetchSubAdminAccess(UserData?.subadminId);
      }
    }
  }, [cookies, history]);

  const fetchSubAdminAccess = async (userId) => {
    try {
      setIsLoading(true);
      const response = await getLabSubAdminAccess({ id: userId });
      if (response?.data) {
        setAllowedPaths(response?.data);
        setIsSubAdminAccessFetched(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      // setsidebarMini(false);
    } else {
      // setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const filteredRoutes = LabRoutes.filter(route => allowedPaths.includes(route.path));

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/lab") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const pathName =  window.location.pathname.slice(4,);
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        // routes={
        //   props.SubscriptionStatus === "Not Activated" ||
        //   props.SubscriptionStatus === "Expired"
        //     ? LabNoSubscriptionRoutes
        //     : LabRoutes
        // }
        routes={!isSubAdmin ? LabRoutes :  isLoading ? [] : filteredRoutes}
        bgColor="blue"
        activeColor="info"
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        { 
          (isSubAdmin &&  isLoading)?(
            <div className='content w-full h-full d-flex flex-column justify-content-center align-items-center'>
              <p className='font-weight-bold h6 text-muted'> ...Loading</p>
            </div>
          ):(isSubAdmin && LabRoutes.find(each=>each.path === pathName)) && !allowedPaths.includes(pathName)? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/lab-packages") && !allowedPaths.includes("/lab-packages")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/patient-details") && !allowedPaths.includes("/patients")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/appointments/test-reports") && !allowedPaths.includes("/appointments")) ? (
            <UnAuthorized/>
          ):(
            <Switch>
              {/* {getRoutes(
                props.SubscriptionStatus === "Not Activated" ||
                  props.SubscriptionStatus === "Expired"
                  ? LabNoSubscriptionRoutes
                  : LabRoutes
              )} */}
              {getRoutes(LabRoutes)}
              <Route
                path="/lab/patient-details/:patientId"
                component={PatientDetails}
              />
              <Route path="/lab/lab-packages/add" component={AddLabPackage} />
              <Route
                path="/lab/lab-packages/edit/:package_id"
                component={EditLabPackage}
              />
              <Route
                path="/lab/appointments/test-reports/:appointment_id"
                component={TestAndReports}
              />
              <Route path="/lab/notifications/" component={Notifications} />
              <Route path="/lab/change-password" component={ChangePassword} />
            </Switch>
          )
        }
        {props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
          <Footer fluid />
        )}
      </div>
    </div>
  );
};
export default Lab;
