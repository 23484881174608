import React, { useEffect, useState } from "react";
import { usePayments } from "./usePayments";
import EllipsisText from "react-ellipsis-text";
import { Card } from "antd";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { getDateTimeFormat } from "Utils/Constants";

const DetailsCard = () => {
  const [paymentsData, setPaymentsData] = useState({ status: false, data: {} });
  const { getPaymentDetails = () => {}, selectedOrderId = "" } = usePayments();

  const fetchData = async () => {
    let response = await getPaymentDetails(selectedOrderId);
    response && setPaymentsData(response);
  };
 
  useEffect(() => {
    fetchData();
  }, [selectedOrderId]); // eslint-disable-line

  const OrderDetails = ({
    practitionerName = "",
    clinicName = "",
    date = "",
    time = "",
    appointmentType = "",
    paymentStatus = "",
    createdDate = "",
    amount = "",
    refundAmount = ""
  }) => {
    return (
      <div className="general-info">
        <Row lg="9" md="12" sm="12" className="column-items">
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray">Appointment date</h4>
              <h4 className="text-black">{date}</h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray">Appointment time</h4>
              <h4 className="text-black">{time}</h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray">Appointment type</h4>
              <h4 className="text-black">{appointmentType}</h4>
            </div>
          </Col>
        </Row>
        <Card
          style={{ width: "100%", padding: "10px", marginTop: 10 }}
          bodyStyle={{ padding: "0px 0px 10px" }}
        >
          <div className="details-wraper">
            <label>Booked on:</label>
            <h4 className="text">{getDateTimeFormat(createdDate)}</h4>
          </div>
          <div className="details-wraper">
            <label>Practitioner name:</label>
            <h4 className="text capitalize">{practitionerName}</h4>
          </div>
          <div className="details-wraper">
            <label>Clinic name:</label>
            <h4 className="text capitalize">{clinicName}</h4>
          </div>
          <div className="details-wraper">
            <label>Amount Paid:</label>
            <h4 className="text">₹{amount}</h4>
          </div>
          <div className="details-wraper">
            <label>Payment status:</label>
            <h4 className="text">{paymentStatus}</h4>
          </div>
          {
          paymentStatus == "Refund" ? (
            <div className="details-wraper">
            <label>AmountRefund:</label>
            <h4 className="text">₹{refundAmount}</h4>
          </div>
          ):null
         }
        </Card>
      </div>
    );
  };

  let { status = false, data = {} } = paymentsData || {};
  return (
    <div className="table-item-details-card p-6">
      {status && (
        <>
          <div className="profile-info">
            <span className="appointment-id">{data?.orderId}</span>
            <h4 className="mt-2">
              <EllipsisText
                className="capitalize"
                text={data?.patientName ? data.patientName : ""}
                length={80}
              />
            </h4>
          </div>
          <div className="order-details-container">
            <OrderDetails {...data} />
          </div>
        </>
      )}
    </div>
  );
};

export default DetailsCard;
