export const modelUpdateClinicSlotDurationAPI = async (response) => {
    let result = {
      status: false,
      message: "Something went wrong",
    };
    try {
      if (typeof response !== "object") throw new Error("invalid response");
      let { data: responseData } = response || {};
      if (responseData.success === true) {
        result.status = true;
      } else {
        result.status = false;
        result.message = responseData?.message || "Something went wrong";
      }
    } catch {
      result.status = false;
    } finally {
      return result;
    }
  };

  export const modelGetClinicPractitionerDaySlotsAPI = (response) => {
    let result = {
      status: false,
      slots: [],
      slotTimings: [],
      timeDuration: "",
      message: "",
    };
    try {
      if (typeof response !== "object") throw new Error("invalid response");
      let { data = {}, status = 500 } = response || {};
      if(status === 400){
        result.status = false;
        result.message = response.message;
        return result;
      }
      if (status !== 200) throw new Error("Invalid response");
      result.status = true;
      if (Array.isArray(data?.slots) && data?.slots?.length) {
        result.slots = data.slots;
      }
      if (Array.isArray(data?.slotTimings) && data?.slotTimings?.length) {
        result.slotTimings = data?.slotTimings;
      }
      result.timeDuration = data?.timeDuration;
    } catch {
      return result;
    } finally {
      return result;
    }
  };

  export const modelUpdateClinicPractitionerScheduleTimingPayload = (
    workHours,
    practitioner_id,
    day,
    AddedTimings,
    RemovedTimings
  ) => {
    let result = {};
    const modelPayloadpostClinicPractitionerDaySlots = {
      "practitioner_id": 1906,
      "day": "sunday",
      "timings": [
        {
          "start": "14:00",
          "end": "18:00"
        }
      ],
      "addedTimings": [
        {
          "start": "14:00",
          "end": "18:00"
        }
      ],
      "removedTimings": [
        {
          "start": "14:00",
          "end": "20:00"
        }
      ]
    }
    try {
      
      //loop through each slot and adding schedule and slot to the day
      let slots = [];
      workHours.forEach((slot, index) => {
        let { start = "", end = "" } = slot || {};
        if (start === "" || end === "") throw new Error("invalid slot");
        // slotsObj[day][`Schedule${index + 1}`] = `${start} to ${end}`;
        slots.push({ start, end });
      });
      result = {
        practitioner_id: practitioner_id.replace("SDP",""),
        day: day,
        timings: slots,
        addedTimings: AddedTimings,
        removedTimings: RemovedTimings,
      };
    } catch (e) {
      throw e;
    } finally {
      return result;
    }
  };