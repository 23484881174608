import { message, Modal, List, Empty, Popconfirm, Button } from "antd";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import { TableSkeletonLoader } from "Utils/Constants";
import DetailsCard from "views/components/practitioners/DetailsCard";
import ListCard from "views/components/practitioners/ListCard";
import { DeleteOutlined } from "@ant-design/icons";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const MyPractitioners = ({
  SearchKeyword,
  FetchMyPractitioners,
  removeMyPractitioner,
  MyPractitioners,
  MyPractitionersLoading,
  FetchAllPractitioners,
  FetchRequestedPractitioners,
}) => {
  const { setHeading, Heading } = useContext(GlobalState);

  const ContentItem = "Practitioner";
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [DetailsItemUrlName, setDetailsItemUrlName] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const removePractitionerHandler = async (id) => {
    setIsBtnLoading(true);
    const response = await removeMyPractitioner(id);

    if (response && response?.status === 200) {
      FetchAllPractitioners(1, 12);
      //message.success(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: response.message
      });
      FetchMyPractitioners();
      FetchRequestedPractitioners();
    } else {
      //message.warning(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.WARNING,
        content: response.message
      });
    }
    setIsBtnLoading(false);
  };

  const CardOnClick = (item) => {
    setshowDetailsModal(true);
    setDetailsItemUrlName(item.url_name);
  };

  const FilteredData = (data) => {
    let filteredData = data;

    if (SearchKeyword && data)
      filteredData = data.filter((item) => {
        return (
          item.first_name.toLowerCase().includes(SearchKeyword.toLowerCase()) ||
          item.last_name.toLowerCase().includes(SearchKeyword.toLowerCase())
        );
      });

    return filteredData?.sort(function (a, b) {
      if (a.first_name < b.first_name) {
        return -1;
      }
      if (a.first_name > b.first_name) {
        return 1;
      }
      return 0;
    });
  };

  const ActionButtons = ({ item }) => {
    return (
      <Popconfirm
        placement="topRight"
        title="Are you sure you want to remove?"
        onConfirm={() => removePractitionerHandler(item.id)}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className="remove-button"
          type="danger"
          size="middle"
          loading={isBtnLoading}
          style={{ color: "white" }}
        >
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    );
  };

  useEffect(() => {
    FetchMyPractitioners();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Practitioners");
    }
  }, [setHeading, Heading]);
  return (
    <div className="content">
      <div className={ContentItem} style={{ borderRadius: "7px !important" }}>
        <Modal
          className="item-details-modal"
          title="Practitioners Details"
          visible={showDetailsModal}
          footer={null}
          width={700}
          onCancel={() => setshowDetailsModal(false)}
          destroyOnClose={true}
        >
          <DetailsCard urlName={DetailsItemUrlName} MyPractitioner={true} />
        </Modal>

        <div className="practitioners-list">
          {MyPractitionersLoading ? (
            TableSkeletonLoader(10, 60)
          ) : FilteredData(MyPractitioners)?.length ? (
            <List
              dataSource={FilteredData(MyPractitioners)}
              pagination={{
                pageSize: 12,
              }}
              renderItem={(item) => (
                <List.Item>
                  <ListCard
                    practitioner={item}
                    CardOnClick={CardOnClick}
                    isBtnLoading={isBtnLoading}
                    ActionButtons={<ActionButtons item={item} />}
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty
              description="No Practitioners Found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default MyPractitioners;
