import React, { useEffect } from "react";
import HospitalCard from "../HospitalCard";
import { List, Empty, Modal } from "antd";
import { useHospitals } from "../../useHospitals";
import FullWidthView from "components/FullWidthView";
import Spinner from "reactstrap/lib/Spinner";
import DetailsModal from "../DetailsModal/MyHospitals";
import "assets/css/practitioner-styles.css";
import { MyHospitalActionButtons } from "./ActionButtons";

const MyHospitals = () => {
  const { SearchKeyword = "" } = useHospitals();
  const {
    showDetailsModal,
    setshowDetailsModal,
    loading,
    data,
    setData,
    fetchMyHospitalsData,
    // setMyPracticesCount,
  } = useHospitals();

  const FilteredData = (data) => {
    let filteredData = data;

    if (SearchKeyword && data)
      filteredData = data?.filter((item) => {
        return item.name.toLowerCase().includes(SearchKeyword.toLowerCase());
      });

    return filteredData;
  };

  useEffect(() => {
    fetchMyHospitalsData();

    return () => {
      setData({
        status: false,
        data: {
          count: 0,
          hospitals: [],
        },
      });
    };
  }, []); // eslint-disable-line

  if (loading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  return (
    <div className="content">
      <Modal
        className="item-details-modal"
        title="Hospital Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setshowDetailsModal(false)}
      >
        <DetailsModal />
      </Modal>

      <div className="practitioners-list">
        {data?.status ? (
          FilteredData(data.data.hospitals).length ? (
            <List
              dataSource={FilteredData(data.data.hospitals)}
              renderItem={(item) => (
                <List.Item>
                  <HospitalCard
                    key={item.id}
                    hospital={item}
                    ActionButtons={MyHospitalActionButtons}
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty
              description="No Hospitals Found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        ) : (
          <Empty
            description="Something went wrong"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};

export default MyHospitals;
