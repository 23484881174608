import { Col, Row, Switch, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const WorkingHoursSelector = ({ triggerChange, time }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fromTime, setFromTime] = useState(1633026646865);
  const [toTime, setToTime] = useState(1633026646865);

  const isValidTime = (time) => {
    return moment(time, "HH:mm").isValid();
  };

  useEffect(() => {
    if (time) {
      if (time === "Closed" || time === "Holiday") setIsOpen(false);
      else {
        setIsOpen(true);
        let hours = time?.split("-");
        isValidTime(hours[0])
          ? setFromTime(moment(hours[0], ["h:mm A"]))
          : setFromTime(undefined);
        isValidTime(hours[1])
          ? setToTime(moment(hours[1], ["h:mm A"]))
          : setToTime(undefined);
      }
    }
  }, [time]);

  useEffect(() => {
    triggerChange(
      isOpen
        ? moment(fromTime).format("h:mm A") +
            " - " +
            moment(toTime).format("h:mm A")
        : "Closed"
    );
  }, [isOpen, fromTime, toTime]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getDisabledToHours = () => {
  //   const hours = [];
  //   for (let i = 0; i > moment(fromTime).hour(); i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  // const getDisabledFromHours = () => {
  //   const hours = [];
  //   for (let i = moment(toTime).hour() + 1; i < 24; i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // };

  // const getDisabledFromMinutes = (selectedHour) => {
  //   if (toTime && selectedHour === toTime.hour()) {
  //     return [...Array(60).keys()].filter(
  //       (minute) => minute >= toTime.minute()
  //     );
  //   }
  // };

  // const getDisabledToMinutes = (selectedHour) => {
  //   if (fromTime && selectedHour === fromTime.hour()) {
  //     return [...Array(60).keys()].filter(
  //       (minute) => minute <= fromTime.minute()
  //     );
  //   }
  // };

  return (
    <Row align="middle">
      <Col xs={24} sm={24} lg={4}>
        <Switch
          checked={isOpen}
          onChange={() => setIsOpen((old) => !old)}
          style={{ marginRight: "10px", marginTop: "10px" }}
        />
        {isOpen ? "Opened" : "Closed"}
      </Col>
      <Col xs={24} sm={24} lg={5}>
        <TimePicker
          // disabledHours={getDisabledFromHours}
          // disabledMinutes={getDisabledFromMinutes}
          style={{ marginTop: "10px" }}
          value={fromTime ? moment(fromTime) : fromTime}
          onChange={(val) => setFromTime(val)}
          use12Hours
          disabled={!isOpen}
          format="h:mm a"
        />
      </Col>
      &nbsp;&nbsp;&nbsp;&nbsp; To &nbsp;&nbsp;&nbsp;&nbsp;
      <Col xs={24} sm={24} lg={5}>
        <TimePicker
          // disabledHours={getDisabledToHours}
          // disabledMinutes={getDisabledToMinutes}
          style={{ marginTop: "10px" }}
          value={toTime ? moment(toTime) : toTime}
          onChange={(val) => setToTime(val)}
          use12Hours
          disabled={!isOpen}
          format="h:mm a"
        />
      </Col>
    </Row>
  );
};

export default WorkingHoursSelector;
