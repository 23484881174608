import { SearchOutlined } from "@ant-design/icons";
import { Col, DatePicker, Input, List, Row, Empty, Modal } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRanges, DATE_FORMAT } from "Utils/Constants";
import { getReviews } from "Utils/Services/Practitioner/Reviews";
import FullWidthView from "components/FullWidthView";
import Spinner from "components/Spinner";
import { useReviews } from "./useReviews";
import ReviewDetailsCard from "./ReviewDetailCard";
import BugContainer from "components/ErrorContainer/BugContainer";
import ReviewCard from "./ReviewCard";


const ReviewsContainer = () => {
  const { RangePicker } = DatePicker;

  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const {
    data,
    setData,
    searchKeyword,
    setSearchKeyword,
    showDetailsModal,
    setShowDetailsModal,
  } = useReviews();
  const [DataLoading, setDataLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const ReviewsPerPage = 20;
  const [totalReviewsCount, setTotalReviewsCount] = useState(0);
  const [date, setDate] = useState([null, null]);

  const FetchData = async (date1 = null, date2 = null) => {
    const startDate = date1 ? moment(date1).format("YYYY-MM-DD") : null;
    const endDate = date2 ? moment(date2).format("YYYY-MM-DD") : null;
    setDataLoading(true);
    const response = await getReviews({
      start: (pageNo - 1) * ReviewsPerPage,
      limit: ReviewsPerPage,
      startDate: startDate,
      endDate: endDate,
      search: searchKeyword,
    });
    if (response?.status) {
      setData(response);
      setTotalReviewsCount(response?.data?.count);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    FetchData(date[0], date[1]);
  }, [date, pageNo, searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Reviews");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalReviewsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalReviewsCount]);

  let { reviews } = data.data;
  return (
    <div className="content">
      {/* <Row
        className="appointments-page-header"
        style={{ justifyContent: "space-between", marginBottom: "20px" }}
        gutter={[0, 10]}
      >
        <Col lg={9} md={8} sm={12} xs={24}>
          <RangePicker
            className="shadow-picker"
            style={{ width: "100%" }}
            value={date}
            ranges={DateRanges()}
            format={DATE_FORMAT}
            onChange={(e) => {
              e === null ? setDate([null, null]) : setDate(e);
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </Col>

        <Col lg={8} md={8} sm={12} xs={24}>
          <Input
            className="shadow-input"
            onChange={(e) => setSearchKeyword(e.target.value)}
            value={searchKeyword}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row> */}
      {!DataLoading ? (
        data.status ? (
          reviews.length ? (
            <List
              className="appointments-page"
              dataSource={reviews}
              pagination={{
                current: pageNo,
                total: totalReviewsCount,
                onChange: (page) => {
                  setPageNo(page);
                },
                pageSize: ReviewsPerPage,
              }}
              renderItem={(review) => (
                <List.Item key={review.id}>
                  <ReviewCard review={review} />
                </List.Item>
              )}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No reviews found"
            />
          )
        ) : (
          <BugContainer />
        )
      ) : (
        <FullWidthView className="flex items-center justify-center min-h-half">
          <Spinner />
        </FullWidthView>
      )}
      <Modal
        className="item-details-modal"
        title="Hospital Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setShowDetailsModal(false)}
      >
        <ReviewDetailsCard />
      </Modal>
    </div>
  );
};
export default ReviewsContainer;
