import React from "react";
import PractitionersIcon from "Images/dashboard-icons/doctor.png";
import PatientIcon from "Images/dashboard-icons/patient.svg";
import {
  AppointmentStatusFormatter,
  getAppointmentDate,
  getAppointmentType,
} from "Utils/Constants";
import { SetCardAppointmentButtons } from "Utils/Constants";
import moment from "moment";

const AppointmentCard = ({
  appointment,
  FetchData,
  setShowRescheduleModal,
  setSelectedPractitionerId,
  setSelectedAppointmentId,
  handleViewClick,
  today,
  history,
}) => {
  const ActionFormatter = (appointment) => {
    return SetCardAppointmentButtons(
      appointment,
      FetchData,
      setShowRescheduleModal,
      setSelectedPractitionerId,
      setSelectedAppointmentId,
      today
    );
  };

  const {
    patient_name,
    relation_name,
    patient_relation_name,
    practitioner_name,
    appointment_status,
    appointment_id,
    appointment_types,
    appointment_date,
    token,
  } = appointment;

  return (
    <div className="appointment-card">
      <div
        className="appointment-card-head abc"
        onClick={() => handleViewClick(appointment)}
      >
        <div className="appointment-card-header">
          <span className="appointment-id">{appointment_id}</span>
          <span className="appointment-type">
            {getAppointmentType(appointment_types)}
          </span>
          {token ? <span className="appointment-token">Token-{token}</span>:null}
          <span className="capitalize patient-name text-ellipsis">
            <img src={PatientIcon} alt="k" />
            {patient_name}
          </span>
        </div>
        <span className="capitalize practitioner-name text-ellipsis">
          <img src={PractitionersIcon} alt="k" /> {practitioner_name}
        </span>
        {
        relation_name ? (
          <span className="capitalize practitioner-name ml-4 mt-0">
            Booked for <span className="font-weight-bold">{relation_name}</span> - <span className="font-weight-bold">{patient_relation_name}</span>
          </span>
        ):null
       }
        <div className="appointment-card-right">
          <span className="appointment-time">
            {" "}
            {moment(appointment_date).format("hh:mm A")}
          </span>
          {!today && (
            <span className="appointment-date">
              {getAppointmentDate(appointment_date)}
            </span>
          )}
          <span className="appointment-status">
            {AppointmentStatusFormatter(appointment_status)}
          </span>
        </div>
      </div>
      {!history && ActionFormatter(appointment)}
    </div>
  );
};

export default AppointmentCard;
