/* eslint-disable */
import { Button, Card, Col, Form, Input, Row, message } from "antd";
import FullWidthView from "components/FullWidthView";
import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import {
  getPractitionerConsultationFee,
  getPractitionerConsultingHospitals,
  updateConsultationFees,
} from "Utils/Services/Practitioner/Profile";
import { modelConsultationFeesPayload } from "models/practitioner/profile.api.model";
import BugContainer from "components/ErrorContainer/BugContainer";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const ConsultationFee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [myHospitals, setMyHospitals] = useState({ status: false, data: [] });
  const [consultationFee, setConsultationFee] = useState({
    status: true,
    data: {},
  });
  const [selectedHospital, setSelectedHospital] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const getConsultationFee = async (clinic) => {
    setIsLoading(true);
    const response = await getPractitionerConsultationFee(clinic);
    response && setConsultationFee(response);
    setIsLoading(false);
  };
  const getData = async () => {
    setIsLoading(true);
    let hospitalsResponse = await getPractitionerConsultingHospitals();
    hospitalsResponse && setMyHospitals(hospitalsResponse);
    if (hospitalsResponse?.status && hospitalsResponse?.data?.length) {
      setSelectedHospital(hospitalsResponse?.data[0]?.id);
      getConsultationFee(hospitalsResponse?.data[0]?.id);
    } else setIsLoading(false);
  };

  const validatePayload = () => {
    if (selectedHospital === "") {
      //message.error("Please select a hospital");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Please select a hospital"
    });
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    setIsBtnLoading(true);
    let isValid = validatePayload();
    if (!isValid) return setIsBtnLoading(false);
    const response = await updateConsultationFees(
      modelConsultationFeesPayload({
        data: consultationFee.data,
        clinic_id: selectedHospital,
      })
    );
    if (response?.status) {
      //message.success("Consultation fees updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Consultation fees updated successfully"
    });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
    });
    }
    setIsBtnLoading(false);
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  if (!myHospitals?.status) return <BugContainer />;
  if (!consultationFee?.status) return <BugContainer />;
  const {
    inPersonConsultation = {},
    videoConsultation = {},
    phoneConsultation = {},
  } = consultationFee.data;
  return (
    <Card>
      <>
        <Form
          layout="vertical"
          name="nest-messages"
          validateMessages={validateMessages}
          initialValues={consultationFee.data}
        >
          {/* row starts here */}
          <div className="buttons-container-times-marking">
            {myHospitals?.status &&
              myHospitals.data.map((item) => (
                <Button
                  key={item.id}
                  className={selectedHospital === item.id ? "tab" : ""}
                  onClick={() => {
                    setSelectedHospital(item.id);
                    getConsultationFee(item.id);
                  }}
                >
                  {item.label}
                </Button>
              ))}
          </div>
          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={8}>
              <Form.Item label="In person consultation">
                <Input
                  size="large"
                  value={inPersonConsultation?.amount}
                  onChange={(event) => {
                    const { value } = event.target;
                    setConsultationFee({
                      ...consultationFee,
                      data: {
                        ...consultationFee.data,
                        inPersonConsultation: {
                          ...inPersonConsultation,
                          amount: value,
                        },
                      },
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <Form.Item label="Video consultation">
                <Input
                  size="large"
                  value={videoConsultation?.amount}
                  onChange={(event) => {
                    const { value } = event.target;
                    setConsultationFee({
                      ...consultationFee,
                      data: {
                        ...consultationFee.data,
                        videoConsultation: {
                          ...videoConsultation,
                          amount: value,
                        },
                      },
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <Form.Item label="Phone consultation">
                <Input
                  size="large"
                  value={phoneConsultation?.amount}
                  onChange={(event) => {
                    const { value } = event.target;
                    setConsultationFee({
                      ...consultationFee,
                      data: {
                        ...consultationFee.data,
                        phoneConsultation: {
                          ...phoneConsultation,
                          amount: value,
                        },
                      },
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* row ends here */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ height: 40 }}
              loading={isBtnLoading}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    </Card>
  );
};

export default ConsultationFee;
