// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white: #ffffff;
  --sd_primary: #0fa0cf;
  --sd_primary_hover: #81de92;
  --sd_primary_low: #0fa0cf26;
  --sd_primary_dark: #339244;
  --sd_secondary: #0fa0cf;
  --sd_secondary_low: #0fa0cf26;
  --light_grey: #eff2f5;
  --light_red: #ff4d4fc4;
}

.mt-2 {
  margin-top: 1.5rem;
}

.bg-white {
  background-color: #fff;
}
.shadow-sm {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.w-full {
  width: 100%;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.flex {
  display: flex;
}
.d-row {
  flex-direction: row;
}
.d-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.bg-gray {
  background-color: #f7f7f7;
}
.bg-white {
  background-color: #fff;
}
.grid {
  display: grid;
}
.p-2 {
  padding: 2px;
}
.p-3 {
  padding: 0.4rem;
}
.rounded-3 {
  border-radius: 7px;
}
.gap-1 {
  gap: 0.7rem;
}
.gap-2 {
  gap: 5rem;
}
.min-h-half {
  min-height: 50vh;
}
@media screen and (max-width: 767px) {
  .sm-p-2 {
    padding: 3px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/globalVariables.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,2BAA2B;EAC3B,2BAA2B;EAC3B,0BAA0B;EAC1B,uBAAuB;EACvB,6BAA6B;EAC7B,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,8CAA8C;AAChD;AACA;EACE,WAAW;AACb;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;AACA;EACE,mBAAmB;AACrB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,SAAS;AACX;AACA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[":root {\n  --white: #ffffff;\n  --sd_primary: #0fa0cf;\n  --sd_primary_hover: #81de92;\n  --sd_primary_low: #0fa0cf26;\n  --sd_primary_dark: #339244;\n  --sd_secondary: #0fa0cf;\n  --sd_secondary_low: #0fa0cf26;\n  --light_grey: #eff2f5;\n  --light_red: #ff4d4fc4;\n}\n\n.mt-2 {\n  margin-top: 1.5rem;\n}\n\n.bg-white {\n  background-color: #fff;\n}\n.shadow-sm {\n  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);\n}\n.w-full {\n  width: 100%;\n}\n.mb-4 {\n  margin-bottom: 1.5rem;\n}\n.flex {\n  display: flex;\n}\n.d-row {\n  flex-direction: row;\n}\n.d-col {\n  flex-direction: column;\n}\n.items-center {\n  align-items: center;\n}\n.justify-center {\n  justify-content: center;\n}\n.bg-gray {\n  background-color: #f7f7f7;\n}\n.bg-white {\n  background-color: #fff;\n}\n.grid {\n  display: grid;\n}\n.p-2 {\n  padding: 2px;\n}\n.p-3 {\n  padding: 0.4rem;\n}\n.rounded-3 {\n  border-radius: 7px;\n}\n.gap-1 {\n  gap: 0.7rem;\n}\n.gap-2 {\n  gap: 5rem;\n}\n.min-h-half {\n  min-height: 50vh;\n}\n@media screen and (max-width: 767px) {\n  .sm-p-2 {\n    padding: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
