import { Button, Col, Form, Input, message, Row } from "antd";
import { GlobalState } from "context-api";
import ShopDocLogo from "Images/shopdocLogoLight.svg";
import md5 from "md5";
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { checkLogin } from "Utils/Services/Auth";
import "./login.css";
import Cookies from "universal-cookie";
import { DecryptString } from "Utils/Constants";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const Login = () => {
  const cookies = new Cookies();
  const formInitialValues = { type: "hospital" };

  const { setTotalLoading } = useContext(GlobalState);
  const history = useHistory();
  const [ShowPassword,SetShowPassword]=useState(false);
  const [ActiveAuthType, setActiveAuthType] = useState("practitioner");

  const hanndleShowPassword=()=>{
    SetShowPassword(prev=>!prev);
  }

  const validateEmail = (rule, value) => {
    const emailRegex=/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if(!emailRegex.test(value)){
      return Promise.reject('Please enter a valid email address!!');
    }
    return Promise.resolve();
  }

  useEffect(() => {
    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);
    if (UserData?.type) {
      if (UserData?.type === "hospital") {
        if(UserData?.subadmin || UserData?.profile_completed){
          history.push("/hospital/dashboard");
        }else{
          history.push("/onboarding/hospital");
        }
      } else if (UserData?.type === "lab") {
        if(UserData?.subadmin || UserData?.profile_completed){
          history.push("/lab/dashboard");
        }else{
          history.push("/onboarding/lab");
        }
      } else if (UserData?.type === "practitioner") {
        if(UserData?.profile_completed){
          history.push("/practitioner/dashboard");
        }else{
          history.push("/onboarding/practitioner");
        }
      }
    }
  }, [cookies, history]);

  const SubmitLogin = async (values) => {
    if (values) {
      const response = await checkLogin({
        ...values,
        type: ActiveAuthType,
        password: md5(values.password),
      });
      if (response?.status) {
        setTotalLoading(true);
        setTimeout(() => {
          setTotalLoading(false);
          // if (ActiveAuthType === "hospital") {
          //   if (
          //     response.subscriptionStatus === "Not Activated" ||
          //     response.subscriptionStatus === "Expired"
          //   ) {
          //     history.push("/hospital/subscription");
          //   }
          // } else if (ActiveAuthType === "lab") {
          //   if (
          //     response.subscriptionStatus === "Not Activated" ||
          //     response.subscriptionStatus === "Expired"
          //   ) {
          //     history.push("/lab/subscription");
          //   }
          // }
        }, 1000);
      } else {
        //message.error("Bad credentials, please login again");
        setTotalLoading(false);
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: response.message || "Bad credentials, please login again"
        });
      }
    } else {
      //message.error("Bad credentials, please login again");
      setTotalLoading(false);
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Bad credentials, please login again"
      });
    }
  };

  return (
    <div className="login-page">
      <div className="container-fluid">
        <Row>
          <Col md={24}>
            <Form
              onFinish={SubmitLogin}
              layout="vertical"
              initialValues={formInitialValues}
            >
              <div className="login-container">
                <div className="logo-container">
                  <img
                    src={ShopDocLogo}
                    alt="shopdoc"
                    style={{ borderRadius: 0 }}
                  />
                </div>

                <div className="form-container">
                  <div className="auth-types">
                    <div
                      className={`auth-type${
                        ActiveAuthType === "practitioner" ? " active" : ""
                      }`}
                      onClick={() => setActiveAuthType("practitioner")}
                    >
                      Practitioner
                    </div>
                    <div
                      className={`auth-type${
                        ActiveAuthType === "hospital" ? " active" : ""
                      }`}
                      onClick={() => setActiveAuthType("hospital")}
                    >
                      Clinic / Hospital
                    </div>
                    <div
                      className={`auth-type${
                        ActiveAuthType === "lab" ? " active" : ""
                      }`}
                      onClick={() => setActiveAuthType("lab")}
                    >
                      Lab
                    </div>
                  </div>
                  <Form.Item
                    name={["email"]}
                    label="Email"
                    rules={[
                      { required: true, message:""},
                      {validator: validateEmail}
                    ]}
                  >
                   <div className="custom-form-input">
                    <Input placeholder="Email"/>
                   </div>
                  </Form.Item>
                
                  <Form.Item
                    name={["password"]}
                    label="Password"
                    rules={[{ required: true, message:"Please input your password!" }]}
                  >
                    <div className="custom-form-input">
                      <Input
                        className="password"
                        placeholder="Password"  
                        type={ShowPassword?"text":"password"} 
                        
                      />
                      <div className="eye-icon">
                      {
                        ShowPassword?
                        <EyeOutlined onClick={hanndleShowPassword}/>:
                        <EyeInvisibleOutlined onClick={hanndleShowPassword}/>
                      }</div> 
                    </div>
                  </Form.Item>
                </div>
                <div className="button-container">
                  <Button htmlType="submit">Login</Button>
                </div>
                <div className="footer-container">
                  <div className="footer-text">
                    Forgot Password?
                    <span
                      className="footer-link"
                      onClick={() => history.push(`/forgot-password`)}
                    >
                      {" "}
                      Click here
                    </span>
                  </div>
                  <div className="footer-text">
                    Don't have account?
                    <span
                      className="footer-link"
                      onClick={() => history.push(`/register`)}
                    >
                      {" "}
                      Register
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Login;
