import Appointments from "views/pages/Practitioner/Appointments/index";
import PractitionerProfile from "views/pages/Practitioner/PractitionerProfile";
import Hospitals from "views/pages/Practitioner/Hospitals";
import Patients from "views/pages/Practitioner/Patients";
import OnlinePayments from "views/pages/Practitioner/OnlinePayments";
import Reviews from "views/pages/Practitioner/Reviews";
import QAIcon from "Images/dashboard-icons/practitioner/qa.png";
import PractitionerProfileIcon from "Images/dashboard-icons/hospital/user.png";
import AppointmentsIcon from "Images/dashboard-icons/hospital/appointment.png";
import OnlinePaymentsIcon from "Images/dashboard-icons/hospital/online.png";
import PatientsIcon from "Images/dashboard-icons/hospital/patient.png";
import QuickBookingIcon from "Images/dashboard-icons/hospital/booking.png";
import QuickBooking from "views/pages/Practitioner/QuickBooking";
import ScheduleTimings from "views/pages/Practitioner/ScheduleTimings";
import DashboardIcon from "Images/dashboard-icons/hospital/speedometer.png";
import Dashboard from "views/pages/Practitioner/Dashboard";
import NotificationsContainer from "views/pages/Practitioner/Notifications/NotificationsContainer";
import NotificationIcon from "Images/dashboard-icons/hospital/notification.png";
import LeaveMarking from "views/pages/Practitioner/LeaveMarking";
import LeaveMarkingIcon from "Images/dashboard-icons/practitioner/leave.png";
import ReviewsIcon from "Images/dashboard-icons/practitioner/reviews.png";
import HospitalIcon from "Images/dashboard-icons/practitioner/hospital.png";
import SchedulingIcon from "Images/dashboard-icons/practitioner/scheduling.png";
import AskedByPatient from "views/pages/Practitioner/AskedByPatient/AskedByPatient";

const PractitionerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/practitioner",
  },
  {
    path: "/quick-booking",
    name: "Instant Booking",
    icon: QuickBookingIcon,
    component: QuickBooking,
    layout: "/practitioner",
  },
  {
    path: "/online-payments",
    name: "Online Payments",
    icon: OnlinePaymentsIcon,
    component: OnlinePayments,
    layout: "/practitioner",
  },
  {
    path: "/practices",
    name: "Practices",
    icon: HospitalIcon,
    component: Hospitals,
    layout: "/practitioner",
  },
  {
    path: "/patients",
    name: "Patients",
    icon: PatientsIcon,
    component: Patients,
    layout: "/practitioner",
  },
  
  {
    path: "/appointments",
    name: "Appointments",
    icon: AppointmentsIcon,
    component: Appointments,
    layout: "/practitioner",
  },
  {
    path: "/schedule-timings",
    name: "Schedule Timings",
    icon: SchedulingIcon,
    component: ScheduleTimings,
    layout: "/practitioner",
  },
  {
    path: "/leave-marking",
    name: "Out of clinic",
    icon: LeaveMarkingIcon,
    component: LeaveMarking,
    layout: "/practitioner",
  },
  {
    path: "/asked-by-patient",
    name: "Asked By Patient",
    icon: QAIcon,
    component: AskedByPatient,
    layout: "/practitioner",
  },
  {
    path: "/reviews",
    name: "Reviews",
    icon: ReviewsIcon,
    component: Reviews,
    layout: "/practitioner",
  },
  
  {
    path: "/practitioner_profile",
    name: "My Profile",
    icon: PractitionerProfileIcon,
    component: PractitionerProfile,
    layout: "/practitioner",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: NotificationIcon,
    component: NotificationsContainer,
    layout: "/practitioner",
  },
];

export default PractitionerRoutes;
