import { Tabs, Input } from "antd";
import React, { useEffect, useState } from "react";
import HospitalAllPractitioners from "./AllPractitioners";
import HospitalMyPractitioners from "./MyPractitioners";
import HospitalRequestedPractitioners from "./RequestedPractitioners";
import {
  getMyPractitioners,
  removeMyPractitioner,
  actionToPractitioner,
  getAllPractitioners,
  getRequestedPractitioners,
} from "Utils/Services/Hospital/Practitioner";

const { TabPane } = Tabs;

const Practitioners = () => {
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [MyPractitioners, setMyPractitioners] = useState([]);
  const [MyPractitionersLoading, setMyPractitionersLoading] = useState(false);
  const [AllPractitioners, setAllPractitioners] = useState([]);
  const [AllPractitionersLoading, setAllPractitionersLoading] = useState(false);
  const [AllPractitionersCount, setAllPractitionersCount] = useState(0);
  const [RequestedPractitioners, setRequestedPractitioners] = useState([]);
  const [RequestedPractitionersLoading, setRequestedPractitionersLoading] = useState(false);
  const [RequestedPractitionersCount, setRequestedPractitionersCount] = useState(0);

  const FetchMyPractitioners = async () => {
    setMyPractitioners(await getMyPractitioners(null));
    setMyPractitionersLoading(false);
  };

  const FetchRequestedPractitioners = async () => {
    setRequestedPractitionersLoading(true);
    const response = await getRequestedPractitioners(SearchKeyword);
    if(response){
      const data = response.doctors;
      setRequestedPractitioners(
        data
      );

      setRequestedPractitionersCount(response.total_count);
    }
    setRequestedPractitionersLoading(false);
  }

  const FetchAllPractitioners = async (Pageno, PractitionersPerPage) => {
    setAllPractitionersLoading(true);
    const response = await getAllPractitioners(
      (Pageno - 1) * PractitionersPerPage,
      PractitionersPerPage,
      SearchKeyword
    );
    if (response) {
      const data = response?.doctors;
      response?.doctors && setAllPractitioners(
        data?.sort(function (a, b) {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        })
      );

      setAllPractitionersCount(response.total_count);
    }

    setAllPractitionersLoading(false);
  };

  return (
    <div className="content">
      <Input
        style={{ marginTop: "10px" }}
        placeholder="Search practitioners.."
        className="card-search"
        value={SearchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
      <Tabs type="card" size="large" animated={true} tabBarGutter={10}>
        <TabPane
          style={{ borderRadius: "7px" }}
          tab={`My Practitioners ${
            MyPractitioners?.length > 0 ? "(" + MyPractitioners?.length + ")" : ""
          }`}
          key="1"
        >
          <HospitalMyPractitioners
            FetchAllPractitioners={FetchAllPractitioners}
            FetchRequestedPractitioners={FetchRequestedPractitioners}
            SearchKeyword={SearchKeyword}
            FetchMyPractitioners={FetchMyPractitioners}
            removeMyPractitioner={removeMyPractitioner}
            MyPractitioners={MyPractitioners}
            MyPractitionersLoading={MyPractitionersLoading}
          />
        </TabPane>
        <TabPane
        style={{ borderRadius: "7px" }}
        tab={`Requested Practitioners ${
          RequestedPractitioners?.length > 0 ? "(" + RequestedPractitioners?.length + ")" : ""
        }`}
        key="2"
        >
          <HospitalRequestedPractitioners
            FetchRequestedPractitioners={FetchRequestedPractitioners}
            RequestedPractitioners={RequestedPractitioners}
            RequestedPractitionersLoading={RequestedPractitionersLoading}
            RequestedPractitionersCount={RequestedPractitionersCount}
            SearchKeyword={SearchKeyword}
            actionToPractitioner={actionToPractitioner}
            FetchMyPractitioners={FetchMyPractitioners}
            FetchAllPractitioners={FetchAllPractitioners}
          />
        </TabPane>
        <TabPane
          style={{ borderRadius: "7px" }}
          tab={`All Practitioners ${
            AllPractitionersCount > 0 ? "(" + AllPractitionersCount + ")" : ""
          }`}
          key="3"
        >
          <HospitalAllPractitioners
            FetchAllPractitioners={FetchAllPractitioners}
            FetchRequestedPractitioners={FetchRequestedPractitioners}
            AllPractitioners={AllPractitioners}
            AllPractitionersLoading={AllPractitionersLoading}
            AllPractitionersCount={AllPractitionersCount}
            SearchKeyword={SearchKeyword}
            actionToPractitioner={actionToPractitioner}
            FetchMyPractitioners={FetchMyPractitioners}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Practitioners;
