import {
  modelCountryDialercodesAPI,
  modelCheckPatientExistAPI,
  modelWorkHorsSlotAPI,
  modelAppointmentCheckoutAPI,
  modelAppointmentSuccessAPI,
  modelAddPatientByInstantBookingAPI,
} from "models/practitioner/quickbooking.api.models";
import { GetData, PostData } from "Utils/ApiCall";
import { PractitionerURLParser } from "Utils/urls.utils";

export const getCountryDialercodes = async () =>
  modelCountryDialercodesAPI(
    await GetData(PractitionerURLParser("/quickbooking/getCountryDialercodes"))
  );

export const CheckPatientExist = async (value) => {
  const response = modelCheckPatientExistAPI(
    await PostData(
      PractitionerURLParser("/quickbooking/checkPatientExists"),
      value
    )
  );
  return response;
};

export const getWorkHourSlots = async (payload) =>
  modelWorkHorsSlotAPI(
    await PostData(PractitionerURLParser("/workHoursSlots"), payload)
  );

export const postAppointmentCheckout = async (payload) =>
  modelAppointmentCheckoutAPI(
    await PostData(
      PractitionerURLParser("/quickbooking/appointment_checkout"),
      payload
    )
  );

export const postAppointmentSuccess = async (payload) =>
  modelAppointmentSuccessAPI(
    await PostData(
      PractitionerURLParser("/quickbooking/appointment_success"),
      payload
    )
  );

export const postAddPatientByInstantBooking = async (payload) =>
  modelAddPatientByInstantBookingAPI(
    await PostData(
      PractitionerURLParser("/quickbooking/addPatientByInstantBooking"),
      payload
    )
  );
