import React from "react";
import { Card, CardBody } from "reactstrap";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

const ListCard = ({ CardOnClick, patient }) => {
  return (
    <div className="patient-list-card">
      <Card className="card-stats" style={{ padding: "10px" }}>
        <CardBody style={{ padding: "0px", position: "relative" }}>
          <div onClick={() => CardOnClick(patient)}>
            <div
              className="profile-image"
              style={
                patient.profile_image
                  ? { backgroundImage: `url(${patient.profile_image})` }
                  : {}
              }
            >
              {!patient.profile_image && (
                <div className="char-avatar">
                  <span>{patient.patient_name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
            <div className="info-container">
              <h4 className="capitalize text-ellipsis">
                {patient.patient_name}
                {/* <EllipsisText
              className="capitalize"
                text={patient.patient_name}
                length={25}
              /> */}
              </h4>
              {patient.mobile && patient.mobile !== "undefined" ? (
                <p className="email">
                  <PhoneOutlined />
                  {patient.mobile}
                </p>
              ) : (
                ""
              )}
              {patient.email && patient.email !== "undefined" ? (
                <p className="mobile">
                  <MailOutlined />
                  {patient.email}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ListCard;
