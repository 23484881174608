export const HospitalURLParser = (url) => {
  return `${process.env.REACT_APP_HOSPITAL_VERSION}${url}`;
};
export const LabURLParser = (url) => {
  return `${process.env.REACT_APP_LAB_VERSION}${url}`;
};

export const PractitionerURLParser = (url) => {
  return `${process.env.REACT_APP_PRACTITIONER_VERSION}${url}`;
};
