import React from "react";
import moment from "moment";
import { useLeaves } from "./useLeaves";
import {
  UpOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

const CalendarHeader = () => {
  const { date, setDate, Today, isDateAreaExpanded, setIsDateAreaExpanded, fetchLeaveDays, selectedHospital } =
    useLeaves();

  return (
    <div className="header-section">
      <h1 className="date-header">{moment(date).format("DD MMM YYYY")}</h1>
      {isDateAreaExpanded ? (
        <UpOutlined
          onClick={() => setIsDateAreaExpanded(!isDateAreaExpanded)}
        />
      ) : (
        <DownOutlined
          onClick={() => setIsDateAreaExpanded(!isDateAreaExpanded)}
        />
      )}
      <h1
        className={date === Today ? "today-header" : "today-header disabled"}
        onClick={() => {
          setDate(Today);
          fetchLeaveDays(selectedHospital,Today);
        }}
      >
        TODAY
      </h1>
      <div className="right-section">
        <LeftOutlined
          onClick={() => {
            date > Date.now() &&
              setDate(moment(date, "YYYY-MM-DD").subtract(1, "day"));
          }}
        />
        <RightOutlined
          onClick={() => {
            setDate(moment(date, "YYYY-MM-DD").add(1, "day"));
          }}
        />
      </div>
    </div>
  );
};

export default CalendarHeader;
