import FullWidthView from "components/FullWidthView";
import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import { getMyHospitalDetails } from "Utils/Services/Practitioner/Hospitals";
import { useHospitals } from "../../../useHospitals";
import { Tabs } from "antd";
import EllipsisText from "react-ellipsis-text";
import { CompassOutlined } from "@ant-design/icons";
import WorkingHours from "./WorkingHours";
import GeneralInformation from "./GeneralInformation";
import Services from "./Services";
import ConsultationFee from "./ConsultationFee";
import Settings from "./Settings";

const DetailsModal = () => {
  const { selectedHospital } = useHospitals();
  const [hospitalDetails, setHospitalDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [profileImage, setProfileImage] = useState("");
  const { TabPane } = Tabs;

  const getData = async () => {
    setLoading(true);
    let response = await getMyHospitalDetails(selectedHospital);
    setHospitalDetails(response);
    getProfileImage(response.data.profileImage, response.data.clinic_name);

    setLoading(false);
  };

  useEffect(() => {
    getData();

    return () => {
      setHospitalDetails({});
    };
  }, [selectedHospital]); // eslint-disable-line

  const getAlphaDummyAvatar = (firstletter) => {
    return <div className="alpha-dummy-avatar">{firstletter}</div>;
  };

  const getProfileImage = (image, name) => {
    if (!image) {
      setProfileImage(getAlphaDummyAvatar(name.charAt(0)));
    } else {
      setProfileImage(image);
    }
  };

  if (loading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  const {
    data: {
      id: clinic_id,
      name,
      address,
      workingHours,
      services,
      bookingAmount,
    },
  } = hospitalDetails;
  return (
    <div className="table-item-details-card">
      <div className="basic-information">
        {typeof profileImage === "string" ? (
          <div
            className="profile-image"
            style={{ backgroundImage: `url(${profileImage})` }}
          />
        ) : (
          { ...profileImage }
        )}
        <div className="profile-info">
          {/* <span className="doctor-speciality">{data?.practitioner_type}</span> */}
          <h4 className="mt-2">
            <EllipsisText
              className="capitalize"
              text={name ? name : ""}
              length={50}
            />
          </h4>
          <p>
            <CompassOutlined />
            <span>
              <EllipsisText text={address ? address : ""} length={80} />
            </span>
          </p>
        </div>
        <div className="details-tab-container tab-style">
          <Tabs type="card" defaultActiveKey="1" className="practitioner-tab">
            <TabPane tab="General" key="1">
              <GeneralInformation {...hospitalDetails.data} />
            </TabPane>
            <TabPane tab="Services" key="2">
              <Services services={services} />
            </TabPane>
            {/* <TabPane tab="Working Hours" key="3">
              <WorkingHours workingHours={workingHours} />
            </TabPane> */}
            <TabPane tab="Consultation Fee" key="4">
              <ConsultationFee data={bookingAmount} clinic_id={clinic_id} />
            </TabPane>
            <TabPane tab="Settings" key="5">
              <Settings {...hospitalDetails.data} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;
