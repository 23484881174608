import React from "react";
import { Empty } from "antd";
import FullWidthView from "components/FullWidthView";
import { FolderAddOutlined } from "@ant-design/icons";

const Services = ({ services }) => {
  return (
    <>
      {services.length ? (
        <div className="service-info">
          {services.map((service, index) => (
            <span className="item" key={`${service}${index}`}>
              <FolderAddOutlined />
              {service}
            </span>
          ))}
        </div>
      ) : (
        <FullWidthView className="flex items-center justify-center min-h-half">
          <Empty
            description="Something went wrong"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </FullWidthView>
      )}
    </>
  );
};

export default Services;
