import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Spinner = ({ size, ...restProps }) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: size || 24 }} {...restProps} spin />
  );
  return <Spin indicator={antIcon} />;
};

export default Spinner;
