import React from "react";
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { TableSkeletonLoader } from "Utils/Constants";

const CardMetrics = ({
  heading,
  stat,
  icon,
  route,
  isCta,
  isAppointments,
  CountsLoading,
  col,
}) => {
  let history = useHistory();

  return (
    // <Col lg={col===4?"4":"3"} md="6" sm="6">
    <Card className="card-stats">
      <CardBody>
        {CountsLoading ? (
          TableSkeletonLoader(3, 10)
        ) : (
          <Row>
            <Col md="3" xs="4">
              <div className="icon-big text-center icon-warning">
                {/* <i className="nc-icon nc-globe text-warning" /> */}
                <img src={icon} alt="k" style={{ height: 40, width: 40 }} />
              </div>
            </Col>
            <Col md="9" xs="8" style={{ paddingLeft: 0 }}>
              <div className="numbers">
                <p className="card-category">{heading}</p>
                <CardTitle tag="p">{stat}</CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        )}
        {/* {isAppointments && (
            <>
            <Row><AppointmentCardStatus title="Pending" count={10} color="orange" /></Row>
            <Row><AppointmentCardStatus title="Upcoming" count={20} color="blue" /></Row>
            <Row><AppointmentCardStatus title="Completed" count={30} color="green" /></Row>
            <Row><AppointmentCardStatus title="Others" count={40} color="grey" /></Row>
            </>
          )} */}
      </CardBody>
      <CardFooter>
        <hr />
        {CountsLoading ? (
          TableSkeletonLoader(1, 20)
        ) : (
          <Button
            className="w-full"
            style={{
              visibility: isCta === false ? "hidden" : "visible",
              background: "#f6f8f9",
              borderColor: "#eaeaea",
              fontSize: 12,
              overflow: "auto",
            }}
            shape="round"
            onClick={() => {
              route && history.push(route);
            }}
          >
            View {heading}
          </Button>
        )}
      </CardFooter>
    </Card>
    // </Col>
  );
};

export default CardMetrics;
