import React from "react";
import { QuickBookingContextProvider } from "./useQuickBooking";
import QuickBookingContainer from "./QuickBookingContainer";

const QuickBooking = () => {
  return (
    <QuickBookingContextProvider>
      <QuickBookingContainer />
    </QuickBookingContextProvider>
  );
};

export default QuickBooking;
