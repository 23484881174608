export const modelReviewsAPI = (data) => {
  let result = {
    status: false,
    data: {
      count: 0,
      reviews: [],
    },
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    if (isFinite(Number(responseData.totalCount)))
      result.data.count = Number(responseData.totalCount);
    if (Array.isArray(responseData.reviews) && responseData.reviews.length) {
      result.data.reviews = responseData.reviews.map(
        ({
          id = 0,
          pat_name = "",
          review = "",
          star_number = 0,
          review_title = "",
          patient_profile_image = "",
          problem = "",
          treatment = "",
        }) => ({
          id,
          patientName: pat_name,
          description: review,
          rating: star_number,
          title: review_title,
          patientProfileImage : patient_profile_image,
          problem : problem,
          treatment : treatment,
        })
      );
    }
  } catch {
    result = {
      status: false,
      data: {
        count: 0,
        reviews: [],
      },
    };
  } finally {
    return result;
  }
};
