import React, { useCallback, useContext, useState} from "react";
import { Button, message } from "antd";
import useRazorpay from "react-razorpay";
import {
  newSubscriptionOrderHospital,
  subscriptionUpgradeOrderHospital,
  subscriptionSuccessHospital,
} from "./Services/Hospital/Subscription";

import {
  newSubscriptionOrderLab,
  subscriptionUpgradeOrderLab,
  subscriptionSuccessLab,
} from "./Services/Lab/Subscription";

import shopdoc_logo from "Images/shopdocLogoDark.svg";
import { GlobalState } from "context-api";
import CryptoJS from "crypto-js";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

export default function RazorpayPayment({
  duration,
  quota,
  couponApplied,
  couponCode,
  buyQuota,
  setShowPaymentSuccess,
  setShowPaymentFailure,
  FetchBillingHistory,
  orderType,
}) {
  const Razorpay = useRazorpay();
  const { HospitalInformation, SubscriptionReload, LabInformation } =
    useContext(GlobalState);
  const [isLoading,setIsLoading] = useState(false);
  let UserDetails;
  if (HospitalInformation?.email) {
    UserDetails = {
      name:
        HospitalInformation?.contact_person_first_name +
        " " +
        HospitalInformation?.contact_person_last_name,
      email: HospitalInformation.email,
      mobile: HospitalInformation.mobile,
    };
  } else if (LabInformation?.email) {
    UserDetails = {
      name:
        LabInformation?.contact_person_first_name +
        " " +
        LabInformation?.contact_person_last_name,
      email: LabInformation?.email,
      mobile: LabInformation?.mobile,
    };
  } else {
    UserDetails = {
      name: "ShopDoc",
      email: "hello@shopdoc.in",
      mobile: "6282016735",
    };
  }

  const handlePayment = useCallback(async () => {
    setIsLoading(true);
    let UserType;
    if (HospitalInformation?.email) {
      UserType = "hospital";
    } else if (LabInformation?.email) {
      UserType = "lab";
    }
    let payload;
    if (buyQuota) {
      payload = { quota: +quota };
    } else {
      payload = { quota: +quota, duration: +duration };
    }
    if (couponApplied) {
      payload.couponcode = couponCode;
    }

    let response;
    if (buyQuota) {
      if (UserType === "hospital") {
        response = await subscriptionUpgradeOrderHospital(payload);
      } else if (UserType === "lab") {
        response = await subscriptionUpgradeOrderLab(payload);
      }
    } else {
      if (UserType === "hospital") {
        response = await newSubscriptionOrderHospital(payload);
      } else if (UserType === "lab") {
        response = await newSubscriptionOrderLab(payload);
      }
    }
    // console.log("RAZORPAY SUBSCRIPTION ORDER RESPONSE=====>",response)

    if (response.status === "error") {
      //message.error("something went wrong");
      AntdCustomMessage({
        content : "Something went wrong",
        type : antdMessageTypes.ERROR,
      });
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: response.amount,
      currency: "INR",
      name: "ShopDoc",
      description: orderType,
      image: shopdoc_logo,
      order_id: response.order_id,
      handler: async (res) => {
        let signature = CryptoJS.HmacSHA256(
          response.order_id + "|" + res.razorpay_payment_id,
          process.env.REACT_APP_RAZORPAY_SECRET
        ).toString(CryptoJS.enc.Hex);

        // console.log("RAZORPAY SUCCESS RESPONSE=====>",res);

        if (res?.razorpay_signature === signature) {
          let payload2 = {
            order_id: response.order_id,
            order_type: orderType,
          };
          let response2;
          if (UserType === "hospital") {
            response2 = await subscriptionSuccessHospital(payload2);
          } else if (UserType === "lab") {
            response2 = await subscriptionSuccessLab(payload2);
          }
          // console.log("RESPONSE AFTER SUBSCRIPTION SUCCESS=====>",response2);
          
          if (response2?.message === "success") {
            FetchBillingHistory();
            SubscriptionReload();
            setShowPaymentFailure(false);
            setShowPaymentSuccess(true);
            setIsLoading(false);
          }
          
          return;
        }
      },
      prefill: {
        name: UserDetails.name,
        email: UserDetails.email,
        contact: UserDetails.mobile,
      },
      notes: {
        address: `Integrated Startup Complex, Kerala Startup Mission
              Kerala Techonology Innovation Zone, Kinfra Hi Tech Park Kalamassery
              Kochi, Kerala 683503, India`,
      },
      theme: {
        color: "#74BE76",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.on("payment.failed", function (response) {
      setShowPaymentSuccess(false);
      setShowPaymentFailure(true);
      setIsLoading(false);
    });

    rzpay.open();
    setIsLoading(false); // eslint-disable-line
  }, [Razorpay]); // eslint-disable-line

  return (
    <Button type="primary" loading={isLoading} style={{ height: 40 }} onClick={handlePayment}>
      Proceed To Pay
    </Button>
  );
}
