import { GetData, PostData } from "Utils/ApiCall";
import axiosApiInstance from "Utils/ApiInstance";
import { HospitalURLParser } from "Utils/urls.utils";

export const GetHospitalPackages = async (data) => {
  const response = await PostData(HospitalURLParser("/getHospitalPackages"), {
    ...data,
  });
  if (response && response.success) {
    return response.data;
  }
  return [];
};

export const AddHospitalPackages = async (
  data,
  package_image_file,
  package_gallery_file
) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  package_gallery_file &&
    package_gallery_file.forEach((item) =>
      formData.append("package_gallery_file", item)
    );
  package_image_file &&
    formData.append("package_image_file", package_image_file);

  const response = await PostData(
    HospitalURLParser("/addHospitalPackages"),
    formData
  );

  if (response) {
    return response;
  }
  return [];
};

export const UpdateHospitalPackages = async (
  data,
  package_image_file,
  package_gallery_file
) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  package_gallery_file &&
    package_gallery_file.forEach((item) =>
      formData.append("package_gallery_file", item)
    );
  package_image_file &&
    formData.append("package_image_file", package_image_file);

  const response = await PostData(
    HospitalURLParser("/updateHospitalPackages"),
    formData
  );
  if (response && response.success) {
    return response;
  }
  return [];
};

export const ChangeHospitalPackageStatus = async (data) => {
  const response = await PostData(
    HospitalURLParser("/changeHospitalPackageStatus"),
    {
      ...data,
    }
  );
  if (response && response.success) {
    return response;
  }
  return [];
};

export const GetPackageCategories = async () => {
  const response = await PostData(
    HospitalURLParser("/GetPackageCategories"),
    {}
  );
  if (response && response.success) {
    return response;
  }
  return [];
};

export const GetPackageTests = async () => {
  const response = await PostData(HospitalURLParser("/GetHospitalTests"), {});
  if (response && response.success) {
    return response;
  }
  return [];
};

export const deleteHospitalPackage = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/hospital/deleteHospitalPackage/${payload.id}`);
  if(response.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response.data || "Something went wrong!",
  }
}
export const getHospitalPackageFaqs = async (payload) => {
  try{
    const response = await axiosApiInstance
      .get(`/api/v1/hospital/package/getFAQs/${payload.id}`);
    if (response?.status == 200) {
      if(response?.data?.status == 200){
        return response.data.data
      }else{
        return []
      } 
    }else{
      return []
    }
  }catch{
    return [];
  }
  
}

export const addHospitalPackageFaqs = async (payload) => {
  const response = await axiosApiInstance
    .post(
      '/api/v1/hospital/package/addFAQs',
      payload
    );
  if(response.status == 200){
    return response.data
  }
  return {
    message: "Something went wrong."
  };
};

export const updateHospitalPackageFaqs = async (payload) => {
  const response = await axiosApiInstance
    .put(
      '/api/v1/hospital/package/updateFAQs',
      payload
    );
  if(response.status == 200){
    return response.data
  }
  return {
    message: "Something went wrong."
  };
};

export const deleteHospitalPackageFaqs = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/hospital/package/deleteFAQs/${payload.id}`);
  if(response.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response.data || "Something went wrong!",
  }
}
