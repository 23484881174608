import { Card } from "antd";
import React, { useEffect } from "react";
import EllipsisText from "react-ellipsis-text";
import { Col, Row } from "reactstrap";
import Cookies from "universal-cookie";
import {
  getNotificationType,
  getDateTimeFormat,
  DecryptString,
} from "Utils/Constants";
import { changeHospitalNotificationStatus } from "Utils/Services/Hospital/Notifications";
import { changeLabNotificationStatus } from "Utils/Services/Lab/Notifications";
import { changePractitionerNotificationStatus } from "Utils/Services/Practitioner/Notifications";

const NotificationDetailsCard = ({
  notification,
  FetchNotifications,
  FetchData = () => {},
}) => {
  const cookies = new Cookies();
  let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
  let UserData = cookieUserData && JSON.parse(cookieUserData);
  const updateNotificationStatus = async () => {
    if (UserData?.type === "practitioner") {
      const response = await changePractitionerNotificationStatus({
        notification_id: notification.id,
      });
      if (response?.status) {
        FetchNotifications(UserData?.type);
        FetchData && FetchData();
      }
      return;
    }
    if (UserData?.type === "hospital") {
      const response = await changeHospitalNotificationStatus({
        notification_id: notification.id,
      });
      if (response?.status) {
        FetchNotifications(UserData?.type);
        FetchData && FetchData();
      }
      return;
    } else if (UserData?.type === "lab") {
      const response = await changeLabNotificationStatus({
        notification_id: notification.id,
      });
      if (response?.status) {
        FetchNotifications(UserData?.type);
        FetchData && FetchData();
      }
      return;
    }
  };

  useEffect(() => {
    if (notification?.read !== 1) {
      updateNotificationStatus();
    }
  }, [notification]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <h4
          className="mt-2"
          style={{
            textTransform: "capitalize",
            paddingBottom: "10px",
            fontSize: "16px",
          }}
        >
          <EllipsisText text={notification?.subject} length={50} />
        </h4>
      </div>
      <div className="details-tab-container tab-style">
        <div className="general-info">
          <Row lg="9" md="12" sm="12" className="column-items">
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray" style={{ fontSize: "14px" }}>
                  Date Time
                </h4>
                <h4 className="text-black" style={{ fontSize: "14px" }}>
                  {getDateTimeFormat(notification?.created_at)}
                </h4>
              </div>
            </Col>
            <Col lg="4" md="4" sm="6" className="item column-item">
              <div className="column-item-inner">
                <h4 className="label-gray" style={{ fontSize: "14px" }}>
                  Type
                </h4>
                <h4
                  className="text-black"
                  style={{ textTransform: "capitalize", fontSize: "14px" }}
                >
                  {getNotificationType(notification?.type)}
                </h4>
              </div>
            </Col>
          </Row>
          <Card
            style={{ width: "100%", padding: "10px", marginTop: 10 }}
            bodyStyle={{ padding: "0px 0px 10px" }}
          >
            {notification?.patient_name ? (
              <div
                className="details-wraper"
                style={{ textTransform: "capitalize" }}
              >
                <label style={{ fontSize: "14px" }}>Patient Name:</label>
                <h4 className="text" style={{ fontSize: "14px" }}>
                  {notification?.patient_name}
                </h4>
              </div>
            ) : (
              ""
            )}
            <div className="details-wraper" style={{ padding: 15 }}>
              <label style={{ fontSize: "14px" }}>Message:</label>
              <h4 className="text" style={{ fontSize: "14px" }}>
                {notification?.message}
              </h4>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default NotificationDetailsCard;
