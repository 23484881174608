import React, { useState } from "react";
import { Button, message } from "antd";
import { useAppointments } from "../useAppointments";
import { generateAppointmentPdf } from "Utils/Services/Practitioner/Appointments";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const PrintTab = () => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  let { selectedAppointmentId = "" } = useAppointments();

  const handlePrintClick = async () => {
    setIsBtnLoading(true);
    const response = await generateAppointmentPdf(selectedAppointmentId);
    if (response?.status) {
      setTimeout(()=>{
        window.open(response.path, "_blank");
        setIsBtnLoading(false);
      },1000);
      return;
    }
    //message.error("Something went wrong");
    AntdCustomMessage({
      type: antdMessageTypes.ERROR,
      content: "Something went wrong"
    });
    setIsBtnLoading(false);
  };

  return (
    <div>
      <Button type="primary" loading={isBtnLoading} onClick={handlePrintClick}>
        Print Appointment Details
      </Button>
    </div>
  );
};

export default PrintTab;
