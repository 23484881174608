import React from "react";
import PractitionerProfileContainer from "./Tabs/PractitionerProfileContainer";
import { ProfileContextProvider } from "./useProfile";

const PractitionerProfile = () => {
  return (
    <ProfileContextProvider>
      <PractitionerProfileContainer />
    </ProfileContextProvider>
  );
};
export default PractitionerProfile;
