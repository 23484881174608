import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  Typography,
} from "antd";
import { GlobalState } from "context-api";
import { useHistory } from "react-router-dom";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import {
  getPackageAppointmentReports,
  savePackageReports,
} from "Utils/Services/Hospital/Appointments";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { getReadableFileSize } from "Utils/GetReadableFileSize";

const TestAndReports = (props) => {
  const history = useHistory();
  const { setHeading, Heading } = useContext(GlobalState);
  const [isLoading, setIsLoading] = useState(false);

  const [LabAppointmentReports, setLabAppointmentReports] = useState([]);
  const [NewReports, setNewReports] = useState([
    { id: 1, name: "Report 1", file: "" },
  ]);

  const appointment_id = props.match.params.appointment_id;
  const fetchData = async () => {
    const response = await getPackageAppointmentReports({
      appointment_id: appointment_id,
    });
    response && setLabAppointmentReports(response?.reports);
  };

  const updateAllData = async () => {
    setIsLoading(true);
    let ReportArray = [];
    NewReports.forEach((item, index) => {
      let a = {};
      a.report_title = item.name;
      a.report = item.file;
      ReportArray.push(a);
    });

    const formData = new FormData();
    const payload = {
      appointment_id: appointment_id,
    };
    Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
    ReportArray.map((item, index) => {
      formData.append(
        `upload_reports[${index}][report_title]`,
        item.report_title
      );
      formData.append(`upload_reports[${index}][report]`, item.report);
      return null;
    });
    const response = await savePackageReports(formData);

    if (response?.status === 200) {
      setIsLoading(false);
      //message.success("Reports updated successfully");
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content :"Reports updated successfully",
      });
      fetchData();
      setNewReports([{ id: nanoid(), name: "", file: "" }]);
    } else {
      setIsLoading(false);
      //message.error("Something went wrong");
      AntdCustomMessage({
        type : antdMessageTypes.ERROR,
        content :"Something went wrong",
      });
    }
  };

  const updateReports = (id, name, value) => {
    const newReports = [...NewReports];
    const index = newReports.findIndex((item) => item.id === id);
    newReports[index][name] = value;
    setNewReports(newReports);
  };

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Test Reports");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    if (appointment_id) {
      fetchData();
    }
  }, [appointment_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content">
      <Button style={{ marginBottom: "1rem" }} onClick={() => history.goBack()}>
        Back
      </Button>
      <Card className="mb-4">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Add Reports{" "}
            <span style={{ fontSize: 12 }}>( Pdf, Jpeg, Jpg, Png )</span>
          </Typography.Title>
          <Button
            onClick={() => {
              setNewReports([
                ...NewReports,
                { id: nanoid(), name: "", file: "" },
              ]);
            }}
            style={{ height: 40, padding: "0px 20px" }}
            icon={<PlusOutlined />}
            size="small"
            type="secondary"
          >
            Upload New Report
          </Button>
        </div>
        <Form layout="vertical" className="report-add-container">
          {NewReports.map((report) => (
            <Row key={report.id} className="report-add-row">
              <Col xs={12} sm={24} lg={10}>
                <Form.Item label="Report Title">
                  <Input
                    onChange={(e) =>
                      updateReports(report.id, "name", e.target.value)
                    }
                    size="medium"
                    placeholder="Report Title"
                    style={{ width: "90%" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={24} lg={10}>
                <Form.Item label="Report File">
                  <Input
                    type="file"
                    name="file"
                    accept=".jpeg, .jpg, .png, .pdf"
                    onChange={(e) =>{
                      //check image size here
                      const file = e?.target?.files[0];
                      const fileSize = file?.size;
                      if(
                        fileSize < Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES) || 
                        fileSize > Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)
                      ){
                        AntdCustomMessage({
                          type : antdMessageTypes.ERROR,
                          content : `Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
                            getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} and ${
                            getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}.`,
                        });
                        updateReports(report.id, "file", "");
                        return;
                      }else{
                        updateReports(report.id, "file",  e?.target?.files[0]);
                      }
                    }}
                  /> 
                  {
                    !report.file ? (
                      <div className="flex flex-row justify-center items-center text-center">
                        <p>
                          <span style={{color:"red"}}>*</span>Updating file size should be between 
                          <span className="font-weight-bold text-black"> 
                            &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} 
                          </span>
                          &nbsp;and 
                          <span className="font-weight-bold text-black">
                            &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}
                          </span>.
                        </p>
                      </div>
                    ):null
                  }
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                lg={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CloseCircleFilled
                  onClick={() => {
                    const newReports = [...NewReports];
                    const index = newReports.findIndex(
                      (item) => item.id === report.id
                    );
                    newReports.splice(index, 1);
                    setNewReports(newReports);
                  }}
                  style={{ fontSize: "18px", color: "#ff4040", marginLeft: 15 }}
                />
              </Col>
            </Row>
          ))}
        </Form>

        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={updateAllData}
          size="large"
          type="primary"
          style={{
            float: "right",
          }}
        >
          Submit Reports
        </Button>
      </Card>
      {LabAppointmentReports?.length ? (
        <Card>
          <Typography.Title level={4} style={{ margin: 0, marginBottom: 15 }}>
            Download Reports
          </Typography.Title>
          <Table
            rowKey="id"
            dataSource={LabAppointmentReports.map((item, index) => {
              return {
                id: index,
                report_name: item.title,
                report_url: item.file,
              };
            })}
          >
            <Table.Column 
              title="Sl No" 
              dataIndex="id" 
              key="id"
              render={(text, record) => (
                <p>
                  {record.id + 1}
                </p>
              )} 
            />
            <Table.Column
              title="Report Title"
              dataIndex="report_name"
              key="report_name"
            />
            <Table.Column
              key="report_url"
              dataIndex="report_url"
              title="Download"
              render={(text, record) => (
                <Button type="primary" href={text}>
                  Download
                </Button>
              )}
            />
          </Table>
        </Card>
      ) : null}
    </div>
  );
};

export default TestAndReports;
