import React, { useEffect, useContext, useState } from "react";
import { GlobalState } from "context-api";
import { Tabs } from "antd";
import GeneralInformation from "views/components/hospitalProfile/GeneralInformation";
import HospitalInformation from "views/components/hospitalProfile/HospitalInformation";
import WorkingHours from "views/components/hospitalProfile/WorkingHours";
import LocationDetails from "views/components/hospitalProfile/LocationDetails";
import Settings from "views/components/hospitalProfile/Settings";
import ChangeFacility from "views/components/hospitalProfile/ChangeFacility";

const HospitalProfile = () => {
  const { setHeading, Heading } = useContext(GlobalState);
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Practice Profile");
    }
  }, [setHeading, Heading]);

  //set active tab from url params if present

  useEffect(() => {
    if (window.location.href.includes("tab")) {
      const tab = window.location.href?.split("tab=")[1];
      setActiveTab(tab);
    }
  }, []);

  //save current tab id in url
  const onTabChange = (key) => {
    window.history.pushState(
      null,
      null,
      `/hospital/hospital_profile/?tab=${key}`
    );
    setActiveTab(key);
  };

  return (
    <div className="content">
      <div className="details-tab-container tab-style">
        <Tabs
          type="card"
          activeKey={activeTab}
          animated={true}
          tabBarGutter={6}
          onChange={onTabChange}
        >
          <TabPane tab="General information" key="1">
            <GeneralInformation />
          </TabPane>
          <TabPane tab="Practice information" key="2">
            <HospitalInformation />
          </TabPane>
          <TabPane tab="Working hours" key="3">
            <WorkingHours />
          </TabPane>
          <TabPane tab="Location details" key="4">
            <LocationDetails />
          </TabPane>
          <TabPane tab="Account Settings" key="5">
            <Settings />
          </TabPane>
          <TabPane tab="Facility & Insurance" key="6">
            <ChangeFacility />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default HospitalProfile;
