import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select, Switch } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import MultipleImageUploader from "components/MultipleImageUploader";
import { GlobalState } from "context-api";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useContext, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory } from "react-router";
import { getReadableFileSize } from "Utils/GetReadableFileSize";
import {
  AddLabPackages,
  GetLabTests,
  GetPackageCategories,
} from "Utils/Services/Lab/LabPackages";
import { validateMessages } from "Utils/validations";

const AddLabPackage = () => {
  const { setHeading, Heading } = useContext(GlobalState);
  const [availableTests, setAvailableTests] = useState(null);
  const [categories, setCategories] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [packageGalleryFiles, setPackageGalleryFiles] = useState(null);
  const [packageImageMain, setPackageMainImage] = useState(null);

  const history = useHistory();
  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Add Lab Package");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    const fetchData = async () => {
      const labTestsResponse = await GetLabTests();
      const packageCategoriesResponse = await GetPackageCategories();
      packageCategoriesResponse && packageCategoriesResponse.status === 200 &&
        setCategories(packageCategoriesResponse?.data?.packagecategories);
      labTestsResponse && labTestsResponse.status === 200 &&
        setAvailableTests(labTestsResponse?.data?.labtests);
    };

    fetchData();
  }, []);

  const onFinish = async (values) => {
    if (!packageImageMain) {
      //message.error("Package image is empty");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Package image is empty"
      });
      return;
    }
    const response = await AddLabPackages(
      {
        ...values,
        discount: values.discount,
        about_package: stateToHTML(editorState.getCurrentContent()),
        sample_at_home: values.sample_at_home ? true : false,
        package_image: packageImageMain,
        package_category_id: values.package_category_id.join(","),
        available_tests: values.available_tests.join(","),
      },
      packageGalleryFiles
    );
    if (response?.status === 200) {
      //message.success("Lab package addded successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Lab package addded successfully"
      });
      history.goBack();
    }
  };

  return (
    <div className="content">
      <Button onClick={() => history.goBack()} style={{ marginBottom: "10px" }}>
        Back
      </Button>
      <div className="procedure-container">
        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          initialValues={{ promotion_text: "" }}
        >
          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name={["package_name"]}
                label="Package Name"
                rules={[{ required: true }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item name={["promotion_text"]} label="Promotion Text">
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name={["available_tests"]}
                label="Package Tests"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  size="large"
                  placeholder="Please select tests"
                  style={{ width: "100%" }}
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {availableTests?.map((item) => (
                    <Select.Option key={item.test_id}>
                      {item.test_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} lg={6}>
              <Form.Item
                name={["price"]}
                label="Price"
                //price can't be negative
                rules={[
                  { required: true },
                  {
                    validator: (rule, value) => {
                      if (value < 0) {
                        return Promise.reject("Price can't be negative");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input size="large" type="number" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} lg={6}>
              <Form.Item name={["discount"]} label="Discount">
                <Input size="large" type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name={["package_category_id"]}
                label="Package Category"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  size="large"
                  placeholder="Please select tests"
                  style={{ width: "100%" }}
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {categories?.map((item) => (
                    <Select.Option key={item.id}>
                      {item.categoryname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                name={["sample_at_home"]}
                label="Sample collection at Home"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={24}>
              <Form.Item label="About Package">
                <Editor
                  editorStyle={{
                    border: "1px solid #D9D9D9",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(value) => {
                    setEditorState(value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={20}>
              <p className="font-weight-bold">Package Gallery </p>

              <Form.Item style={{ marginTop: "5px" }}>
                <MultipleImageUploader
                  onChange={(image) => {
                    setPackageGalleryFiles(image.newImages);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={{ sm: 8, lg: 24 }}>
            <Col xs={24} sm={24} lg={20}>
              <p ><span className="text-danger">*</span> <span className="font-weight-bold">Package Image </span>
              
              <p>
                Updating file size should be between 
                <span className="font-weight-bold text-black"> 
                  &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} 
                </span>
                &nbsp;and 
                <span className="font-weight-bold text-black">
                  &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}
                </span>.
              </p>
              </p>
              <div style={{ display: "flex" }}>
                {packageImageMain && (
                  <img
                    alt="lab"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "10px",
                      objectFit: "cover",
                    }}
                    src={URL.createObjectURL(packageImageMain)}
                  />
                )}
              </div>
              <Form.Item style={{ marginTop: "5px" }}>
                <input
                  name="clinic_images"
                  style={{ display: "none" }}
                  type="file"
                  accept="image/jpeg,image/png"
                  id="file-uploader2"
                  onChange={(event) =>{
                    //check image size here
                    const file = event?.target?.files[0];
                    const fileSize = file?.size;
                    if(
                      fileSize < Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES) || 
                      fileSize > Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)
                    ){
                      AntdCustomMessage({
                        type : antdMessageTypes.ERROR,
                        content : `Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
                          getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} and ${
                          getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}.`,
                      });
                      return;
                    }else{
                      setPackageMainImage(event?.target?.files[0])
                    }  
                  }}
                />
                <label
                  htmlFor="file-uploader2"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    padding: "5px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    border: "1px solid #e8e8e8",
                    borderRadius: "7px",
                  }}
                >
                  <PlusOutlined />
                  Upload image
                </label>
                
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ height: 40 }}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddLabPackage;
