import { LabURLParser } from "Utils/urls.utils";
import { GetData, PostData } from "../../ApiCall";
import { modelCheckPatientExistAPI } from "models/quickbooking.api.models";
import axiosApiInstance from "Utils/ApiInstance";

export const CheckPatientExist = async (value) => {
  const response = modelCheckPatientExistAPI(
    await PostData(LabURLParser("/patient/checkPatientExists"), value)
  );
  return response;
};

export const AddPatientByInstantBooking = async (values) => {
  const response = await PostData(
    LabURLParser("/patient/addPatientByInstantBooking"),
    values
  );
  if (response) {
    return response;
  }
};

export const saveQuickBookingPaymentDetails = async (values) => {
  return await PostData(LabURLParser("/quickbooking/savePaymentDetails"), {
    ...values,
  }).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const saveQuickBookingPackageBooking = async (values) => {
  return await PostData(LabURLParser("/quickbooking/savePackageBooking"), {
    ...values,
  }).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const GetLabLinkedTests = async () => {
  return await GetData(
    LabURLParser("/Get/LabLinkedTests")
  ).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const postSaveLabTest = async (payload) => {
  return await PostData(LabURLParser("/add/newTests"),{
    ...payload
  }).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const postSaveLinkedTest = async (payload) => {
  return await PostData(LabURLParser("/create/LabLinkedTests"),{
    ...payload
  }).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const postSaveLabTestBooking = async (payload) => {
  return await PostData(LabURLParser("/quickbooking/saveLabTestBooking"),{
    ...payload
  }).then((response) => {
    if (response) {
      return response;
    }
  });
};

export const updateLabLinkedTest = async (payload) => {
  const response = await axiosApiInstance
    .put(
      '/api/v1/lab/update/labLinkedTest',
      payload
    );
  if(response?.status == 200){
    return response?.data
  }
  return {
    message: "Something went wrong."
  };
};

export const deleteLabLinkedTest = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/lab/delete/labLinkedTest/${payload.id}`);
  if(response?.status == 200){
    return response?.data
  }
  return {
    success:"false",
    message:response?.data || "Something went wrong!",
  }
}
