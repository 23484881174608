import React, { useState, createContext, useContext } from "react";
// import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { getPractitionerInformation } from "Utils/Services/Practitioner/Profile";
import { EditorState } from "draft-js";

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ status: false, data: {} });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const fetchData = async () => {
    setIsLoading(true);
    let profile = await getPractitionerInformation();
    if (profile?.status && profile?.data) {
      const contentState = stateFromHTML(profile.data.about_me);
      setEditorState(EditorState.createWithContent(contentState));
    }
    profile && setData(profile);

    setIsLoading(false);
  };

  const values = {
    isLoading,
    setIsLoading,
    data,
    setData,
    fetchData,
    editorState,
    setEditorState,
  };

  return <ProfileContext.Provider value={values} {...props} />;
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
