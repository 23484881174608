import { Tabs } from "antd";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import AccountSettings from "views/components/Lab/Profile/AccountSettings";
import GeneralInformation from "views/components/Lab/Profile/GeneralInformation";
import LabInformation from "views/components/Lab/Profile/LabInformation";
import LocationDetails from "views/components/Lab/Profile/LocationDetails";
import WorkingHours from "views/components/Lab/Profile/WorkingHours";

const HospitalProfile = () => {
  const { setHeading, Heading } = useContext(GlobalState);
  const [activeTab, setActiveTab] = useState("1");
  const { TabPane } = Tabs;
  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Lab Profile");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    if (window.location.href.includes("tab")) {
      const tab = window.location.href?.split("tab=")[1];
      setActiveTab(tab);
    }
  }, []);

  //save current tab id in url
  const onTabChange = (key) => {
    window.history.pushState(null, null, `/lab/lab-profile/?tab=${key}`);
    setActiveTab(key);
  };

  return (
    <div className="content">
      <div className="details-tab-container tab-style">
        <Tabs
          type="card"
          activeKey={activeTab}
          animated={true}
          onChange={onTabChange}
        >
          <TabPane tab="General information" key="1">
            <GeneralInformation />
          </TabPane>
          <TabPane tab="Lab information" key="2">
            <LabInformation />
          </TabPane>
          <TabPane tab="Working hours" key="3">
            <WorkingHours />
          </TabPane>
          <TabPane tab="Location details" key="4">
            <LocationDetails />
          </TabPane>
          <TabPane tab="Account Settings" key="5">
            <AccountSettings />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default HospitalProfile;
