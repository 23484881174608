import React, { useEffect, useState } from "react";
import {
  UpOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getWorkHourSlots } from "Utils/Services/Hospital/Appointments";
import { getLeaveDays } from "Utils/Services/Hospital/LeaveDays";
import moment from "moment";
import { Spin } from "antd";
import { nanoid } from "nanoid";

const ScheduleCard = ({ practitioner_id, triggerChange }) => {
  const Today = moment(new Date()).format("YYYY-MM-DD");
  const [isDateAreaExpanded, setIsDateAreaExpanded] = useState(true);
  const [slots, setSlots] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(Today);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [LeaveDays, setLeaveDays] = useState([
    { from: "", to: "", id: nanoid() },
  ]);

  useEffect(() => {
    const FetchSlots = async () => {
      setIsLoading(true);
      setSelectedTime("");

      const response = await getWorkHourSlots({
        practitioner_id: +practitioner_id?.replace("SDP", "") || "",
        date: moment(date).format("YYYY-MM-DD") || Today,
      });

      response && setSlots(response);
      setIsLoading(false);
    };
    if (date && practitioner_id) {
      FetchSlots();
    }
    // eslint-disable-next-line
  }, [date, practitioner_id]);

  useEffect(() => {
    const FetchLeaveDays = async () => {
      const response = await getLeaveDays({
        practitioner_id: +practitioner_id?.replace("SDP", "") || "",
        leave_date: moment(date).format("YYYY-MM-DD") || Today,
      });
      response && setLeaveDays(response.data.leaveDays);
    };
    if (date && practitioner_id) {
      FetchLeaveDays();
    }
    // eslint-disable-next-line
  }, [date, practitioner_id]);

  useEffect(() => {
    if (triggerChange) {
      triggerChange({
        date: moment(date).format("YYYY-MM-DD"),
        time: selectedTime,
        token: selectedToken
      });
    }
    // eslint-disable-next-line
  }, [date, selectedTime, selectedToken]);

  const SingleDate = ({ Date }) => {
    const isSelected =
      moment(date).format("YYYY-MM-DD") === moment(Date).format("YYYY-MM-DD");

    return (
      <div
        className={isSelected ? "date-card selected" : "date-card"}
        onClick={() => setDate(Date)}
      >
        <h1 className={isSelected ? "day selected" : "day"}>
          {moment(Date).format("DD")}
        </h1>
        <h1 className={isSelected ? "month selected" : "month"}>
          {moment(Date).format("MMM YYYY")}
        </h1>
      </div>
    );
  };

  const DatesInSelectedMonth = () => {
    const dates = new Array(27)
      .fill(0)
      .map((_item, index) => moment().add(index, "days").format("YYYY-MM-DD"));
    return dates.map((Date) => {
      return <SingleDate key={Date} Date={Date} />;
    });
  };

  const TimeCard = () => {
    let slotsRaw = Object.entries(slots.slots) || {};
    let slotsArr = [];
    // eslint-disable-next-line
    slotsRaw.map((slot) => {
      //set slotsArr slotsArray and set new values
      slotsArr = [
        ...slotsArr,
        slot[1]?.filter((value) => {
          return moment().isBefore(
            moment(moment(date).format("YYYY-MM-DD") + " " + value.time, [
              "YYYY-MM-DD h:mm A",
            ])
          );
        }),
      ];
    });

    let newSlots = [];
    slotsArr?.length &&
      slotsArr.flat(Infinity).forEach((slot) => {
        newSlots.push({ ...slot, isLeave: false });
      });

    if (LeaveDays.length) {
      LeaveDays.forEach((leave) => {
        newSlots.forEach((slot) => {
          let from = moment(leave.from, "HH:mm A");
          let to = moment(leave.to, "HH:mm A");
          let slotTime = moment(slot.time, "HH:mm A");
          if (slotTime.isBetween(from, to) || slotTime.isSame(from)) {
            slot.isLeave = true;
          }
        });
      });
    }
    if (newSlots.length) {
      return (
        <div className="times-container">
          {newSlots.map((slot, index) => {
            return slot.booked === "false" && !slot.isLeave ? (
              <div
                className={
                  selectedTime === slot.time
                    ? "time-card selected"
                    : "time-card"
                }
                key={index}
                onClick={() => {
                  setSelectedTime(slot.time);
                  setSelectedToken(slot.token);
                }}
              >
                <p className="mt-1 mb-0">{slot.token}</p>
                <p>{slot.time}</p>
              </div>
            ) : (
              <div className="time-card disabled" key={index}>
                <p className="mt-1 mb-0">{slot.token}</p>
                <p>{slot.time}</p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p>No slots available</p>;
    }
  };
  return (
    <div className="reschedule-container">
      <div className="header-section">
        <h1 className="date-header">{moment(date).format("DD MMM YYYY")}</h1>
        {isDateAreaExpanded ? (
          <UpOutlined
            onClick={() => setIsDateAreaExpanded(!isDateAreaExpanded)}
          />
        ) : (
          <DownOutlined
            onClick={() => setIsDateAreaExpanded(!isDateAreaExpanded)}
          />
        )}
        <h1
          className={date === Today ? "today-header" : "today-header disabled"}
          onClick={() => setDate(Today)}
        >
          TODAY
        </h1>
        <div className="right-section">
          <LeftOutlined
            onClick={() => {
              date > Date.now() &&
                setDate(moment(date, "YYYY-MM-DD").subtract(1, "day"));
            }}
          />
          <RightOutlined
            onClick={() => {
              setDate(moment(date, "YYYY-MM-DD").add(1, "day"));
            }}
          />
        </div>
      </div>
      {isDateAreaExpanded && (
        <div className="dates-container">
          <DatesInSelectedMonth />
        </div>
      )}

      {!isLoading ? (
        slots?.dates && <TimeCard />
      ) : (
        <div
          style={{ minHeight: "200px", display: "grid", placeItems: "center" }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default ScheduleCard;
