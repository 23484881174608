import React from "react";
import { AppointmentsContextProvider } from "./useAppointments";
import PractitionerAppointments from "./AppointmentsContainer";

const Appointments = () => {
  return (
    <AppointmentsContextProvider>
      <PractitionerAppointments />
    </AppointmentsContextProvider>
  );
};

export default Appointments;
