export const modelHospitalReviewsAPI = (response) => {
    let result = {
      status: false,
      data: {
        count: 0,
        reviews: [],
      },
    };
    try {
      let { data: [], status = 500 } = response;
      if (response.status !== 200) throw new Error("invalid status");
      result.status = true;
      result.data.count = response.data.length;
      if (Array.isArray(response.data) && response.data.length) {
        result.data.reviews = response.data.map(
          ({
            id = "",
            clinic_id = "",
            patient_id = "",
            review_title = "",
            review = "",
            star_rating = "",
            publish_status = "",
            problem = "",
            treatment = "",
            created_date = "",
            updated_date = "",
            clinic_name = "",
            first_name = "",
            last_name = "",
          }) => ({
            id,
            patientName: first_name + " " + last_name,
            description: review,
            rating: star_rating,
            title: review_title,
            problem : problem,
            treatment : treatment,
          })
        );
      }
    } catch {
      result = {
        status: false,
        data: {
          count: 0,
          reviews: [],
        },
      };
    } finally {
      return result;
    }
  };
  