import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import EllipsisText from "react-ellipsis-text";
import { getAppointmentDetails } from "Utils/Services/Practitioner/Appointments";
import Spinner from "components/Spinner";
import FullWidthView from "components/FullWidthView.js";
import { useAppointments } from "../useAppointments";
import GeneralTab from "./GeneralTab";
import PrescriptionTab from "./PrescriptionTab";
import ReportsTab from "./ReportsTab";
import ShareTab from "./ShareTab";
import PrintTab from "./PrintTab";
import AppointmentHistoryTab from "./AppointmentHistoryTab";
import PatientUploadedReportsTab from "./PatientUploadedReports";
import { getAgeFromDOB } from "Utils/GetAgeFromDOB";

const { TabPane } = Tabs;

const DetailsCard = () => {
  const [data, setData] = useState({
    status: false,
    data: {
      general: {},
      prescription: {},
      history:{},
      reports: {},
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  let { selectedAppointmentId = "" } = useAppointments();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      getAppointmentDetails({
        appointment_id: selectedAppointmentId,
      }).then((response) => {
        setData(response);
        setIsLoading(false);
      });
    };
    if (selectedAppointmentId) {
      getData();
    }
  }, [selectedAppointmentId]);

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  if (!data.status)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <h1>No Data Found</h1>
      </FullWidthView>
    );
  let { general, history, prescription, reports } = data.data;
  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <span className="appointment-id" style={{ fontSize: 16 }}>
          {general.appointmentId}
        </span>
        {
          general.token ?(
            <span className="appointment-token" style={{ fontSize: 16, fontWeight:"bold", color:"black", marginTop:"15px" }}>
              {`TOKEN-${general.token}`}
            </span>
          ): null
        }
        <h4 className="mt-2">
          <EllipsisText
            style={{ fontSize: 16, fontWeight: "bold" }}
            className="capitalize"
            text={general.patientName}
            length={50}
          />
        </h4>
        {general.dob !== "" && (
          <div className="dob-wrapper">
            <span className="label">DOB:</span>
            <span className="dob">{general.dob}</span>
          </div>
        )}
        {general.dob !== "" && (
          <div className="dob-wrapper">
            <span className="label">Age:</span>
            <span className="dob">{getAgeFromDOB(general.dob)}</span>
          </div>
        )}
      </div>
      <div className="details-tab-container tab-style">
        <Tabs type="card" defaultActiveKey="1">
          <TabPane tab="General" key="1">
            <GeneralTab data={general} />
          </TabPane>
          <TabPane tab="Appointment History" key="2">
            <AppointmentHistoryTab data={history}/>
          </TabPane>
         {
          !general.relationName ? (
            <TabPane tab="Patient Uploaded Reports" key="3">
              <PatientUploadedReportsTab patientId={general.patientId}/>
            </TabPane>
          ):null
         }
          <TabPane tab="Prescription" key="4">
            <PrescriptionTab data={prescription} />
          </TabPane>
          <TabPane tab="Report" key="5">
            <ReportsTab data={reports} />
          </TabPane>
          <TabPane tab="Share" key="6">
            <ShareTab />
          </TabPane>
          <TabPane tab="Print" key="7">
            <PrintTab />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default DetailsCard;
