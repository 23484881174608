import React from "react";
import { FolderAddOutlined } from "@ant-design/icons";

const GeneralInformation = (props) => {
  const { about, departments, email, mobile, contactPerson } = props;
  return (
    <div className="grid gap-1">
      {about ? (
        <div className="details-container">
          <h6 className="mb-1">About</h6>
          <p dangerouslySetInnerHTML={{ __html: about }} />
        </div>
      ) : (
        ""
      )}
      {departments.length ? (
        <div className="details-container">
          <h6 className="mb-1">Speciality</h6>
          <div className="service-info">
            {departments.map((department, index) => (
              <span className="item" key={`${department}${index}`}>
                <FolderAddOutlined />
                {department}
              </span>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {email && (
        <div className="details-container">
          <h6 className="mb-1">Email</h6>
          <p>{email}</p>
        </div>
      )}
      {mobile && (
        <div className="details-container">
          <h6 className="mb-1">Mobile</h6>
          <p>{mobile}</p>
        </div>
      )}
      {contactPerson && (
        <div className="details-container">
          <h6 className="mb-1">Contact Person</h6>
          <p>{contactPerson}</p>
        </div>
      )}
    </div>
  );
};

export default GeneralInformation;
