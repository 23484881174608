import { GetData, PostData } from "Utils/ApiCall";
import axiosApiInstance from "Utils/ApiInstance";
import { HospitalURLParser } from "Utils/urls.utils";

export const getMedicalProcedures = async (start, limit) => {
  const response = await PostData(HospitalURLParser("/GetHospitalProcedures"), {
    start: start,
    limit: limit,
  });
  if (response) {
    return response.data;
  }
};

export const getActiveProcedures = async (start, limit) => {
  const response = await GetData(HospitalURLParser("/getActiveProcedures"));
  if (response) {
    return response.data;
  }
};

export const addMedicalProcedure = async (procedure_id) => {
  const response = await PostData(HospitalURLParser("/SaveHospitalProcedure"), {
    procedure_id,
  });
  if (response) {
    return response;
  }
};

export const getProcedureRequests = async (values) => {
  const response = await PostData(
    HospitalURLParser("/ProcedureRequests"),
    values
  );
  if (response) {
    return response.data;
  }
};

export const updateMedicalProcedure = async (
  procedure_entry_id,
  new_procedure_id
) => {
  const response = await PostData(
    HospitalURLParser("/UpdateHospitalProcedure"),
    {
      id: procedure_entry_id,
      procedure_id: new_procedure_id,
    }
  );
  if (response) {
    return response;
  }
};

export const updateHospitalProcedureStatus = async (id, status) => {
  const response = await PostData(
    HospitalURLParser("/UpdateHospitalProcedureStatus"),
    {
      id,
      status,
    }
  );
  if (response) {
    return response;
  }
};

export const deleteHospitalProcedure = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/hospital/deleteHospitalProcedure/${payload.id}`);
  if(response && response?.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response.data || "Something went wrong!",
  }
}
export const getHospitalProcedureFaqs = async (payload) => {
  const response = await GetData(`/api/v1/hospital/procedure/getFAQs/${payload.id}`);
  if (response) {
    return response.data;
  }
}

export const addHospitalProcedureFaqs = async (payload) => {
  const response = await axiosApiInstance
    .post(
      '/api/v1/hospital/procedure/addFAQs',
      payload
    );
  if(response && response?.status == 200){
    return response.data
  }
  return {
    message: "Something went wrong."
  };
};

export const updateHospitalProcedureFaqs = async (payload) => {
  const response = await axiosApiInstance
    .put(
      '/api/v1/hospital/procedure/updateFAQs',
      payload
    );
  if(response && response?.status == 200){
    return response.data
  }
  return {
    message: "Something went wrong."
  };
};

export const deleteHospitalProcedureFaqs = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/hospital/procedure/deleteFAQs/${payload.id}`);
  if(response && response?.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response.data || "Something went wrong!",
  }
}
