import { Button, Card, Col, Form, message, Row, Switch, Radio } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import React, { useEffect, useState } from "react";
import {
  GetLabProfileSettingsInformation,
  PostLabProfileSettingsInformation,
} from "Utils/Services/Lab/LabProfile";

const AccountSettings = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [AutoApproval, setAutoApproval] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState(0);

  const PaymentOptions = [
    { value: 0, label: "Offline" },
    { value: 1, label: "Online" },
    { value: 2, label: "Both" },
  ];

  const FetchData = async () => {
    const response = await GetLabProfileSettingsInformation();
    if (response) {
      setPaymentMethod(response?.labSettingsInfo[0]?.onlinePaymentMethod);
      setAutoApproval(response?.labSettingsInfo[0]?.appointmentAutoApproval);
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    let payload = {
      onlinePaymentMethod: PaymentMethod,
      appointmentAutoApproval: AutoApproval,
    };
    const response = await PostLabProfileSettingsInformation(payload);
    if (response.message === "Successfully Updated") {
      //message.success("Account settings updated successfully");
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content :"Account settings updated successfully",
      });
    } else {
      //message.success("Something went wrong");
      AntdCustomMessage({
        type : antdMessageTypes.ERROR,
        content : "Something went wrong",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <Card>
      <Form layout="vertical" onFinish={onFinish} name="nest-messages">
        {/* row starts here */}
        <Row gutter={{ sm: 24, lg: 24 }}>
          <Col xs={24} sm={24} lg={18}>
            <Form.Item label="Auto Approve Appointments">
              <Switch
                checked={AutoApproval === 1 ? true : false}
                onChange={() => setAutoApproval(AutoApproval === 1 ? 0 : 1)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 24, lg: 24 }}>
          <Col xs={24} sm={24} lg={18}>
            <Form.Item label="Payment Method">
              <Radio.Group
                className="primaryButtonRadio"
                options={PaymentOptions}
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
                value={PaymentMethod}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={{ sm: 24, lg: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ height: 40, float: "right" }}
            >
              Submit
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AccountSettings;
