import { PostData } from "Utils/ApiCall";
import { LabURLParser } from "Utils/urls.utils";

export const newSubscriptionOrderLab = async (payload) => {
  const response = await PostData(
    LabURLParser("/NewSubscriptionOrder"),
    payload
  );
  if (response) {
    return response.data;
  }
};
export const subscriptionUpgradeOrderLab = async (payload) => {
  const response = await PostData(
    LabURLParser("/subscriptionUpgradeOrder"),
    payload
  );
  if (response) {
    return response.data;
  }
};
export const checkCouponCode = async (payload) => {
  const response = await PostData(LabURLParser("/checkCouponCode"), payload);
  if (response) {
    return response.data;
  }
};

export const getSubscriptionHistory = async () => {
  const response = await PostData(LabURLParser("/getSubscriptionHistory"), {});
  if (response) {
    return response.data;
  }
};

export const subscriptionSuccessLab = async (payload) => {
  const response = await PostData(
    LabURLParser("/subscriptionSuccess"),
    payload
  );
  if (response) {
    return response.data;
  }
};

export const getSubscriptionHistoryDetails = async (payload) => {
  const response = await PostData(
    LabURLParser("/getSubscriptionHistoryDetails"),
    payload
  );
  if (response) {
    return response.data;
  }
};
