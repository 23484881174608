import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useRef, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import AddMedicalProcedures from "views/pages/Hospital/MedicalProcedures/AddMedicalProcedure";
import EditMedicalProcedure from "views/pages/Hospital/MedicalProcedures/EditMedicalProcedure";
import Notifications from "views/pages/Hospital/Notifications.js";
import PatientDetails from "views/pages/Hospital/Patients/PatientDetails";
import {
  HospitalRoutes,
  HospitalNoSubscriptionRoutes,
} from "Routes/HospitalRoutes";
import { 
  getHospitalSubAdminAccess
} from "Utils/Services/Hospital/MultipleAdmins";
import Cookies from "universal-cookie";
import { DecryptString } from "Utils/Constants";
import ReportsContainer from "views/pages/Hospital/Appointments/Reports";
import AddHospitalPackage from "views/pages/Hospital/HospitalPackages/AddHospitalPackage";
import EditHospitalPackage from "views/pages/Hospital/HospitalPackages/EditHospitalPackage";
import TestAndReports from "views/pages/Hospital/Appointments/Reports/PackageAppointmentReports";
import UnAuthorized from "views/pages/UnAuthorized";
import ChangePassword from "views/pages/Auth/ChangePassword";

const Hospital = (props) => {
  const mainPanel = useRef();
  let history = useHistory();
  let cookies = new Cookies();
  // const [sidebarMini, setsidebarMini] = useState(false);

  const [allowedPaths, setAllowedPaths] = useState([]);
  const [isSubAdminAccessFetched, setIsSubAdminAccessFetched] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);

  useEffect(() => {
      let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
      let UserData = cookieUserData && JSON.parse(cookieUserData);
      (UserData?.subadmin === true) && setIsSubAdmin(true);
      if (UserData?.type !== "hospital") {
        history.push("/login");
      } else {
        if (!isSubAdminAccessFetched && UserData?.subadmin) {
          fetchSubAdminAccess(UserData?.subadminId);
        }
      }
  }, [cookies, history]);

  const fetchSubAdminAccess = async (userId) => {
    try {
      setIsLoading(true);
      const response = await getHospitalSubAdminAccess({ id: userId });
      if (response?.data) {
        setAllowedPaths(response?.data);
        setIsSubAdminAccessFetched(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };


  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      // setsidebarMini(false);
    } else {
      // setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const filteredRoutes = HospitalRoutes.filter(route => allowedPaths.includes(route.path));
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/hospital") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const pathName =  window.location.pathname.slice(9,);
  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        // routes={
        //   props.SubscriptionStatus === "Not Activated" ||
        //   props.SubscriptionStatus === "Expired"
        //     ? HospitalNoSubscriptionRoutes
        //     : HospitalRoutes
        // }
        routes={!isSubAdmin ? HospitalRoutes :  isLoading ? [] : filteredRoutes}
        bgColor={"blue"}
        activeColor={"info"}
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        { 
          (isSubAdmin &&  isLoading)?(
            <div className='content w-full h-full d-flex flex-column justify-content-center align-items-center'>
              <p className='font-weight-bold h6 text-muted'> ...Loading</p>
            </div>
          ):(isSubAdmin && HospitalRoutes.find(each=>each.path === pathName)) && !allowedPaths.includes(pathName)? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName === "/add-medical-procedures" && !allowedPaths.includes("/medical-procedures")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/edit-medical-procedures") && !allowedPaths.includes("/medical-procedures")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/hospital-package") && !allowedPaths.includes("/hospital-packages")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/patient-details") && !allowedPaths.includes("/patients")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/reports") && !allowedPaths.includes("/appointments")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/test-reports") && !allowedPaths.includes("/package-appointments")) ? (
            <UnAuthorized/>
          ):(isSubAdmin && pathName.startsWith("/hospital_profile") && !allowedPaths.includes("/hospital_profile")) ? (
            <UnAuthorized/>
          ):(
            <Switch>
              {/* {getRoutes(
                props.SubscriptionStatus === "Not Activated" ||
                  props.SubscriptionStatus === "Expired"
                  ? HospitalNoSubscriptionRoutes
                  : HospitalRoutes
              )} */}
              {getRoutes(allowedPaths.length > 0 ? filteredRoutes : HospitalRoutes)}
    
              <Route
                path="/hospital/patient-details/:patientId"
                component={PatientDetails}
              />
              <Route
                path="/hospital/reports/:appointment_id"
                component={ReportsContainer}
              />
              <Route
                path="/hospital/test-reports/:appointment_id"
                component={TestAndReports}
              />
              <Route
                path="/hospital/hospital-package/add"
                component={AddHospitalPackage}
              />
              <Route
                path="/hospital/hospital-package/edit/:package_id"
                component={EditHospitalPackage}
              />
              <Route
                path="/hospital/add-medical-procedures"
                component={AddMedicalProcedures}
              />
              <Route
                path="/hospital/edit-medical-procedures/:entry_id/:procedure_id"
                component={EditMedicalProcedure}
              />
              <Route path="/hospital/notifications" component={Notifications} />
              <Route path="/hospital/change-password" component={ChangePassword} />
            </Switch>
          )
        }
       
        {props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
          <Footer fluid />
        )}
      </div>
    </div>
  );
};
export default Hospital;
