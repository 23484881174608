import React, { useState, createContext, useContext } from "react";

export const ScheduleContext = createContext();

export const ScheduleContextProvider = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState("sunday");
  const values = { isModalOpen, setIsModalOpen, selectedDay, setSelectedDay };

  return <ScheduleContext.Provider value={values} {...props} />;
};

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
