import React, { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { getHospitalNotifications } from "Utils/Services/Hospital/Notifications";
import { getLabNotifications } from "Utils/Services/Lab/Notifications";
import { getHospitalProfileGeneralInformation } from "Utils/Services/Hospital/HospitalProfile";
import { getLabProfileGeneralInformation } from "Utils/Services/Lab/LabProfile";
import DummyUser from "Images/dummy/user.png";
// import { updateSubscriptionStatus } from "Utils/Constants";
import { getPractitionerNotifications } from "Utils/Services/Practitioner/Notifications";
import { getProfileGeneralInformation, updateAvailablityStatus } from "Utils/Services/Practitioner/Profile";
import { DecryptString, EncriptString } from "Utils/Constants";
import { rejectToken } from "Utils/Services/Auth";
import { useHistory } from "react-router";
// import {
//   ACTIVATED,
//   GRACE_ACTIVATED,
//   NOT_ACTIVATED,
//   TRIAL_ACTIVATED,
// } from "views/pages/Hospital/Subscription/DummyData";

export const GlobalState = createContext();

export const GlobalStateProvider = (props) => {
  const cookies = new Cookies();
  const history = useHistory();
  let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
  let UserData = cookieUserData && JSON.parse(cookieUserData);

  const ListSize = 30;
  const token = "";

  const [Heading, setHeading] = useState("");
  const [HeadingCount, setHeadingCount] = useState("");
  const [SearchKey, setSearchKey] = useState("");
  const [TotalLoading, setTotalLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [HospitalInformation, setHospitalInformation] = useState("");
  const [LabInformation, setLabInformation] = useState("");
  const [SubscriptionData, setSubscriptionData] = useState("");
  const [SubscriptionStatus, setSubscriptionStatus] = useState("");
  const [PractitionerInformation, setPractitionerInformation] = useState({
    status: false,
    data: {},
  });
  const [ProfileName, setProfileName] = useState("");
  const [ClinicRefreshPage, setClinicRefreshPage] = useState(false);
  const [LabRefreshPage, setLabRefreshPage] = useState(false);
  const [PractitionerRefreshPage, setPractitionerRefreshPage] = useState(false);

  const FetchAccountInformation = async (type, refresh) => {
    //resetting all data
    setHospitalInformation({});
    setLabInformation({});
    setPractitionerInformation({ status: false, data: {} });

    let response;
    if (type === "hospital") {
      response = await getHospitalProfileGeneralInformation();
      response?.subscriptionDetails && setSubscriptionData(response?.subscriptionDetails);
      response?.profile && setHospitalInformation(response?.profile[0]);
      response?.profile && setProfileName(response?.profile[0]?.clinic_name);
      response?.subscriptionDetails && setSubscriptionStatus(response?.subscriptionDetails?.subscriptionStatus);
      let UpdatedUserData = {
        ...UserData,
        subscriptionStatus: response?.subscriptionDetails?.subscriptionStatus,
      };
      cookies.set("userdata", EncriptString(JSON.stringify(UpdatedUserData)), {
        path: "/",
      });

      if (refresh) {
        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }
    }

    if (type === "lab") {
      response = await getLabProfileGeneralInformation();
      response?.subscriptionDetails && setSubscriptionData(response.subscriptionDetails);
      response?.profile && setLabInformation(response?.profile[0]);
      response?.profile && setProfileName(response?.profile[0]?.lab_name);
      setSubscriptionStatus(response?.subscriptionDetails?.subscriptionStatus);
      let UpdatedUserData = {
        ...UserData,
        subscriptionStatus: response?.subscriptionDetails?.subscriptionStatus,
      };
      cookies.set("userdata", EncriptString(JSON.stringify(UpdatedUserData)), {
        path: "/",
      });

      if (refresh) {
        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }
    }
    if (type === "practitioner") {
      response = await getProfileGeneralInformation();
      response && setProfileName(
        `${response?.data?.first_name} ${response?.data?.last_name}`
      );
      response && setPractitionerInformation(response);
    }
  };

  const FetchNotifications = async (type) => {
    let response;
    if (type === "hospital") {
      response = await getHospitalNotifications({
        start: 0,
        limit: 5,
        startDate: "",
        endDate: "",
        search: "",
        visibility_status: "unread",
        userType: "clinic",
      });
    } else if (type === "lab") {
      response = await getLabNotifications({
        start: 0,
        limit: 5,
        startDate: "",
        endDate: "",
        search: "",
        visibility_status: "unread",
        userType: type,
      });
    } else if (type === "practitioner") {
      response = await getPractitionerNotifications({
        start: 0,
        limit: 5,
        startDate: "",
        endDate: "",
        search: "",
        visibility_status: "unread",
        userType: type,
      });
    }
    if (response) {
      setNotifications(
        response?.notifications?.slice(0, 5) ||
          response?.data?.notifications?.slice(0, 5) ||
          []
      );
      setNotificationsCount(
        response?.unread_notifications || response?.data?.total_count || 0
      );
    }
  };

  const SubscriptionReload = () => {
    FetchAccountInformation(UserData.type, true);
  };

  useEffect(() => {
    setHeading("Dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (UserData?.email) {
      FetchNotifications(UserData.type);
      FetchAccountInformation(UserData.type);
    }
  }, [UserData?.email]); // eslint-disable-line

  const logout = async () => {
    if(UserData?.type === "practitioner"){
      await updateAvailablityStatus({
        live_availability_status: false,
      })
    };
    UserData?.refreshToken && await rejectToken(UserData?.refreshtoken);
    cookies?.remove("userdata", { path: "/" });
    cookies?.remove("token", { path: "/" });
    history?.push("/login");
    setHospitalInformation("");
    setLabInformation("");
    setPractitionerInformation({
      status: false,
      data: {},
    })
    setAccountImage(null);
    setProfileName("");
  }

  useEffect(()=>{
    if(window?.location?.pathname?.includes("onboarding")){
      if(!cookies?.get("userdata",{path:"/"})) logout();
      else return;
    };
    if(
      !cookies?.get("userdata",{path:"/"}) || 
      ProfileName === "undefined undefined" || 
      ProfileName === "undefined"
    ){
      if(
        window?.location?.pathname === "/forgot-password" || 
        window?.location?.pathname === "/login" ||
        window?.location?.pathname === "/register" ||
        window?.location?.pathname?.startsWith("/reset-password")
      ){
        return;
      }else{
        logout();
      }  
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[UserData?.id, ProfileName]);

  useEffect(() => {
    if (UserData?.subscriptionStatus) {
      setSubscriptionStatus(UserData?.subscriptionStatus);
    } // eslint-disable-next-line
  }, [UserData?.subscriptionStatus]);

  const [AccountImage, setAccountImage] = useState(DummyUser);

  useEffect(() => {
    if (LabInformation?.email) {
      setAccountImage(LabInformation.lab_profile_image);
    }
    if (HospitalInformation?.email) {
      setAccountImage(HospitalInformation.clinic_profile_image);
    }
    if (PractitionerInformation?.status && PractitionerInformation?.data) {
      setAccountImage(
        PractitionerInformation?.data?.practitioner_profile_image
      );
    }
  }, [HospitalInformation, LabInformation, PractitionerInformation]);

  return (
    <GlobalState.Provider
      value={{
        TotalLoading,
        setTotalLoading,
        Heading,
        setHeading,
        SearchKey,
        setSearchKey,
        ListSize,
        token,
        setNotifications,
        setNotificationsCount,
        notifications,
        notificationsCount,
        FetchNotifications,
        HospitalInformation,
        LabInformation,
        FetchAccountInformation,
        AccountImage,
        setAccountImage,
        UserData,
        HeadingCount,
        setHeadingCount,
        setHospitalInformation,
        setLabInformation,
        SubscriptionData,
        setSubscriptionData,
        SubscriptionStatus,
        setSubscriptionStatus,
        SubscriptionReload,
        ProfileName,
        setProfileName,
        PractitionerRefreshPage,
        setPractitionerRefreshPage,
        ClinicRefreshPage,
        setClinicRefreshPage,
        LabRefreshPage,
        setLabRefreshPage,
      }}
    >
      {props.children}
    </GlobalState.Provider>
  );
};
