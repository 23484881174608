import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  Spin,
  Tabs,
  Card,
  Table,
  Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router";
import {
  GetHospitalPackages,
  GetPackageCategories,
  GetPackageTests,
  UpdateHospitalPackages,
  addHospitalPackageFaqs,
  deleteHospitalPackageFaqs,
  getHospitalPackageFaqs,
  updateHospitalPackageFaqs,
} from "Utils/Services/Hospital/HospitalPackages";
import { validateMessages } from "Utils/validations";
import MultipleImageUploader from "components/MultipleImageUploader";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { getReadableFileSize } from "Utils/GetReadableFileSize";

const EditHospitalPackage = (props) => {
  const packageId = props.match.params.package_id;
  const { setHeading, Heading } = useContext(GlobalState);

  const [packageDetails, setPackageDetails] = useState(null);
  const [availableTests, setAvailableTests] = useState(null);
  const [categories, setCategories] = useState(null);
  const [editorState, setEditorState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [packageGalleryFiles, setPackageGalleryFiles] = useState([]);
  const [packageImageFile, setPackageImageFile] = useState(null);
  const [packageGallery, setPackageGallery] = useState([]);

  const history = useHistory();

  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");

  // start of Faqs
  const [isAddFaq, setIsAddFaq] = useState(false);
  const [isEditFaq, setIsEditFaq] = useState(false);
  const [faqs,setFaqs] = useState([]);
  const [faq, setFaq] = useState({
    title:"",
    description:"",
  });

  const handleToggleIsAddFaq = () => {
    setIsAddFaq(prev=>!prev)
  };

  const handleToggleIsEditFaq = () => {
    setIsEditFaq(prev=>!prev);
  }

  const handleConfirmAddFaq = () => {
    setFaq({
      id: "",
      title: "",
      description: ""
    });
    handleToggleIsAddFaq();
  }

  const handleConfirmEditFaq = async (row) => {
    handleToggleIsEditFaq();
    setFaq({
      id:row.id, 
      title:row.title,
      description:row.description
    });
  }

  const FetchPackagesFaqs = async () => {
    const faqsResponse = await getHospitalPackageFaqs({id:packageId});
    faqsResponse && setFaqs(faqsResponse);
  };

  const handleAddFaq = async (values) => {
    try{
        const payload = {
            "package_id": packageId,
            "title":values.title.replace(/'/g, "''"),
            "description":values.description.replace(/'/g, "''"),
        };
        const response = await addHospitalPackageFaqs(payload);
        if(response?.status == 200){
            //message.success(response.data.message);
            AntdCustomMessage({
              type : antdMessageTypes.SUCCESS,
              content : response.data.message,
            });
            handleToggleIsAddFaq();
            FetchPackagesFaqs();
        }else{
            //message.error(response.message);
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content : response.message,
            });
        }
    }catch(e){
        //message.error(e.message || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || "Something went wrong.",
        });
    }    
  }
  
  const handleUpdateFaq = async (values) => {
    try{
        const payload = {
          "id": values.id,
          "title":values.title.replace(/'/g, "''"),
          "description":values.description.replace(/'/g, "''"),
          "active":"1",
        };
        const response = await updateHospitalPackageFaqs(payload);
        if(response?.status == 200){
            //message.success(response.data.message);
            AntdCustomMessage({
              type : antdMessageTypes.SUCCESS,
              content :response.data.message,
            });
            window.location.reload();
        }else{
            //message.error(response.message);
            AntdCustomMessage({
              type : antdMessageTypes.ERROR,
              content :response.message,
            });
        }
    }catch(e){
        //message.error(e.message || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || "Something went wrong.",
        });
    }    
  }

  const handleDeleteFaq = async (row) => {
    try{
        const deleteResponse = await deleteHospitalPackageFaqs({id: row.id});
        if(deleteResponse){
          //message.success(deleteResponse.data);
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content :deleteResponse.data,
          });
          window.location.reload();
        }
        
    }catch(e){
        //message.error(e.message || e.data || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || e.data || "Something went wrong.",
        });
    }
    
  };

  const columns = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        render: (_,row) =>{
            return row.id
        },
    },
    {
        title: 'Question',
        dataIndex: 'title',
        key: 'title',
        render: (_,row) =>{
            return row.title
        },
    },
    {
        title: 'Answer',
        dataIndex: 'description',
        key: 'description',
        render: (_,row) =>{
            return row.description
        },
    },
    {
      title: 'Edit',
      key: 'action',
      render: (_, row) => (
        <Popconfirm
          title={`Are you sure you want to edit this faq?`}
          onConfirm={() => {
            handleConfirmEditFaq(row);
          }}
          okText="Yes"
          cancelText="No"
        >
          <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
    {
        title: 'Delete',
        key: 'action',
        render: (_, row) => (
          <Popconfirm
            title={`Are you sure you want to delete this faq?`}
            onConfirm={() => handleDeleteFaq(row)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        ),
      }
]
/////end of faqs
  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Edit Health Packages");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    const fetchData = async () => {
      const hospitalTestsResponse = await GetPackageTests();
      const packageCategoriesResponse = await GetPackageCategories();
      packageCategoriesResponse?.success &&
        setCategories(packageCategoriesResponse?.data?.packageCategories);
      hospitalTestsResponse?.success &&
        setAvailableTests(hospitalTestsResponse?.data?.hospitalTests);
      const packageDetailsResponse = await GetHospitalPackages({
        package_id: +packageId,
      });
      if(packageDetailsResponse && packageDetailsResponse?.packages){
        let packageDetailsAPI = packageDetailsResponse?.packages[0];
        setPackageDetails({
          ...packageDetailsAPI,
          sample_at_home: packageDetailsAPI?.sample_at_home === "0" ? false : true,
        });
        setEditorState(packageDetailsAPI?.about_package);
      }
    };

    if (packageId) {
      fetchData();
      FetchPackagesFaqs();
    }
  }, [packageId]);

  const onFinish = async (values) => {
    setIsLoading(true);
    const response = await UpdateHospitalPackages(
      {
        package_id: +packageId,
        ...values,
        package_name: values?.package_name.replace(/'/g, "''"),
        promotion_text: values?.promotion_text.replace(/'/g, "''"),
        about_package: editorState.replace(/'/g, "''"),
        sample_at_home: values.sample_at_home ? "1" : "0",
        package_gallery: packageGallery.join(","),
      },
      packageImageFile,
      packageGalleryFiles
    );

    if (response?.success) {
      //message.success("Health package updated successfully");
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content :"Health package updated successfully",
      });
      // history.goBack();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (window?.location?.href?.includes("tab")) {
      const tab = window?.location?.href?.split("tab=")[1];
      setActiveTab(tab);
    }
  }, []);

  //save current tab id in url
  const onTabChange = (key) => {
    window.history.pushState(
      null,
      null,
      `/hospital/hospital-packages/edit/${packageId}?tab=${key}`
    );
    setActiveTab(key);
  };

  return (
    <div className="content">
      <Button onClick={() => history.push("/hospital/hospital-packages")} style={{ marginBottom: "10px" }}>
        Back
      </Button>
      <Card>
      <Tabs
          type="card"
          activeKey={activeTab}
          animated={true}
          tabBarGutter={6}
          onChange={onTabChange}
        >
          <TabPane tab="Package Info" key="1">
            <div className="procedure-container">
              {packageDetails ? (
                <Form
                  layout="vertical"
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={packageDetails}
                >
                  <Row gutter={{ sm: 8, lg: 24 }}>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["package_name"]}
                        label="Package Name"
                        rules={[{ required: true }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item name={["promotion_text"]} label="Promotion Text">
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={{ sm: 8, lg: 24 }}>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["available_tests"]}
                        label="Package Tests"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          size="large"
                          placeholder="Please select tests"
                          style={{ width: "100%" }}
                          mode="multiple"
                          getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                          {availableTests?.map((item) => (
                            <Select.Option key={item.test_id}>
                              {item.test_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Form.Item
                        name={["price"]}
                        label="Price"
                        rules={[
                          { required: true },
                          {
                            validator: (rule, value) => {
                              if (value < 0) {
                                return Promise.reject("Price can't be negative");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input size="large" type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ sm: 8, lg: 24 }}>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item name={["discount"]} label="Discount %">
                        <Input size="large" type="number" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["package_category_id"]}
                        label="Package Category"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          size="large"
                          placeholder="Please select categories"
                          style={{ width: "100%" }}
                          mode="multiple"
                          getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                          {categories?.map((item) => (
                            <Select.Option key={item.id}>
                              {item.categoryname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ sm: 8, lg: 24 }}>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["sample_at_home"]}
                        label="Sample collection at Home"
                        valuePropName="checked"
                      >
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={24}>
                      <Form.Item label="About Package">
                        <ReactQuill
                          theme="snow"
                          onChange={(value) => setEditorState(value)}
                          value={editorState}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={{ sm: 8, lg: 24 }}>
                    <Col xs={24} sm={24} lg={20}>
                      <p>Package Gallery </p>

                      <Form.Item style={{ marginTop: "5px" }}>
                        <MultipleImageUploader
                          existingImages={
                            packageDetails?.package_gallery?.length
                              ? packageDetails?.package_gallery
                              : []
                          }
                          onChange={(files) => {
                            setPackageGalleryFiles(files.newImages);
                            setPackageGallery(files.oldImages);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={{ sm: 8, lg: 24 }}>
                    <Col xs={24} sm={24} lg={20}>
                      <p className="font-weight-bold">Package Image </p>
                      <p style={{marginTop:0}}>
                        Updating file size should be between 
                        <span className="font-weight-bold text-black"> 
                          &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} 
                        </span>
                        &nbsp;and 
                        <span className="font-weight-bold text-black">
                          &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}
                        </span>.
                      </p>
                      <div style={{ display: "flex" }}>
                        {
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              marginLeft: "10px",
                              objectFit: "cover",
                            }}
                            src={
                              packageImageFile
                                ? URL.createObjectURL(packageImageFile)
                                : packageDetails?.package_image
                            }
                            alt="package"
                          />
                        }
                      </div>
                      <Form.Item style={{ marginTop: "5px" }}>
                        <input
                          name="clinic_images"
                          style={{ display: "none" }}
                          type="file"
                          accept="image/jpeg,image/png"
                          id="file-uploader2"
                          onChange={(event) => {
                            //check image size here
                            const file = event?.target?.files[0];
                            const fileSize = file?.size;
                            if(
                              fileSize < Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES) || 
                              fileSize > Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)
                            ){
                              AntdCustomMessage({
                                type : antdMessageTypes.ERROR,
                                content : `Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
                                  getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} and ${
                                  getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}.`,
                              });
                              return;
                            }else{
                              setPackageImageFile(file);
                            }
                          }}
                        />
                        <label
                          htmlFor="file-uploader2"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "white",
                            padding: "5px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            border: "1px solid #e8e8e8",
                            borderRadius: "7px",
                          }}
                        >
                          <PlusOutlined />
                          Upload image
                        </label>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Spin style={{ marginLeft: "10px" }} />
              )}
            </div>
          </TabPane>
          <TabPane tab="FAQs" key="2">
          {
          
          (isAddFaq || isEditFaq) ? (
              <Card title={`${isEditFaq ? "Update" : "Add"} Faq Form`}>
                <Form
                  layout="vertical"
                  name="nest-messages"
                  onFinish={isEditFaq ? handleUpdateFaq : handleAddFaq}
                  initialValues={faq}
                >
                  {
                    isEditFaq && faq.id && 
                    <Row gutter={{ sm: 8, lg: 24 }}>
                      <Col xs={24} sm={24} lg={12}>
                          <Form.Item
                          name={["id"]}
                          label="FaqID"
                          rules={[{ required: true }]}
                          >
                          <Input size="large" disabled/>
                          </Form.Item>
                      </Col>
                      </Row>
                  }
                  <Row gutter={{ sm: 8, lg: 24 }}>
                  <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                      name={["title"]}
                      label="Question"
                      rules={[{ required: true }]}
                      >
                      <Input size="large"/>
                      </Form.Item>
                  </Col>
                  </Row>

                  <Row>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name={["description"]}
                        label="Answer"
                        rules={[{ required: true }]}
                        >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                
                  <Row>
                    <Button type="primary" danger onClick={isEditFaq ? handleToggleIsEditFaq : handleConfirmAddFaq}>Cancel</Button>
                    <Button type="primary" style={{marginLeft:"1rem"}} htmlType="Submit">{isEditFaq ? "Update":"Add"}</Button>
                  </Row>
                </Form>
              </Card>
          ):(
            <Card>
              <div className="mb-5">
                  <Button onClick={handleToggleIsAddFaq}>Add Faq</Button>
              </div>
              {
                faqs.length ? (
                  <Card title="List of Faqs">
                    <Table columns={columns} dataSource={faqs} />
                  </Card>
                ) : null
              }
            </Card>
          )
        }
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default EditHospitalPackage;
