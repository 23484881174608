import React from "react";
import { Typography, Divider } from "antd";
import { Col, Row } from "reactstrap";
import { TableSkeletonLoader } from "Utils/Constants";
import moment from "moment";
import { getAgeFromDOB } from "Utils/GetAgeFromDOB";

const PatientsInfoCard = ({ data ,DetailsLoading}) => {
  const { Title, Text } = Typography;

  return (
    <div className="patient-details-container">
      <Title
        level={3}
        style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
      >
        Patient Details
      </Title>
      <Divider style={{ margin: "12px 0px" }} />
{DetailsLoading?TableSkeletonLoader(5,30):
<>
      <Row className="patient-info-row" style={{ fontSize: "16px" }}>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}> Patient Id </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14 }}>{data.id} </Text>{" "}
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Patient Name </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14 }} className="capitalize">
                {data.first_name + " " + data.last_name}{" "}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="patient-info-row" style={{ fontSize: "16px" }}>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Gender </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14 }}>{data.gender} </Text>
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>DOB </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14 }}>{data.dob&&(moment(data.dob,"YYYY-MM-DD")).format("DD MMM YYYY")} </Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="patient-info-row" style={{ fontSize: "16px" }}>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Email Address </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>{data.email&&data.email!=="undefined"?data.email:""} </Text>{" "}
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}> Mobile </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "##3b3b3b" }}>{data.mobile}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="patient-info-row" style={{ fontSize: "16px" }}>
      <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Age </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>{data.dob && getAgeFromDOB(data.dob)} </Text>
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Blood Group </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>{data.blood_group} </Text>
            </Col>
          </Row>
        </Col>
        
      </Row>

      <Row className="patient-info-row" style={{ fontSize: "16px" }}>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Weight </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>{data.weight} </Text>
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Height </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>{data.height} </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="patient-info-row" style={{ fontSize: "16px" }}>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Allergic Conditions: </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>
                {data.allergic_conditions}{" "}
              </Text>{" "}
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Row>
            <Col lg={4}>
              <Text strong   style={{ fontSize: 14 }}>Medical Conditions </Text>{" "}
            </Col>
            <Col lg={8}>
              <Text style={{ color: "#3b3b3b",fontSize: 14  }}>
                {data.medical_conditions}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      </>
}
    </div>
  );
};

export default PatientsInfoCard;
