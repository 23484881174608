import { Button, Modal } from "antd";
import React, { useState } from "react";
import ActivateSubscription from "../actions/ActivateSubscription";

const NotActivated = ({ FetchBillingHistory, SubscriptionData }) => {
  const [ActivateModal, setActivateModal] = useState(false);
  return (
    <>
      <Modal
        className="subscription-modal"
        title="Subscription Activation"
        visible={ActivateModal}
        footer={null}
        width={700}
        onCancel={() => setActivateModal(false)}
        destroyOnClose={true}
      >
        <ActivateSubscription
          FetchBillingHistory={FetchBillingHistory}
          SubscriptionData={SubscriptionData}
        />
      </Modal>
      <div className="subscription-content">
        <h5>No Subscription Found!</h5>
        <p>
          You have not yet started any subscription plan.<br></br>
          Activate the subscription and get{" "}
          <b style={{ fontWeight: "bold" }}>Free trial of 30 days.</b>
        </p>
        <Button
          type="primary"
          style={{ marginTop: 15 }}
          onClick={() => setActivateModal(true)}
        >
          Activate Subscription
        </Button>
      </div>
    </>
  );
};

export default NotActivated;
