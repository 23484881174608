import React,{useEffect,useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getDateInteger} from "Utils/Constants";
import { conditionallyUpdateScrollbar } from "reactstrap/lib/utils";

const RevenuesChart = ({RevenuesChartData}) => {
    const [Data, setData] = useState([]);
    useEffect(() => {
        const AllignData =async ()=>{
          const Counts = RevenuesChartData.revenuecountArr;
          const Dates = RevenuesChartData.dateArr;
      
          let RevenueData = []
      
          await Dates.forEach((date,index)=>{
              RevenueData.push([getDateInteger(date),Counts[index]])
          })
          setData(RevenueData)
        }
        if(RevenuesChartData){
        AllignData()
        }
    }, [RevenuesChartData])

  const options = {
    chart: {
      type: "areaspline",
      height: 300,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        overflow: "justify",
      },
      gridLineColor: "#f3f3f3",
      gridLineDashStyle: "longdash",
    },
    tooltip: {
      pointFormat: "Revenue : {point.y}",
     
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    series: [
      {
        data: Data,
      },
    ],
    time:{
      useUTC:false
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default RevenuesChart;
