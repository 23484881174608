import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Typography, Table, Modal, DatePicker, Card } from "antd";
import { PlusOutlined, DeleteFilled } from "@ant-design/icons";
import columnResponsiveWrapper from "Utils/columnResponsiveWrapper";
import { nanoid } from "nanoid";
import { message } from "antd";
import { addPrescription } from "Utils/Services/Practitioner/Appointments";
import AddPrescriptionModal from "./AddPrescriptionModal";
import PrescriptionTableColumns from "./PrescriptionTableColumns";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const Prescription = ({ data, history, options = {}, appointmentId = "" }) => {
  const [form] = Form.useForm();
  const [medicineData, setMedicineData] = useState([]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { TextArea } = Input;

  const handleDeleteClick = (id) => {
    if (!id){
      //message.error("Can't delete already saved record");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Can't delete already saved record"
      });
    } 
    const filteredData = medicineData.filter((item) => item.id !== id);
    filteredData && setMedicineData(filteredData);
  };

  const onMedicineAdd = (values) => {
    // console.log("on medicine add", values);
    const {
      medicine_name,
      type,
      concentration,
      composition,
      dose,
      unit,
      route,
      frequency,
      directions,
      duration,
    } = values;
    setMedicineData((prevState) => [
      ...prevState,
      {
        medicine_name,
        type,
        concentration,
        composition,
        dose,
        unit,
        route,
        frequency,
        directions,
        duration,
        id: nanoid(),
      },
    ]);
    form.setFieldsValue({
      medicine_name: "",
      type:"",
      concentration:"",
      composition:"",
      dose: "",
      unit: "",
      route: "",
      frequency: "",
      directions: "",
      duration: "",
    });
    // form.resetFields();

    setShowModal(false);
  };

  const handlePrescriptionSubmit = async (values) => {
    if(
      Object.values(form.getFieldsValue()).every(each=>each==="") || 
      form.getFieldValue('observation')==="" || 
      form.getFieldValue('clinicalFindings')==="" || 
      form.getFieldValue('advice')===""
    ){
      //message.error("Please fill all the required fields.")
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Please fill all the required fields."
      });
      return;
    }
    setIsBtnLoading(true);
   
    // if(!medicineData.length){
    //   setIsBtnLoading(false);
    //   message.error("Please add atleast one new record in prescription.")
    //   return;
    // }
    let payload = {
      appointment_id: appointmentId,
      observation: form.getFieldValue("observation"),
      clinical_findings:form.getFieldValue("clinicalFindings"),
      diagnosis:form.getFieldValue("diagnosis"),
      advice:form.getFieldValue("advice"),
      follow_up:form.getFieldValue('followUp'),
      bp:form.getFieldValue("bp"),
      prescription_array: medicineData.length?medicineData.map((item) => {
        return {
          medicine_name: item.medicine_name,
          type:item.type,
          concentration:item.concentration,
          composition:item.composition,
          dose: Number(item.dose),
          unit: Number(item.unit),
          route: Number(item.route),
          freequency: Number(item.frequency),
          directions: Number(item.directions),
          duration: Number(item.duration),
        };
      }):null,
    };
   
    const response = await addPrescription(payload);
    if (response?.status) {
      //message.success("Prescription added successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Prescription added successfully"
      });
    }
    else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
      });
    }
    form.resetFields();
    setMedicineData([]);
    window.location.reload(true);
    setIsBtnLoading(false);
  };

  return (
    <>
      <Card
          style={{ width: "100%", padding: "10px" }}
          bodyStyle={{ padding: "0px 0px 10px" }}
        >
          <h4 style={{fontWeight:"bold"}}>Appointment History</h4>
          <div className="details-wraper" style={{alignItems:"start"}}>
          <label style={{ fontSize: 14 }}>Problems</label>
          <h4 className="text" style={{ width:'100em',fontSize: 14, overflowX:"scroll" }}>
              {history.problems}
          </h4>
          </div>
          <div className="details-wraper" style={{alignItems:"start"}}>
          <label style={{ fontSize: 14 }}>Symptoms</label>
          <h4 className="text" style={{ width:'100em',fontSize: 14, overflowX:"scroll" }}>
              {history.symptoms}
          </h4>
          </div>
          <div className="details-wraper" style={{alignItems:"start"}}>
          <label style={{ fontSize: 14 }}>Existing Medical Conditions</label>
          <h4 className="text" style={{ width:'100em',fontSize: 14, overflowX:"scroll"}}>
              {history.existingMedicalConditions}
          </h4>
          </div>
          <div className="details-wraper" style={{alignItems:"start"}}>
          <label style={{ fontSize: 14 }}>Allergies</label>
          <h4 className="text" style={{ width:'100em',fontSize: 14, overflowX:"scroll" }}>
              {history.allergies}
          </h4>
          </div>
          <div className="details-wraper" style={{alignItems:"start"}}>
          <label style={{ fontSize: 14 }}>Current Medications using</label>
          <h4 className="text" style={{ width:'100em',fontSize: 14, overflowX:"scroll" }}>
              {history.currentMedications}
          </h4>
          </div>
          <div className="details-wraper" style={{alignItems:"start"}}>
          <label style={{ fontSize: 14 }}>Previous Surgeries</label>
          <h4 style={{ width:'100em',fontSize: 14, overflowX:"scroll" }} className="text">
              {history.previousSurgeries}
          </h4>
          </div>
        </Card>
      <Form
        layout="vertical"
        name="observations"
        // validateMessages={validateMessages}
        onFinish={handlePrescriptionSubmit}
        initialValues={data}
        form={form}
      >
        <Row>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              label={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  B.P
                </Typography.Title>
              }
              name="bp"
            >
              <Input placeholder={"Enter patient's Blood Pressure"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 10, lg: 24 }}>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Observations/Chief Complaints
                </Typography.Title>
              }
              name="observation"
              rules={[{required:true,message:"Please fill observation"}]}
              
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              label={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Clinical Findings
                </Typography.Title>
              }
              name="clinicalFindings"
              rules={[{required:true,message:"Please fill clinical findings"}]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              label={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Diagnosis
                </Typography.Title>
              }
              name="diagnosis"
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
          
        </Row>
        <Row>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              label={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Advice
                </Typography.Title>
              }
              name="advice"
              rules={[{required:true,message:"Please fill advice"}]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={8} sm={8}>
            <Form.Item
              label={
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Follow Up on:
                </Typography.Title>
              }
              name="followUp"
            >
              
              <DatePicker value={data.followUp}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        className="item-details-modal"
        title="Hospital Details"
        visible={showModal}
        footer={null}
        width={700}
        onCancel={() => setShowModal(false)}
      >
        <AddPrescriptionModal
          onFinish={onMedicineAdd}
          options={options}
          form={form}
          data
        />
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "8px",
          flexWrap:'wrap'
        }}
      >
        <Typography.Title level={4} style={{ margin: 0,marginBottom:8 }}>
          Prescriptions
        </Typography.Title>
        {data.appointmentStatus !== "checkout" ||
        data.appointmentStatus === "completed" ? (
          <Button
            onClick={() => setShowModal(true)}
            style={{ height: 40, padding: "0px 20px" }}
            icon={<PlusOutlined />}
            size="small"
            type="secondary"
          >
            Add New Medicine to Prescription
          </Button>
        ) : null}
      </div>
      <Table
        rowKey={nanoid()}
        dataSource={[...data.prescription, ...medicineData]}
        columns={columnResponsiveWrapper(
          PrescriptionTableColumns(
            options,
            data?.appointmentStatus,
            handleDeleteClick
          )
        )}
      />
      <Button
        size="small"
        type="primary"
        style={{
          float: "right",
          marginTop:8,
          height: 40, 
          padding: "0px 20px"
        }}
        loading={isBtnLoading}
        onClick={() => handlePrescriptionSubmit()}
      >
        Save Prescription
      </Button>
    </>
  );
};

export default Prescription;
