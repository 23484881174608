import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Empty, Input, List, Modal, Select, Col, Row } from "antd";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRanges, DATE_FORMAT, TableSkeletonLoader } from "Utils/Constants";
import { useAppointments } from "./useAppointments";
import AppointmentCard from "./AppointmentCard";
import DetailsCard from "./AppointmentDetails";
import { getStatusTypes } from "Utils/Services/Practitioner/Appointments";
import RescheduleCard from "views/pages/Practitioner/Appointments/RescheduleCard";
// import RescheduleCard from "./RescheduleCard/index.js";

const PractitionerAppointments = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const {
    selectedAppointmentId,
    selectedStatusType,
    setSelectedStatusType,
    FetchData,
    Data,
    appointmentsPerPage,
    setAppointmentsPerPage,
    searchValue,
    setSearchValue,
    DataLoading,
    totalAppointmentsCount,
    pageNo,
    setPageNo,
    showRescheduleModal,
    setShowRescheduleModal,
    showDetailsModal,
    setShowDetailsModal,
    selectedPractitionerId,
  } = useAppointments();

  const { Option } = Select;

  const [date, setDate] = useState([null, null]);
  const { RangePicker } = DatePicker;

  const [appointmentStatusTypes, setAppointmentStatusTypes] = useState([]);

  useEffect(() => {
    FetchData(date[0], date[1]);
  }, [date, pageNo, selectedStatusType, searchValue, appointmentsPerPage]); // eslint-disable-line

  const fetchAppointmentStatusTypes = async () => {
    setAppointmentStatusTypes((await getStatusTypes()).statusTypes);
  };

  //fetching appointment status types
  useEffect(() => {
    fetchAppointmentStatusTypes();
  }, []); // eslint-disable-line

  //setting page heading
  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Appointments");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalAppointmentsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalAppointmentsCount]);

  let { appointments = [] } = Data.data || {};
  return (
    <div className="content">
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Hospital Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setShowDetailsModal(false)}
      >
        <DetailsCard />
      </Modal>
      <Modal
        className="item-details-modal"
        title="Reschedule Details"
        visible={showRescheduleModal}
        footer={null}
        width={500}
        onCancel={() => {
          setShowRescheduleModal(false);
        }}
        destroyOnClose={true}
      >
        <RescheduleCard
          practitioner_id={selectedPractitionerId}
          appointment_id={selectedAppointmentId}
          FetchData={FetchData}
          setShowRescheduleModal={setShowRescheduleModal}
        />
      </Modal>

      <div className="appointments-container">
        <Row
          className="appointments-page-header"
          gutter={[0, 10]}
          justify="space-between"
          style={{ marginBottom: "10px" }}
        >
          <Col lg={9} md={8} sm={12} xs={24}>
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              // disabledDate={(current) =>
              //   current && current > moment().endOf("day")
              // }
            />
          </Col>

          <Col lg={6} md={8} sm={12} xs={24}>
            <Select
              style={{ width: "100%" }}
              defaultValue="All Status"
              size="medium"
              className="shadow-select"
              onChange={(value) => setSelectedStatusType(value)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Option value="">All Status</Option>
              {appointmentStatusTypes &&
                appointmentStatusTypes.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24}>
            <Input
              className="shadow-input"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>

        {DataLoading ? (
          TableSkeletonLoader(10, 60)
        ) : appointments?.length ? (
          <List
            className="appointments-page"
            dataSource={appointments}
            pagination={{
              current: pageNo,
              total: totalAppointmentsCount,
              onChange: (page) => {
                setPageNo(page);
              },
              pageSize: appointmentsPerPage,
              onShowSizeChange: (current, size) => {
                setAppointmentsPerPage(size);
              },
              pageSizeOptions: ["12", "24", "36", "48", "60"],
            }}
            renderItem={(appointment) => (
              <List.Item>
                <AppointmentCard
                  appointment={appointment}
                  FetchData={FetchData}
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No Appointments Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};
export default PractitionerAppointments;
