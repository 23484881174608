import { GetData } from "../../ApiCall";
import axiosApiInstance from "Utils/ApiInstance";

export const postAddLabMultipleAdmins = async (payload) => {
  try{
    const response = await axiosApiInstance
      .post(
        '/api/v1/lab/create/subAdmin',
        payload
      );
    if(response?.status== 403){
      return {
        success:false,
        message: "Dont have access to add admin."
      }
    }
    if(response?.status == 200 && response?.data?.affectedRows == 1){
      return {
        success:true, 
        message:"Admin added successfully."
      }
    }
    return {
      success:false, 
      message: "Something went wrong."
    };
  }catch(e){
    return {
      message : e?.response?.data?.message ||
        e?.response?.data?.error || 
        e?.response?.data?.errors[0] || 
        e?.error || 
        e?.message || 
        "Something went wrong. Or email used is already taken."
    }
  }
  
};

export const getLabSubAdmins = async () => {
  try{
    const response = await GetData('/api/v1/lab/get/SubAdmins');
    if (response) {
      return {
        success: true,
        data: response?.data,
      }
    }else{
      return {
        success: false,
        data:[],
      }
    }
  }catch(e){
    return {
      success: false,
      data: [],
    }
  }  
}

export const deleteLabSubAdmin = async (payload) => {
  try{
    const response = await axiosApiInstance
      .delete(`/api/v1/lab/deleteSubAdmin/${payload.id}`);
    if(response?.status == 200 && response?.data.status === 200){
      return {
        success : true,
        data: response?.data?.data
      }
    }else{
      return {
        success : false,
        data: response?.data?.data
      }
    }
  }catch(e){
    return {
      success: false,
      message : e?.response?.data?.message ||
        e?.response?.data?.error || 
        e?.response?.data?.errors[0] || 
        e?.error || 
        e?.message || 
        "Something went wrong."
    }
  }
}

export const postUpdateLabSubAdminAccess = async (payload) => {
  try{
    const response = await axiosApiInstance
      .post(
        "/api/v1/lab/subAdmins/updateAccess",
        payload
      );

    if(response?.status === 200 && response?.data?.status === 200){
      return {
        success: true,
        data: response?.data?.data
      }
    }else{
      return {
        success: false,
        message: "Something went wrong."
      }
    };
  }catch(e){
    return {
      success: false,
      message : e?.response?.data?.message ||
        e?.response?.data?.error || 
        e?.response?.data?.errors[0] || 
        e?.error || 
        e?.message || 
        "Something went wrong."
    }
  }  
};
export const postUpdateLabSubAdminRole = async (payload) => {
  try{
    const response = await axiosApiInstance
      .post(
        "/api/v1/lab/subAdmins/updateRole",
        payload
      );

    if(response?.status == 200 && response?.data?.status === 200){
      return {
        success:true,
        data: response?.data?.data
      }
    }else{
      return {
        success: false,
        message: response?.data?.message || "Something went wrong."
      }
    };
  }catch(e){
    return {
      message : e?.response?.data?.message ||
        e?.response?.data?.error || 
        e?.response?.data?.errors[0] || 
        e?.error || 
        e?.message || 
        "Something went wrong."
    }
  }  
};

export const getLabSubAdminAccess = async (payload) => {
  // try{
  //   const response = await axiosApiInstance
  //     .get(`/api/v1/lab/getSubAdminAccess/${payload.id}`);
  //   if(response?.status == 200){
  //     return response?.data
  //   }
  //   return {
  //     success:"false",
  //     message:response?.data || "Something went wrong!",
  //   }
  // }catch(e){
  //   return {
  //     success: false,
  //     message : e?.response?.data?.message ||
  //       e?.response?.data?.error || 
  //       e?.response?.data?.errors[0] || 
  //       e?.error || 
  //       e?.message || 
  //       "Something went wrong."
  //   }
  // }
  
  const response = await axiosApiInstance
      .get(`/api/v1/lab/getSubAdminAccess/${payload.id}`);
    if(response?.status == 200){
      return response?.data
    }
    return {
      success:"false",
      message:response?.data || "Something went wrong!",
    }
}