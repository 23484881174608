import { Button, Card, Tabs, Empty, Input} from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import EllipsisText from "react-ellipsis-text";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { AppointmentStatusFormatter, getDateTimeFormat } from "Utils/Constants";
import { getPatientAppointmentDetails, shareAppointmentDetailsClinic } from "Utils/Services/Hospital/Appointments";
import { getRefundData, getRefundIdDetails } from "Utils/Services/Practitioner/Appointments";
import PatientUploadedReportsTab from "views/pages/Practitioner/Appointments/AppointmentDetails/PatientUploadedReports";
import { CloseOutlined } from '@ant-design/icons';
import { getAgeFromDOB } from "Utils/GetAgeFromDOB";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { nanoid } from "nanoid";

const { TabPane } = Tabs;

const DetailsCard = ({ appointmentId }) => {
  const [data, setData] = useState("");
  const [displayPdf, setDisplayPdf] = useState(false);
  const [refundData, setRefundData] = useState({});
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [emails, setEmails] = useState([
    {
      id: nanoid(),
      email: "",
    },
  ]);

  const handleAddEmail = () => {
    setEmails([
      ...emails,
      {
        id: nanoid(),
        email: "",
      },
    ]);
  };
  const handleRemoveEmail = (id) => {
    setEmails(emails.filter((email) => email.id !== id));
  };
  const handleSubmitShare = async () => {
    setIsBtnLoading(true);
    let emailsArray = [];
    emails.forEach((item) => {
      if (item?.email) {
        emailsArray.push(item?.email);
      }
    });
    let payload = {
      appointment_id: appointmentId,
      emails: emailsArray,
    };
    if(emailsArray.length ){
      try{
        const response = await shareAppointmentDetailsClinic(payload);
        if (response?.status) {
          //message.success("Successfully shared appointment details");
          AntdCustomMessage({
            type: antdMessageTypes.SUCCESS,
            content: "Successfully shared appointment details"
          });
        } else {
          //message.error("Something went wrong");
          AntdCustomMessage({
            type: antdMessageTypes.ERROR,
            content: "Something went wrong"
          });
        }
        setEmails([{
          id: nanoid(),
          email: "",
        }]);
      }catch(error){
        //message.error("Sometthing went wrong")
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: "Something went wrong"
        });
      }
    }else{
      //message.error("Please add atleast one email")
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Please add atleast one email"
      });
    }
    
    setIsBtnLoading(false);
  };
  const handleCloseRefundDetails = ()=>{
    setRefundData({});
  }
  const handleShowRefundDetails = async (id) => {
    setRefundData({});
    const refundIdData = await getRefundIdDetails(id);
    if(refundIdData?.status && !refundIdData?.data?.refundId){
      //message.error("Not raised any refund for this appointment!");
      AntdCustomMessage({
        content : "Not raised any refund for this appointment!",
        type : antdMessageTypes.ERROR,
      });
      return;
    }
    const resp = await getRefundData(id);
    setRefundData(resp.data); 
  };

  const GetReportDownloadButton = (report) => {
    let reports = report.report || [];
    let buttons = [];
    reports.forEach((element,index) => {
      let entries = Object.entries(element);
      buttons.push(
        <Button
          //set key as element object key
          key={`report${index}-${entries[0][0]}`}
          onClick={() => {
            window.open(entries[0][1]);
          }}
          style={{ marginRight: "5px" }}
        >
          {`Download ${entries[0][0]}`}
        </Button>
      );
    });
    return buttons.map((btn) => btn);
  };

  const onTabChange = (key) => {
    if(key === "3" || key === "4"){
      setTimeout(()=>{setDisplayPdf(true)},500)
    }else{
      setDisplayPdf(false);
    }
  }

  useEffect(() => {
    const getData = async () => {
      getPatientAppointmentDetails(appointmentId).then((response) => {
        setData(response);
      });
    };
    if (appointmentId) {
      getData();
    }
  }, [appointmentId]);

  const AppointmentTab = () => {
    return (
      <div className="general-info">
        <Row lg="9" md="12" sm="12" className="column-items">
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Appointment date
              </h4>
              <h4 className="text-black" style={{ fontSize: 16 }}>
                {data?.appointment_date ? data.appointment_date : ""}
              </h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 14 }}>
                Appointment time
              </h4>
              <h4 className="text-black" style={{ fontSize: 14 }}>
                {data?.appointment_time ? data.appointment_time : ""}
              </h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 14 }}>
                Appointment type
              </h4>
              <h4 className="text-black" style={{ fontSize: 14 }}>
                {data?.appointment_type ? data.appointment_type : ""}
              </h4>
            </div>
          </Col>
        </Row>
        <Card
          style={{ width: "100%", padding: "10px" }}
          bodyStyle={{ padding: "0px 0px 10px" }}
        >
          <div className="details-wraper">
            <label style={{ fontSize: 14 }}>Practitioner name</label>
            <h4 className="text" style={{ fontSize: 14 }}>
              {data?.practitioner_name ? data.practitioner_name : ""}
            </h4>
          </div>
          <div className="details-wraper">
            <label style={{ fontSize: 14 }}>Booked on</label>
            <h4 className="text" style={{ fontSize: 14 }}>
              {data?.booked_on ? getDateTimeFormat(data?.booked_on) : ""}
            </h4>
          </div>
          {
          data?.relation_name ? (
            <>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Booked For</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.patient_relation_name }
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Relation</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.relation_name }
                </h4>
              </div>
            </>
          ):null
        }
          <div className="details-wraper">
            <label style={{ fontSize: 14 }}>Appointment status</label>
            <h4 className="text" style={{ fontSize: 14 }}>
              {data?.appointment_status
                ? AppointmentStatusFormatter(data.appointment_status)
                : ""}
            </h4>
          </div>
          {
          data?.appointment_status === "cancelled" &&  (
          <div className="details-wraper" style={{alignItems:"center"}}>
            <label style={{ fontSize: 14 }}>Check Refund Details</label>
            {
              refundData.status ? (
                <Card>
                  <div className="d-flex justify-content-end">
                    <CloseOutlined 
                      style={{marginLeft:"auto",cursor:"pointer"}} 
                      onClick={handleCloseRefundDetails}
                    />
                  </div>
                  <div className="details-wraper">
                    <label style={{ fontSize: 14 }}>Amount :</label>
                    <h4 className="text" style={{ fontSize: 14 }}>
                      {refundData.amount}
                    </h4>
                  </div>
                  <div className="details-wraper">
                    <label style={{ fontSize: 14 }}>Status :</label>
                    <h4 className="text" style={{ fontSize: 14 }}>
                      {refundData.status}
                    </h4>
                  </div>
                </Card>
                ):(
                <Button 
                  type="primary" 
                  onClick={()=>{handleShowRefundDetails(appointmentId)}}
                >
                  Check Refund Status
                </Button>
            )}
          </div>
        )}
          <div className="details-wraper">
            <label style={{ fontSize: 14 }}>Online payment</label>
            <h4 className="text" style={{ fontSize: 14 }}>
              {data?.online_amount && data.online_amount}
            </h4>
          </div>
          <div className="details-wraper">
            <label style={{ fontSize: 14 }}>Offline payment</label>
            <h4 className="text" style={{ fontSize: 14 }}>
              {data?.offline_payment
                ? data?.offline_payment !== "undefined"
                  ? data.offline_payment
                  : ""
                : ""}
            </h4>
          </div>
          <div className="details-wraper">
            <label style={{ fontSize: 14 }}>Doctor observation</label>
            <h4 style={{ fontSize: 14 }} className="text">
              {data?.doctor_observation ? data.doctor_observation : ""}
            </h4>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <span className="appointment-id" style={{ fontSize: 16 }}>
          {data?.appointment_id}
        </span>
        {
          data?.token ? (
            <span 
              className="appointment-type" 
              style={{ 
                fontSize: 16, 
                border:"2px solid rgba(225,225,225,0.5)",
                padding: "2px 5px",
                borderRadius: "5px",
                margin: "15px 5px"
              }}
            >
              TOKEN-{data?.token}
            </span>
          ) : null
        }
        <h4 className="mt-2">
          <EllipsisText
            style={{ fontSize: 16, fontWeight: "bold" }}
            className="capitalize"
            text={data?.patient_name ? data.patient_name : " "}
            length={50}
          />
        </h4>
        {data.dob && data.dob !== "" && (
          <div className="dob-wrapper">
            <span className="label">DOB:</span>
            <span className="dob">{data.dob}</span>
          </div>
        )}
        {data.dob && data.dob !== "" && (
          <div className="dob-wrapper">
            <span className="label">Age:</span>
            <span className="dob">{data?.dob && getAgeFromDOB(data?.dob)}</span>
          </div>
        )}
      </div>
      <div className="details-tab-container tab-style">
        <Tabs type="card" defaultActiveKey="1" onChange={onTabChange}>
          <TabPane tab="General" key="1">
            <AppointmentTab />
          </TabPane>
          <TabPane tab="Appointment History" key="2">
            <div className="general-info">
            <Card
              style={{ width: "100%", padding: "10px" }}
              bodyStyle={{ padding: "0px 0px 10px" }}
            >
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Problems</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.problems ? data.problems : ""}
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Symptoms</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.symptoms ? data.symptoms : ""}
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Existing Medical Conditions</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.existing_medical_conditions ? data.existing_medical_conditions : "" }
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Allergies</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.allergies && data.allergies}
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Current Medications using</label>
                <h4 className="text" style={{ fontSize: 14 }}>
                  {data?.current_medications ? data?.current_medications : ""}
                </h4>
              </div>
              <div className="details-wraper">
                <label style={{ fontSize: 14 }}>Previous Surgeries</label>
                <h4 style={{ fontSize: 14 }} className="text">
                  {data?.previous_surgeries ? data.previous_surgeries : ""}
                </h4>
              </div>
            </Card>
            </div>
          </TabPane>
          {
            !data?.relation_name ? (
              <TabPane tab="Patient Uploaded Reports" key="3">
                <PatientUploadedReportsTab patientId={data.patient_id || ""}/>
              </TabPane>
            ):null
          }
          <TabPane tab="Prescription" key="4">
            <div className="prescription-container">
              {
                data?.prescription_url ? displayPdf && <iframe
                    title="PDF Viewer"
                    width="100%"
                    height="600px"
                    src={data?.prescription_url}
                    frameBorder="0"
                    scrolling="auto"
                  ></iframe> : <h1>
                  {data?.prescription ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: data.prescription }}
                    />
                  ) : (
                    <Empty
                      description="No Prescriptions Found"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                </h1>
              }
            </div>
          </TabPane>
          <TabPane tab="Report" key="5">
            <div className="report-container">
              {
                data?.report_text && 
                <>
                  <div className="p-3">
                    <p>{data.report_text}</p>
                  </div>
                  <hr/>
                </>
              }
              {data?.report ? (
                <GetReportDownloadButton report={data.report} />
              ) : (
                <Empty
                  description="No Reports Found"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>
          </TabPane>
          <TabPane tab="Share" key="6">
          <div className="flex d-col">
            <Button
              style={{ alignSelf: "flex-end", width: "fit-content" }}
              onClick={handleAddEmail}
            >
              Add
            </Button>
            <div className="flex d-col gap-1 mt-2">
              {emails.map((item) => (
                <div className="flex d-row items-center" key={`ROW_${item.id}`}>
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Enter Email"
                    value={item?.email}
                    onChange={(e) => {
                      item.email = e.target.value;
                      setEmails([...emails]);
                    }}
                  />
                  <CloseCircleFilled
                    style={{ fontSize: "18px", color: "#ff4040", marginLeft: 15 }}
                    onClick={() => handleRemoveEmail(item.id)}
                  />
                </div>
              ))}
              <Button
                type="primary"
                style={{ width: "fit-content" }}
                onClick={handleSubmitShare}
                loading={isBtnLoading}
              >
                Share
              </Button>
            </div>
          </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default DetailsCard;
