import React, { useEffect, useState } from "react";
import {
  UpOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getWorkHourSlots } from "Utils/Services/Hospital/Appointments";
import { getLeaveDays } from "Utils/Services/Hospital/LeaveDays";
import moment from "moment";
import { Spin, Col, Form, Select } from "antd";
import { nanoid } from "nanoid";

const ConsultationTypesCard = ({ practitioner_id, triggerChange, form }) => {
    const Today = moment(new Date()).format("YYYY-MM-DD");
    const [date, setDate] = useState(Today);
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentTypes, setAppointmentTypes] = useState([]);

    useEffect(() => {
        const FetchSlots = async () => {
            setIsLoading(true);
            const response = await getWorkHourSlots({
                practitioner_id: +practitioner_id?.replace("SDP", "") || "",
                date: moment(date).format("YYYY-MM-DD") || Today,
            });
            response && setAppointmentTypes(response.appointmentTypes);
            form && form.resetFields(["booking_type_id"]); // Reset only the "booking_type_id" field
            setIsLoading(false);
        };
        FetchSlots();
        // eslint-disable-next-line
    }, [practitioner_id]);

    useEffect(() => {
        triggerChange &&  triggerChange(null);
        form && form.resetFields(["booking_type_id"]); 
        // eslint-disable-next-line
    }, [practitioner_id]);


    return (
        <div className="w-100">
            {practitioner_id && appointmentTypes && (
                <Col xs={24} sm={24} lg={24}>
                    <Form.Item
                        name="booking_type_id"
                        label="Appointment Type"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select Appointment Type"
                            optionFilterProp="children"
                            size="large"
                            onChange={(val) => {
                            triggerChange && triggerChange(val)
                            }}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                            {appointmentTypes?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item?.type}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )}
        </div>
    );
};

export default ConsultationTypesCard;
