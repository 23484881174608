import React, { useState } from "react";
import { Form, Select, Row, Col, Button, AutoComplete, Input } from "antd";
import { getMedicineNames } from "Utils/Services/Practitioner/Appointments";

const AddPrescriptionModal = ({ options = {}, form, onFinish = () => {} }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [medicines, setMedicines] = useState(options.medicines || []);
  const [types,setTypes] = useState([
    "Tablet","Syrup","Injection","Ointment", "Spansules",
    "Capsule", "Drops","Inhaler","Solution",
    "Gel","Spray","Cream","Lotion","Powder","Suppositories","Supplements"
  ]);
  const onSearch = async (value) => {
    if (!value) return;
    const med = await getMedicineNames({medicine_name:value});
    if(med?.status===true){
      setMedicines(med.data.medicine)
    }
    // let filtered = options.medicines.filter(
    //   (item) => item.value.toLowerCase().indexOf(value.toLowerCase()) > -1
    // );
    // setMedicines(filtered);
  };
  
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={()=>{}}
      name="prescription"
      className="p-3"
    >
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            name="medicine_name"
            label="Medicine"
            rules={[{ required: true }]}
          >
            <AutoComplete
              options={medicines}
              placeholder="Medicine"
              onChange={(value) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  medicine_name: value,
                });
              }}
              onSearch={onSearch}
              value={form.getFieldValue("medicine_name")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item name="type" label="Type of Medicine" rules={[{ required: true }]}>
          <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="tablet, injection, syrup"
            >
              {types.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item name="concentration" label="Concentration(in mcg, mg,...any)" rules={[{ required: true }]}>
                <Input placeholder="Enter Concentration specify unit also"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item name="composition" label="Drug Composition" rules={[{ required: true }]}>
                <TextArea placeholder={"Enter drug composition with its composition in units."} rows={3}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item name="dose" label="Dose" rules={[{ required: true }]}>
            <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.dose.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item label="Unit" name="unit" rules={[{ required: true }]}>
            <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.unit.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item label="Route" name="route" rules={[{ required: true }]}>
            <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.route.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="Frequency"
            name="frequency"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.frequency.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="Directions"
            name="directions"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.directions.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            name="duration"
            label="Durations"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {options.duration.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Button 
        type="primary" 
        className="mb-2" 
        htmlType="button"
        onClick={()=>{onFinish(form.getFieldsValue())}}
      >
        Add Medicine to List
      </Button>
    </Form>
  );
};

export default AddPrescriptionModal;
