import React from "react";
import { Button, Col, Form, Input, Row, Table, Empty } from "antd";
import { useProfile } from "../../useProfile";
import columnResponsiveWrapper from "Utils/columnResponsiveWrapper";

const EducationSection = () => {
  const { data, setData } = useProfile();
  const { education = [] } = data.data || {};
  const [form] = Form.useForm();

  const deleteEducation = (row) => {
    const newData = education.filter((item, index) => index !== row.key);
    setData({ ...data, data: { ...data.data, education: newData } });
  };

  const addEducation = (values) => {
    let { university = "", course = "", description = "" } = values;
    setData((prevState) => {
      let { education = [] } = prevState.data;
      education.push({
        university,
        course,
        description: description,
      });
      return { ...prevState, data: { ...prevState.data, education } };
    });
    form.resetFields();
  };

  const columns = [
    { title: "University", dataIndex: "university", key: "university" },
    { title: "Course", dataIndex: "course", key: "course" },
    { title: "Year", dataIndex: "description", key: "description" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <Button danger size="small" onClick={() => deleteEducation(record)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="bg-gray p-6 rounded-3 gap-2 mt-2">
      {/* selected section starts here */}
      <Row
        xs={24}
        sm={24}
        lg={24}
        className="bg-white p-2 rounded-3 shadow-sm w-full mb-4 flex items-center justify-center"
      >
        {education.length ? (
          <Table
            columns={columnResponsiveWrapper(columns)}
            dataSource={education.map((item, key) => {
              return {
                ...item,
                key: key,
              };
            })}
            className="w-full"
          />
        ) : (
          <Empty
            description="No Educations Added"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </Row>
      {/* selected section ends here */}
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={addEducation}
        form={form}
      >
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["university"]}
              label="University"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["course"]}
              label="Course"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form.Item
              name={["description"]}
              label="Year Range"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Button htmlType="submit">Add</Button>
        </Row>
      </Form>
    </div>
  );
};

export default EducationSection;
