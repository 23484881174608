import {
  modelAppointmentsApi,
  modelStatusTypesApi,
  modelAppointmentDetailsAPI,
  modelChangeAppointmentStatusAPI,
  modelGetReportsAPI,
  modelSaveReportsAPI,
  modelAppointmentPrescriptionAPI,
  modelUpdateAppointmentPrescriptionAPI,
  modelGetAppointmentPdfAPI,
  modelShareAppointmentDetailsAPI,
  modelGetPrescriptionInstructionsAPI,
  modelAddPrescriptionAPI,
  modelGetMedicinesAPI,
  modelGetRefundDetailsAPI,
  modelGetRefundDataAPI,
  modelGetPatientUploadedReports,
} from "models/practitioner/appointments.api.models";
import { PractitionerURLParser } from "Utils/urls.utils";
import { GetData, PostData } from "../../ApiCall";

export const getPractitionerAppointments = async (payload) => {
  return modelAppointmentsApi(
    await PostData(PractitionerURLParser("/getAppointments"), payload)
  );
};
export const getStatusTypes = async () => {
  return modelStatusTypesApi(
    await GetData(PractitionerURLParser("/getAppointmentTypes"))
  );
};

export const getAppointmentDetails = async (payload) =>
  modelAppointmentDetailsAPI(
    await PostData(PractitionerURLParser("/getAppointmentDetails"), payload)
  );

export const changeAppointmentStatus = async (payload) => {
  return modelChangeAppointmentStatusAPI(
    await PostData(PractitionerURLParser("/changeAppointmentStatus"), payload)
  );
};

export const getReports = async (payload) =>
  modelGetReportsAPI(
    await PostData(PractitionerURLParser("/getReports"), payload)
  );

export const savePractitionerReports = async (payload) =>
  modelSaveReportsAPI(
    await PostData(PractitionerURLParser("/savePractitionerReports"), payload)
  );

export const getAppointmentPrescription = async (payload) =>
  modelAppointmentPrescriptionAPI(
    await PostData(
      PractitionerURLParser("/getAppointmentPrescription"),
      payload
    )
  );

export const updateAppointmentPrescription = async (payload) =>
  modelUpdateAppointmentPrescriptionAPI(
    await PostData(PractitionerURLParser("/updatePrescription"), payload)
  );

export const generateAppointmentPdf = async (appointmentId) =>
  modelGetAppointmentPdfAPI(
    await GetData(
      PractitionerURLParser(`/generateAppointmentPDF/${appointmentId}`)
    )
  );

export const shareAppointmentDetails = async (payload) =>
  modelShareAppointmentDetailsAPI(
    await PostData(PractitionerURLParser("/shareAppointmentDetails"), payload)
  );

export const getPrescriptionInstructions = async () =>
  modelGetPrescriptionInstructionsAPI(
    await GetData(PractitionerURLParser("/getPrescriptionInstructions"))
  );

export const addPrescription = async (payload) =>
  modelAddPrescriptionAPI(
    await PostData(PractitionerURLParser("/addPrescription"), payload)
  );

export const getMedicineNames = async (payload) =>
  modelGetMedicinesAPI(
    await PostData(PractitionerURLParser("/getMedicineNames"), payload)
  );

export const getRefundIdDetails = async (payload) =>
  modelGetRefundDetailsAPI(await PostData(
    "/api/common/getRefundAppointmentDetails",
    {appointment_id:payload.replace("SDA","")}
  ));

export const getRefundData = async (payload) =>
  modelGetRefundDataAPI(await PostData(
    "/api/common/getRefundStatus",
    {appointment_id:payload.replace("SDA","")}
  ));

export const getPatientUploadedReports = async (payload) =>
  modelGetPatientUploadedReports(await PostData(
    "/api/common/getPatientReports",
    {patient_id:payload}
  ));
