import React from "react";
import { Button, Popconfirm } from "antd";

export const setPackageCardAppointmentButtons = (
  row,
  history,
  changeStatus,
  setShowCheckInModal
) => {
  if (row.appointment_status === "pending") {
    return (
      <div className="appointment-action-buttons">
        <Button
          className="approve"
          onClick={() => {
            changeStatus("approved");
          }}
        >
          Approve
        </Button>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to cancel?"
          onConfirm={() => {
            changeStatus("cancelled");
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="cancel">Cancel</Button>
        </Popconfirm>
      </div>
    );
  } else if (row.appointment_status === "approved") {
    return (
      <div className="appointment-action-buttons">
        <Button
          className="checkin"
          onClick={() => {
            setShowCheckInModal(true);
          }}
        >
          Check In
        </Button>
      </div>
    );
  } else if (row.appointment_status === "check-in") {
    return (
      <div className="appointment-action-buttons">
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to checkout?"
          onConfirm={() => {
            changeStatus("completed");
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button className="checkout">Checkout</Button>
        </Popconfirm>
      </div>
    );
  } else if (row.appointment_status === "completed") {
    return (
      <div className="appointment-action-buttons">
        <Button
          className="reports"
          onClick={() =>
            history.push("/hospital/test-reports/" + row.appointment_id)
          }
        >
          Test Reports
        </Button>
      </div>
    );
  } else {
    return (
      <div className="appointment-action-buttons">
        <Button>No Actions</Button>
      </div>
    );
  }
};
