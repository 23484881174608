import { PostData } from "../../ApiCall";
import { PractitionerURLParser } from "Utils/urls.utils";
import {
  modelDashboardCountsAPI,
  modelAppointmentsChartAPI,
} from "models/practitioner/dashboard.api.models";

export const getDashboardCounts = async () =>
  modelDashboardCountsAPI(
    await PostData(PractitionerURLParser("/dashboardCounts"))
  );

export const getDashboardAppointmentsChart = async (payload) =>
  modelAppointmentsChartAPI(
    await PostData(
      PractitionerURLParser("/dashboardAppointmentsCharts"),
      payload
    )
  );
