import React, { useState } from "react";
import { message, Popconfirm, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { removeMyHospital } from "Utils/Services/Practitioner/Hospitals";
import { useHospitals } from "../../useHospitals";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

export const MyHospitalActionButtons = ({ hospital }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { fetchMyHospitalsData } = useHospitals();
  const removeHospital = async (id) => {
    setIsBtnLoading(true);
    const response = await removeMyHospital(id);

    if (response && response?.status === 200) {
      //message.success(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: response.message
    });
      
      fetchMyHospitalsData();
    } else {
      //message.warning(response.message);
      AntdCustomMessage({
        type: antdMessageTypes.WARNING,
        content: response.message
    });
    }
    setIsBtnLoading(false);

    // if (response.status && response.data.success) {
    //   message.success("Clinic removed successfully");
    //   fetchMyHospitalsData();
    // } else {
    //   message.error("Something went wrong");
    //   setIsBtnLoading(false);
    // }
  };

  return (
    <Popconfirm
      placement="topRight"
      title="Are you sure you want to remove?"
      onConfirm={() => removeHospital(hospital.id)}
      okText="Yes"
      cancelText="No"
    >
      <Button
        className="remove-button"
        type="danger"
        size="middle"
        loading={isBtnLoading}
        style={{ color: "white" }}
      >
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
};
