import { getRefundData } from "Utils/Services/Practitioner/Appointments";
import moment from "moment";

export const modelOnlinePaymentsListAPI = async (data) => {
  let result = {
    status: false,
    data: {
      count: 0,
      onlinePayments: [],
    },
  };
  try{
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    let { totalCount = 0, online_payments = [] } = responseData || {};
    if (isFinite(Number(totalCount))) result.data.count = totalCount;
    if (Array.isArray(online_payments) && online_payments.length) {
      const payments = online_payments.map(
          async ({
            order_id = "",
            patient_name = "",
            practitioner_name = "",
            clinic_name = "",
            appointment_date = "",
            appointment_time = "",
            appointment_type_name = "",
            payment_status = "",
            created_date = "",
            amount = 0,
            refund_id = "",
            appointment_id = "",
          }) =>{
            let refund_amount = 0;
            if(refund_id){
              const r = await getRefundData(appointment_id.toString());
              refund_amount = r.data.amount;
            }
            return {
              orderId: order_id,
              patientName: patient_name,
              practitionerName: practitioner_name,
              clinicName: clinic_name,
              date: appointment_date,
              time: appointment_time,
              appointmentType: appointment_type_name,
              paymentStatus: payment_status,
              createdDate: moment(created_date).isValid ? created_date : "unknown",
              amount,
              refundId: refund_id,
              refundAmount: refund_amount,
              appointmentId: appointment_id,
            }
          }
    );
    result.data.onlinePayments = await Promise.all(payments);
  }
  }catch(e){
    result = {
      status: false,
      data: {
        count: 0,
        onlinePayments: [],
      },
    };
  }finally{
    return result;

  }
};
