export const modelPatientsListAPI = (data) => {
  let result = {
    status: false,
    data: {
      totalCount: 0,
      patients: [],
    },
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    let { totalCount = 0, patients = [] } = responseData || {};
    if (isFinite(Number(totalCount))) result.data.totalCount = totalCount;
    if (Array.isArray(patients) && patients.length) {
      result.data.patients = patients.map(
        ({
          id = 0,
          first_name = "",
          last_name = "",
          email = "",
          mobile = "",
        }) => ({
          id,
          name: `${first_name} ${last_name}`,
          email: email || "",
          mobile: mobile || "",
        })
      );
    }
  } catch {
    result = {
      status: false,
      data: {
        totalCount: 0,
        patients: [],
      },
    };
  } finally {
    return result;
  }
};

export const modelPatientDetailsAPI = (data) => {
  let result = {
    status: false,
    data: {},
  };
  try {
    if (typeof data !== "object") throw new Error("invalid data");
    let { data: responseData, status = 500 } = data;
    if (status !== 200) throw new Error("invalid status");
    result.status = true;
    let {
      id = 0,
      first_name = "",
      last_name = "",
      gender = "",
      dob = "",
      blood_group = "",
      email = "",
      mobile = "",
      height = "",
      weight = "",
      medical_conditions = "",
      allergic_conditions = "",
    } = responseData || {};
    result.data = {
      id,
      name: `${first_name} ${last_name}`,
      gender: gender || "",
      dob: dob || "",
      bloodGroup: blood_group || "",
      email: email || "",
      mobile: mobile || "",
      height: height || "",
      weight: weight || "",
      medicalConditions: medical_conditions || "",
      allergicConditions: allergic_conditions || "",
    };
  } catch {
    result = {
      status: false,
      data: {},
    };
  } finally {
    return result;
  }
};
