import React, { useEffect, useState } from 'react';
import {
 Card, Typography, Button, message,
 Form, Row, Col, Select, Modal, 
 Divider, Alert, Empty, TimePicker, Tabs
} from 'antd'
import { days, timesDropDownArray } from 'Utils/Constants';
import { nanoid } from "nanoid";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import {  
    modelUpdateClinicPractitionerScheduleTimingPayload, 
} from 'models/hospital/clinic-practitioner-schedule-times.models';
import { getClinicPractitionerDaySlots, postClinicPractitionerDaySlots, updateClinicPractitionerSlotDuration } from 'Utils/Services/Hospital/Practitioner';
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';
const { TabPane } = Tabs;

const ScheduleTimings = (props) => {
    const {practitioner_id} = props;
    const {Title} = Typography;
    const [timeDuration, setTimeDuration] = useState(null);
    const [selectedDay, setSelectedDay] = useState("sunday");
    const [scheduleTimings, setScheduleTimings] = useState([]);
    const [timings, setTimings] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    const [timesData, setTimesData] = useState([]);
    const [ExistingData, setExistingData] = useState([]);
    const [AddedTimings, setAddedTimings] = useState([]);
    const [RemovedTimings, setRemovedTimings] = useState([]);
    const [DuplicateTimings, setDuplicateTimings] = useState(false);

   
   
    const getScheduleTimings = async () => {
        setIsLoading(true);
        const response = await getClinicPractitionerDaySlots({
            practitioner_id : practitioner_id?.replace("SDP",""),
            day:selectedDay, 
        });
        if (response?.status) {
            setTimeDuration(response?.timeDuration);
            setTimings(response?.slots);
            setScheduleTimings(response?.slotTimings);
        }
        if(!response?.status && response?.message){
            AntdCustomMessage({
                type : antdMessageTypes.ERROR,
                content : response?.message,
              })
        }
        setIsLoading(false);
    };
    
    const handleDurationChange = async (duration) => {
        setTimeDuration(duration);
        const response = await updateClinicPractitionerSlotDuration({ 
            time_duration: duration,
            practitioner_id:  practitioner_id?.replace("SDP","")
        });
        if (response?.status) {
          //message.success("Slot duration updated successfully");
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content : "Slot duration updated successfully",
          })
          getScheduleTimings();
        } else {
          setTimeDuration("10");
          getScheduleTimings();
          //message.error(response.message);
          AntdCustomMessage({
            type: antdMessageTypes.ERROR,
            content: response?.message
          })
        }
    };

    const addSchedule = () => {
        setTimesData([
          ...timesData,
          { start: "00:00", end: "24:00", id: nanoid() },
        ]);
    };

    //new data added cant be in the range of the previous data
    const checkRange = (start, end, index) => {
        const newData = [...timesData];
        const prevData = newData.filter((item, i) => i < index);
        const isInRange = prevData.some((item) => {
        const startTime = moment(item.start, "HH:mm");
        const endTime = moment(item.end, "HH:mm");
        const newStartTime = moment(start, "HH:mm");
        const newEndTime = moment(end, "HH:mm");
        return (
            (newStartTime.isBetween(startTime, endTime) ||
            newEndTime.isBetween(startTime, endTime)) &&
            item.id !== newData[index].id
        );
        });
        return isInRange;
    };

    const removeSchedule = (id) => {
        let data = timesData.filter((item) => item.id !== id);
        setTimesData(data);
    };

    const onSubmit = async () => {
        setIsBtnLoading(true);
        //check if the data is valid
        let isValid = true;
        timesData.forEach((item, index) => {
            if (
                !item.start ||
                !item.end ||
                item.start === "" ||
                item.end === "" ||
                item.start === item.end ||
                checkRange(item.start, item.end, index)
            ) {
                isValid = false;
            }
        });
        if (!isValid) {
            //message.error("Please enter valid time range");
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: "Please enter valid time range"
            });
            setIsBtnLoading(false);
            return;
        }
        //leave can't be added in t
        let res = await postClinicPractitionerDaySlots(
            modelUpdateClinicPractitionerScheduleTimingPayload(
                timesData,
                practitioner_id,
                selectedDay,
                AddedTimings,
                RemovedTimings
            ));

        if (res?.status) {
            //message.success("Schedule updated successfully");
            AntdCustomMessage({
                type: antdMessageTypes.SUCCESS,
                content: "Schedule updated successfully",
            });
            setIsModalOpen(false);
            setIsModalOpen(false);
            setIsBtnLoading(false);
            getScheduleTimings();
        } else {
            //message.error(res.message);
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: res.message,
            });
            setIsModalOpen(false);
            setIsBtnLoading(false);
        }
    };

    useEffect(()=>{
        getScheduleTimings();
    },[selectedDay, practitioner_id]);

    useEffect(()=>{
        let timings = scheduleTimings.map((item) => ({ ...item, id: nanoid() }));
        setTimesData([...timings]);
    },[scheduleTimings]);

    useEffect(()=>{
        let timings = scheduleTimings.map((item) => ({ ...item, id: nanoid() }));
        setExistingData([...timings]);
    },[scheduleTimings]);


    useEffect(() => {
        if (timesData) {
        let addedTimings = timesData.filter(
            // eslint-disable-next-line
            ({ start: start, end: end }) =>
            !ExistingData.some(
                ({ start: start1, end: end1 }) => start === start1 && end === end1
            ) &&
            start !== "" &&
            end !== ""
        );

        let removedTimings = ExistingData.filter(
            // eslint-disable-next-line
            ({ start: start, end: end }) =>
            !timesData.some(
                ({ start: start1, end: end1 }) => start === start1 && end === end1
            )
        );

        if (
            timesData?.length > ExistingData?.length &&
            addedTimings.length === 0
        ) {
            //message.error("Duplicate timings are not allowed");
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: "Duplicate timings are not allowed"
            })
            setDuplicateTimings(true);
        } else {
            setDuplicateTimings(false);
        }

       

        // if(addedTimings.length === 0 && timesData.length === 1){
        //     setAddedTimings(timesData.map((item) => {
        //         return { start: item.start, end: item.end };
        //     }))
        // }else{
            
        // }
        setAddedTimings(
            addedTimings.map((item) => {
            return { start: item.start, end: item.end };
            })
        );
        setRemovedTimings(
            removedTimings.map((item) => {
            return { start: item.start, end: item.end };
            })
        );
        }
        // eslint-disable-next-line
    }, [timesData]);

  return (
    <div>
        <Card>
            <Form layout="vertical" name="nest-messages">
                <Row>
                    <Col xs={24} sm={24} lg={12}>
                    <Form.Item
                        name={["timeslot"]}
                        initialValue={timeDuration}
                    >
                        <Title level={4}>Select the duration of the time slot</Title>
                        <Select
                        optionFilterProp="children"
                        size="large"
                        placeholder="Please select slot duration"
                        value={timeDuration}
                        onChange={(value) =>{ 
                            handleDurationChange(value,practitioner_id);
                        }}
                        style={{ width: "100%" }}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                        {timesDropDownArray.map((time) => (
                            <Select.Option key={time.value} value={time.value}>
                            {time.label}
                            </Select.Option>
                        ))}
                        </Select>
                    </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Title level={4}>Select the day</Title>
            <div className="details-tab-container tab-style">
                <Tabs 
                    type="card" 
                    defaultActiveKey={selectedDay}   
                    onChange={(key)=>{
                        setSelectedDay(key);
                    }}  
                >
                    {days.map((day) => (
                        <TabPane
                            tab={day.toUpperCase()}
                            key={day}
                            className={`day ${selectedDay === day ? "active" : ""}`}
                        >
                        </TabPane>
                    ))}
                </Tabs>
            </div>
            {/* <div className="schedule-days-container">
                {days.map((day) => (
                    <div
                        className={`day ${selectedDay === day ? "active" : ""}`}
                        onClick={() => {
                            setSelectedDay(day);
                        }}
                        key={day}
                    >
                        {day}
                    </div>
                ))}
            </div> */}

        <div className="heading-container">
        <Title level={4}>Time Slots</Title>
          <div>
            <Button
              style={{ marginBottom: "10px" }}
              type="secondary"
              disabled={!timeDuration}
              onClick={() => setIsModalOpen(true)}
            >
              Manage Slots
            </Button>
            {timeDuration === null && (
              <Alert type="error" message="Please Add Time Duration" />
            )}
          </div>
        </div>

        {timings?.length ? (
          <div className="slots-container">
            {timings.map((time) => (
              <div className="slot" key={time}>
                {time}
              </div>
            ))}
          </div>
        ) : (
        <Empty description="No slots available" />
        )}
        </Card>
        <Modal
            className="item-details-modal"
            title="Manage Slots"
            visible={isModalOpen}
            footer={null}
            width={700}
            onCancel={() => setIsModalOpen(false)}
            destroyOnClose={true}
        >
        <div className="manage-slots-card">
            <div className="heading-container">
                <h2 className="manage-slot-heading">Add Time Slots</h2>
            </div>
            <Divider />
            <div className="manage-container">
                <div style={{ textAlign: timesData?.length ? "right" : "center" }}>
                    <Button type="secondary" onClick={() => addSchedule()}>
                        {timesData?.length ? "Add More" : "Add Slots"}
                    </Button>
                </div>
                <Form layout="vertical" name="nest-messages">
                {timesData.length
                    ? timesData.map((item) => (
                        <Row gutter={{ sm: 8, lg: 24 }} key={item.id}>
                        <Col xs={24} sm={24} lg={10}>
                            <Form.Item label="Start Time">
                            <TimePicker
                                size="large"
                                use12Hours
                                format="h:mm a"
                                className="w-full"
                                hourStep={1}
                                minuteStep={60}
                                allowClear={false}
                                showNow={false}
                                defaultValue={
                                item.start
                                    ? moment(item.start, "HH:mm")
                                    : moment("00:00", "HH:mm")
                                }
                                onChange={(value) => {
                                //update start time with id
                                setTimesData((prevState) => {
                                    const newData = [...prevState];
                                    newData.map((time) => {
                                    if (item.id === time.id) {
                                        item.start = value.format("HH:mm");
                                    }
                                    return item;
                                    });
                                    return newData;
                                });
                                }}
                            />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={10}>
                            <Form.Item label="End Time">
                            <TimePicker
                                size="large"
                                use12Hours
                                format="h:mm a"
                                className="w-full"
                                hourStep={1}
                                minuteStep={60}
                                allowClear={false}
                                showNow={false}
                                defaultValue={
                                item.end
                                    ? moment(item.end, "HH:mm")
                                    : moment("24:00", "HH:mm")
                                }
                                onChange={(value) => {
                                //update end time with id
                                setTimesData((prevState) => {
                                    const newData = [...prevState];
                                    newData.map((time) => {
                                    if (item.id === time.id) {
                                        item.end = value.format("HH:mm");
                                    }
                                    return item;
                                    });
                                    return newData;
                                });
                                }}
                            />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            lg={4}
                            style={{ display: "flex", alignItems: "center" }}
                            className="slot-remove-btn"
                        >
                            <div
                            className="btn-delete"
                            onClick={() => removeSchedule(item.id)}
                            >
                            <DeleteOutlined className="icon" />
                            </div>
                        </Col>
                        </Row>
                    ))
                    : null}
                </Form>

                {
                    ExistingData?.length || timesData?.length ? (
                        <div className="flex justify-center" style={{ marginTop: 10 }}>
                            <Button
                            disabled={DuplicateTimings}
                            size="large"
                            type="primary"
                            loading={isBtnLoading}
                            style={{ width: "100%" }}
                            onClick={() => onSubmit()}
                            >
                            {DuplicateTimings ? "Remove Duplicate Timings" : "Save Changes"}
                            </Button>
                        </div>
                    ) : null
                }
                </div>
            </div>
      </Modal>
    </div>
  )
}

export default ScheduleTimings