import React, { useEffect, useState } from 'react';
import RegisterBG from 'Images/bg/login.jpg';
import { 
    Typography,
    Form,
    Input,
    Button,
    Select,
    Tabs,
} from  'antd';

import 'react-phone-input-2/lib/style.css';
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';
import { useHistory } from "react-router";
import {  getDepartmentsList, getDistrictsOfState, getPincodeOfPlace, getPlacesOfDistrict, getStatesList, updateHospitalOnboardingProfile } from 'Utils/Services/Onboarding';
import { format } from 'date-fns';
import Cookies from "universal-cookie";
import { DecryptString } from 'Utils/Constants';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const {Option} = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;
const providerTypes = [
    {
        label: "Hospital/Clinic",
        id: "hospital",
        value: "hospital",
    }
]


const HospitalOnboarding = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const cookies = new Cookies();
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [adminStates, setAdminStates] = useState([]);
    const [adminDistricts, setAdminDistricts] = useState([]);
    const [adminPlaces, setAdminPlaces] = useState([]);
    const [adminPincodes, setAdminPincodes] = useState([]);
    const [adminDepartments, setAdminDepartments] = useState([]);
    
    const [activeTab, setActiveTab] = useState('1');
    const [onboardingData, setOnboardingData] = useState({
        telephone:"",
        address:"",
        state_id:"",
        district_id:"",
        place:"",
        pincode:"",
        available_departments:[],
        type: "hospital",
        about_us:"",
        order_number:"",
        created_at:"",
        updated_at:"",
    });
    
    const handleFinishTab1 = async () => {
        try {
            await form.validateFields(['telephone','address',"state_id","district_id", "place","pincode"]);
            setActiveTab('2');
        } catch (errorInfo) {
            // console.log('Validation failed:', errorInfo);
        }
    };

    const handlePrevTab = () => {
        setActiveTab('1');
    };

    const FetchStateDistricts = async () => {
        const districtsResponse = await getDistrictsOfState({
            state_id:form.getFieldValue("state_id") || undefined
        });
        if(districtsResponse?.status){
            const districts = districtsResponse?.data?.map(eachDistrict => ({
                value: eachDistrict?.id.toString(),
                id: eachDistrict?.id.toString(),
                label: eachDistrict?.districts,
                state_id: eachDistrict?.state_id,
            }));
            setAdminDistricts([...districts]);
        }
    };

    const FetchDistrictPlaces = async () => {
        const placesResponse = await getPlacesOfDistrict({
            district_id:form.getFieldValue("district_id") || undefined
        });
        if(placesResponse?.status){
            const places = placesResponse?.data?.map(eachPlace => ({
                value: eachPlace?.city,
                id: eachPlace?.id,
                label: eachPlace?.city,
            }));
            setAdminPlaces([...places]);
        }
    };

    const FetchPincodeByPlace = async () => {
        const pincodesResponse = await getPincodeOfPlace({
            place: form.getFieldValue("place")|| undefined
        });
        if(pincodesResponse?.status){
            const pincodes = pincodesResponse?.data?.map(eachPincode => ({
                value: eachPincode?.pincodes,
                id: eachPincode?.pincodes,
                label: eachPincode?.pincodes,
            }));
            setAdminPincodes([...pincodes]);
        }
    };

    const FetchDropdownList = async () => {
        const statesResponse = await getStatesList();
        if(statesResponse?.status){
            const states = statesResponse?.data?.map(eachState => ({
                value: eachState?.id.toString(),
                id: eachState?.id.toString(),
                label: eachState?.state
            }));
            setAdminStates([...states]);
        };

        const departmentsResponse = await getDepartmentsList();
        if(departmentsResponse?.status){
            const departments = departmentsResponse?.data?.map(eachDepartment => ({
                value: eachDepartment?.id.toString(),
                id: eachDepartment?.id.toString(),
                label: eachDepartment?.department
            }));
            setAdminDepartments([...departments]);
        }
    };

    const onFinishFailed = () =>{
        setIsLoading(false);
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const onboardingResponse = await updateHospitalOnboardingProfile({
            ...values,
            available_departments: JSON.stringify(values.available_departments),
            created_at: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
            updated_at: `${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`,
        });
        if(onboardingResponse?.status){
            // AntdCustomMessage({
            //     type:antdMessageTypes.SUCCESS,
            //     content: "Onboarding is successfully completed!"
            // });
            window.alert("Onboarding is successfully completed. Please wait for admin's approval to use your account");
            cookies.remove("userdata", { path: "/" });
            cookies.remove("token", { path: "/" });
            history.push("/login");
            window.location.reload();
        }else{
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: onboardingResponse?.message || "something went wrong"
            });
        }
        setIsLoading(false);
    };

    useEffect(()=>{
        FetchDropdownList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);

    if(!UserData?.type){
        return <Redirect to="/login" />;
    }
    if(UserData?.subadmin || UserData?.profile_completed){
        return <Redirect to="/hospital/dashboard" />;
    };
    return (
        <div
            style={{
                backgroundImage:`url(${RegisterBG})`,
                backgroundSize: "cover",
                minHeight: '100vh',
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{
                minHeight: "50vh",
                width: "80%",
                borderRadius: "10px",
                backgroundColor:"rgba(255,255,255,1)",
                // backdropFilter: "blur(10px)",
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className='w-75 p-5'>
                    <Typography.Title style={{textAlign:"center"}}>Onboarding</Typography.Title>
                    <Form
                        form={form}
                        name="onBoardingForm"
                        initialValues={onboardingData}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                    >
                         <Tabs 
                            activeKey={activeTab}
                            tabBarStyle={{ 
                                //background: 'rgba(3, 111, 211, 0.8)', 
                                background: 'rgba(123, 111, 111, 0.2)', 
                                padding: '8px',
                                width: "100%",
                                fontWeight: "bold",
                                borderRadius: "5px"
                            }}
                        >
                            <TabPane tab="Location Information" key="1">
                                <Form.Item
                                    label="Telephone"
                                    name="telephone"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input telephone number!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value && !/^\d*$/.test(value)) {
                                                    return Promise.reject('Please enter only digits');
                                                    }
                                                    return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                     <Input type="tel" pattern="^[0-9]*$"/>
                                </Form.Item>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input address!',
                                        },
                                    ]}
                                >
                                     <TextArea rows={4} />
                                </Form.Item>

                                <Form.Item
                                    name="state_id"
                                    label="Select State"
                                    rules={[
                                        { required: true, message: 'Please select state!' }
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select state"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={FetchStateDistricts}
                                    >
                                        {adminStates.map(state => (
                                            <Option key={state.id} value={state.id}>
                                                {state.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="district_id"
                                    label="Select District"
                                    rules={[
                                        { required: true, message: 'Please select district!' }
                                    ]}
                                >
                                    <Select
                                        placeholder="Please select place"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={FetchDistrictPlaces}
                                    >
                                        {adminDistricts.map(district => (
                                            <Option key={district.id} value={district.id}>
                                                {district.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                
                                <Form.Item
                                    name="place"
                                    label="Select place"
                                    rules={[
                                        { required: true, message: 'Please select place!' }
                                    ]}
                                >
                                   <Select
                                        placeholder="Please select place"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={FetchPincodeByPlace}
                                    >
                                        {adminPlaces.map(place => (
                                            <Option key={place.id} value={place.label}>
                                                {place.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="place"
                                    label="Enter Place here if not found in list"
                                >
                                   <Input type="text"/>
                                </Form.Item>

                                <Form.Item
                                    name="pincode"
                                    label="Select pincode"
                                    rules={[
                                        { required: true, message: 'Please select pincode!' }
                                    ]}
                                >
                                   <Select
                                        placeholder="Please select pincode"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {adminPincodes.map(pincode => (
                                            <Option key={pincode.id} value={pincode.label}>
                                                {pincode.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="pincode"
                                    label="Enter Pincode here if not found in list"
                                >
                                   <Input type="text"/>
                                </Form.Item>

                              

                                <Form.Item
                                    wrapperCol={{
                                       
                                        span: 24,
                                    }}
                                    className='text-center'
                                >
                                    <Button 
                                        type="primary" 
                                        onClick={handleFinishTab1}
                                    >
                                        Next
                                    </Button>
                                </Form.Item>
                            </TabPane>
                            <TabPane tab="General Information" key="2">
                            <Form.Item
                            label="Select"
                            name="type"
                            rules={[
                                {
                                required: true,
                                message: 'Please select type',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                options={providerTypes}
                                disabled
                            />
                        </Form.Item>
                               
                                <Form.Item
                                    name="available_departments"
                                    label="Select atleast one department"
                                    rules={[
                                        { required: true, message: 'Please select at least one department!' }
                                    ]}
                                >
                                    <Select 
                                        mode="multiple" 
                                        placeholder="Please select departments"
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {adminDepartments.map((department) => (
                                            <Option key={department.id} value={department.id}>
                                            {department.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                
                               

                                <Form.Item
                                    label="About Us"
                                    name="about_us"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input about us!',
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                                <div className="d-flex flex-row align-items-center justify-between">
                                
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        onClick={handlePrevTab}
                                    >
                                        Prev
                                    </Button>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        // disabled={!isValid}
                                        loading={isLoading}
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                                </div>
                            </TabPane>
                        </Tabs>     
                    </Form>
                </div>
                
            </div>
        </div>
  )
}

export default HospitalOnboarding