import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LabAppointmentStatusChart = ({ AppointmentsByStatus, height = 175 }) => {
  const options = {
    chart: {
      type: "pie",
      height: height,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "100%"],
        size: "180%",
      },
    },
    tooltip: {
      backgroundColor: "#fff",
      borderWidth: 0,
      padding: 12,
    },
    series: [
      {
        name: "",
        data: [
          {
            x: "pending",
            y: AppointmentsByStatus.pending,
            name: "Pending",
            color: "orange",
          },
          {
            x: "approved",
            y: AppointmentsByStatus.approved,
            name: "Approved",
            color: "#0fa0cf",
          },
          {
            x: "completed",
            y: AppointmentsByStatus.completed,
            name: "Completed",
            color: "#45bd5b",
          },
          {
            x: "cancelled",
            y: AppointmentsByStatus.cancelled,
            name: "Cancelled",
            color: "#f6546c",
          },
        ],
        innerSize: "40%",
        showInLegend: true,
        dataLabels: {
          color: "black",
          style: {
            fontWeight: "normal",
          },
          distance: 5,
          formatter: function () {
            return this.point.isNull ? void 0 : this.point.name + " :" + this.y;
          },
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LabAppointmentStatusChart;
