import { LabURLParser } from "Utils/urls.utils";
import { GetData, PostData } from "../../ApiCall";
import axiosApiInstance from "Utils/ApiInstance";

export const getLabPackages = async (values) => {
  return await PostData(LabURLParser("/GetLabPackages"), { ...values }).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const changePackageStatus = async (values) => {
  return await PostData(LabURLParser("/changePackageStatus"), values).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
};

export const GetLabPackageDetails = async (values) => {
  return await PostData(LabURLParser("/GetLabPackageDetails"), values).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const GetPackageCategories = async (values) => {
  return await PostData(LabURLParser("/GetPackageCategories"), values).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
};

export const GetLabTests = async (values) => {
  return await PostData(LabURLParser("/GetLabTests"), values).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
};

export const AddLabPackages = async (values, images) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => formData.append(key, values[key]));
  images && images.forEach((item) => formData.append("package_gallery", item));

  return await PostData(LabURLParser("/AddLabPackages"), formData).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
};

export const UpdateLabPackages = async (values, images) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => formData.append(key, values[key]));
  images && images.forEach((item) => formData.append("package_gallery", item));

  return await PostData(LabURLParser("/UpdateLabPackages"), formData).then(
    (response) => {
      if (response) {
        return response;
      }
    }
  );
};

export const getLabPackageDetails = async (values) => {
  return await PostData(LabURLParser("/GetLabPackageDetails"), values).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const deleteLabPackage = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/lab/deleteLabPackage/${payload.id}`);
  if(response.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response.data || "Something went wrong!",
  }
}

export const getLabPackageFaqs = async (payload) => {
  const response = await GetData(`/api/v1/lab/package/getFAQs/${payload.id}`);
  if (response) {
    return response.data;
  }
}

export const addLabPackageFaqs = async (payload) => {
  const response = await axiosApiInstance
    .post(
      '/api/v1/lab/package/addFAQs',
      payload
    );
  if(response?.status == 200){
    return response?.data
  }
  return {
    message: "Something went wrong."
  };
};

export const updateLabPackageFaqs = async (payload) => {
  const response = await axiosApiInstance
    .put(
      '/api/v1/lab/package/updateFAQs',
      payload
    );
  if(response?.status == 200){
    return response?.data
  }
  return {
    message: "Something went wrong."
  };
};

export const deleteLabPackageFaqs = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/lab/package/deleteFAQs/${payload.id}`);
  if(response?.status == 200){
    return response?.data
  }
  return {
    success:"false",
    message:response?.data || "Something went wrong!",
  }
}


