import { message } from "antd";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";

export const antdMessageTypes = {
  SUCCESS : "success",
  ERROR : "error",
  INFO : "info",
  WARNING: "warning",
  LOADING: "loading",
}

const AntdCustomMessage = ({
  type,
  content,
  title,
  subject,
  icon,
  duration = 5,
  closeable = true,
  ...restProps
}) => {
  switch (type){
    case antdMessageTypes.ERROR:
      return message.error({
        content: (
          <div className="flex flex-row">
            <span>{content}</span>
            {
              closeable && <CloseOutlined 
                style={{ marginLeft: '8px', cursor: 'pointer' }} 
                onClick={()=>{
                  message.destroy();
                }} 
              />
            }
          </div>
        ),
        duration: closeable ? 0 : duration,
        ...restProps
      });
    case antdMessageTypes.INFO:
      return message.info({
        content: (
          <div className="flex flex-row">
            <span>{content}</span>
            {
              closeable && <CloseOutlined 
                style={{ marginLeft: '8px', cursor: 'pointer' }} 
                onClick={()=>{
                  message.destroy();
                }} 
              />
            }
          </div>
        ),
        duration: closeable ? 0 : duration,
        ...restProps
      });
    case antdMessageTypes.WARNING:
      return message.warning({
        content: (
          <div className="flex flex-row">
            <span>{content}</span>
            {
              closeable && <CloseOutlined 
                style={{ marginLeft: '8px', cursor: 'pointer' }} 
                onClick={()=>{
                  message.destroy();
                }} 
              />
            }
          </div>
        ),
        duration: closeable ? 0 : duration,
        ...restProps
      });
    case antdMessageTypes.LOADING:
      return message.loading({
        content: (
          <div className="flex flex-row">
            <span>{content}</span>
            {
              closeable && <CloseOutlined 
                style={{ marginLeft: '8px', cursor: 'pointer' }} 
                onClick={()=>{
                  message.destroy();
                }} 
              />
            }
          </div>
        ),
        duration: closeable ? 0 : duration,
        ...restProps
      });
    default:
      return message.success({
        content: (
          <div className="flex flex-row">
            <span>{content}</span>
            {
              closeable && <CloseOutlined 
                style={{ marginLeft: '8px', cursor: 'pointer' }} 
                onClick={()=>{
                  message.destroy();
                }} 
              />
            }
          </div>
        ),
        duration: closeable ? 0 : duration,
        ...restProps
      });
  }
  
  
  // return message.success({
  //   content: (
  //     <div className="antd-message">
  //       <div className="antd-message-icon">
  //         <img src={icon} alt="Appointments" width="auto" height="auto" />
  //       </div>
  //       <div className="body">
  //         <div className="message-heading">{title}</div>
  //         <div className="message-body">{subject}</div>
  //       </div>
  //     </div>
  //   ),
  //   duration: duration || 3,
  //   icon: <></>,
  //   ...restProps,
  // });
};

export default AntdCustomMessage;