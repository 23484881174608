import React, { useState } from "react";
import {
  CLINIC_TRIAL_DAYS,
  getDateFormat,
  getSubscriptionDaysRemaining,
} from "Utils/Constants";
import { Button, Modal } from "antd";
import UpgradeQuota from "../actions/UpgradeQuota";

const TrialActivated = ({ SubscriptionData, FetchBillingHistory }) => {
  const [UpgradeModal, setUpgradeModal] = useState(false);
  return (
    <>
      <Modal
        className="subscription-modal"
        title="Upgrade Practitioners Quota"
        visible={UpgradeModal}
        footer={null}
        width={700}
        onCancel={() => setUpgradeModal(false)}
        destroyOnClose={true}
      >
        <UpgradeQuota
          FetchBillingHistory={FetchBillingHistory}
          trialExpiry={SubscriptionData?.trialExpiry}
          subscriptionExpiry={SubscriptionData?.subscriptionExpiry}
          trialPeriod={SubscriptionData.trialPeriod === "started"}
        />
      </Modal>
      <div className="subscription-content">
        <h5>You are Running on Free Trial</h5>
        <p>
          <b style={{ fontWeight: "bold" }}>
            {getSubscriptionDaysRemaining(SubscriptionData?.trialExpiry)} days
          </b>{" "}
          are remaining in your free trial of {CLINIC_TRIAL_DAYS} days.{" "}
          <br></br>
          After that, your subscription period will be activated from{" "}
          <b style={{ fontWeight: "bold" }}>
            {getDateFormat(SubscriptionData?.trialExpiry)}
          </b>
          , and expires on{" "}
          <b style={{ fontWeight: "bold" }}>
            {getDateFormat(SubscriptionData?.subscriptionExpiry)}.
          </b>
        </p>
        <div className="subscription-count-items">
          <div
            className="subscription-count-item"
            style={{ background: "#e6fdf6", color: "#0e936c" }}
          >
            <div className="subcription-count-label">
              Practitioners Quota Purchased
            </div>
            <div className="subcription-count-value">
              {SubscriptionData?.subscriptionQuota.quotaPurchased}
            </div>
          </div>
          <div
            className="subscription-count-item"
            style={{ background: "#fff0d1", color: "#db7b01" }}
          >
            <div className="subcription-count-label">
              Practitioners Quota Used
            </div>
            <div className="subcription-count-value">
              {SubscriptionData?.subscriptionQuota.quotaUsed}
            </div>
          </div>
          <div
            className="subscription-count-item"
            style={{ background: "#e4f8ff", color: "#0394d1" }}
          >
            <div className="subcription-count-label">
              Practitioners Quota Balance
            </div>
            <div className="subcription-count-value">
              {SubscriptionData?.subscriptionQuota.quotaBalance}
            </div>
          </div>
        </div>
        <Button
          type="secondary"
          style={{ marginTop: 15, marginLeft: 10 }}
          onClick={() => setUpgradeModal(true)}
        >
          Upgrade Practitioners Quota
        </Button>
      </div>
    </>
  );
};

export default TrialActivated;
