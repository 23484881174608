import React, { useState } from "react";
import { Modal, Empty, List } from "antd";
import DetailsCard from "views/components/appointments/DetailsCard";
import HospitalRescheduleCard from "views/components/appointments/HospitalRescheduleCard";
import { TableSkeletonLoader } from "Utils/Constants";
import AppointmentCard from "views/components/appointments/AppointmentCard";

const TodaysAppointments = ({
  TodaysAppointments,
  FetchTodayAppointments,
  TodayAppointmentsLoading,
}) => {
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [selectedPractitionerId, setSelectedPractitionerId] = useState("");
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");

  const handleViewClick = (row) => {
    setSelectedAppointmentId(row.appointment_id);
    setshowDetailsModal(true);
  };

  return (
    <div className="today-appointments">
      <Modal
        className="item-details-modal"
        title="Reschedule Details"
        visible={showRescheduleModal}
        footer={null}
        width={500}
        onCancel={() => setShowRescheduleModal(false)}
      >
        <HospitalRescheduleCard
          practitioner_id={selectedPractitionerId}
          appointment_id={selectedAppointmentId}
          FetchData={FetchTodayAppointments}
          setShowRescheduleModal={setShowRescheduleModal}
        />
      </Modal>
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Appointment Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setshowDetailsModal(false)}
      >
        <DetailsCard appointmentId={selectedAppointmentId} />
      </Modal>
      {TodayAppointmentsLoading ? (
        TableSkeletonLoader(15, 30)
      ) : TodaysAppointments?.length ? (
        <List
          dataSource={TodaysAppointments}
          renderItem={(appointment) => (
            <List.Item style={{ padding: 0 }}>
              <AppointmentCard
                appointment={appointment}
                FetchData={FetchTodayAppointments}
                setShowRescheduleModal={setShowRescheduleModal}
                setSelectedPractitionerId={setSelectedPractitionerId}
                setSelectedAppointmentId={setSelectedAppointmentId}
                handleViewClick={handleViewClick}
                today={true}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description="No Appointments Today"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="empty-nodata"
        />
      )}
    </div>
  );
};

export default TodaysAppointments;
