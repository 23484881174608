import React, { useEffect, useState, useContext } from "react";
import { getPatients } from "Utils/Services/Practitioner/Patients";
import { TableSkeletonLoader } from "Utils/Constants";
import { Empty, List, Input } from "antd";
import PatientCard from "./PatientCard";
import { usePatients } from "./usePatients";
import { GlobalState } from "context-api";
import BugContainer from "components/ErrorContainer/BugContainer";

const PatientsContainer = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const [patientsData, setPatientsData] = useState({ status: false, data: {} });
  const [patientsCount, setPatientsCount] = useState(0);
  const limit = 20;
  const [Pageno, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const { SearchKeyword, setSearchKeyword } = usePatients();

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getPatients({
      limit: limit || 12,
      start: (Pageno - 1) * limit || 0,
      search: SearchKeyword || "",
    });
    response && setPatientsData(response);
    if (response && response?.status) setPatientsCount(response?.data?.totalCount);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [SearchKeyword, limit, Pageno]); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Patients");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(patientsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [patientsCount]);

  let { patients } = patientsData.data;
  let { status } = patientsData;
  return (
    <div className="content">
      <div className="card-list-header patient">
        {/* <p>{patientsCount + " "} Patients found</p> */}
        <Input
          placeholder="Search patients.."
          className="card-search"
          value={SearchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </div>
      <div className="patients-list" style={{ marginTop: 35 }}>
        {!isLoading ? (
          status ? (
            patients.length ? (
              <List
                dataSource={patients}
                pagination={{
                  current: Pageno,
                  total: patientsCount,
                  onChange: (page) => {
                    setPageNo(page);
                  },
                  pageSize: limit,
                }}
                renderItem={(item) => (
                  <List.Item>
                    <PatientCard patient={item} />
                  </List.Item>
                )}
              />
            ) : (
              <Empty
                description="No Patients Found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )
          ) : (
            <BugContainer />
          )
        ) : (
          TableSkeletonLoader(10, 60)
        )}
      </div>
    </div>
  );
};

export default PatientsContainer;
