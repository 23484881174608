import React from "react";
import { Empty, Button } from "antd";

const ReportsTab = ({ data }) => {
  return (
    <div className="report-container">
      {
        data?.reportText && 
        <>
          <div className="p-3">
            <p>{data.reportText}</p>
          </div>
          <hr/>
        </>
      }
      {data?.reports?.length ? (
        data.reports.map((report, index) => (
          <Button
            style={{ marginRight: 10 }}
            key={`report-${report?.title}-${index}`}
            onClick={() => window.open(report?.report)}
          >
            {`Download ${report?.title}`}
          </Button>
        ))
      ) : (
        <Empty
          description="No Reports Found"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
};

export default ReportsTab;
