import React from 'react'

const UnAuthorized = () => {
  return (
    <div className='content w-full h-full d-flex flex-column justify-content-center align-items-center'>
       <p className='font-weight-bold h1 text-muted'> UnAuthorized!</p>
    </div>
    
  )
}

export default UnAuthorized