import React from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import { updateConsultationFees } from "Utils/Services/Practitioner/Profile";
import { modelConsultationFeesPayload } from "models/practitioner/profile.api.model";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const ConsultationFee = ({ data, clinic_id }) => {
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);

  const { inperson, phone, video } = data || {};
  let formValues = {
    inperson: inperson?.amount,
    phone: phone?.amount,
    video: video?.amount,
  };

  const onSubmit = async (values) => {
    setIsBtnLoading(true);
    values = {
      inPersonConsultation: {
        id: 1,
        amount: values.inperson,
      },
      videoConsultation: {
        id: 3,
        amount: values.video,
      },
      phoneConsultation: {
        id: 4,
        amount: values.phone,
      },
    };
    const response = await updateConsultationFees(
      modelConsultationFeesPayload({
        data: values,
        clinic_id,
      })
    );
    if (response?.status) {
      //message.success("Consultation fees updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Consultation fees updated successfully"
    });
    } else {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
    });
    }
    setIsBtnLoading(false);
  };
  return (
    <Form
      layout="vertical"
      name="nest-messages"
      onFinish={onSubmit}
      initialValues={formValues}
    >
      {/* row starts here */}
      <Row gutter={{ sm: 8, lg: 24 }}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="In person consultation"
            name="inperson"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="Video consultation"
            name="video"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} lg={8}>
          <Form.Item
            label="Phone consultation"
            name="phone"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>
      {/* row ends here */}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ height: 40 }}
          loading={isBtnLoading}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConsultationFee;
