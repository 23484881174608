import { Card, Typography, Rate } from "antd";
import React, { useEffect, useState } from "react";
import { useReviews } from "./useReviews";

const ReviewDetailsCard = () => {
  const [reviewsData, setReviewsData] = useState({
    status: false,
    data: {},
  });
  const { getReviewDetails, selectedReviewId = "" } = useReviews();

  useEffect(() => {
    const fetchData = async () => {
      const result = await getReviewDetails(selectedReviewId);
      result && setReviewsData(result);
    };
    fetchData();
  }, [selectedReviewId]); // eslint-disable-line
  let { status = false, data = {} } = reviewsData || {};
  return (
    <>
      {status && (
        <Card bordered={false} title="Review Details">
          <Typography.Title
            style={{
              marginBottom: "0px",
              marginTop: "0px",
              paddingTop: "0px",
              textTransform: "capitalize",
            }}
            level={4}
          >
            {data.title}
          </Typography.Title>
          <Typography.Text type="secondary">
            Reviewed By {data.patientName}
          </Typography.Text>
          {/* <Typography.Text
            type="secondary"
            style={{ display: "block", fontWeight: "500", fontSize: "12px" }}
          >
            On {data.createdDate}
          </Typography.Text> */}
          <Rate style={{ display: "block" }} disabled value={data.rating} />
          <Typography.Paragraph style={{ marginTop: "15px" }} level={4}>
            {data.description}
          </Typography.Paragraph>
        </Card>
      )}
    </>
  );
};
export default ReviewDetailsCard;
