import { 
  SearchOutlined, 
  PlusOutlined, 
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Input,
  List,
  Modal,
  Typography,
  Card,
  Form,
  message,
  Table,
  Popconfirm
} from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { GlobalState } from "context-api";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  DateRanges,
  DATE_FORMAT,
  DecryptString,
  TableSkeletonLoader,
} from "Utils/Constants";
import { getReadableFileSize } from "Utils/GetReadableFileSize";
import {
  addPatientReports,
  deletePatientReport,
  getPatientAppointments,
  getPatientDetails,
  getPatientReports,
  updatePatientReports,
} from "Utils/Services/Hospital/Patient";
import AppointmentCard from "views/components/appointments/AppointmentCard";
import DetailsCard from "views/components/appointments/DetailsCard";
import PatientsInfoCard from "views/components/patients/patientInfoCard";

const PatientDetails = (props) => {
  const { UserData } = useContext(GlobalState);
  
  const [patientId, setPatientId] = useState("");

  const { Title } = Typography;
  const history = useHistory();
  const { RangePicker } = DatePicker;

  const [data, setData] = useState("");
  const [appointmentsHistory, setAppointmentsHistory] = useState("");
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const [DetailsLoading, setDetailsLoading] = useState(false);
  const [DataLoading, setDataLoading] = useState(false);
  const [Pageno, setPageNo] = useState(1);
  const appointmentsPerPage = 10;
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState([null, null]);
  const [isReports, setIsReports] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [reportsLoading,setReportsLoading] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal,setShowModal] = useState(false);
  const [reportUrl, setReportUrl] = useState("");


  const [NewReports, setNewReports] = useState([
    { id: nanoid(), name: "", file: "" },
  ]);
  const handleViewClick = (row) => {
    setSelectedAppointmentId(row.appointment_id);
    setshowDetailsModal(true);
  };
  const handleToggleIsReports = () => {
    setIsReports(prev=>!prev);
  }

  const FetchReports = async () => {
    setReportsLoading(true);
    const reportsResponse = await getPatientReports(
      {patient_id: patientId?.replace("SDP", "")}
    );
    if(reportsResponse.status){
      setReportsData(reportsResponse.data);
    }
    setReportsLoading(false);
  }

  const FetchAppointments = async () => {
    setDataLoading(true);
    const startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : null;
    const endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : null;
    const { appointments, total_count } = await getPatientAppointments(
      patientId?.replace("SDP", ""),
      (Pageno - 1) * appointmentsPerPage,
      appointmentsPerPage,
      searchValue,
      startDate,
      endDate
    );
    setTotalAppointmentsCount(total_count);
    setAppointmentsHistory(appointments);
    setDataLoading(false);
  };

  const updateReports = (id, name, value) => {
    const newReports = [...NewReports];
    const index = newReports.findIndex((item) => item.id === id);
    newReports[index][name] = value;
    setNewReports(newReports);
  };
  const updateAllData = async () => {
    let isValid = true;
    setIsBtnLoading(true);
    let ReportArray = [];
    NewReports.forEach((item, index) => {
      let a = {};
      a.report_title = item.name;
      a.report = item.file;
      if (a.report_title && a.report) {
        ReportArray.push(a);
      } else {
        isValid = false;
      }
    });
    if (!isValid) {
      //message.error("Report Title and File can't be empty");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Report Title and File can't be empty"
      });
      setIsBtnLoading(false);
      return;
    }

    const formData = new FormData();
    ReportArray.map((item, index) => {
      formData.append(
        `upload_reports[${index}][clinic_report_title]`,
        item.report_title
      );
      formData.append(`upload_reports[${index}][report]`, item.report);
      return null;
    });
    formData.append("patient_id",patientId?.replace("SDP",""));
    const response = await addPatientReports(formData);
    if (response?.status === 200) {
      setIsBtnLoading(false);
      //message.success("Reports updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Reports updated successfully"
      });
      FetchReports();
      setNewReports([{ id: nanoid(), name: "", file: "" }]);
    } else {
      setIsBtnLoading(false);
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
      });
    }
  };
  const handleEditReport = async (record) => {
    setNewReports([{
      id: nanoid(),
      index: record.report_index,
      file: "",
      name: record.report_title,
      clinic_id: record.clinic_id,
    }]);
    setIsEdit(true)
  };
  const handleUpdatePatientReport = async () => {
    setIsBtnLoading(true);
    if(!NewReports[0].file){
      //message.error("Report file can't be empty.");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Report file can't be empty."
      });
      setIsBtnLoading(true);
      return;
    }
    const formData = new FormData();

    formData.append('clinic_id',NewReports[0].clinic_id);
    formData.append("patient_id",patientId?.replace("SDP",""));
    formData.append("index",Number(NewReports[0].index));
    formData.append(
      `upload_reports[clinic_report_title]`,
      NewReports[0].name
    );
    formData.append(`file`, NewReports[0].file);
    const response = await updatePatientReports(formData);
    if (response?.status === 200) {
      //message.success("Report updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Report updated successfully"
      });
      setNewReports([{ id: nanoid(), name: "", file: "" }]);
      setIsEdit(false);
    } else {
      //message.error(response?.error || "Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: response?.error || "Something went wrong"
      });
    }
    setIsBtnLoading(false);
  };
  const handleDeleteReport = async (record) => {
    try{
        const deleteResponse = await deletePatientReport({
          id: record.report_clinic_Id,
          index: record.report_index,
          clinic_id: record.clinic_id,
        });
        //message.success(deleteResponse.data);
        AntdCustomMessage({
          type: antdMessageTypes.SUCCESS,
          content: deleteResponse.data
        });
        FetchReports();
    }catch(e){
        //message.error(e.message || e.data || "Something went wrong.")
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: e.message || e.data || "Something went wrong."
        });
    }
    
};
const handleShowModal = ()=>{
  setShowModal(true);
}
const handleSetUrl = (url) => {
  setReportUrl(url);
}

  useEffect(() => {
    const FetchData = async () => {
      setDetailsLoading(true);
      const { profile } = await getPatientDetails(patientId);
      setData(profile[0]);
      setDetailsLoading(false);
    };

    patientId && FetchData();
    patientId && FetchReports();
  }, [patientId]);

  useEffect(() => {
    patientId && FetchAppointments();
  }, [patientId, Pageno, date, searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.match.params.patientId) {
      setPatientId(DecryptString(props.match.params.patientId));
    }
  }, [props.match.params.patientId]);

  return (
    <div className="content">
      <Modal
        destroyOnClose={true}
        className="item-details-modal p-5"
        title="Report"
        visible={showModal}
        footer={null}
        width={700}
        onCancel={() => setShowModal(false)}
      >
        <div className="p-5">
         <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center", margin:"1px"}}>
        {
            reportUrl && !reportUrl.endsWith(".pdf")?(
              reportUrl && <img 
              src={reportUrl} 
              alt={`report-${reportUrl}`}
              style={{width:"400px",height:"380px",borderRadius:"5px"}}
            />
            ):(
                <iframe
                  title="PDF Viewer"
                  src={`https://docs.google.com/gview?url=${reportUrl}&embedded=true`}
                  width="80%"
                  height="500"
                  frameBorder="0"
                />
            )
          }
        </div>
        </div>
      </Modal>
      <Button style={{ marginBottom: "1rem" }} onClick={() => history.goBack()}>
        Back to patients
      </Button>
      <Button style={{ marginBottom: "1rem", marginLeft: "1rem" }} onClick={() => {
        handleToggleIsReports();
      }}>
        {isReports ? "Appointment History" : "Reports"}
      </Button>
      {
        !isReports ? (
          <div>
            <PatientsInfoCard data={data} DetailsLoading={DetailsLoading} />

            <div className="appointments-history-container">
              <Title
                level={3}
                style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
              >
                Appointment History
              </Title>
              <Divider style={{ margin: "12px 0px" }} />

              <Row
                style={{
                  display: "flex",
                  position: "relative",

                  justifyContent: "space-between",
                }}
              >
                <Col md="6" sm="6" xs="12">
                  <RangePicker
                    // className="shadow-picker"
                    style={{
                      width: "100%",
                      height: "40px",
                      marginBottom: isMobile ? 10 : 0,
                    }}
                    value={date}
                    ranges={DateRanges()}
                    format={DATE_FORMAT}
                    onChange={(e) => {
                      e === null ? setDate([null, null]) : setDate(e);
                    }}
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  />
                </Col>
                <Col md="6" sm="6" xs="12">
                  <Input
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    placeholder="Search"
                    style={{
                      alignSelf: isMobile ? "center" : "flex-end",
                      width: isMobile ? "100%" : "70%",
                      float: isMobile ? "" : "right",
                    }}
                    prefix={<SearchOutlined />}
                    size="large"
                  />
                </Col>
              </Row>

              {DataLoading ? (
                TableSkeletonLoader(8, 30)
              ) : appointmentsHistory?.length ? (
                <List
                  className="appointments-page patient"
                  dataSource={appointmentsHistory}
                  pagination={{
                    current: Pageno,
                    total: totalAppointmentsCount,
                    onChange: (page) => {
                      setPageNo(page);
                    },
                    pageSize: appointmentsPerPage,
                  }}
                  renderItem={(appointment) => (
                    <List.Item>
                      <AppointmentCard
                        history={true}
                        appointment={appointment}
                        FetchData={FetchAppointments}
                        setSelectedAppointmentId={setSelectedAppointmentId}
                        handleViewClick={handleViewClick}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Empty
                  description="No Appointments Found"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            </div>

            <Modal
              className="item-details-modal"
              title="Appointment Details"
              visible={showDetailsModal}
              footer={null}
              width={700}
              destroyOnClose={true}
              onCancel={() => setshowDetailsModal(false)}
            >
              <DetailsCard appointmentId={selectedAppointmentId} />
            </Modal>
          </div>
        ):(
          <div>
            <Card>
              <Form layout="vertical" className="report-add-container">
                <div 
                  style={{ 
                    paddingBottom: "5px",
                    marginBottom: "1rem", 
                    borderBottom:"2px solid rgba(230,230,230,0.8)",
                    display: "flex", 
                    justifyContent: "space-between", 
                  }}>
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    {isEdit ? "Edit Report" : "Add Reports"}{" "}
                    <span style={{ fontSize: 12 }}>( Pdf, Jpeg, Jpg, Png )</span>
                  </Typography.Title>
                  {
                    !isEdit ? (
                      <Button
                        onClick={() => {
                          setNewReports([
                            ...NewReports,
                            { id: nanoid(), name: "", file: "" },
                          ]);
                        }}
                        style={{ height: 40, padding: "0px 20px" }}
                        icon={<PlusOutlined />}
                        size="small"
                        type="secondary"
                      >
                        Upload New Report
                      </Button>
                    ): null
                  }
                </div>
                {NewReports.map((report) => (
                  <Row key={report.id} className="report-add-row">
                    <Col xs={6} sm={6} lg={6}>
                      <Form.Item label="Report Title">
                        <Input
                          size="medium"
                          placeholder="Report Title"
                          style={{ width: "100%" }}
                          value={report.name}
                          onChange={(e) =>
                            updateReports(report.id, "name", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={5} sm={5} lg={5}>
                    <Form.Item label="Report File">
                  <Input
                    type="file"
                    name="file"
                    accept=".jpeg, .jpg, .png, .pdf"
                    onChange={(e) =>{
                      //check image size here
                      const file = e?.target?.files[0];
                      const fileSize = file?.size;
                      if(
                        fileSize < Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES) || 
                        fileSize > Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES)
                      ){
                        AntdCustomMessage({
                          type : antdMessageTypes.ERROR,
                          content : `Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
                            getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} and ${
                            getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}.`,
                        });
                        updateReports(report.id, "file", "");
                        return;
                      }else{
                        updateReports(report.id, "file",  e?.target?.files[0]);
                      }
                    }}
                  /> 
                  {
                    !report.file ? (
                      <div className="flex flex-row justify-center items-center text-center">
                        <p>
                          <span style={{color:"red"}}>*</span>Updating file size should be between 
                          <span className="font-weight-bold text-black"> 
                            &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MIN_SIZE_IN_BYTES))} 
                          </span>
                          &nbsp;and 
                          <span className="font-weight-bold text-black">
                            &nbsp;{getReadableFileSize(Number(process.env.REACT_APP_BANNER_IMAGE_MAX_SIZE_IN_BYTES))}
                          </span>.
                        </p>
                      </div>
                    ):null
                  }
                </Form.Item>
                    </Col> 
                    {
                      !isEdit ? (
                        <Col
                          xs={1}
                          sm={1}
                          lg={1}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <CloseCircleFilled
                            onClick={() => {
                              const newReports = [...NewReports];
                              const index = newReports.findIndex(
                                (item) => item.id === report.id
                              );
                              newReports.splice(index, 1);
                              setNewReports(newReports);
                            }}
                            style={{ fontSize: "18px", color: "#ff4040", marginLeft: 15 }}
                          />
                        </Col>
                      ) : null
                    }
                  </Row>
                ))}
              </Form>
              <Button
                loading={isBtnLoading}
                onClick={isEdit? handleUpdatePatientReport : updateAllData}
                size="large"
                type="primary"
                style={{
                  float: "right",
                }}
                disabled={NewReports.length > 0 ? false : true}
              >
                {isEdit ? "Update Report" : "Save Reports"}
              </Button>
            </Card>
            {
              !isEdit ? (
                <Card style={{marginTop:"1rem"}}>
                  <Table
                    rowKey="id"
                    dataSource={reportsData?.map((item, index) => {
                      return {
                        id: index,
                        report_clinic_Id: item.id,
                        clinic_id: item.clinic_id,
                        clinic_name: item.clinic_name,  
                        report_index: item.index,
                        report_title: item.title,
                        report: item.report
                      };
                    })}
                  >
                    <Table.Column 
                      title="Sl No" 
                      dataIndex="id" 
                      key="id" 
                      render={(text, record) => (
                        <p>
                          {record.id + 1}
                        </p>
                      )}
                    />
                    <Table.Column
                      title="Report Title"
                      dataIndex="report_title"
                      key="report_title"
                    />
                    <Table.Column
                      title="Uploaded By Clinic"
                      dataIndex="clinic_name"
                      key="clinic_name"
                    />
                    {/* <Table.Column
                      title="Report Url"
                      dataIndex="report"
                      key="report"
                    /> */}
                    <Table.Column
                      key="delete"
                      dataIndex="delete"
                      title="Delete"
                      render={(text, record) => {
                        if(UserData.userid === record.clinic_id){
                          return (
                            <Popconfirm
                              title={`Are you sure you want to delete report ${record.report_title}?`}
                              onConfirm={() => handleDeleteReport(record)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                            </Popconfirm>
                          )
                        }else{
                          return null
                        } 
                      }}
                    />
                    <Table.Column
                      key="change"
                      dataIndex="change"
                      title="Change"
                      render={(text, record) =>{
                        if(UserData.userid === record.clinic_id){
                          return (
                            <Popconfirm
                            title={`Are you sure you want to edit report ${record.report_title}?`}
                            onConfirm={() => handleEditReport(record)}
                            okText="Yes"
                            cancelText="No"
                            >
                              <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
                            </Popconfirm>
                          )
                        }else{
                          return null
                        }
                      }}
                    />
                    <Table.Column
                      key="download"
                      dataIndex="download"
                      title="Download"
                      render={(text, record) => (
                      <button
                        onClick={()=>{
                          handleSetUrl(record.report);
                          handleShowModal();
                        }}
                        className="rounded"
                      >
                        View
                      </button>
                      )}
                    />
                  </Table>
                </Card>
              ):null
            }
          </div>
        )
      }
    </div>
  );
};

export default PatientDetails;
