import React from "react";
import HospitalTabs from "./Tabs";
import { HospitalsContextProvider } from "./useHospitals";

const Hospitals = () => {
  return (
    <HospitalsContextProvider>
      <HospitalTabs />
    </HospitalsContextProvider>
  );
};

export default Hospitals;
