import React from "react";
import { Card, CardBody } from "reactstrap";
import { MedicineBoxOutlined } from "@ant-design/icons";

const ListCard = ({ CardOnClick, practitioner, ActionButtons }) => {
  return (
    <div className="practitioner-list-card">
      <Card className="card-stats" style={{ padding: "10px" }}>
        <CardBody style={{ padding: "0px", position: "relative" }}>
          <div onClick={() => CardOnClick(practitioner)}>
            <div
              className="profile-image"
              style={
                practitioner.practitioner_profile_image
                  ? {
                      backgroundImage: `url(${practitioner.practitioner_profile_image})`,
                    }
                  : {}
              }
            >
              {!practitioner.practitioner_profile_image && (
                <div className="char-avatar">
                  <span>{practitioner.first_name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
            <div className="info-container">
              <h4 className="capitalize text-ellipsis">
                {practitioner.first_name + " " + practitioner.last_name}
                {/* <EllipsisText
              className="capitalize"
                text={patient.patient_name}
                length={25}
              /> */}
              </h4>
              <p className="department">
                <MedicineBoxOutlined />
                {practitioner.practitioner}
              </p>
            </div>

            {/* <p className="job-title">
              <AlertOutlined className="job-title-icon" />
              <EllipsisText text={practitioner.jobtitle} length={85} />
            </p> */}
          </div>
          {ActionButtons}
        </CardBody>
      </Card>
    </div>
  );
};

export default ListCard;
