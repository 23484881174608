import React, { useState, useEffect } from "react";
import EllipsisText from "react-ellipsis-text";
import { Card } from "antd";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { getPaymentDetails } from "Utils/Services/Hospital/Payments";
import { getDateTimeFormat } from "Utils/Constants";

const DetailsCard = ({ orderId, appointmentData }) => {
  const [data, setData] = useState("");
  
  useEffect(() => {
    const getData = async () => {
      getPaymentDetails(orderId).then((response) => {
        setData(response?.payment_details[0]);
      });
    };

    if (orderId) {
      getData();
    }
  }, [orderId]);

  const OrderDetails = () => {
    return (
      <div className="general-info">
        <Row lg="9" md="12" sm="12" className="column-items">
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray">Appointment date</h4>
              <h4 className="text-black">
                {data?.appointment_date ? data.appointment_date : ""}
              </h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray">Appointment time</h4>
              <h4 className="text-black">
                {data?.appointment_time ? data.appointment_time : ""}
              </h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray">Appointment type</h4>
              <h4 className="text-black">
                {data?.appointment_type_name ? data.appointment_type_name : ""}
              </h4>
            </div>
          </Col>
        </Row>
        <Card
          style={{ width: "100%", padding: "10px", marginTop: 10 }}
          bodyStyle={{ padding: "0px 0px 10px" }}
        >
          <div className="details-wraper">
            <label>Booked on:</label>
            <h4 className="text">
              {data?.created_date ? getDateTimeFormat(data.created_date) : ""}
            </h4>
          </div>
          <div className="details-wraper">
            <label>Practitioner name:</label>
            <h4 className="text capitalize">
              {data?.practitioner_name ? data.practitioner_name : ""}
            </h4>
          </div>
          <div className="details-wraper">
            <label>Amount:</label>
            <h4 className="text">{data?.amount ? data.amount : ""}</h4>
          </div>
          <div className="details-wraper">
            <label>Payment status:</label>
            <h4 className="text">
              {data?.payment_status ? appointmentData?.paymentStatus : ""}
            </h4>
          </div>
          {
           appointmentData?.paymentStatus  == "Refund" ? (
            <div className="details-wraper">
            <label>AmountRefund:</label>
            <h4 className="text">₹{appointmentData?.refundAmount}</h4>
          </div>
          ):null
         }
        </Card>
      </div>
    );
  };

  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <span className="appointment-id">{data?.order_id}</span>
        <h4 className="mt-2">
          <EllipsisText
            className="capitalize"
            text={data?.patient_name ? data.patient_name : " "}
            length={"50"}
          />
        </h4>
      </div>
      <div className="order-details-container">
        <OrderDetails />
      </div>
    </div>
  );
};
export default DetailsCard;
