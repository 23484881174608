import { SearchOutlined } from "@ant-design/icons";
import { Col, DatePicker, Empty, Input, List, Modal, Row, Select } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRanges, DATE_FORMAT, TableSkeletonLoader } from "Utils/Constants";
import { getAppointmentStatusTypes } from "Utils/Services/Lab/Appointments";
import { getLabAppointments } from "Utils/Services/Lab/Appointments";
import AppointmentLabCard from "views/components/Lab/Appointments/AppointmentLabCard";
import LabAppointmentsDetailsCard from "views/components/Lab/Appointments/LabAppointmentsDetailsCard";

const HospitalAppointments = () => {
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);
  const history = useHistory();
  const [Data, setData] = useState([]);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [DataLoading, setDataLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const appointmentsPerPage = 12;
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [appointmentStatusTypes, setAppointmentStatusTypes] = useState([]);
  const [selectedStatusType, setSelectedStatusType] = useState("");
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState([null, null]);
  const { RangePicker } = DatePicker;

  const handleViewClick = (row) => {
    setSelectedAppointmentId(row.appointment_id);
  };

  const FetchData = async (date1 = null, date2 = null) => {
    setDataLoading(true);
    const startDate = date1 ? moment(date1).format("YYYY-MM-DD") : null;
    const endDate = date2 ? moment(date2).format("YYYY-MM-DD") : null;
    const response = await getLabAppointments({
      start: (pageNo - 1) * appointmentsPerPage,
      limit: appointmentsPerPage,
      appointment_status: selectedStatusType,
      search: searchValue,
      startDate,
      endDate,
    });
    if (response) {
      setData(response.appointments);
      setTotalAppointmentsCount(response.total_count);
    }
    setAppointmentStatusTypes(await getAppointmentStatusTypes());
    setDataLoading(false);
  };

  useEffect(() => {
    FetchData(date[0], date[1]);
  }, [date, pageNo, selectedStatusType, searchValue]); // eslint-disable-line

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Appointments");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalAppointmentsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalAppointmentsCount]);

  return (
    <div className="content">
      <Modal
        destroyOnClose={true}
        className="item-details-modal"
        title="Appointment Details"
        visible={selectedAppointmentId !== null}
        footer={null}
        width={700}
        onCancel={() => setSelectedAppointmentId(null)}
      >
        <LabAppointmentsDetailsCard appointmentId={selectedAppointmentId} />
      </Modal>

      <div className="appointments-container">
        <Row
          className="appointments-page-header"
          gutter={[0, 10]}
          justify="space-between"
          style={{ marginBottom: "10px" }}
        >
          <Col lg={9} md={8} sm={12} xs={24}>
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              // disabledDate={(current) =>
              //   current && current > moment().endOf("day")
              // }
            />
          </Col>

          <Col lg={6} md={8} sm={12} xs={24}>
            <Select
              style={{ width: "100%" }}
              defaultValue="All Status"
              size="medium"
              className="shadow-select"
              onChange={(value) => setSelectedStatusType(value)}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              <Option value="">All Status</Option>
              {appointmentStatusTypes &&
                appointmentStatusTypes.map((item) => (
                  <Option value={item.status} key={item.name}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col lg={8} md={8} sm={12} xs={24}>
            <Input
              className="shadow-input"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>

        {DataLoading ? (
          TableSkeletonLoader(10, 60)
        ) : Data?.length ? (
          <List
            className="appointments-page"
            dataSource={Data}
            pagination={{
              current: pageNo,
              total: totalAppointmentsCount,
              onChange: (page) => {
                setPageNo(page);
              },
              pageSize: appointmentsPerPage,
            }}
            renderItem={(appointment) => (
              <List.Item>
                <AppointmentLabCard
                  history={history}
                  appointment={appointment}
                  FetchData={FetchData}
                  setSelectedAppointmentId={setSelectedAppointmentId}
                  handleViewClick={handleViewClick}
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No Appointments Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};
export default HospitalAppointments;
