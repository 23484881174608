import React from "react";

const FullWidthView = ({ children, ...restProps }) => {
  return (
    <div style={{ width: "100%" }} {...restProps}>
      {children}
    </div>
  );
};

export default FullWidthView;
