import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import BugContainer from "components/ErrorContainer/BugContainer";
import FullWidthView from "components/FullWidthView";
import { GlobalState } from "context-api";
import { modelGeneralInformationPayload } from "models/practitioner/profile.api.model";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Spinner from "reactstrap/lib/Spinner";
import {
  getProfileGeneralInformation,
  updateGeneralInformation,
} from "Utils/Services/Practitioner/Profile";
import { validateMessages } from "Utils/validations";
import Compressor from 'compressorjs';
import { getReadableFileSize } from "Utils/GetReadableFileSize";

const GeneralInformation = () => {
  const [data, setData] = useState({ status: false, data: {} });
  const [newUserPhoto, setNewUserPhoto] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { setAccountImage } = useContext(GlobalState);
  const [showSizeError, setShowSizeError] = useState(false);
  const [sizeErrorMessage, setSizeErrorMessage] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    let profile = await getProfileGeneralInformation();
    profile && setData(profile);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const compressAndResizeImage = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.5, // Adjust quality as per your requirement (0 to 1)
        maxWidth: Number(process.env.REACT_APP_PROFILE_PIC_MAX_WIDTH_PX),
        maxHeight: Number(process.env.REACT_APP_PROFILE_PIC_MAX_HEIGHT_PX),
        minWidth: Number(process.env.REACT_APP_PROFILE_PIC_MIN_WIDTH_PX),
        minHeight: Number(process.env.REACT_APP_PROFILE_PIC_MIN_HEIGHT_PX),
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
  };

  const handleProfileImageChange = async (event) => {
    event.persist();
    const file = event?.target?.files[0];
    const fileSize = file?.size;
    if(
      fileSize < process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES ||
      fileSize > process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES
    ){
      message.error(`Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.`);
      setShowSizeError(true);
      setSizeErrorMessage(`Uploaded file is of size ${getReadableFileSize(fileSize)}, File size should be between ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
        getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.`);
      return;
    }

    const resizedImage = await compressAndResizeImage(file);
    setNewUserPhoto(resizedImage);
    setShowSizeError(false);
  }
  const onFinish = async (values) => {
    setIsBtnLoading(true);

    if(newUserPhoto){
      // code for checking image size and dimensions
      if(
        newUserPhoto.size < process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES ||
        newUserPhoto.size > process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES
      ){
        message.error(`Uploaded file is of size ${
          getReadableFileSize(newUserPhoto.size)}, File size should be between ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.`);
        setShowSizeError(true);
        setSizeErrorMessage(`Uploaded file is of size ${
          getReadableFileSize(newUserPhoto.size)}, File size should be between ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} and ${
          getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}.`);
        setIsBtnLoading(false);
        return;
      }
      setShowSizeError(false);
      const img = new Image();
      img.onload = () => {
        if(
          !img.width <= process.env.REACT_APP_PROFILE_PIC_MAX_WIDTH_PX &&
          !img.width >= process.env.REACT_APP_PROFILE_PIC_MIN_WIDTH_PX &&
          !img.height <= process.env.REACT_APP_PROFILE_PIC_MAX_HEIGHT_PX &&
          !img.height >= process.env.REACT_APP_PROFILE_PIC_MIN_HEIGHT_PX 
        ){
          message.error(`Uploaded Image is of ${img.width}*${img.height}px.
            Please upload image with required dimensions.`);
          setShowSizeError(true);
          setSizeErrorMessage(`Uploaded Image is of ${img.width}*${img.height}px.
            Please upload image with required dimensions.`);
          setIsBtnLoading(false);
          return;
        }
      }
      img.error = (error) => {
        throw new Error('Failed to load image');
      }
      img.src = URL.createObjectURL(newUserPhoto);
      // end of checking size and dimensions
    }

    const { first_name, last_name, telephone, gender } = values;
    let payload = {
      first_name,
      last_name,
      telephone: telephone,
      gender,
      practitioner_profile_video: values.practitioner_profile_video,
      ...(newUserPhoto && { practitioner_profile_image: newUserPhoto }),
    };
    const response = await updateGeneralInformation(
      modelGeneralInformationPayload(payload)
    );
    if (response?.status) {
      message.success("Profile updated successfully");
      setShowSizeError(false);
      setSizeErrorMessage("");
    } else {
      message.error("Something went wrong.");
      //setting the new profile image to the global state
      newUserPhoto && setAccountImage(newUserPhoto);
    }
    setIsBtnLoading(false);
  };

  if (isLoading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  let { practitioner_profile_image } = data.data;
  return (
    <Card>
      {data?.status ? (
        <>
          <Row justify="center">
            <Avatar
              size={{ xs: 80, sm: 80, md: 40, lg: 64, xl: 80, xxl: 100 }}
              icon={
                !newUserPhoto ? (
                  practitioner_profile_image ? (
                    <img src={practitioner_profile_image} alt="user" />
                  ) : (
                    <UserOutlined />
                  )
                ) : (
                  <img src={URL.createObjectURL(newUserPhoto)} alt="user" />
                )
              }
            />
          </Row>
          <Row
            justify="center"
          >
            <div>
              <div className="flex flex-row justify-center items-center text-center">
                <p>
                  Profile Image size should be between 
                  <span className="font-weight-bold"> 
                    &nbsp;{getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MIN_SIZE_IN_BYTES)} 
                  </span>
                  &nbsp;and 
                  <span className="font-weight-bold">
                    &nbsp;{getReadableFileSize(process.env.REACT_APP_PROFILE_PIC_MAX_SIZE_IN_BYTES)}
                  </span>.
                </p>
              </div>
              {
                showSizeError && (
                  <div className="flex flex-row justify-center items-center text-center">
                    <p
                      style={{color:"red"}}
                    >
                      * {sizeErrorMessage}
                    </p>
                  </div>
                )
              }
            </div>
          </Row>
          <Row
            justify="center"
            style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
          >
            <input
              style={{ display: "none" }}
              type="file"
              accept="image/jpeg,image/png"
              id="file-upload"
              onChange={handleProfileImageChange}
            />
            <label
              htmlFor="file-upload"
              style={{
                cursor: "pointer",
                backgroundColor: "white",
                padding: "5px",
                paddingLeft: "12px",
                paddingRight: "12px",
                border: "1px solid #e8e8e8",
                borderRadius: "7px",
              }}
            >
              Upload photo
            </label>
          </Row>
          <Form
            layout="vertical"
            name="nest-messages"
            validateMessages={validateMessages}
            initialValues={data.data || {}}
            onFinish={onFinish}
          >
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["practitioner_id"]}
                  label="Practitioner ID"
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    //value={data?.id}
                    readOnly
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["email"]}
                  label="Email"
                  rules={[{ type: "email", required: true }]}
                >
                  <Input size="large" readOnly disabled />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["medical_license_number"]}
                  label="Medical License Number"
                  rules={[{ required: true }]}
                >
                  <Input size="large" readOnly disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["first_name"]}
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["last_name"]}
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["mobile"]}
                  label="Mobile Number"
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    //value={data?.mobile}
                    readOnly
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name={["telephone"]} label="Telephone Number">
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["url_name"]}
                  label="Url Name"
                  rules={[{ required: false }]}
                >
                  <Input size="large" disabled readOnly />
                </Form.Item>
              </Col>
            </Row>
            {/* row ends here */}
            {/* row starts here */}
            <Row gutter={{ sm: 8, lg: 24 }}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item name={["gender"]} label="Gender">
                  <Select
                    optionFilterProp="children"
                    size="large"
                    placeholder="Please select gender"
                    style={{ width: "100%" }}
                  >
                    <Select.Option key="male">Male</Select.Option>
                    <Select.Option key="female">Female</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name={["practitioner_profile_video"]}
                  label=" Profile introductory Video (Please upload video on Youtube or Vimeo and place the link here.)"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if(value){
                          const youtubeRegex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                          const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(\d+)(?:.*)/;

                          // Check if the value matches either regex
                          if (!(youtubeRegex.test(value) || vimeoRegex.test(value))) {
                            return Promise.reject('Invalid URL!'); // Reject if both regex checks fail
                          }
                          return Promise.resolve(); 
                        }else{
                          return Promise.resolve();
                        }
                        
                      },
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isBtnLoading}
                style={{ height: 40 }}
                disabled={showSizeError}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <BugContainer />
      )}
    </Card>
  );
};

export default GeneralInformation;