import React from "react";
import { ScheduleContextProvider } from "./useSchedule";
import ScheduleTimingsContainer from "./ScheduleTimingContainer";

const ScheduleTimings = () => {
  return (
    <ScheduleContextProvider>
      <ScheduleTimingsContainer />
    </ScheduleContextProvider>
  );
};

export default ScheduleTimings;
