export const modelWorkHorsSlotAPI = async (response) => {
  let result = {
    slots: {},
    dates: [],
    appointmentTypes: [],
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (typeof response.data !== "object") throw new Error("Invalid response");
    result.slots = response?.data?.slots || {};
    if (Array.isArray(response?.data?.datesWithSlots)) {
      result.dates = response?.data?.datesWithSlots || [];
    }
    if (Array.isArray(response?.data?.appointmentTypes)) {
      result.appointmentTypes = response?.data?.appointmentTypes || [];
    }
  } catch {
    result = {
      slots: {},
      dates: [],
    };
  } finally {
    return result;
  }
};
