import PhoneInput from "react-phone-input-2";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import { getCountryDialercodes } from "Utils/Services/Hospital/Patient";
import { getCountryDialercodes as getCountryDialercodesPractitioner } from "Utils/Services/Practitioner/QuickBooking";
import { getCountryDialercodesLab } from "Utils/Services/Lab/PatientRegistration";
import { Alert } from "antd";

const PhoneInputComponent = (props) => {
  const [countryDialercodes, setCountryDialercodes] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let countryDilaerCodesResponse;
      if (props?.type === "lab")
        countryDilaerCodesResponse = await getCountryDialercodesLab();
      else if (props?.type === "practitioner")
        countryDilaerCodesResponse = await getCountryDialercodesPractitioner();
      else countryDilaerCodesResponse = await getCountryDialercodes();
      setCountryDialercodes(
        countryDilaerCodesResponse.map((item) =>
          item.country_code.toLowerCase()
        )
      );
    };
    fetchData();
  }, [props?.type]);

  const {
    idFor,
    label,
    onChange = () => {},
    placeholder,
    value,
    error = false,
    errorMessage = "",
    labelClasses = "",
    country = "in",
    disableDropdown = false,
    ...restProps
  } = props || {};
  return countryDialercodes ? (
    <div className="w-full">
      {label ? (
        <label
          className={classNames(
            labelClasses,
            "block uppercase tracking-wide text-font-body text-xs font-bold mb-2"
          )}
          htmlFor={idFor}
        >
          {label}
        </label>
      ) : null}
      <PhoneInput
        onlyCountries={countryDialercodes}
        enableSearch={true}
        country={country}
        value={value}
        autoFormat={false}
        onChange={onChange}
        placeholder={placeholder}
        containerStyle={{
          display: "flex",
          borderRadius: "0.25rem",
          textTransform: "uppercase",
          // border: "1px solid #e5e7eb",
        }}
        dropdownStyle={{
          border: "border: 1px solid #e5e7eb",
        }}
        inputStyle={{
          flex: 1,
          color: "#4B5966",
          border: "1px solid #e5e7eb",
          background: "#f3f4f6",
          height: "40px",
        }}
        disableDropdown={disableDropdown}
        countryCodeEditable={false}
        {...restProps}
      />
      {error ? (
        <Alert
          message={errorMessage}
          type="error"
          style={{ marginTop: "9px", marginBottom: "0px" }}
        />
      ) : null}
    </div>
  ) : (
    <p>loading</p>
  );
};

export default PhoneInputComponent;
