import React, { useState, createContext, useContext } from "react";

export const ReviewsContext = createContext();

export const ReviewsContextProvider = (props) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState({
    status: false,
    data: {
      count: 0,
      reviews: [],
    },
  });
  const [selectedReviewId, setSelectedReviewId] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const getReviewDetails = async (id) => {
    let result = {
      status: false,
      data: {},
    };
    try {
      //find review details from data with id
      const review = data?.data?.reviews?.find((review) => review.id === id);
      if (!review) throw new Error("review not found");
      result.status = true;
      result.data = review;
    } catch {
      result = {
        status: false,
        data: {},
      };
    } finally {
      return result;
    }
  };

  const values = {
    data,
    setData,
    searchKeyword,
    setSearchKeyword,
    getReviewDetails,
    selectedReviewId,
    setSelectedReviewId,
    showDetailsModal,
    setShowDetailsModal,
  };

  return <ReviewsContext.Provider value={values} {...props} />;
};

export const useReviews = () => {
  const context = useContext(ReviewsContext);
  if (context === undefined)
    throw new Error(`must be used within a UserContextProvider.`);
  return context;
};
