import { Button, Card, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import EllipsisText from "react-ellipsis-text";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { getDateTimeFormat } from "Utils/Constants";
import { getAgeFromDOB } from "Utils/GetAgeFromDOB";
import { getPackageAppointmentDetails } from "Utils/Services/Hospital/Appointments";

const { TabPane } = Tabs;

const PackageDetailCard = ({ appointmentId }) => {
  const [data, setData] = useState("");
  const Status = data?.appointment_status;
  const PaymentMethod = data?.payment_method;

  const GetReportDownloadButton = (report) => {
    let buttons = [];
    const data = report.report;
    for (const key in data) {
      buttons.push(
        <Button
          style={{ marginRight: 10 }}
          key={data[key]}
          onClick={() => window.open(data[key])}
        >{`${key}`}</Button>
      );
    }
    return buttons.map((btn) => btn);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getPackageAppointmentDetails({
        appointment_id: appointmentId,
      });
      response && setData(response);
    };
    if (appointmentId) {
      getData();
    }
  }, [appointmentId]);

  const SingleDetails = ({ name, data }) => {
    return (
      <div className="details-wraper">
        <label style={{ fontSize: 14 }}>{name}</label>
        <h4
          className="text"
          style={{ fontSize: 14, textTransform: "capitalize" }}
        >
          {data ? data : ""}
        </h4>
      </div>
    );
  };

  const AppointmentTab = () => {
    return (
      <div className="general-info">
        <Row lg="9" md="12" sm="12" className="column-items">
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Package
              </h4>
              <h4 className="text-black" style={{ fontSize: 16 }}>
                {data?.package_name ? data.package_name : ""}
              </h4>
            </div>
          </Col>
          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Booked On
              </h4>
              <h4 className="text-black" style={{ fontSize: 16 }}>
                {data?.booked_on ? getDateTimeFormat(data?.booked_on) : ""}
              </h4>
            </div>
          </Col>

          <Col lg="4" md="4" sm="6" className="item column-item">
            <div className="column-item-inner">
              <h4 className="label-gray" style={{ fontSize: 16 }}>
                Appointment Status
              </h4>
              <h4
                className="text-black"
                style={{ fontSize: 16, textTransform: "capitalize" }}
              >
                {Status ? Status : ""}
              </h4>
            </div>
          </Col>
        </Row>
        <Card
          style={{ width: "100%", padding: "10px" }}
          bodyStyle={{ padding: "0px 0px 10px" }}
        >
          <SingleDetails name="Platform" data={data?.platform} />

          {/* {Status !== "pending" && Status !== "approved" ? (
            <SingleDetails
              name="Appointment Date"
              data={
                moment(data?.appointment_date).isValid()
                  ? moment(data.appointment_date).format("DD-MMM-yyyy hh:mm A")
                  : ""
              }
            />
          ) : null} */}

          {Status !== "cancelled" &&
          Status !== "completed" &&
          Status !== "check-in" ? (
            <SingleDetails
              name="Appointment End Date"
              data={getDateTimeFormat(data?.appointment_end_date)}
            />
          ) : null}
          {Status !== "pending" &&
          Status !== "approved" &&
          Status !== "cancelled" ? (
            <SingleDetails
              name="Check In Time"
              data={getDateTimeFormat(data?.checkin_time)}
            />
          ) : null}
          {Status === "completed" ? (
            <SingleDetails
              name="Checkout Time"
              data={getDateTimeFormat(data?.checkout_time)}
            />
          ) : null}

          {data?.cancelled_time && (
            <>
              <SingleDetails
                name="Cancelled Time"
                data={
                  data?.cancelled_time
                    ? moment(data?.cancelled_time).format("DD-MMM-yyyy hh:mm A")
                    : ""
                }
              />
              <SingleDetails
                name="Cancelled User"
                data={data?.cancelled_user}
              />
            </>
          )}
        </Card>
      </div>
    );
  };

  return (
    <div className="table-item-details-card p-6">
      <div className="profile-info">
        <span className="appointment-id">{data?.order_id}</span>
        <h4 className="mt-2" style={{ textTransform: "capitalize" }}>
          <EllipsisText
            style={{ fontSize: 16, fontWeight: "bold" }}
            className="capitalize"
            text={data?.patient_name ? data.patient_name : " "}
            length={50}
          />
        </h4>
        {data?.dob && data?.dob !== "" && (
          <div className="dob-wrapper">
            <span className="label">Dob:</span>
            <span className="dob">{data?.dob}</span>
          </div>
        )}
        {data?.dob && data?.dob !== "" && (
          <div className="dob-wrapper">
            <span className="label">Age:</span>
            <span className="dob">{getAgeFromDOB(data?.dob)}</span>
          </div>
        )}
      </div>
      <div className="details-tab-container tab-style">
        <Tabs type="card" defaultActiveKey="1">
          <TabPane tab="Appointment" key="1">
            <AppointmentTab />
          </TabPane>

          <TabPane tab="Payments" key="2">
            <div className="general-info">
              <Row lg="9" md="12" sm="12" className="column-items">
                <Col lg="4" md="4" sm="6" className="item column-item">
                  <div className="column-item-inner">
                    <h4 className="label-gray" style={{ fontSize: 16 }}>
                      Amount
                    </h4>
                    <h4 className="text-black" style={{ fontSize: 16 }}>
                      ₹{data?.amount && data.amount}
                    </h4>
                  </div>
                </Col>
                {PaymentMethod === "online" ? (
                  <Col lg="4" md="4" sm="6" className="item column-item">
                    <div className="column-item-inner">
                      <h4 className="label-gray" style={{ fontSize: 16 }}>
                        Payment Status
                      </h4>
                      <h4
                        className="text-black"
                        style={{ fontSize: 16, textTransform: "capitalize" }}
                      >
                        {data?.payment_status ? data.payment_status : ""}
                      </h4>
                    </div>
                  </Col>
                ) : null}
                <Col lg="4" md="4" sm="6" className="item column-item">
                  <div className="column-item-inner">
                    <h4 className="label-gray" style={{ fontSize: 16 }}>
                      Payment Method
                    </h4>
                    <h4
                      className="text-black"
                      style={{ fontSize: 16, textTransform: "capitalize" }}
                    >
                      {PaymentMethod ? PaymentMethod : ""}
                    </h4>
                  </div>
                </Col>
              </Row>
            </div>
          </TabPane>
          {Status === "completed" && data?.lab_reports?.length ? (
            <TabPane tab="Test Reports" key="3">
              <div className="report-container">
                {data?.lab_reports.map((item, index) => (
                  <GetReportDownloadButton report={item} key={index} />
                ))}
              </div>
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};
export default PackageDetailCard;
