import { EditOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, List, message, Modal, Row, Switch, Popconfirm } from "antd";
import { GlobalState } from "context-api";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import PackageDetailsCard from "views/components/packages/PackageDetailsCard";
import { TableSkeletonLoader } from "Utils/Constants";
import {
  GetHospitalPackages,
  ChangeHospitalPackageStatus,
  deleteHospitalPackage,
} from "Utils/Services/Hospital/HospitalPackages";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const HospitalPackages = () => {
  const history = useHistory();
  const ItemsPerPage = 12;

  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [selectedHospitalPackageId, setSelectedHospitalPackageId] =
    useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [PageNo, setPageNo] = useState(1);
  const [TotalItemsCount, setTotalItemsCount] = useState(0);

  const FetchData = async () => {
    setDataLoading(true);
    const response = await GetHospitalPackages({
      startDate: null,
      endDate: null,
      start: (PageNo - 1) * ItemsPerPage + "",
      limit: ItemsPerPage,
      search: searchValue,
    });

    response && setTotalItemsCount(response?.totalCount);
    response && setData(response?.packages);
    setDataLoading(false);
  };

  const updatePackageStatus = async (id, active_flag) => {
    const payload = {
      package_id: id,
      status: active_flag === 0 ? 1 : 0,
    };
    const response = await ChangeHospitalPackageStatus(payload);
    if (response?.success) {
      //message.success("Health package status updated successfully");
      AntdCustomMessage({
        type : antdMessageTypes.SUCCESS,
        content :"Health package status updated successfully",
      });
      FetchData();
    }
  };

  const handleDeletePackage = async (id) => {
    try{
      const confirmation = window.confirm("Are you sure to delete this package?");
      if(confirmation){
        const deleteResponse = await deleteHospitalPackage({id:id});
        if(deleteResponse?.status === 200){
          //message.success(deleteResponse.data);
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content :deleteResponse.data,
          });
          window.location.reload();
        }else{
          //message.error("Something went wrong.");
          AntdCustomMessage({
            type : antdMessageTypes.ERROR,
            content :"Something went wrong.",
          });
        }
      }
    }catch(e){
        //message.error(e.message || e.data || "Something went wrong.")
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content :e.message || e.data || "Something went wrong.",
        });
    }
  }

  useEffect(() => {
    FetchData(); // eslint-disable-line react-hooks/exhaustive-deps
  }, [PageNo, searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Health Packages");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(TotalItemsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [TotalItemsCount]);

  const PackageCard = ({ package_details }) => {
    return (
      <div className="procedure-card">
        <div className="procedure-card-image-container">
          {package_details.package_image ? (
            <img src={package_details.package_image} alt="package" />
          ) : (
            <div
              style={{
                width: "220px",
                height: "210px",
                borderRadius: "7px",
                backgroundColor: "#cfcfcf",
                color: "white",
                fontSize: "40px",
                display: "grid",
                placeItems: "center",
              }}
            >
              No Image
            </div>
          )}
          <div className="procedure-title">
            <p>{package_details.package_name}</p>
          </div>
        </div>

        <div className="procedure-card-actions">
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={package_details.active_flag === 1}
            onChange={() =>
              updatePackageStatus(
                package_details.package_id,
                package_details.active_flag
              )
            }
          />
          <Button
            onClick={() => {
              history.push(
                `/hospital/hospital-package/edit/${package_details?.package_id}/tab=1`
              );
            }}
          >
            <EditOutlined style={{ marginTop: -3 }} />
          </Button>
          <DeleteOutlined 
            style={{ color: 'red', cursor: 'pointer' }} 
            onClick={()=>{handleDeletePackage(package_details.package_id)}}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="content">
      <div>
        <Modal
          className="item-details-modal"
          title="Package Details"
          visible={showDetailsModal}
          footer={null}
          width={700}
          onCancel={() => setshowDetailsModal(false)}
        ></Modal>

        <div className="appointments-container">
          <Row
            className="appointments-page-header"
            style={{ justifyContent: "space-between", marginBottom: "10px" }}
          >
            <Col lg={9} md={4} sm={6} xs={24}>
              <Button
                style={{ height: 40, marginBottom: 10 }}
                type="primary"
                onClick={() => history.push("/hospital/hospital-package/add")}
              >
                Add Health Package
              </Button>
            </Col>

            <Col lg={8} md={4} sm={6} xs={24}>
              <Input
                className="shadow-input"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder="Search"
                size="large"
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
          {dataLoading
            ? TableSkeletonLoader(10, 60)
            : data && (
                <List
                  className="procedures-page"
                  dataSource={data}
                  pagination={{
                    current: PageNo,
                    total: TotalItemsCount,
                    onChange: (page) => {
                      setPageNo(page);
                    },
                    pageSize: ItemsPerPage,
                  }}
                  renderItem={(package_details) => (
                    <List.Item>
                      <PackageCard package_details={package_details} />
                    </List.Item>
                  )}
                />
              )}
        </div>
      </div>
      <Modal
        className="item-details-modal"
        title="Health Package Details"
        visible={selectedHospitalPackageId !== null}
        footer={null}
        width={700}
        onCancel={() => setSelectedHospitalPackageId(null)}
      >
        {selectedHospitalPackageId ? (
          <PackageDetailsCard id={selectedHospitalPackageId} />
        ) : (
          <p>loading...</p>
        )}
      </Modal>
    </div>
  );
};
export default HospitalPackages;
