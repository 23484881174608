import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Input } from "antd";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { shareAppointmentDetails } from "Utils/Services/Practitioner/Appointments";
import { message } from "antd";
import { useAppointments } from "../useAppointments";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const ShareTab = () => {
  let { selectedAppointmentId = "" } = useAppointments();
  const [emails, setEmails] = useState([
    {
      id: nanoid(),
      email: "",
    },
  ]);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const handleAddEmail = () => {
    setEmails([
      ...emails,
      {
        id: nanoid(),
        email: "",
      },
    ]);
  };
  const handleRemoveEmail = (id) => {
    setEmails(emails.filter((email) => email.id !== id));
  };
  const handleSubmit = async () => {
    setIsBtnLoading(true);
    let emailsArray = [];
    emails.forEach((item) => {
      if (item.email) {
        emailsArray.push(item.email);
      }
    });
    let payload = {
      appointment_id: selectedAppointmentId,
      emails: emailsArray,
    };
    if(emailsArray.length ){
      try{
        const response = await shareAppointmentDetails(payload);
        if (response?.status) {
          //message.success("Successfully shared appointment details");
          AntdCustomMessage({
            type: antdMessageTypes.SUCCESS,
            content: "Successfully shared appointment details"
          });
        } else {
          //message.error("Something went wrong");
          AntdCustomMessage({
            type: antdMessageTypes.ERROR,
            content: "Something went wrong"
          });
        }
        setEmails([{
          id: nanoid(),
          email: "",
        }]);
      }catch(error){
        //message.error("Sometthing went wrong")
        AntdCustomMessage({
          type: antdMessageTypes.ERROR,
          content: "Something went wrong"
        });
      }
    }else{
      //message.error("Please add atleast one email")
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Please add atleast one email"
      });
    }
    
    setIsBtnLoading(false);
  };

  return (
    <div className="flex d-col">
      <Button
        style={{ alignSelf: "flex-end", width: "fit-content" }}
        onClick={handleAddEmail}
      >
        Add
      </Button>
      <div className="flex d-col gap-1 mt-2">
        {emails.map((item) => (
          <div className="flex d-row items-center" key={`ROW_${item.id}`}>
            <Input
              style={{ width: "100%" }}
              placeholder="Enter Email"
              value={item.email}
              onChange={(e) => {
                item.email = e.target.value;
                setEmails([...emails]);
              }}
            />
            <CloseCircleFilled
              style={{ fontSize: "18px", color: "#ff4040", marginLeft: 15 }}
              onClick={() => handleRemoveEmail(item.id)}
            />
          </div>
        ))}
        <Button
          type="primary"
          style={{ width: "fit-content" }}
          onClick={handleSubmit}
          loading={isBtnLoading}
        >
          Share
        </Button>
      </div>
    </div>
  );
};

export default ShareTab;
