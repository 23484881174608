import { SearchOutlined } from "@ant-design/icons";
import { Col, DatePicker, Input, List, Modal, Row, Empty } from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRanges, DATE_FORMAT } from "Utils/Constants";
import { getLabNotifications } from "Utils/Services/Lab/Notifications";
import NotificationDetailsCard from "views/components/Notifications/NotificationDetailsCard";
import NotificationCard from "views/components/Notifications/NotificationCard";
import { TableSkeletonLoader } from "../../../../Utils/Constants";

const Notifications = () => {
  const { setHeading, Heading, FetchNotifications, setHeadingCount } =
    useContext(GlobalState);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageNo, setPageNo] = useState(1);

  const { RangePicker } = DatePicker;

  const [date, setDate] = useState([null, null]);

  const NotificationsPerPage = 15;

  const CardOnClick = (notification) => {
    setSelectedNotification(notification);
  };

  const FetchData = async () => {
    setIsLoading(true);
    const startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : "";
    const endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : "";
    const response = await getLabNotifications({
      start: (pageNo - 1) * NotificationsPerPage,
      limit: NotificationsPerPage,
      startDate: startDate,
      endDate: endDate,
      search: searchValue,
      notification_type: "all",
      visibility_status: "all",
      userType: "lab",
    });
    let { status, data } = response || {};
    if (status) {
      setData(data?.notifications);
      setHeadingCount(data?.total_count);
      setTotalNotificationsCount(data?.total_count);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    FetchData();
  }, [searchValue, pageNo, date]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Notifications");
    }
  }, [setHeading, Heading]);

  return (
    <div className="content">
      <div className="payments-container">
        <Row
          className="appointments-page-header"
          style={{ justifyContent: "space-between" }}
          gutter={[0, 10]}
        >
          <Col lg={9} md={8} sm={12} xs={24}>
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
          <Col lg={8} md={8} sm={12} xs={24}>
            <Input
              className="shadow-input"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <div
          className="details-tab-container tab-style"
          style={{ marginTop: 15 }}
        >
          {isLoading ? (
            TableSkeletonLoader(10, 60)
          ) : data?.length ? (
            <List
              dataSource={data}
              pagination={{
                current: pageNo,
                total: totalNotificationsCount,
                onChange: (page) => {
                  setPageNo(page);
                },
                pageSize: NotificationsPerPage,
              }}
              renderItem={(notification) => (
                <List.Item style={{ display: "block", padding: "2px 7px" }}>
                  <NotificationCard
                    notification={notification}
                    CardOnClick={CardOnClick}
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty description="No Notifications Found" />
          )}
        </div>
      </div>
      <Modal
        className="item-details-modal"
        title="Notification Details"
        visible={selectedNotification !== null}
        footer={null}
        width={700}
        onCancel={() => setSelectedNotification(null)}
      >
        {selectedNotification !== null ? (
          <NotificationDetailsCard
            notification={selectedNotification}
            FetchData={FetchData}
            FetchNotifications={FetchNotifications}
          />
        ) : (
          <p>loading...</p>
        )}
      </Modal>
    </div>
  );
};
export default Notifications;
