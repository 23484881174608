import axiosApiInstance from "./ApiInstance";
// import CryptoJS from 'crypto-js'

async function sendApiRequest(url, formData, reqType) {
  // const KEY = "abc"
  // const encrypted = CryptoJS.AES.encrypt(JSON.stringify(formData), KEY).toString()
  // const bytes = CryptoJS.AES.decrypt(encrypted, KEY).toString(CryptoJS.enc.Utf8)
  // const decrypted = bytes&&JSON.parse(bytes)

  // (encrypted)
  // console.log(decrypted)
  let response;
  if (reqType === "POST") {
    response = await axiosApiInstance
      .post(url, formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      });
    let res = await response;
    if (res) return res.data;
  } else {
    response = await axiosApiInstance
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  let res = await response;
  if (res) return res.data;
}

export async function GetData(url) {
  return sendApiRequest(url, {}, "GET");
}
export async function PostData(url, payload) {
  return sendApiRequest(url, payload, "POST");
}
