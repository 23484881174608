import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Input,
  List,
  Modal,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { GlobalState } from "context-api";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  DateRanges,
  DATE_FORMAT,
  TableSkeletonLoader,
  DecryptString,
} from "Utils/Constants";
import AppointmentLabCard from "views/components/Lab/Appointments/AppointmentLabCard";
import {
  getPatientAppointments,
  getPatientDetails,
} from "Utils/Services/Lab/Patients";
import LabAppointmentsDetailsCard from "views/components/Lab/Appointments/LabAppointmentsDetailsCard";
import PatientsInfoCard from "views/components/patients/patientInfoCard";

const PatientDetails = (props) => {
  const [patientId, setPatientId] = useState("");
  const { setHeading, Heading } = useContext(GlobalState);
  const { Title } = Typography;
  const history = useHistory();
  const { RangePicker } = DatePicker;

  const [data, setData] = useState("");
  const [appointmentsHistory, setAppointmentsHistory] = useState("");
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const [DetailsLoading, setDetailsLoading] = useState(false);
  const [DataLoading, setDataLoading] = useState(false);
  const [Pageno, setPageNo] = useState(1);
  const appointmentsPerPage = 10;
  const [totalAppointmentsCount, setTotalAppointmentsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState([null, null]);

  const FetchData = async () => {
    setDetailsLoading(true);
    const { profile } = await getPatientDetails(patientId);
    profile && setData(profile[0]);
    setDetailsLoading(false);
  };
  const FetchAppointments = async () => {
    setDataLoading(true);
    const startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : null;
    const endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : null;
    const { appointments, total_count } = await getPatientAppointments(
      patientId,
      (Pageno - 1) * appointmentsPerPage,
      appointmentsPerPage,
      searchValue,
      startDate,
      endDate
    );
    total_count && setTotalAppointmentsCount(total_count);
    appointments && setAppointmentsHistory(appointments);
    setDataLoading(false);
  };

  useEffect(() => {
    patientId && FetchData();
  }, [patientId]); // eslint-disable-line

  useEffect(() => {
    patientId && FetchAppointments();
  }, [patientId, Pageno, date, searchValue]); // eslint-disable-line

  const handleViewClick = (row) => {
    setSelectedAppointmentId(row.appointment_id);
    setshowDetailsModal(true);
  };
  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Patient Details");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    if (props.match.params.patientId) {
      setPatientId(DecryptString(props.match.params.patientId));
    }
  }, [props.match.params.patientId]);

  return (
    <div className="content">
      <Button style={{ marginBottom: "1rem" }} onClick={() => history.goBack()}>
        Back
      </Button>
      <PatientsInfoCard data={data} DetailsLoading={DetailsLoading} />

      <div className="appointments-history-container">
        <Title
          level={3}
          style={{ fontSize: "16px", marginBottom: "0px", color: "#0fa0cf" }}
        >
          Appointment History
        </Title>
        <Divider style={{ margin: "12px 0px" }} />

        <Row
          style={{
            display: "flex",
            position: "relative",

            justifyContent: "space-between",
          }}
        >
          <Col md="6" sm="6" xs="12">
            <RangePicker
              // className="shadow-picker"
              style={{
                width: "100%",
                height: "40px",
                marginBottom: isMobile ? 10 : 0,
              }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
          <Col md="6" sm="6" xs="12">
            <Input
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search"
              style={{
                alignSelf: isMobile ? "center" : "flex-end",
                width: isMobile ? "100%" : "70%",
                float: isMobile ? "" : "right",
              }}
              prefix={<SearchOutlined />}
              size="large"
            />
          </Col>
        </Row>

        {DataLoading ? (
          TableSkeletonLoader(8, 30)
        ) : appointmentsHistory.length ? (
          <List
            className="appointments-page patient"
            dataSource={appointmentsHistory}
            pagination={{
              current: Pageno,
              total: totalAppointmentsCount,
              onChange: (page) => {
                setPageNo(page);
              },
              pageSize: appointmentsPerPage,
            }}
            renderItem={(appointment) => (
              <List.Item>
                <AppointmentLabCard
                  appointment={appointment}
                  FetchData={FetchData}
                  setSelectedAppointmentId={setSelectedAppointmentId}
                  handleViewClick={handleViewClick}
                  history={history}
                />
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description="No Appointments Found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>

      <Modal
        className="item-details-modal"
        title="Patient Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        destroyOnClose={true}
        onCancel={() => setshowDetailsModal(false)}
      >
        <LabAppointmentsDetailsCard appointmentId={selectedAppointmentId} />
      </Modal>
    </div>
  );
};

export default PatientDetails;
