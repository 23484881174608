import {
  modelPractitionerConsultationFeeAPI,
  modelPractitionerConsultingHospitalsAPI,
  modelPractitionerInformationAPI,
  modelProfileGeneralInformationAPI,
  modelPractitionerWorkingHoursAPI,
  modelGeneralInformationUpdateApi,
  modelUpdateConsultationFeesAPI,
  modelUpdateWorkingHoursAPI,
  modelUpdatePractitionerInformationAPI,
  modelGetPractitionerSettingsAPI,
  modelPractitionerUpdateSettingsAPI,
  modelPractitionerGetRefundAPI,
} from "models/practitioner/profile.api.model";
import { PractitionerURLParser } from "Utils/urls.utils";
import { GetData, PostData } from "../../ApiCall";

export const getProfileGeneralInformation = async () =>
  modelProfileGeneralInformationAPI(
    await GetData(PractitionerURLParser("/getGeneralInformation"))
  );

export const getPractitionerInformation = async () =>
  modelPractitionerInformationAPI(
    await GetData(PractitionerURLParser("/getPractitionerInformation"))
  );

export const getPractitionerConsultationFee = async (clinicId) =>
  modelPractitionerConsultationFeeAPI(
    await PostData(PractitionerURLParser("/getConsultationFees"), {
      clinic_id: clinicId,
    })
  );

export const getPractitionerConsultingHospitals = async () =>
  modelPractitionerConsultingHospitalsAPI(
    await PostData(PractitionerURLParser("/getMyHospitals"), {
      start: "",
      limit: "100",
      search: "",
    })
  );

export const getPractitionerWorkingHours = async (payload) =>
  modelPractitionerWorkingHoursAPI(
    await PostData(
      PractitionerURLParser("/getWorkingHoursInformation"),
      payload
    )
  );

export const updateGeneralInformation = async (payload) =>
  modelGeneralInformationUpdateApi(
    await PostData(
      PractitionerURLParser("/updatePractitionerGeneralInformation"),
      payload
    )
  );

export const updateConsultationFees = async (payload) =>
  modelUpdateConsultationFeesAPI(
    await PostData(PractitionerURLParser("/updateConsultationFees"), payload)
  );

export const updateWorkingHours = async (payload) =>
  modelUpdateWorkingHoursAPI(
    await PostData(
      PractitionerURLParser("/updateWorkingHoursInformation"),
      payload
    )
  );

export const updatePractitionerInformation = async (payload) =>
  modelUpdatePractitionerInformationAPI(
    await PostData(
      PractitionerURLParser("/updatePractitionerInformation"),
      payload
    )
  );

export const getPractitionerSettings = async () =>
  // modelGetPractitionerSettingsAPI(
    await GetData(PractitionerURLParser("/getPractitionerSettings")
  )
  // );

export const updatePractitionerSettings = async (payload) =>
  modelPractitionerUpdateSettingsAPI(
    await PostData(
      PractitionerURLParser("/updatePractitionerSettings"),
      payload
    )
  );

export const getPractitionerRefund = async () => 
  modelPractitionerGetRefundAPI(await GetData(
    PractitionerURLParser("/getRefundInfo")
  ));

export const updatePractitionerRefund = async (payload)=>{
  const response = await PostData(
    PractitionerURLParser("/updatePractitionerRefund"),
    payload
  );
  return response;
}

export const updateAvailablityStatus = async (payload)=>{
  const response = await PostData(
    PractitionerURLParser("/updateLiveAvailability"),
    payload
  );
  return response;
}