import AppointmentsIcon from "Images/dashboard-icons/hospital/appointment.png";
import DashboardIcon from "Images/dashboard-icons/hospital/speedometer.png";
import AdminIcon from "Images/dashboard-icons/hospital/admin.png";
import MyPractitionersIcon from "Images/dashboard-icons/hospital/doctor.png";
import HospitalProfileIcon from "Images/dashboard-icons/hospital/user.png";
import ProcedureRequestsIcon from "Images/dashboard-icons/hospital/request.png";
import ProcedureIcon from "Images/dashboard-icons/hospital/procedure.png";
import PackagesIcon from "Images/dashboard-icons/lab/flask.png";
import PackagesAppointments from "Images/dashboard-icons/hospital/package-calendar.png";

import OnlinePaymentsIcon from "Images/dashboard-icons/hospital/online.png";
import PatientsIcon from "Images/dashboard-icons/hospital/patient.png";
import QuickBookingIcon from "Images/dashboard-icons/hospital/booking.png";
import ReviewsIcon from "Images/dashboard-icons/practitioner/reviews.png";
// import PatientRegistrationIcon from "Images/dashboard-icons/hospital/register.png";
import HospitalAppointments from "views/pages/Hospital/Appointments/index";
import HospitalDashboard from "views/pages/Hospital/Dashboard/index.js";
import HospitalProfile from "views/pages/Hospital/HospitalProfile/index";
import MedicalProcedures from "views/pages/Hospital/MedicalProcedures";
import HospitalOnlinePayments from "views/pages/Hospital/OnlinePayments/index";
import HospitalPatients from "views/pages/Hospital/Patients/index";
import Practitioners from "views/pages/Hospital/Practitioners";
import ProcedureRequests from "views/pages/Hospital/ProcedureRequests";
import QuickBooking from "views/pages/Hospital/QuickBooking";
import HospitalReviews from "views/pages/Hospital/Reviews";
// import PatientRegistration from "views/pages/Hospital/PatientRegistration";
import Notifications from "views/pages/Hospital/Notifications.js";
import NotificationIcon from "Images/dashboard-icons/hospital/notification.png";
import SubscriptionIcon from "Images/dashboard-icons/hospital/subscription.png";
import Subscription from "views/pages/Hospital/Subscription";
import HospitalPackages from "views/pages/Hospital/HospitalPackages";
import PackageAppointments from "views/pages/Hospital/PackageAppointments";
import MultipleAdmins from "views/pages/Hospital/MultipleAdmins";

export const HospitalRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: HospitalDashboard,
    layout: "/hospital",
  },
  {
    path: "/quick_booking",
    name: "Instant booking",
    icon: QuickBookingIcon,
    component: QuickBooking,
    layout: "/hospital",
  },

  {
    path: "/online-payments",
    name: "Online Payments",
    icon: OnlinePaymentsIcon,
    component: HospitalOnlinePayments,
    layout: "/hospital",
  },
  {
    path:"/subAdmins",
    name: "SubAdmins",
    icon: AdminIcon,
    component: MultipleAdmins,
    layout: "/hospital",
  },
  {
    path: "/practitioners",
    name: "Practitioners",
    icon: MyPractitionersIcon,
    component: Practitioners,
    layout: "/hospital",
  },

  {
    path: "/patients",
    name: "Patients",
    icon: PatientsIcon,
    component: HospitalPatients,
    layout: "/hospital",
  },
  {
    path: "/medical-procedures",
    name: "Medical Procedures",
    icon: ProcedureIcon,
    component: MedicalProcedures,
    layout: "/hospital",
  },
  {
    path: "/hospital-packages",
    name: "Health Packages",
    icon: PackagesIcon,
    component: HospitalPackages,
    layout: "/hospital",
  },
  {
    path: "/appointments",
    name: "Practitioner Appointments",
    icon: AppointmentsIcon,
    component: HospitalAppointments,
    layout: "/hospital",
  },
  {
    path: "/package-appointments",
    name: "Package Appointments",
    icon: PackagesAppointments,
    component: PackageAppointments,
    layout: "/hospital",
  },
  {
    path: "/procedure-requests",
    name: "Procedure Requests",
    icon: ProcedureRequestsIcon,
    component: ProcedureRequests,
    layout: "/hospital",
  },

  // {
  //   path: "/patient_registration",
  //   name: "Patient Registration",
  //   icon: PatientRegistrationIcon,
  //   component: PatientRegistration,
  //   layout: "/hospital",
  // },
  {
    path: "/reviews",
    name: "Reviews",
    icon: ReviewsIcon,
    component: HospitalReviews,
    layout: "/hospital",
  },
  // {
  //   path: "/subscription",
  //   name: "Subscription",
  //   icon: SubscriptionIcon,
  //   component: Subscription,
  //   layout: "/hospital",
  // },
  {
    path: "/hospital_profile",
    name: "Practice Profile",
    icon: HospitalProfileIcon,
    component: HospitalProfile,
    layout: "/hospital",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: NotificationIcon,
    component: Notifications,
    layout: "/hospital",
  },
];

export const HospitalNoSubscriptionRoutes = [
  {
    path: "/subscription",
    name: "Subscription",
    icon: SubscriptionIcon,
    component: Subscription,
    layout: "/hospital",
  },
  {
    path: "/hospital_profile",
    name: "Practice Profile",
    icon: HospitalProfileIcon,
    component: HospitalProfile,
    layout: "/hospital",
  },
];

// export default HospitalRoutes;
