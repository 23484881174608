import HospitalIcon from "Images/dashboard-icons/hospital.svg";
import PatientIcon from "Images/dashboard-icons/patient.svg";
import moment from "moment";
import React from "react";
import {
  AppointmentStatusFormatter,
  getAppointmentDate,
  getAppointmentType,
} from "Utils/Constants";
import { useAppointments } from "./useAppointments";
import { SetPractitionerAppointmentButtons } from "./CardAppointmentButtons";

const AppointmentCard = ({
  appointment,
  today = false,
  history,
  FetchData,
  patientId = "",
}) => {
  const { handleViewClick } = useAppointments();
  const ActionFormatter = (appointment) => {
    return SetPractitionerAppointmentButtons(
      appointment,
      moment(appointment.date).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD"),
      FetchData,
      patientId
    );
  };

  const {
    appointmentId,
    status,
    patientName,
    date,
    hospitalName,
    appointmentType,
    token,
    relationName,
    patientRelationName
  } = appointment || {};

  return (
    <div className="appointment-card">
      <div
        className="appointment-card-head"
        onClick={() => handleViewClick(appointment)}
      >
        <div className="appointment-card-header">
          <span className="appointment-id">{appointmentId}</span>
          <span className="appointment-type">
            {getAppointmentType(appointmentType)}
          </span>
          {token && <span className="appointment-token">Token-{token}</span>}
          <span className="capitalize patient-name">
            <img src={PatientIcon} alt="k" />
            {patientName}
          </span>
        </div>
        <span className="capitalize practitioner-name">
          <img src={HospitalIcon} alt="k" /> {hospitalName}
        </span>
        {
        relationName ? (
          <span className="capitalize practitioner-name ml-4 mt-0">
            Booked for <span className="font-weight-bold">{relationName}</span> - <span className="font-weight-bold">{patientRelationName}</span>
          </span>
        ):null
       }
        <div className="appointment-card-right">
          <span className="appointment-time">
            {moment(date).format("hh:mm A")}
          </span>

          <span className="appointment-date">{getAppointmentDate(date)}</span>

          <span className="appointment-status">
            {AppointmentStatusFormatter(status)}
          </span>
        </div>
      </div>
      {!history && ActionFormatter(appointment)}
    </div>
  );
};

export default AppointmentCard;
