import {
  modelScheduleTimingsAPI,
  modelUpdateSlotDurationAPI,
  modelUpdateScheduleTimingsAPI,
  modelWorkHoursSlotsAPI,
  modelGetDaySlotsAPI,
} from "models/practitioner/schedule-timings.api.models";
import { PractitionerURLParser } from "Utils/urls.utils";
import { PostData } from "../../ApiCall";

export const getPractitionerScheduleTimings = async (payload) =>
  modelScheduleTimingsAPI(
    await PostData(PractitionerURLParser("/getScheduleTimings"), payload)
  );

export const updateSlotDuration = async (payload) =>
  modelUpdateSlotDurationAPI(
    await PostData(PractitionerURLParser("/updateTimeDuration"), payload)
  );

export const updateScheduleTimings = async (payload) =>
  modelUpdateScheduleTimingsAPI(
    await PostData(PractitionerURLParser("/updateScheduleTimings"), payload)
  );

export const getWorkHoursSlots = async (payload) =>
  modelWorkHoursSlotsAPI(
    await PostData(PractitionerURLParser("/workHoursSlots"), payload)
  );

export const getDaySlots = async (payload) =>
  modelGetDaySlotsAPI(
    await PostData(PractitionerURLParser("/getDaySlots"), payload)
  );

export const postDaySlots = async (payload) =>
  modelUpdateSlotDurationAPI(
    await PostData(PractitionerURLParser("/postDaySlots"), payload)
  );
