export const modelPatientReports = (response) => {
    let result = {
        status: false,
        data: [],
        error: "",
    }
    try {
        if (typeof response !== "object") throw new Error("Invalid response");
        if (response.status !== 200) throw new Error("Invalid response");
        if (typeof response.data !== "object") throw new Error("Invalid response");
        result.status = true;
        response.data.forEach(eachClinic=>{
            const r = eachClinic.reports.map(eachReport=>({
                id: eachClinic.id,
                clinic_id: eachClinic.clinic_id,
                clinic_name: eachClinic.clinic_name,
                index: eachReport.index,
                report: eachReport.report,
                title: eachReport.title,
            }));
            result.data = [...result.data,...r];
        });
      } catch(e) {
        result.status = false;
        result.data = [];
        result.error = e?.message || e?.error || e?.err || "Something went Wrong";
      } finally {
        return result;
      }
}