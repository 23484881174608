import { PostData, GetData } from "Utils/ApiCall";
import axiosApiInstance from "Utils/ApiInstance";
import { HospitalURLParser } from "Utils/urls.utils";

export const getHospitalProfileGeneralInformation = async () => {
  const response = await PostData(
    HospitalURLParser("/GetHospitalProfileGeneralInformation"),
    {}
  );
  if (response) {
    return response.data;
  }
};

export const PostHospitalProfileGeneralInformation = async (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  const response = PostData(
    HospitalURLParser("/PostHospitalProfileGeneralInformation"),
    formData
  );

  if (response) {
    return response;
  }
};
export const GetHospitalProfileInformation = async () => {
  const response = await PostData(
    HospitalURLParser("/GetHospitalProfileInformation"),
    {}
  );
  if (response) {
    return response.data;
  }
};

export const PostHospitalProfileInformation = async (values, images) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => formData.append(key, values[key]));
  images && images.forEach((item) => formData.append("clinic_images", item));

  const response = await PostData(
    HospitalURLParser("/PostHospitalProfileInformation"),
    formData
  );
  if (response) {
    return response;
  }
};

export const GetHospitalProfileWorkingHoursInfomation = async () => {
  const response = await PostData(
    HospitalURLParser("/GetHospitalProfileWorkingHoursInfomation"),
    {}
  );
  if (response) {
    return response.data;
  }
};

export const PostHospitalProfileWorkingHoursInformation = async (values) => {
  const response = await PostData(
    HospitalURLParser("/PostHospitalProfileWorkingHoursInformation"),
    { working_hours: values.toString() }
  );
  if (response) {
    return response;
  }
};

export const GetHospitalLocationDetails = async () => {
  const response = await PostData(
    HospitalURLParser("/GetHospitalProfileLocationDetails"),
    {}
  );
  if (response) {
    return response.data;
  }
};

export const GetDistrictsRelatedCities = async (data) => {
  const response = await PostData(
    HospitalURLParser("/getDistrictsRelatedCities"),
    data
  );
  if (response) {
    return response?.data?.cities;
  }
};

export const PostHospitalLocationDetails = async (data) => {
  const response = await PostData(
    HospitalURLParser("/PostHospitalProfileLocationDetails"),
    data
  );
  if (response) {
    return response;
  }
};

export const getHospitalProfileSettingsInformation = async () => {
  const response = await PostData(
    HospitalURLParser("/GetHospitalProfileSettingsInfomation"),
    {}
  );
  if (response) {
    return response.data;
  }
};

export const PostHospitalProfileSettingsInformation = async (values) => {
  const response = await PostData(
    HospitalURLParser("/PostHospitalProfileSettingsInfomation"),
    values
  );
  if (response) {
    return response;
  }
};

export const PostHospitalUpdateFacilityType = async (values) => {
  const response = await PostData(
    HospitalURLParser("/updateType"),
    values
  );
  if(response){
    return response;
  }
}

export const getHospitalProfileType = async () => {
  const response = await GetData(
    HospitalURLParser("/getProfileType")
  );
  if (response) {
    return response.data;
  }
};

export const UpdateHospitalInsuranceDetails = async (payload) => {
  const response = await PostData(
    HospitalURLParser("/add/insurance"),
    payload
  );
  if(response){
    return response;
  }
}

export const  DeleteHospitalInsuranceDetails = async (payload) => {
  const response = await axiosApiInstance
    .delete(`/api/v1/hospital/delete/insurance/${payload.id}`);
  if(response && response?.status == 200){
    return response.data
  }
  return {
    success:"false",
    message:response.data || "Something went wrong!",
  }
}