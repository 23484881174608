import { HospitalURLParser } from "Utils/urls.utils";
import { GetData, PostData } from "../../ApiCall";
import { modelPatientReports } from "models/hospital/patient-reports.api.models";
import axiosApiInstance from "Utils/ApiInstance";

export const getPatients = async (start, limit, search = null) => {
  const response = await PostData(HospitalURLParser("/patients"), {
    start: start,
    limit: limit,
    search,
  });
  if (response) {
    return response.data;
  }
};

export const getCountryDialercodes = async () => {
  const response = await GetData(
    HospitalURLParser("/patient/getCountryDialercodes")
  );
  if (response) {
    return response.data;
  }
};

export const getPatientDetails = async (id) => {
  const response = await PostData(HospitalURLParser("/patientDetails"), {
    id,
  });
  if (response) {
    return response.data;
  }
};

export const getPatientAppointments = async (
  patient_id,
  start,
  limit,
  search,
  startDate = null,
  endDate = null
) => {
  const response = await PostData(HospitalURLParser("/patientAppointments"), {
    patient_id,
    start,
    limit,
    search,
    startDate,
    endDate,
  });
  if (response) {
    return response.data;
  }
};

export const getPatientReports = async (payload) => {
  const response =  modelPatientReports(await PostData(
    HospitalURLParser("/get/patientReport"),
    payload
  ));
return response;
}

export const addPatientReports = async (payload) => {
  const response =  await PostData(
      HospitalURLParser("/upload/patientReport"),
      payload
    );
  return response;
}

export const updatePatientReports = async (payload) => {
  const response = await axiosApiInstance
    .post(
      `/api/v1/hospital/update/patientReport`,
      payload
    );
  return response;
}

export const deletePatientReport = async (payload) => {
  const response =  await PostData(
    HospitalURLParser("/delete/patientReport"),
    payload
  );
  return response;
}
