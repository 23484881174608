/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  UpOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Button, message } from "antd";
import { useAppointments } from "./useAppointments";
import { getWorkHourSlots } from "Utils/Services/Practitioner/QuickBooking";
import { changeAppointmentStatus } from "Utils/Services/Practitioner/Appointments";
import { getLeaveDays } from "Utils/Services/Practitioner/LeaveDays";
import { nanoid } from "nanoid";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const RescheduleCard = () => {
  const {
    selectedHospitalId,
    selectedAppointmentId,
    FetchData,
    setShowRescheduleModal,
  } = useAppointments();

  const Today = moment(new Date()).format("YYYY-MM-DD");
  const [isDateAreaExpanded, setIsDateAreaExpanded] = useState(true);
  const [slots, setSlots] = useState({
    slots: {},
    dates: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isRescheduleLoading,setIsRescheduleLoading] = useState(false);
  const [date, setDate] = useState(Today);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedToken, setSelectedToken] = useState("");
  const [LeaveDays, setLeaveDays] = useState([
    { from: "", to: "", id: nanoid() },
  ]);

  useEffect(() => {
    const FetchSlots = async () => {
      setIsLoading(true);
      setSelectedTime("");
      setSelectedToken("");
      const response = await getWorkHourSlots({
        clinic_id: selectedHospitalId || "",
        date: moment(date).format("YYYY-MM-DD") || Today,
      });
      response && setSlots(response);
      setIsLoading(false);
    };
    if (date && selectedHospitalId) {
      FetchSlots();
    }
  }, [date, selectedHospitalId]);

  useEffect(() => {
    const FetchLeaveDays = async () => {
      const response = await getLeaveDays({
        clinic_id: selectedHospitalId || "",
        leave_date: moment(date).format("YYYY-MM-DD") || Today,
      });
      response && setLeaveDays(response.data.leaveDays);
    };
    if (date && selectedHospitalId) {
      FetchLeaveDays();
    }
  }, [date, selectedHospitalId]);

  const handleRescheduleClick = () => {
    let data = {
      appointment_id: selectedAppointmentId,
      appointment_status: "rescheduled",
      rescheduled_appointment_date: date,
      rescheduled_appointment_time: selectedTime,
      token: selectedToken,
    };
    setIsRescheduleLoading(true);
    changeAppointmentStatus(data).then((response) => {
      if (response && response?.status) {
        //message.success("Action completed successfully");
        AntdCustomMessage({
          type: antdMessageTypes.SUCCESS,
          content: "Action completed successfully"
        });
        setShowRescheduleModal(false);
        setIsRescheduleLoading(false);
        FetchData();
      } else {
        //message.warning("Something went wrong");
        setIsRescheduleLoading(false);
        AntdCustomMessage({
          type: antdMessageTypes.WARNING,
          content: "Something went wrong"
        });
      }
    });
  };

  const SingleDate = ({ Date }) => {
    const isSelected =
      moment(date).format("YYYY-MM-DD") === moment(Date).format("YYYY-MM-DD");

    return (
      <div
        className={isSelected ? "date-card selected" : "date-card"}
        onClick={() => setDate(Date)}
      >
        <h1 className={isSelected ? "day selected" : "day"}>
          {moment(Date).format("DD")}
        </h1>
        <h1 className={isSelected ? "month selected" : "month"}>
          {moment(Date).format("MMM YYYY")}
        </h1>
      </div>
    );
  };

  const DatesInSelectedMonth = () => {
    return slots?.dates?.map((Date) => {
      return <SingleDate key={Date} Date={moment(Date).format("YYYY MM DD")} />;
    });
  };

  const TimeCard = () => {
    let slotsRaw = Object.entries(slots.slots) || {};
    let slotsArr = [];

    slotsRaw.map((slot) => {
      //set slotsArr slotsArray and set new values
      slotsArr = [
        ...slotsArr,
        slot[1]?.filter((value) => {
          return moment().isBefore(
            moment(moment(date).format("YYYY-MM-DD") + " " + value.time, [
              "YYYY-MM-DD h:mm A",
            ])
          );
        }),
      ];
    });

    let newSlots = [];
    slotsArr?.length &&
      slotsArr.flat(Infinity).forEach((slot) => {
        newSlots.push({ ...slot, isLeave: false });
      });

    if (LeaveDays.length) {
      LeaveDays.forEach((leave) => {
        newSlots.forEach((slot) => {
          let from = moment(leave.from, "HH:mm A");
          let to = moment(leave.to, "HH:mm A");
          let slotTime = moment(slot.time, "HH:mm A");
          if (slotTime.isBetween(from, to) || slotTime.isSame(from)) {
            slot.isLeave = true;
          }
        });
      });
    }

    if (newSlots.length) {
      return (
        <div className="times-container">
          {newSlots.map((slot, index) => {
            return slot.booked === "false" && !slot.isLeave ? (
              <div
                className={
                  selectedTime === slot.time
                    ? "time-card selected"
                    : "time-card"
                }
                key={index}
                onClick={() => setSelectedTime(slot.time)}
              >
                <div 
                  className="text-center"
                  onClick={()=> setSelectedToken(slot.token)}
                >
                  <p className="mt-1 mb-0">{slot.token}</p>
                  <p>{slot.time}</p>
                </div>
              </div>
            ) : (
              <div className="time-card disabled" key={index}>
                <p className="mt-1 mb-0">{slot.token}</p>
                <p>{slot.time}</p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p>No slots available</p>;
    }
  };
  return (
    <div className="reschedule-container">
      <div className="header-section">
        <h1 className="date-header">{moment(date).format("DD MMM YYYY")}</h1>
        {isDateAreaExpanded ? (
          <UpOutlined
            onClick={() => setIsDateAreaExpanded(!isDateAreaExpanded)}
          />
        ) : (
          <DownOutlined
            onClick={() => setIsDateAreaExpanded(!isDateAreaExpanded)}
          />
        )}
        <h1
          className={date === Today ? "today-header" : "today-header disabled"}
          onClick={() => setDate(Today)}
        >
          TODAY
        </h1>
        <div className="right-section">
          <LeftOutlined
            onClick={() => {
              date > Date.now() &&
                setDate(moment(date, "YYYY-MM-DD").subtract(1, "day"));
            }}
          />
          <RightOutlined
            onClick={() => {
              setDate(moment(date, "YYYY-MM-DD").add(1, "day"));
            }}
          />
        </div>
      </div>
      {isDateAreaExpanded && (
        <div className="dates-container">
          <DatesInSelectedMonth />
        </div>
      )}

      {!isLoading ? slots && <TimeCard /> : <p>Loading..</p>}

      <Button 
        type={"primary"}
        onClick={() => handleRescheduleClick()} 
        className="w-full text-white" 
        style={{
          fontSize: '18px',
          fontWeight: 500,
          backgroundColor: '#7dbe7a',
          borderRadius: '0px 0px 5px 5px',
          padding:"5px 0 0 10px",
          cursor: 'pointer',
        }}
        loading={isRescheduleLoading}
      >
        Reschedule Appointment
      </Button>
    </div>
  );
};

export default RescheduleCard;
