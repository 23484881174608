import React from "react";
import { Button } from "antd";

const PrescriptionTableColumns = (
  options = {},
  appointmentStatus,
  handleDeleteClick = () => {}
) => {
  return [
    {
      key: "medicine_name",
      dataIndex: "medicine_name",
      title: "Medicine",
      render:(text,record)=>{
        return <div>
          <p style={{fontWeight:"bold"}}>{record.type && record.type+". "}{text} {record?.concentration}</p>
          <p>
            <span style={{fontWeight:"bold"}}>Composition:</span>
            <span style={{fontStyle:"italic"}}>{record?.composition}</span>
          </p>
        </div>
      }
    },
    {
      key: "dose",
      dataIndex: "dose",
      title: "Dose",
      render: (text, record) => {
        //convert id to title from options.dose
        const dose = options.dose.find((item) => item.id === record.dose);
        return dose ? dose.title : "";
      },
    },
    {
      key: "unit",
      dataIndex: "unit",
      title: "Unit",
      render: (text, record) => {
        //convert id to title from options.unit
        const unit = options.unit.find((item) => item.id === record.unit);
        return unit ? unit.title : "";
      },
    },
    {
      key: "route",
      dataIndex: "route",
      title: "Route",
      render: (text, record) => {
        //convert id to title from options.route
        const route = options.route.find((item) => item.id === record.route);
        return route ? route.title : "";
      },
    },
    { 
      key: "frequency",
      dataIndex: "frequency",
      title: "Frequency",
      render: (text, record) => {
        //convert id to title from options.frequency
        const frequency = options.frequency.find(
          (item) => item.id === record.frequency
        );
        return frequency ? frequency.title : "";
      },
    },
    {
      key: "directions",
      dataIndex: "directions",
      title: "directions",
      render: (text, record) => {
        //convert id to title from options.directions
        const directions = options.directions.find(
          (item) => item.id === record.directions
        );
        return directions ? directions.title : "";
      },
    },
    {
      key: "duration",
      dataIndex: "duration",
      title: "Durations",
      render: (text, record) => {
        //convert id to title from options.durations
        const duration = options.duration.find(
          (item) => item.id === record.duration
        );
        return duration ? duration.title : "";
      },
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Action",
      render: (text, record) => (
        <>
          {appointmentStatus !== "checkout" ||
          appointmentStatus !== "completed" ? (
            <Button
              type="danger"
              size="small"
              onClick={() => handleDeleteClick(record?.id)}
            >
              Delete
            </Button>
          ) : (
            <Button>No action</Button>
          )}
        </>
      ),
    },
  ];
};

export default PrescriptionTableColumns;
