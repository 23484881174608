import { Button, Card, Col, Form, Input, message, Row, Select } from "antd";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import MultipleImageUploader from "components/MultipleImageUploader";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  GetHospitalProfileInformation,
  PostHospitalProfileInformation,
} from "Utils/Services/Hospital/HospitalProfile";
import { validateMessages } from "Utils/validations";
const {Option} = Select;

const HospitalInformation = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicImages, setClinicImages] = useState([]);
  const [clinicImageFiles, setClinicImageFiles] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const fetchData = async () => {
    let response = await GetHospitalProfileInformation();
    if(response){
      setData(response);
      const contentState = stateFromHTML(response?.hospital_info[0].about_us);
      setEditorState(EditorState?.createWithContent(contentState));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    const response = await PostHospitalProfileInformation(
      {
        ...values,
        clinic_images: clinicImages.join(","),
        about_us: stateToHTML(editorState.getCurrentContent()),
      },
      clinicImageFiles
    );

    if (response && response?.status === 200) {
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Succesfully Updated.",
        style: {
          marginTop: "100px",
        },
      });
      // message.success({
      //   content: "Succesfully Updated.",
      //   style: {
      //     marginTop: "100px",
      //   },
      // });
    }else{
    }
    setIsLoading(false);
  };

  return data?.hospital_info ? (
    <Card>
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={data?.hospital_info[0]}
      >
        {/* row starts here */}
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item
              name={["clinic_name"]}
              label="Practice Name "
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={18}>
            <Form.Item label="About Practice">
              <Editor
                editorStyle={{
                  border: "1px solid #D9D9D9",
                  padding: "10px",
                  borderRadius: "5px",
                }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(value) => {
                  setEditorState(value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* row ends here */}
        {/* row starts here */}
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name="available_departments"
            label="Select atleast one department"
            rules={[
                { required: true, message: 'Please select at least one department!' }
            ]}
          >
              <Select 
                  mode="multiple" 
                  placeholder="Please select departments"
                  allowClear
                  showSearch
                  optionFilterProp="children"
              >
                  {data?.departments?.map((department) => (
                      <Option key={department?.id} value={department?.id?.toString()}>
                      {department?.department}
                      </Option>
                  ))}
              </Select>
          </Form.Item>
            {/* <Form.Item name={["available_departments"]} label="Specializations">
              <Select
                mode="multiple"
                optionFilterProp="children"
                size="large"
                placeholder="Please select"
                style={{ width: "100%" }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {data?.departments?.map((item) => (
                  <Select.Option key={item.id}>{item.department}</Select.Option>
                ))}
              </Select>
            </Form.Item> */}
          </Col>
        </Row>
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={12}>
          <Form.Item
            name="available_services"
            label="Services"
            rules={[
                { required: true, message: 'Please select at least one service!' }
            ]}
          >
              <Select 
                  mode="multiple" 
                  placeholder="Please select services"
                  allowClear
                  showSearch
                  optionFilterProp="children"
              >
                  {data?.services?.map((item) => (
                      <Option key={item.id} value={item.id.toString()}>
                      {item.services}
                      </Option>
                  ))}
              </Select>
          </Form.Item>
            {/* <Form.Item 
              name={"available_services"}
              
            >
            <p className="font-weight-bold"><span className="text-danger">*</span>Services </p>
              <Select
                mode="multiple"
                optionFilterProp="children"
                size="large"
                placeholder="Please select"
                style={{ width: "100%" }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {data?.services?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>{item.services}</Select.Option>
                ))}
              </Select>
            </Form.Item> */}
          </Col>
        </Row>
        {/* row ends here */}
        <Row gutter={{ sm: 8, lg: 24 }}>
          <Col xs={24} sm={24} lg={20}>
            <Form.Item style={{ marginTop: "15px" }}>
              <p>Practice Images</p>
              <MultipleImageUploader
                existingImages={
                  data?.hospital_info[0].clinic_images?.length
                    ? data?.hospital_info[0].clinic_images
                    : []
                }
                onChange={(images) => {
                  setClinicImages(images.oldImages);
                  setClinicImageFiles(images.newImages);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            style={{ height: 40 }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  ) : (
    <p>loading.......</p>
  );
};

export default HospitalInformation;
