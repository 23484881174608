import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getDateInteger } from "Utils/Constants";
import moment from "moment";

const PackageAppointmentsChart = ({ data }) => {
  const [Data, setData] = useState([]);

  useEffect(() => {
    const AllignData = async () => {
      const Counts = data?.countArray || [];
      const Dates = data?.dateArr || [];
      const Names = data?.packageArray || [];

      let ChartData = [];

      Names.forEach((name, index) => {
        ChartData.push({ name: name });
      });

      ChartData.forEach((data, index) => {
        let newArray = [];
        Counts[index].forEach((count, index2) => {
          newArray.push([getDateInteger(Dates[index2]), count]);
        });

        data.data = newArray;
      });
      setData(ChartData);
    };
    if (data) {
      AllignData();
    }
  }, [data]);

  const options = {
    chart: {
      type: "spline",
      height: 500,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        overflow: "justify",
      },
      gridLineColor: "#f3f3f3",
      gridLineDashStyle: "longdash",
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return s + "<br/>" + point.series.name + ": " + point.y;
        }, "<b>" + moment(this.x).format("DD MMM YYYY") + "</b>");
      },
      pointFormat: "{series.name} : {point.y}<br></br>",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    series: Data,
    time: {
      useUTC: false,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PackageAppointmentsChart;
