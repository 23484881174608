import React, { useState, useEffect } from 'react';
import { 
    Button, Card, 
    message, Row, 
    Radio, Switch, 
    Spin, Input, 
    Table, Popconfirm 
} from "antd";
import { 
    DeleteHospitalInsuranceDetails,
    PostHospitalUpdateFacilityType, 
    UpdateHospitalInsuranceDetails, 
    getHospitalProfileType 
} from 'Utils/Services/Hospital/HospitalProfile';
import { 
    DeleteOutlined, 
    EditOutlined 
} from "@ant-design/icons";
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';

const ChangeFacility = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);
    const [type, setType] = useState("");
    const [acceptance, setAcceptance] = useState(false);
    const [acceptedInsurances, setAcceptedInsurances] = useState([]);
    const [insurance, setInsurance] = useState("");
    const [editInsurance, setEditInsurance] = useState(false);
    const [activeInsurance, setActiveInsurance] = useState("");

    const facilityOptions = [
        { value: "hospital", label: "Hospital" },
        { value: "clinic", label: "Clinic" },
    ];

    const FetchData = async () => {
        setIsLoading(true);
        setIsInsuranceLoading(true);
        const response = await getHospitalProfileType();
        if (response && response?.profile[0]?.type) {
          setType(response?.profile[0]?.type)
        }
        if (response && response?.profile[0]?.accepts_insurance) {
            setAcceptance(response?.profile[0]?.accepts_insurance == 1 ? true : false)
          }
        if(response && response.profile[0].accepted_insurances){
            const insurances = response?.profile[0]?.accepted_insurances;
            setAcceptedInsurances([...insurances]);
        }
        setIsLoading(false);
        setIsInsuranceLoading(false);
    };

    const onFinish = async (value) => {
        setIsLoading(true);
        setType(value);
        const response = await PostHospitalUpdateFacilityType({type:value});
        if (response?.status === 200) {
            // message.success("Facility Type updated succcessfully.");
            AntdCustomMessage({
              content : "Facility Type updated succcessfully.",
              type : antdMessageTypes.SUCCESS,
            });
        } else {
            //message.error("Something went wrong");
            AntdCustomMessage({
              content : "Something went wrong",
              type : antdMessageTypes.ERROR,
            });
        }
        setIsLoading(false);
    };

    const handleSetInsurance = (value) => {
        setInsurance(value);
    };

    const handleAddInsurance = () => {
        if(!insurance.length){
            //message.error("Fill insurance name to add.");
            AntdCustomMessage({
              content : "Fill insurance name to add.",
              type : antdMessageTypes.ERROR,
            });
            return;
        }
        const insurances =  [...acceptedInsurances,{accepted_insurances : insurance}]
        .map(each=>each.accepted_insurances);
        handleInsuranceDetailsChange(acceptance,insurances);
    };

    const handleConfirmEditInsurance = (record) => {
        setEditInsurance(true);
        setInsurance(record.insurance);
        setActiveInsurance(record.rowId);
    };

    const handleConfirmDeleteInsurance = async (record) => {
        try{
            const deleteResponse = await DeleteHospitalInsuranceDetails({id: record.insuranceIndex});
            //message.success(deleteResponse.data);
            AntdCustomMessage({
              content : deleteResponse.data,
              type : antdMessageTypes.SUCCESS,
            });
            // window.location.reload();
            FetchData();
            setEditInsurance(false);
        }catch(e){
            //message.error(e.message || e.data || "Something went wrong.")
            AntdCustomMessage({
              content : e.message || e.data || "Something went wrong.",
              type : antdMessageTypes.ERROR,
            });
        }
    };

    const handleEditInsurance = () => {
        const insurances = [
            ...acceptedInsurances.slice(0,activeInsurance),
            {accepted_insurances : insurance},
            ...acceptedInsurances.slice(activeInsurance+1,)
        ].map(each=>each.accepted_insurances);
        handleInsuranceDetailsChange(acceptance,insurances);    
    }
    // const handleDeleteAllInsurances = async () => {
    //     const payload = {
    //         "accepts_insurance" : acceptance,
    //         "accepted_insurances" : "",
    //     };
    //     const response = await UpdateHospitalInsuranceDetails(payload);
    //     if (response.status === 200) {
    //         message.success("Insurances deleted successfully.");
    //         FetchData();
    //     } else {
    //         message.error("Something went wrong");
    //     }
    // }
    const handleInsuranceDetailsChange = async (isChecked, insurances) => {
        setIsInsuranceLoading(true);
        setAcceptance(isChecked);
        const payload = {
            "accepts_insurance" : isChecked,
            "accepted_insurances" : insurances.length ? insurances : [],
        };
        const response = await UpdateHospitalInsuranceDetails(payload);
        if (response?.status === 200) {
            // message.success("Insurance Details updated succcessfully.");
            AntdCustomMessage({
                content : "Insurance Details updated succcessfully.",
                type : antdMessageTypes.SUCCESS,
            });
            FetchData();
        } else {
            //message.error("Something went wrong");
            AntdCustomMessage({
                content : "Something went wrong",
                type : antdMessageTypes.ERROR,
            });
        }
        setIsInsuranceLoading(false);
        setInsurance("");
        setEditInsurance(false);
    }
    
    useEffect(() => {
        FetchData();
    }, []);

    return (
        <>
            <Card title="Facility Type" style={{minHeight:"150px", marginBottom:"5px"}}>
                {
                    isLoading ? (
                       <Spin/>
                    ):(
                        <Row gutter={{ sm: 24, lg: 24 }}>
                            <Radio.Group 
                                options={facilityOptions} 
                                onChange={(e) => {
                                onFinish(e.target.value);
                                    }} 
                                value={type} 
                            />
                        </Row>
                    )
                }

                {/* <Row gutter={{ sm: 24, lg: 24 }} className='mt-3'>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ height: 40 }}
                        onClick={onFinish}
                    >
                        Submit
                    </Button>
                </Row>    */}
            </Card>
            <Card>
                <div className='d-flex align-center'>
                    <p className="mr-5">Accept Insurance</p>
                    <Switch 
                        loading={ isInsuranceLoading }
                        checked = { acceptance }
                        onChange={(isChecked, event)=>{
                            handleInsuranceDetailsChange(isChecked, [])
                        }}  
                    />
                </div>
            </Card>
            <Card title={"Accepted Insurances"} style={{marginTop:"1rem"}}>
                <div className='d-flex gap-1 mb-3'>
                    <Input 
                        placeholder = "Enter Insurance name" 
                        value = { insurance }
                        onChange = {(e)=>{
                            handleSetInsurance(e.target.value);
                        }}
                    />
                    <Button 
                        type = "primary"
                        disabled={!insurance.length}
                        onClick={()=>{editInsurance ? handleEditInsurance() : handleAddInsurance()}}
                    >
                        {editInsurance ? "Update" : "Add"}
                    </Button>
                    {/* {
                        acceptedInsurances.length ? (
                            <Popconfirm
                                title={`Are you sure you want to delete all insurances?`}
                                onConfirm={() => {handleDeleteAllInsurances()}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button 
                                    type = "danger"
                                >
                                    Delete All Insurances
                                </Button>
                            </Popconfirm>
                        ): null
                    } */}
                </div>
                {
                    !editInsurance ? (
                        <Table
                            rowKey="id"
                            dataSource={acceptedInsurances?.map((item, index) => {
                            return {
                                rowId: index,
                                insuranceIndex: item.id,
                                insurance: item.accepted_insurances
                            };
                            })}
                        >
                            <Table.Column 
                                title="Sl No" 
                                dataIndex="id" 
                                key="id" 
                                render={(text, record) => (
                                    <p>
                                        {record.rowId + 1}
                                    </p>
                                )}
                            />
                            <Table.Column 
                                title="Insurance Id" 
                                dataIndex="insurance_id" 
                                key="insurance_id" 
                                render={(text, record) => (
                                    <p>
                                        {record.insuranceIndex}
                                    </p>
                                )}
                            />
                            <Table.Column
                                title="Insurance Name"
                                dataIndex="insurance_title"
                                key="insurance_title"
                                render={(text, record) => (
                                    <p>
                                        {record.insurance}
                                    </p>
                                )}
                            />
                            <Table.Column
                                key="delete"
                                dataIndex="delete"
                                title="Delete"
                                render={(text, record) => {
                                    return (
                                        <Popconfirm
                                            title={`Are you sure you want to delete insurance ${record.insurance}?`}
                                            onConfirm={() => {handleConfirmDeleteInsurance(record)}}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                                        </Popconfirm>
                                    )
                                }}
                            />
                            {/* <Table.Column
                                key="change"
                                dataIndex="change"
                                title="Change"
                                render={(text, record) =>{
                                    return (
                                        <Popconfirm
                                            title={`Are you sure you want to edit insurance ${record.insurance}?`}
                                            onConfirm={() =>{handleConfirmEditInsurance(record)}}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <EditOutlined style={{ color: 'green', cursor: 'pointer' }} />
                                        </Popconfirm>
                                    )
                                }}
                            /> */}
                            
                        </Table>
                    ):null
                }
            </Card>
        </>
    )
}

export default ChangeFacility