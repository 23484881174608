import { Button, Col, Form, Input, message, Row } from "antd";
import ShopDocLogo from "Images/shopdocLogoLight.svg";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./login.css";
import Cookies from "universal-cookie";
import { resetPassword } from "Utils/Services/Auth";
import { DecryptString } from "Utils/Constants";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const ResetPassword = (props) => {
  const cookies = new Cookies();
  const formInitialValues = { type: "hospital" };
  let history = useHistory();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const location = window.location.pathname;
  let id = location?.split("/")[2];
  let type = location?.split("/")[3];
  useEffect(() => {
    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);
    if (UserData?.type) {
      if (UserData?.type === "hospital") {
        history.push("/hospital/dashboard");
      } else if (UserData?.type === "lab") {
        history.push("/lab/dashboard");
      } else if (UserData?.type === "practitioner") {
        history.push("/practitioner/dashboard");
      }
    }
  }, [cookies, history]);

  const handleResetClick = (values) => {
    setIsBtnLoading(true);
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      //message.error("Passwords do not match");
      AntdCustomMessage({
        type : antdMessageTypes.ERROR,
        content: "Passwords do not match",
        style: {
          marginTop: "100px",
        },
      });
      setIsBtnLoading(false);
      return;
    }
    const payload = {
      new_password: password,
      id_hashed: id,
      type_hashed: type,
    };
    resetPassword(payload)
      .then((response) => {
        if (response?.data?.status === 200) {
          //message.success(response?.data?.data?.message);
          AntdCustomMessage({
            type : antdMessageTypes.SUCCESS,
            content: response?.data?.data?.message,
          });
          setIsBtnLoading(false);
          history.push("/login");
        } else {
          //message.error(response?.data?.data?.message);
          AntdCustomMessage({
            type : antdMessageTypes.ERROR,
            content: response?.data?.data?.message,
          });
          setIsBtnLoading(false);
        }
      })
      .catch((e) => {
        //message.error("Something went wrong");
        AntdCustomMessage({
          type : antdMessageTypes.ERROR,
          content: e?.response?.data?.data?.error || 
              e?.response?.data?.data?.message ||
              "Something went wrong"
        });
        setIsBtnLoading(false);
      });
    setIsBtnLoading(false);
  };

  return (
    <div className="login-page">
      <div className="container-fluid">
        <Row>
          <Col md={24}>
            <Form
              onFinish={handleResetClick}
              layout="vertical"
              initialValues={formInitialValues}
            >
              <div className="login-container">
                <div className="logo-container">
                  <img
                    src={ShopDocLogo}
                    alt="shopdoc"
                    style={{ borderRadius: 0 }}
                  />
                </div>
                <div className="flex items-center justify-center text-heading mb-4">
                  Reset Password
                </div>
                <div className="form-container">
                  <Form.Item
                    name={["password"]}
                    label="New Password"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Enter password" type="password" />
                  </Form.Item>
                  <Form.Item
                    name={["confirmPassword"]}
                    label="Confirm Password"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="Confirm password" type="password" />
                  </Form.Item>
                </div>
                <div className="button-container">
                  <Button htmlType="submit" loading={isBtnLoading}>
                    Reset Password
                  </Button>
                </div>
                <div className="footer-container">
                  <div className="footer-text">
                    Have an account?
                    <span
                      className="footer-link"
                      onClick={() => history.push(`/login`)}
                    >
                      {" "}
                      Login
                    </span>
                  </div>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ResetPassword;
