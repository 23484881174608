import { getDateInteger } from "Utils/Constants";

export const modelDashboardCountsAPI = (response) => {
  let result = {
    status: false,
    data: {},
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    if (response.status !== 200) throw new Error("Invalid status code");
    if (typeof response.data !== "object")
      throw new Error("Invalid response data");
    result.status = true;
    result.data = response.data;
  } catch {
    result.status = false;
  } finally {
    return result;
  }
};

export const modelAppointmentsChartAPI = (response) => {
  let result = {
    status: false,
    data: {
      ChartData: [],
    },
  };
  try {
    if (typeof response !== "object") throw new Error("Invalid response");
    let { data, status } = response;
    if (status !== 200) throw new Error("Invalid status code");
    if (typeof response.data !== "object")
      throw new Error("Invalid response data");
    result.status = true;
    let counts, dates, names;
    if (Array.isArray(data.datesArr)) {
      dates = data.datesArr;
    }
    if (Array.isArray(data.countArr)) {
      counts = data.countArr;
    }
    if (Array.isArray(data.namesArr)) {
      names = data.namesArr;
    }
    let ChartData = [];
    names.forEach((name, index) => {
      ChartData.push({ name: name });
    });
    ChartData.forEach((data, index) => {
      let newArray = [];
      counts[index].forEach((count, index2) => {
        newArray.push([getDateInteger(dates[index2]), count]);
      });
      data.data = newArray;
    });
    result.data = ChartData;
  } catch (e) {
    result.status = false;
  } finally {
    return result;
  }
};
