import React, { useContext, useEffect, useState, useRef } from "react";
import { usePayments } from "./usePayments";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRanges, DATE_FORMAT, getDateTimeFormat } from "Utils/Constants";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  Empty,
} from "antd";
import { getOnlinePayments } from "Utils/Services/Practitioner/OnlinePyaments";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { TableSkeletonLoader } from "../../../../Utils/Constants";
import DetailsCard from "./DetailsCard";
import BugContainer from "components/ErrorContainer/BugContainer";
import columnResponsiveWrapper from "Utils/columnResponsiveWrapper";
import { GlobalState } from "context-api";

const PaymentsContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const limit = 12;
  const [totalPaymentsCount, setTotalPaymentsCount] = useState(0);
  const isMounted = useRef(true);
  const {
    SearchKeyword,
    setSearchKeyword,
    paymentsData,
    setPaymentsData,
    date,
    setDate = (date) => setDate(date),
    setSelectedOrderId,
    setShowDetailsModal,
    showDetailsModal,
  } = usePayments();
  const { RangePicker } = DatePicker;
  const { setHeading, Heading, setHeadingCount } = useContext(GlobalState);

  const getTagColor = (status) => {
    if (status === "completed") {
      return "green";
    } else if (status === "pending") {
      return "orange";
    } else if (status === "Cancelled") {
      return "red";
    } else if (status === "Not Completed") {
      return "blue";
    }
  };

  const Columns = [
    {
      key: "patientName",
      dataIndex: "patientName",
      title: "Name",
      render: (text, record) => (
        <p style={{ textTransform: "capitalize" }}>{text}</p>
      ),
    },
    {
      key: "orderId",
      dataIndex: "orderId",
      title: "Order Id",
    },
    {
      key: "createdDate",
      dataIndex: "createdDate",
      title: "Appointment Date",
      render: (text,record) => {
      // return <p>{getDateTimeFormat(text)}</p>
      return <p>{record.date + " " + record.time}</p>
      }
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Amount Paid",
      render: (text) => <p>₹{text}</p>,
    },
    {
      key: "amountRefund",
      dataIndex: "amountRefund",
      title: "Amount Refund",
      render: (text, record) => {
        if(record.refundAmount)return <p>₹{record.refundAmount}</p>
        else return null
      },
    },
    {
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      title: "Payment Status",
      render: (text) => (
        <Tag color={getTagColor(text)} key={text}>
          {text.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => {
            setSelectedOrderId(record.orderId);
            setShowDetailsModal(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const fetchData = async () => {
    const startDate = date[0] ? moment(date[0]).format("YYYY-MM-DD") : "";
    const endDate = date[1] ? moment(date[1]).format("YYYY-MM-DD") : "";
    isMounted.current && setIsLoading(true);
    const response = await getOnlinePayments({
      start: (pageNo - 1) * limit || 0,
      limit: limit || 12,
      search: SearchKeyword || "",
      startDate,
      endDate,
    });
    response && isMounted.current && setPaymentsData(response);
    if (response && response?.status) setTotalPaymentsCount(response?.data?.count);
    isMounted.current && setIsLoading(false);
  };

  useEffect(() => {
    fetchData();

    // return () => {
    //   isMounted.current = false;
    // };
  }, [SearchKeyword, date, pageNo, limit]); // eslint-disable-line

  let { status = false, data = {} } = paymentsData || {};
  let { onlinePayments = [] } = data || {};

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Online Payments");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    setHeadingCount(totalPaymentsCount);
    return () => {
      setHeadingCount(0);
    }; // eslint-disable-next-line
  }, [totalPaymentsCount]);

  return (
    <div className="content">
      <div className="payments-container">
        <Row
          className="appointments-page-header"
          style={{ justifyContent: "space-between", marginBottom: "20px" }}
          gutter={[0, 10]}
        >
          <Col lg={9} md={8} sm={12} xs={24}>
            <RangePicker
              className="shadow-picker"
              style={{ width: "100%" }}
              value={date}
              ranges={DateRanges()}
              format={DATE_FORMAT}
              onChange={(e) => {
                e === null ? setDate([null, null]) : setDate(e);
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            />
          </Col>
          <Col lg={8} md={8} sm={12} xs={24}>
            <Input
              className="shadow-input"
              onChange={(e) => setSearchKeyword(e.target.value)}
              value={SearchKeyword}
              placeholder="Search"
              size="large"
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        {!isLoading ? (
          status ? (
            onlinePayments.length ? (
              <Table
                rowKey={"orderId"}
                dataSource={onlinePayments}
                columns={columnResponsiveWrapper(Columns)}
                pagination={{
                  current: pageNo,
                  total: totalPaymentsCount,
                  onChange: (page) => {
                    setPageNo(page);
                  },
                  pageSize: limit,
                }}
              />
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Payments Found"
              />
            )
          ) : (
            <BugContainer />
          )
        ) : (
          TableSkeletonLoader(10, 60)
        )}
        <Modal
          className="item-details-modal"
          title="Hospital Details"
          visible={showDetailsModal}
          footer={null}
          width={700}
          onCancel={() => setShowDetailsModal(false)}
        >
          <DetailsCard />
        </Modal>
      </div>
    </div>
  );
};

export default PaymentsContainer;
