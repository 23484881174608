import React from "react";

export default   (columns_old) => {
    let columns = columns_old.map((item) => ({
      key: item.key,
      title: item.title,
      dataIndex: item.key,
      render: (text, record) => {
        return {
          children: item.render ? item?.render?.(text, record) : <p>{text}</p>,
          props: {
            "data-label": item.title,
          },
        };
      },
    }));
    return columns;
  };