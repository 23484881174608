import React,{useEffect,useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getDateInteger} from "Utils/Constants";

const AppointmentsChart = ({AppointmentsChartData}) => {




const [Data, setData] = useState([])

 



  

  useEffect(() => {
    const AllignData =async ()=>{
      const Counts = AppointmentsChartData.appointmentcountArr;
      const Dates =AppointmentsChartData.dateArr
  
      let ChartData = []
  
      await Dates.forEach((date,index)=>{
        ChartData.push([getDateInteger(date),Counts[index]])
      })
      setData(ChartData)
    }
    if(AppointmentsChartData){
      AllignData()
    }
  }, [AppointmentsChartData])

  const options = {
    chart: {
      type: "areaspline",
      height: 300,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        overflow: "justify",
      },
      gridLineColor: "#f3f3f3",
      gridLineDashStyle: "longdash",
    },
    tooltip: {
      pointFormat: "Appointments : {point.y}",
     
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    series: [
      {
        data: Data,
      },
    ],
    time:{
      useUTC:false
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AppointmentsChart;
