import { Button, Modal } from "antd";
import React, { useState } from "react";
import RenewSubscription from "../actions/RenewSubscription";
import { getDateFormat } from "Utils/Constants";

const GraceActivated = ({ SubscriptionData, FetchBillingHistory }) => {
  const [RenewModal, setRenewModal] = useState(false);
  return (
    <>
      <Modal
        className="subscription-modal"
        title="Subscription Renewal"
        visible={RenewModal}
        footer={null}
        width={700}
        onCancel={() => setRenewModal(false)}
      >
        <RenewSubscription
          FetchBillingHistory={FetchBillingHistory}
          subscriptionExpiry={SubscriptionData.subscriptionExpiry}
          usedQuota={SubscriptionData.subscriptionQuota.quotaUsed}
        />
      </Modal>
      <div className="subscription-content">
        <h5>You are Running on Grace Period</h5>
        <p>
          Your last subscription plan ended on{" "}
          <b style={{ fontWeight: "bold" }}>
            {getDateFormat(SubscriptionData.subscriptionExpiry)}.
          </b>
          <br></br>Your account will be deactivated if you're not able to renew
          the subscription before{" "}
          <b style={{ fontWeight: "bold" }}>
            {getDateFormat(SubscriptionData.graceExpiry)}.
          </b>
        </p>
        <div className="subscription-count-items">
          <div
            className="subscription-count-item"
            style={{ background: "#e6fdf6", color: "#0e936c" }}
          >
            <div className="subcription-count-label">
              Practitioners Quota Purchased
            </div>
            <div className="subcription-count-value">
              {SubscriptionData.subscriptionQuota.quotaPurchased}
            </div>
          </div>
          <div
            className="subscription-count-item"
            style={{ background: "#fff0d1", color: "#db7b01" }}
          >
            <div className="subcription-count-label">
              Practitioners Quota Used
            </div>
            <div className="subcription-count-value">
              {SubscriptionData.subscriptionQuota.quotaUsed}
            </div>
          </div>
          <div
            className="subscription-count-item"
            style={{ background: "#e4f8ff", color: "#0394d1" }}
          >
            <div className="subcription-count-label">
              Practitioners Quota Balance
            </div>
            <div className="subcription-count-value">
              {SubscriptionData.subscriptionQuota.quotaBalance}
            </div>
          </div>
        </div>
        <Button
          type="primary"
          style={{ marginTop: 15 }}
          onClick={() => setRenewModal(true)}
        >
          Renew Subscription Now
        </Button>
      </div>
    </>
  );
};

export default GraceActivated;
