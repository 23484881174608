import React from "react";
import { Empty, List } from "antd";
import { TableSkeletonLoader } from "Utils/Constants";
import ProcedureRequestCard from "views/components/procedures/ProcedureRequestCard";

const TodaysProcedureRequests = ({
  TodaysProcedureRequests,
  TodayProcedureRequestsLoading,
}) => {
  return (
    <div className="today-appointments">
      {TodayProcedureRequestsLoading ? (
        TableSkeletonLoader(15, 30)
      ) : TodaysProcedureRequests.procedureRequests?.length ? (
        <List
          dataSource={TodaysProcedureRequests.procedureRequests}
          renderItem={(request) => (
            <List.Item style={{ padding: 0 }}>
              <ProcedureRequestCard request={request} today={true} />
            </List.Item>
          )}
        />
      ) : (
        <Empty
          description="No Procedure Requests Today"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="empty-nodata"
        />
      )}
    </div>
  );
};

export default TodaysProcedureRequests;
