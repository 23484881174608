import React, { useEffect } from "react";
import HospitalCard from "../HospitalCard";
import { List, Empty, Modal } from "antd";
import { useHospitals } from "../../useHospitals";
import FullWidthView from "components/FullWidthView";
import Spinner from "reactstrap/lib/Spinner";
import DetailsModal from "../DetailsModal/AllHospitals";
import { RequestedHospitalsActionButtons } from "./ActionButtons";

const RequestedHospitals = () => {
  const { SearchKeyword = "" } = useHospitals();

  const {
    showDetailsModal,
    setshowDetailsModal,
    fetchRequestedHospitalsData,
    loading,
    pageNo,
    setPageNo,
    limit,
    data,
    setData,
  } = useHospitals();

  useEffect(() => {
    fetchRequestedHospitalsData();

    return () => {
      setData({
        status: false,
        data: {
          count: 0,
          hospitals: [],
        },
      });
    };
  }, [SearchKeyword, limit, pageNo]); // eslint-disable-line

  if (loading)
    return (
      <FullWidthView className="flex items-center justify-center min-h-half">
        <Spinner />
      </FullWidthView>
    );
  let { count, hospitals = [] } = data.data;

  return (
    <div className="content">
      <Modal
        className="item-details-modal"
        title="Hospital Details"
        visible={showDetailsModal}
        footer={null}
        width={700}
        onCancel={() => setshowDetailsModal(false)}
      >
        <DetailsModal />
      </Modal>
      <div className="practitioners-list">
        {data?.status ? (
          hospitals.length ? (
            <List
              dataSource={data.data.hospitals}
              pagination={{
                current: pageNo,
                onChange: (page) => {
                  setPageNo(page);
                },
                pageSize: limit,
                total: count,
              }}
              renderItem={(item) => (
                <List.Item>
                  <HospitalCard
                    key={item.id}
                    hospital={item}
                    ActionButtons={RequestedHospitalsActionButtons}
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty
              description="No Hospitals Found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        ) : (
          <Empty
            description="Something went wrong"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  );
};

export default RequestedHospitals;
