import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "context-api";
import { getSubscriptionColor } from "Utils/Constants";
import NotActivated from "./windows/NotActivated";
import TrialActivated from "./windows/TrialActivated";
import Activated from "./windows/Activated";
import GraceActivated from "./windows/GraceActivated";
import Expired from "./windows/Expired";
import BillingHistory from "./billing";
import { getSubscriptionHistory } from "Utils/Services/Hospital/Subscription";
import { Modal } from "antd";
import PaymentDetailsCard from "./PaymentDetailsCard";

const Subscription = () => {
  const { setHeading, Heading, SubscriptionStatus, SubscriptionData } =
    useContext(GlobalState);
  const [BillingHistoryData, setBillingHistoryData] = useState([]);
  const [BillingHistoryLoading, setBillingHistoryLoading] = useState(false);
  const [BillingHistoryPageNo, setBillingHistoryPageNo] = useState(1);
  const [TotalTransactionsCount, setTotalTransactionsCount] = useState(0);
  const [ShowPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  const [SelectedOrderId, setSelectedOrderId] = useState("");
  const [SelectedSubscriptionId, setSelectedSubscriptionId] = useState("");
  const [SelectedType, setSelectedType] = useState("");

  const getSubscriptionContent = () => {
    switch (SubscriptionStatus) {
      case "Not Activated":
        return (
          SubscriptionData && (
            <NotActivated
              FetchBillingHistory={FetchBillingHistory}
              SubscriptionData={SubscriptionData}
            />
          )
        );

      case "Trial Activated":
        return (
          SubscriptionData && (
            <TrialActivated
              SubscriptionData={SubscriptionData}
              FetchBillingHistory={FetchBillingHistory}
            />
          )
        );

      case "Activated":
        return (
          SubscriptionData && (
            <Activated
              SubscriptionData={SubscriptionData}
              FetchBillingHistory={FetchBillingHistory}
            />
          )
        );

      case "Grace Activated":
        return (
          SubscriptionData && (
            <GraceActivated
              SubscriptionData={SubscriptionData}
              FetchBillingHistory={FetchBillingHistory}
            />
          )
        );

      case "Expired":
        return SubscriptionData && <Expired />;

      default:
        break;
    }
  };

  const FetchBillingHistory = async () => {
    setBillingHistoryLoading(true);
    const response = await getSubscriptionHistory();
    if (response.length) {
      setBillingHistoryData(response);
      setTotalTransactionsCount(response.length);
    }
    setBillingHistoryLoading(false);
  };

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Subscription");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    FetchBillingHistory();
  }, []);

  return (
    <div className="content">
      <div className="subscription-window">
        <div className="subscription-window-header">
          <div className="subscription-status">
            Status:{" "}
            <span style={{ color: getSubscriptionColor(SubscriptionStatus) }}>
              {SubscriptionStatus}
            </span>
          </div>
        </div>
        {getSubscriptionContent()}
        <div className="subscription-window-footer">
          <p>
            Need further assistance?{" "}
            <a href="https://wa.me/+916282016735">Contact Support</a>
          </p>
        </div>
      </div>
      <Modal
        className="item-details-modal"
        title="Payment Details"
        visible={ShowPaymentDetailsModal}
        destroyOnClose={true}
        footer={null}
        width={700}
        onCancel={() => setShowPaymentDetailsModal(false)}
      >
        <PaymentDetailsCard
          SelectedType={SelectedType}
          orderId={SelectedOrderId}
          SubscriptionId={SelectedSubscriptionId}
        />
      </Modal>
      <BillingHistory
        setSelectedType={setSelectedType}
        setSelectedOrderId={setSelectedOrderId}
        setSelectedSubscriptionId={setSelectedSubscriptionId}
        setShowPaymentDetailsModal={setShowPaymentDetailsModal}
        BillingHistoryLoading={BillingHistoryLoading}
        BillingHistoryData={BillingHistoryData}
        BillingHistoryPageNo={BillingHistoryPageNo}
        TotalTransactionsCount={TotalTransactionsCount}
        setBillingHistoryPageNo={setBillingHistoryPageNo}
      />
    </div>
  );
};
export default Subscription;
