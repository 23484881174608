import React, { useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import EllipsisText from "react-ellipsis-text";
import { MedicineBoxOutlined } from "@ant-design/icons";
import { useHospitals } from "../useHospitals";

const HospitalCard = ({ hospital = {}, ActionButtons = null }) => {
  let {
    id = "",
    name = "",
    location = "",
    // about = "",
    image = "",
  } = hospital;
  const { setSelectedHospital, setshowDetailsModal } = useHospitals();
  // const description = useMemo(() => {
  //   return about.length > 100 ? about.substring(0, 100) + "..." : about;
  // }, [about]);
  const locationEllipse = useMemo(() => {
    return location.length > 20 ? location.substring(0, 20) + "..." : location;
  }, [location]);

  return (
    <div className="practitioner-list-card">
      <Card className="card-stats" style={{ padding: "10px" }}>
        <CardBody
          style={{
            padding: "0px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="card-body-hospitals">
            <div
              className="profile-image"
              style={
                image
                  ? {
                      backgroundImage: `url(${image})`,
                    }
                  : {}
              }
            >
              {!image && (
                <div className="char-avatar">
                  <span>{name.charAt(0).toUpperCase()}</span>
                </div>
              )}
            </div>
            <div
              onClick={() => {
                setSelectedHospital(id);
                setshowDetailsModal(true);
              }}
            >
              <div className="info-container">
                <h4>
                  <EllipsisText
                    className="capitalize"
                    text={name}
                    length={30}
                  />
                </h4>
                <p className="department">
                  <MedicineBoxOutlined />
                  {locationEllipse}
                </p>
              </div>
            </div>
            {ActionButtons && <ActionButtons hospital={hospital} />}
          </div>
          {/* <p className="hospital-description">
            <AlertOutlined className="icon" />
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </p> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default HospitalCard;
