import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LabPaymentsTypeChart = ({ PaymentsByTypes }) => {
  const options = {
    chart: {
      type: "column",
      height: 175,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: ["Online", "Offline"],
      title: {
        text: "",
      },
    },
    yAxis: {
      min: 0,
      title: {
        enabled: false,
      },
      labels: {
        overflow: "justify",
      },
      gridLineColor: "#f3f3f3",
      gridLineDashStyle: "longdash",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    tooltip: {
      backgroundColor: "#fff",
      borderWidth: 0,
      padding: 12,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: [
          {
            name: "Online Payments",
            y: PaymentsByTypes.online,
            color: "#45bd5b",
          },
          {
            name: "Offline Payments",
            y: PaymentsByTypes.offline,
            color: "#0fa0cf",
          },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LabPaymentsTypeChart;
