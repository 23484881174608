import React, { useState, useContext, useEffect } from "react";
import PhoneInputComponent from "components/PhoneInput";
import { Button, Col, Form, message, Row } from "antd";
import { useQuickBooking } from "./useQuickBooking";
import { CheckPatientExist } from "Utils/Services/Practitioner/QuickBooking";
import PatientExistsContainer from "./PatientExistsContainer";
import RegisterPatientContainer from "./RegisterPatientContainer";
import { GlobalState } from "context-api";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const QuickBookingContainer = () => {
  const [error, setError] = useState({
    isError: false,
    message: [],
  });
  const [isChechBtnLoading, setIsCheckBtnLoading] = useState(false);
  const {
    mobileNumber,
    setMobileNumber,

    patientData,
    setPatientData,
    isPatientExist,
    setIsPatientExist,
  } = useQuickBooking();

  const { setHeading, Heading } = useContext(GlobalState);

  const checkPatientExists = async (value) => {
    if (value.dialercode === "91" && value.mobile.length !== 10) {
      //message.error("Enter valid mobile number");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Enter valid mobile number"
      });
      return;
    }
    if (value.dialercode === "971" && value.mobile.length !== 9) {
      //message.error("Enter valid mobile number");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Enter valid mobile number"
      });
      return;
    }

    setIsCheckBtnLoading(true);
    setIsPatientExist(null);
    setPatientData(null);
    setError({
      isError: false,
      message: [],
    });

    const { status, patientExist, data } = await CheckPatientExist(value);
    if (status && patientExist) {
      setIsPatientExist(true);
      setPatientData(data);
    } else if (status && !patientExist) {
      setIsPatientExist(false);
      setPatientData(null);
    } else {
      setError({
        isError: true,
        message: data,
      });
    }
    setIsCheckBtnLoading(false);
  };

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Instant booking");
    }
  }, [setHeading, Heading]);

  return (
    <div className="content">
      <div className="quickbooking-container">
        <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "30px" }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item>
              <PhoneInputComponent
                label="Mobile Number"
                idFor="mobile"
                placeholder="Enter mobile Number"
                type="practitioner"
                value={mobileNumber.mobile}
                country="in"
                error={error.isError}
                errorMessage={error.message}
                onChange={(value, country) => {
                  setMobileNumber({
                    mobile: value,
                    country: country.dialCode,
                  });
                }}
              />
            </Form.Item>
            <Button
              style={{ marginTop: "-10px", marginBottom: "20px", height: 40 }}
              type="primary"
              onClick={() =>{
                mobileNumber.mobile == "" && message.error("Please enter mobile number!");
                mobileNumber.mobile.length > 1 &&
                checkPatientExists({
                  dialercode: mobileNumber.country,
                  mobile: mobileNumber.mobile.substring(
                    mobileNumber.country.length
                  ),
                })
              }}
              loading={isChechBtnLoading}
            >
              Check Patient
            </Button>
          </Col>
        </Row>
        {isPatientExist !== null ? (
          isPatientExist && patientData ? (
            <PatientExistsContainer />
          ) : (
            <RegisterPatientContainer />
          )
        ) : null}
      </div>
    </div>
  );
};
export default QuickBookingContainer;
