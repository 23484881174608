import React, { useEffect, useRef } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import PractitionerRoutes from "./../Routes/PractitionerRoutes";
import { Route, Switch, useHistory } from "react-router-dom";
import PatientDetails from "./../views/pages/Practitioner/Patients/PatientDetails/index.js";
import ReportsContainer from "../views/pages/Practitioner/Appointments/Reports";
import Cookies from "universal-cookie";
import { DecryptString } from "Utils/Constants";
import ChangePassword from "views/pages/Auth/ChangePassword";

const Practitioner = (props) => {
  let history = useHistory();
  let cookies = new Cookies();
  const mainPanel = useRef();

  useEffect(() => {
    let cookieUserData = DecryptString(cookies.get("userdata", { path: "/" }));
    let UserData = cookieUserData && JSON.parse(cookieUserData);
    if (UserData?.type !== "practitioner") {
      history.push("/login");
    }
  }, [cookies, history]);

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      // setsidebarMini(false);
    } else {
      // setsidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/practitioner") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={PractitionerRoutes}
        bgColor="blue"
        activeColor="info"
      />
      <div className="main-panel" ref={mainPanel}>
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>
          {getRoutes(PractitionerRoutes)}
          <Route
            path="/practitioner/patient-details/:patientId"
            component={PatientDetails}
          />
          <Route
            path="/practitioner/reports/:appointment_id/:status"
            component={ReportsContainer}
          />
          <Route path="/practitioner/change-password" component={ChangePassword} />
        </Switch>
        {props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
          <Footer fluid />
        )}
      </div>
    </div>
  );
};
export default Practitioner;
