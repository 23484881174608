import React, { useEffect, useState, useContext } from "react";
import { Card, Button, message, Alert, DatePicker, Checkbox, Radio } from "antd";
import moment from "moment";
import Spinner from "components/Spinner";
import { updateLeaveDays } from "Utils/Services/Practitioner/LeaveDays";
import { modelUpdateLeaveDaysPayload } from "models/practitioner/leave-marking.api.models";
import { useLeaves } from "./useLeaves";
import LeaveAddCard from "./LeaveAddCard";
import { GlobalState } from "context-api";
import Calendar from "./Calendar";
import Slots from "./Slots";
import CalendarHeader from "./CalendarHeader";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";

const LeaveMarkingContainer = () => {
  const { setHeading, Heading } = useContext(GlobalState);
  const {
    slots,
    isLoading,
    myHospitals,
    selectedHospital,
    setSelectedHospital,
    leaveDays,
    date,
    setDate,
    fetchData,
    fetchWorkHoursSlot,
    isDateAreaExpanded,
    fetchLeaveDays,
    leaveDate,
    setLeaveDate,
    endLeaveDate,
    setEndLeaveDate,
  } = useLeaves();

  const [moreDays, setMoreDays] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [leaveMode, setLeaveMode] = useState("apply");

  const [error, setError] = useState({
    status: false,
    message: "",
  });

  const handleChangeLeaveDate = (date, dateString) => {
    setLeaveDate(dateString);
    setDate(dateString);
    fetchLeaveDays(selectedHospital,dateString);
    fetchWorkHoursSlot(selectedHospital,dateString);
  }

  const handleChangeEndLeaveDate = (date, dateString) => {
    setEndLeaveDate(dateString);
    setDate(dateString);
    fetchLeaveDays(selectedHospital,dateString);
    fetchWorkHoursSlot(selectedHospital,dateString);
  }
  
  const validateLeaveEntry = () => {
    let isValid = true;
    let error = "";
    leaveDays.forEach((leaveDay) => {
      if (leaveDay.from && leaveDay.to) {
        let from = moment(leaveDay.from);
        let to = moment(leaveDay.to);
        if (from.isAfter(to)) {
          isValid = false;
          error = "Leave days should not overlap";
        }
      }
    });
    if (!(slots || []).length) {
      isValid = false;
      error = "You don't have any slots today.";
    }
    return { isValid, error };
  };

  const onSubmit = async () => {
    setError({ status: false, message: "" });
    setIsBtnLoading(true);
    const { isValid, error } = validateLeaveEntry();
    if (!isValid) {
      //message.error(error || "");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: error || ""
    });
      setIsBtnLoading(false);
      return;
    }
    if(moreDays && moment(endLeaveDate).isSameOrBefore(leaveDate)){
      //message.error("End Date should not be same or before the Start Date.")
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "End Date should not be same or before the Start Date."
    });
      setIsBtnLoading(false);
      return;
    }
    const defaultSlots = [{ 
      "from": "00:00", 
      "to": "23:59"
    }];

    const clearAllSlots = [];
  
    let response = await updateLeaveDays(
      modelUpdateLeaveDaysPayload({
        leaveDate, 
        endLeaveDate: moreDays ? endLeaveDate : "",
        leaveDays: moreDays ? (leaveMode === "apply" ? defaultSlots : clearAllSlots) : leaveDays , 
        clinic_id: selectedHospital,
      })
    );
    if (!response?.status) {
      //message.error("Something went wrong");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Something went wrong"
    });
      setIsBtnLoading(false);
      setMoreDays(false);
      setLeaveMode("apply");
      return;
    }
    if (response?.data?.success) {
      setDate(leaveDate);
      fetchWorkHoursSlot(selectedHospital, leaveDate);
      //message.success("Leave days updated successfully");
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Leave days updated successfully"
      });
      window.location.reload();
    } else {
      setLeaveMode("apply");
      setError({
        status: true,
        message: response.data.message,
      });
    }
    setIsBtnLoading(false);
    setMoreDays(false);
    setLeaveMode("apply");
  };

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Out of clinic");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  return (
    <div className="content">
      <Card>
        <div className="reschedule-container">
          {/* Hospitals tab buttons */}
          <div className="buttons-container-times-marking">
            {myHospitals?.status &&
              myHospitals.data.map((item) => (
                <Button
                  key={item.id}
                  className={selectedHospital === item.id ? "tab" : ""}
                  onClick={() => {
                    setSelectedHospital(item.id);
                    fetchWorkHoursSlot(item.id, date);
                    fetchLeaveDays(item.id, date);
                  }}
                >
                  {item.label}
                </Button>
              ))}
          </div>
          {/* Hospital tab buttons ends here */}

          {/* Calendar and slots */}
          <CalendarHeader />
          {
            isDateAreaExpanded && (  
            <Card title="Select the date to see applied leave slots for the day" className="w-full mt-2">
              <div className="dates-container">
                <Calendar/>
              </div>
            </Card>      
          )}

          <Card title="Slots" className="w-full mt-2" style={{ minHeight: "20vh" }}>
            {isLoading ? <Spinner /> : <Slots />}
          </Card>
          {/* Calendar and slots ends here */}
          <Card className="w-full mt-2">
            <Radio.Group onChange={(e)=>setLeaveMode(e.target.value)} value={leaveMode}>
              <Radio value={"apply"}>Applying leave?</Radio>
              <Radio value={"remove"}>Removing leave?</Radio>
            </Radio.Group>
          </Card>
          <Card title={`Select dates ${leaveMode === "apply" ? "of" : "to remove"} Leave`}  className="w-full mt-2" style={{ minHeight: "20vh" }}>
            <div className="mb-2">
              <Checkbox 
                onChange={(e)=>{setMoreDays(e.target.checked)}}
                checked = {moreDays}
              >
                Do you want to {leaveMode === "apply" ? "take" : "remove"} leave for more than one day?
              </Checkbox>
            </div>
            
            <div className="my-2 flex flex-row align-center gap-1 items-center">
              {moreDays && <p>Start Date:</p>}
              <DatePicker
                onChange={handleChangeLeaveDate}
                allowClear={false}
                value={moment(leaveDate)}
                format={"YYYY-MM-DD"}
                disabledDate={current => current && current < moment(leaveDate).startOf('day')}
              />
            </div>
            {
              moreDays && 
              <div className="my-2 flex flex-row align-center  gap-1 items-center">
                <p>End Date:</p>
                <DatePicker
                    onChange={handleChangeEndLeaveDate}
                    allowClear={false}
                    value={moment(endLeaveDate)}
                    format={"YYYY-MM-DD"}
                    disabledDate={current => current && current < moment(leaveDate).startOf('day')}
                />
              </div>  
            }
          </Card>
          {/* Leave card starts here */}

          <Card
            // title="Leave"
            className="w-full mt-2"
            style={{ minHeight: "20vh" }}
          >
            {
              !moreDays ? (
                isLoading ? <Spinner /> : <>
                  <LeaveAddCard disable={moreDays} leaveMode={leaveMode}/>
                </>
              ):(<p>Total slots of the day will be selected by default for more days.</p>)
            }
            {error?.status && (
              <Alert
                message={error.message}
                type="error"
                closable
                style={{ marginTop: "1rem", width: "40%" }}
              />
            )}
          </Card>
          

          <Button
            type="primary"
            style={{ alignSelf: "flex-start" }}
            className="mt-2"
            size="large"
            onClick={() => onSubmit()}
            loading={isBtnLoading}
          >
            {leaveMode === "apply" ? "Mark" : "Unmark"} Leave
          </Button>
        </div>
      </Card>
      {/* Leave card ends here */}
    </div>
  );
};

export default LeaveMarkingContainer;