import { PostData } from "../../ApiCall";
import { LabURLParser } from "Utils/urls.utils";

// const DashboardCount = {
//   totalMyAppointments: 10,
//   totalMyPatients: 7,
//   totalMyLabPackages: 12,
//   paymentsTypes: {
//     online: 5,
//     offline: 10,
//   },
//   appointmentStatus: {
//     pending: 10,
//     upcoming: 20,
//     completed: 30,
//     other: 40,
//     cancelled: 50,
//   },
// };

// const DashboardChartData = {
//   graphdata_lab_packages_appointment: {
//     dateArr: [
//       "23-01-2022",
//       "24-01-2022",
//       "25-01-2022",
//       "26-01-2022",
//       "27-01-2022",
//       "28-01-2022",
//       "29-01-2022",
//       "30-01-2022",
//     ],
//     appointmentcountArr: [
//       [0, 0, 0, 0, 0, 0, 0, 0],
//       [0, 1, 2, 0, 0, 1, 1, 0],
//       [0, 2, 0, 1, 1, 1, 3, 0],
//     ],
//     nameArr: ["Test Package One", "Test Package Two", "Test Package Three"],
//   },
// };

export const getDashboardCounts = async () => {
  return await PostData(LabURLParser("/dashboardCounts"), {}).then(
    (response) => {
      if (response) {
        return response.data;
      }
    }
  );
};

export const getLabPackagesAppointmentsChartData = async (payload) => {
  // return await DashboardChartData;
  return await PostData(
    LabURLParser("/dashboardAppointmentsCharts"),
    payload
  ).then((response) => {
    if (response) {
      return response.data;
    }
  });
};
